import React, { Component, Fragment } from "react";
import checkPermissions from "../../utils/permissionManager";
import {
  Table,
  Tag,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  InputNumber,
  DatePicker,
  message,
  Popconfirm,
  Collapse,
  Switch,
  Row,
  Col,
  Divider,
  Card
} from "antd";
import { PlusCircleOutlined, DeleteOutlined, EditFilled, HomeOutlined, EditOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import {
  createAirConditioner,
  getAirConditionerDetails,
  UpdateAirConditioner,
  dataUpdateGeneralDetails,
} from "../../api/index";

import moment from "moment";
import AssetDelete from "../site_components/asset_delete";
import { checkServiceType } from "../../actions/service-type";

class AirConditioners extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

    this.state = {
      title: "",
      showModal: false,
      open: false,
      openEdit: false,
      tabaleLoading: false,
      actionView: false,
      acName: "",
      acType: "",
      indoor_acSerialNumber: "",
      indoor_unitModel: "",
      materialCodeOfTheAc: "",
      acTemperatureSetting: "",
      acCapacity: "",
      indoor_acwarrantyExpireDate: null,
      indoor_acInstalledDate: null,
      indoor_acLastMaintainanceDate: null,
      aclastServicedDate: null,
      acPowerConsumption: "",
      acModeOfOperation: "",
      selectedAssetId: null,
      AssetType: [],
      acId: "",
      AssetManagementPermission: false,
      openViewMoreDetails: false,
      selectedElement: "",

      selectedMakeId: null,
      makeType: null,
      selectedTypeId: null,
      AcType: null,
      materialCodeoftheAC: "",
      outdoor_unitModel: "",
      outdoor_acSerialNumber: "",
      outdoor_acwarrantyExpireDate: null,
      outdoor_acInstalledDate: null,
      outdoor_acLastMaintainanceDate: null,
      switchStatus: true,
      objectSet: {
        airTicketNumberOfPendingFaultyTicket: 0,
        airPlannedACs: false,
        airCoolingMethod: null,
      },
      objectDropdownAc: {
        airCoolingMethod: "",
        acModeOfOperation: "",
      },
    };
    this.requestFromClose = this.requestFromClose.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleAcType = this.handleAcType.bind(this);
    this.handleindoor_AcSerialNumber =
      this.handleindoor_AcSerialNumber.bind(this);
    this.handleActemperatureSetting =
      this.handleActemperatureSetting.bind(this);
    this.handleAcPowerConsumption = this.handleAcPowerConsumption.bind(this);
    this.handleAcModeOfOperation = this.handleAcModeOfOperation.bind(this);
    this.handleindoorUnitModel = this.handleindoorUnitModel.bind(this);
    this.handleOutdoorUnitModel = this.handleOutdoorUnitModel.bind(this);
    this.handleOutdoor_AcSerialNumber =
      this.handleOutdoor_AcSerialNumber.bind(this);
  }
  acUpdata(id) {
    console.log("dfdfd");
    console.log(id);
    this.getDataAirConditionerDetails(id);
    this.setState({ openEdit: true });
  }
  getDataAirConditionerDetails = async (id) => {
    var res = await getAirConditionerDetails(id);

    console.log(res);
    if (res.success) {
      this.setState({
        acEdit: res?.data,
        acName: res.data.name,
        // acType: res.data.type,
        materialCodeOfTheAc: res.data.materialCodeOfTheAc,
        acTemperatureSetting: res.data.temperatureSetting,
        acCapacity: res.data.capacity,
        acPowerConsumption: res.data.powerConsumption,
        acModeOfOperation: res.data.modeOfOperation,
        acId: res.data.id,

        indoor_acSerialNumber: res.data.serialNumber,
        outdoor_acSerialNumber: res.data.outdoorSerialNumber,
        outdoor_unitModel: res.data.outdoorModel,
        indoor_unitModel: res.data.indoorModel,
      });
      if (res.data?.make != null) {
        this.setState({
          selectedMakeId: res.data?.make?.id,
          makeType: res.data?.make,
        });
      } else {
        this.setState({
          selectedMakeId: null,
          makeType: null,
        });
      }
      if (res.data?.type != null) {
        this.setState({
          selectedTypeId: res.data?.type?.id,
          AcType: res.data?.type,
        });
      } else {
        this.setState({
          selectedTypeId: null,
          AcType: null,
        });
      }

      if (res.data?.lastServicedDate != null) {
        this.setState({
          aclastServicedDate: res.data?.lastServicedDate,
        });
      } else {
        this.setState({
          aclastServicedDate: "",
        });
      }
      if (res.data?.lastMaintainanceDate != null) {
        this.setState({
          indoor_acLastMaintainanceDate: res.data?.lastMaintainanceDate,
        });
      } else {
        this.setState({
          indoor_acLastMaintainanceDate: "",
        });
      }
      if (res.data?.installedDate != null) {
        this.setState({
          indoor_acInstalledDate: res.data?.installedDate,
        });
      } else {
        this.setState({
          indoor_acInstalledDate: "",
        });
      }
      if (res.data?.warrantyExpireDate != null) {
        this.setState({
          indoor_acwarrantyExpireDate: res.data?.warrantyExpireDate,
        });
      } else {
        this.setState({
          indoor_acwarrantyExpireDate: "",
        });
      }

      if (res.data?.outdoorLastMaintainanceDate != null) {
        this.setState({
          outdoor_acLastMaintainanceDate: res.data?.outdoorLastMaintainanceDate,
        });
      } else {
        this.setState({
          outdoor_acLastMaintainanceDate: "",
        });
      }
      if (res.data?.outdoorInstalledDate != null) {
        this.setState({
          outdoor_acInstalledDate: res.data?.outdoorInstalledDate,
        });
      } else {
        this.setState({
          outdoor_acInstalledDate: "",
        });
      }
      if (res.data?.outdoorWarrantyExpireDate != null) {
        this.setState({
          outdoor_acwarrantyExpireDate: res.data?.outdoorWarrantyExpireDate,
        });
      } else {
        this.setState({
          outdoor_acwarrantyExpireDate: "",
        });
      }
    }
  };
  handleOutdoorUnitModel(e) {
    console.log(e.target.value);
    this.setState({ outdoor_unitModel: e.target.value });
  }
  handleOutdoor_AcSerialNumber(e) {
    console.log(e.target.value);
    this.setState({ outdoor_acSerialNumber: e.target.value });
  }
  handleindoorUnitModel(e) {
    console.log(e.target.value);
    this.setState({ indoor_unitModel: e.target.value });
  }
  handleAcModeOfOperation(e) {
    this.setState({ acModeOfOperation: e });
  }
  handleAcPowerConsumption(e) {
    console.log(e);
    this.setState({ acPowerConsumption: e });
  }
  handleActemperatureSetting(e) {
    console.log(e);
    this.setState({ acTemperatureSetting: e });
  }
  handleindoor_AcSerialNumber(e) {
    console.log(e.target.value);
    this.setState({ indoor_acSerialNumber: e.target.value });
  }
  handleAcType(e) {
    console.log(e.target.value);
    this.setState({ acType: e.target.value });
  }
  handleName(e) {
    console.log(e.target.value);
    this.setState({ acName: e.target.value });
  }
  onDrawerClose = () => {
    console.log("sddsds");
    this.validator.hideMessages();
    this.formRef.current.resetFields();

    this.setState({
      open: false,
      openEdit: false,
      selectedAssetId: null,
      AssetType: [],
      acName: "",
      acType: "",
      indoor_acSerialNumber: "",
      materialCodeOfTheAc: "",
      acTemperatureSetting: "",
      acCapacity: "",
      indoor_acwarrantyExpireDate: null,
      indoor_acInstalledDate: null,
      indoor_acLastMaintainanceDate: null,
      aclastServicedDate: null,
      acPowerConsumption: "",
      acModeOfOperation: "",
      selectedMakeId: null,
      makeType: null,
      selectedTypeId: null,
      AcType: null,
      materialCodeoftheAC: "",
      outdoor_unitModel: "",
      outdoor_acSerialNumber: "",
      outdoor_acwarrantyExpireDate: null,
      outdoor_acInstalledDate: null,
      outdoor_acLastMaintainanceDate: null,
    });
  };
  requestFromClose() {
    console.log("dsdsd");
    this.setState({ showModal: true, open: true });
  }
  onChangeLastMaintainanceDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      indoor_acLastMaintainanceDate: dateString,
    });
  };
  onChangelastServicedDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      aclastServicedDate: dateString,
    });
  };
  onChangeInstalledDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      indoor_acInstalledDate: dateString,
    });
  };
  onChangeWarrantyExpireDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      indoor_acwarrantyExpireDate: dateString,
    });
  };
  onChangeAsset = (value) => {
    console.log("onChangeAsset");
    //console.log(value);
    this.setState({
      selectedAssetId: value,
      AssetType: {
        id: value,
      },
    });
  };
  onChangeACType = (value) => {
    console.log("onChangeMake");
    this.setState({
      selectedTypeId: value,
      AcType: {
        id: value,
      },
    });
  };
  onChangeMake = (value) => {
    console.log("onChangeMake");
    this.setState({
      selectedMakeId: value,
      makeType: {
        id: value,
      },
    });
  };
  onChangeOutdoorLastMaintainanceDate = (date, dateString) => {
    this.setState({
      outdoor_acLastMaintainanceDate: dateString,
    });
  };
  onChangeOutdoorInstalledDate = (date, dateString) => {
    this.setState({
      outdoor_acInstalledDate: dateString,
    });
  };
  onChangeOutdoorWarrantyExpireDate = (date, dateString) => {
    this.setState({
      outdoor_acwarrantyExpireDate: dateString,
    });
  };
  componentDidMount() {
    console.log("tytqwe14");
    console.log(this.props);
    console.log(this.props.details2);
    if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
      const { history } = this.props;

      history.push("/");
    } else {
      this.getSetPageData(
        JSON.parse(localStorage.getItem("serviceTypeSet")),
        38
      );
    }
    checkServiceType(38).then((data) =>
      this.setState(
        {
          AssetManagementPermission: data,
        },
        () => { }
      )
    );
    this.setState({
      objectSet: {
        airTicketNumberOfPendingFaultyTicket:
          this.props?.details?.access_technologies_data
            ?.airTicketNumberOfPendingFaultyTicket,
        airPlannedACs:
          this.props.details?.access_technologies_data?.airPlannedACs,
        airCoolingMethod:
          this.props.details?.access_technologies_data?.airCoolingMethod,
      },
    });
  }
  getSetPageData = (serviceTypeIs, Pageid) => {
    if (
      serviceTypeIs.serviceType.filter(
        (checkuser) => checkuser?.id == Pageid
      )[0] != null
    ) {
      console.log("fddf");
      this.setState({
        actionView: true,
      });
    }
  };
  AllDataSubmitUpdate = async () => {
    if (this.validator.allValid()) {
      var dataSet;
      dataSet = {
        id: this.state.acId,
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        name: this.state.acName != "" ? this.state.acName : "Air Con-",
        //"type": this.state.acType,
        temperatureSetting: this.state.acTemperatureSetting,
        lastServicedDate: this.state.aclastServicedDate,
        powerConsumption: this.state.acPowerConsumption,
        modeOfOperation: this.state.acModeOfOperation,

        make: this.state.makeType,
        type: this.state.AcType,
        indoorModel: this.state.indoor_unitModel,
        serialNumber: this.state.indoor_acSerialNumber,
        warrantyExpireDate: this.state.indoor_acwarrantyExpireDate,
        installedDate: this.state.indoor_acInstalledDate,
        lastMaintainanceDate: this.state.indoor_acLastMaintainanceDate,
        outdoorModel: this.state.outdoor_unitModel,
        outdoorSerialNumber: this.state.outdoor_acSerialNumber,
        outdoorWarrantyExpireDate: this.state.outdoor_acwarrantyExpireDate,
        outdoorInstalledDate: this.state.outdoor_acInstalledDate,
        outdoorLastMaintainanceDate: this.state.outdoor_acLastMaintainanceDate,
      };
      console.log(dataSet);
      var res = await UpdateAirConditioner(dataSet);
      console.log(res);
      if (res.success) {
        message.success("Successfully AC Edited!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,
          openEdit: false,

          selectedAssetId: null,
          AssetType: [],
          acName: "",
          acType: "",
          indoor_acSerialNumber: "",
          materialCodeOfTheAc: "",
          acTemperatureSetting: "",
          acCapacity: "",
          indoor_acwarrantyExpireDate: null,
          indoor_acInstalledDate: null,
          indoor_acLastMaintainanceDate: null,
          aclastServicedDate: null,
          acPowerConsumption: "",
          acModeOfOperation: "",
          selectedMakeId: null,
          makeType: null,
          selectedTypeId: null,
          AcType: null,
          materialCodeoftheAC: "",
          outdoor_unitModel: "",
          outdoor_acSerialNumber: "",
          outdoor_acwarrantyExpireDate: null,
          outdoor_acInstalledDate: null,
          outdoor_acLastMaintainanceDate: null,
        });
        this.props.parentMethod();
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  AllDataSubmit = async () => {
    if (this.validator.allValid()) {
      var dataSet;

      dataSet = {
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        name: this.state.acName != "" ? this.state.acName : "Air Con-",
        // "type": this.state.acType,
        temperatureSetting: this.state.acTemperatureSetting,
        powerConsumption: this.state.acPowerConsumption,
        modeOfOperation: this.state.acModeOfOperation,

        make: this.state.makeType,
        type: this.state.AcType,

        indoorModel: this.state.indoor_unitModel,
        serialNumber: this.state.indoor_acSerialNumber,
        warrantyExpireDate: this.state.indoor_acwarrantyExpireDate,
        installedDate: this.state.indoor_acInstalledDate,
        lastMaintainanceDate: this.state.indoor_acLastMaintainanceDate,
        outdoorModel: this.state.outdoor_unitModel,
        outdoorSerialNumber: this.state.outdoor_acSerialNumber,
        outdoorWarrantyExpireDate: this.state.outdoor_acwarrantyExpireDate,
        outdoorInstalledDate: this.state.outdoor_acInstalledDate,
        outdoorLastMaintainanceDate: this.state.outdoor_acLastMaintainanceDate,
      };
      console.log(dataSet);
      var res = await createAirConditioner(dataSet);
      if (res.success) {
        message.success("Successfully AC Added!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,

          selectedAssetId: null,
          AssetType: [],
          acName: "",
          acType: "",
          indoor_acSerialNumber: "",
          materialCodeOfTheAc: "",
          acTemperatureSetting: "",
          acCapacity: "",
          indoor_acwarrantyExpireDate: null,
          indoor_acInstalledDate: null,
          indoor_acLastMaintainanceDate: null,
          aclastServicedDate: null,
          acPowerConsumption: "",
          acModeOfOperation: "",
          selectedMakeId: null,
          makeType: null,
          selectedTypeId: null,
          AcType: null,
          materialCodeoftheAC: "",
          outdoor_unitModel: "",
          outdoor_acSerialNumber: "",
          outdoor_acwarrantyExpireDate: null,
          outdoor_acInstalledDate: null,
          outdoor_acLastMaintainanceDate: null,
        });
        this.props.parentMethod();
        // const { history } = this.props;
        // history.push("/site");
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  getDataDetailsForDelete = async (id) => {
    var res = await getAirConditionerDetails(id);
    console.log(res);
    if (res.success) {
      var respons = AssetDelete.Action(
        res.data,
        this.props?.detailsSite?.site_details.id,
        UpdateAirConditioner
      );
      respons.then((value) => {
        if (value == 1) {
          message.success("Successfully Delete!");
          this.props.parentMethod();
        } else {
          message.warning(" Something went wrong!");
        }
      });
    }
  };
  submitForm = async (item) => {
    this.getDataDetailsForDelete(item);
  };

  viewMoreDetails(element) {
    this.setState({
      showModal2: true,
      openViewMoreDetails: true,
      selectedElement: element,
    });
  }

  onViewMoreDetailsDrawerClose = () => {
    this.setState({
      showModal2: false,
      openViewMoreDetails: false,
    });
  };

  validatedTextLable = (field) => {
    return field !== null && field !== "" ? (
      <span class="badge badge-outline-secondary text-muted">{field}</span>
    ) : (
      <span class="badge text-danger">Currently Not Set</span>
    );
  };
  handlairTicketNumberOfPendingFaultyTicket = (e) => {
    this.setState(
      {
        objectSet: {
          ...this.state.objectSet,
          airTicketNumberOfPendingFaultyTicket: e,
        },
      },
      () => {
        console.log(this.state.objectSet);
      }
    );
  };
  handlairCoolingMethod = (e) => {
    this.setState(
      {
        objectSet: {
          ...this.state.objectSet,
          airCoolingMethod: e.target.value,
        },
      },
      () => {
        console.log(this.state.objectSet);
      }
    );
  };
  switchTo2() {
    this.state.objectSet["airPlannedACs"] =
      !this.state.objectSet["airPlannedACs"];
  }
  dataUpdate() {
    console.log("ssss");
    this.setState({
      switchStatus: false,
    });
  }
  close = async () => {
    this.props.parentMethod();
    this.setState({
      objectSet: {
        airTicketNumberOfPendingFaultyTicket:
          this.props?.details?.access_technologies_data
            ?.airTicketNumberOfPendingFaultyTicket,
        airPlannedACs:
          this.props.details?.access_technologies_data?.airPlannedACs,
        airCoolingMethod:
          this.props.details?.access_technologies_data?.airCoolingMethod,
      },
    });
    this.setState({
      switchStatus: true,
    });
  };
  dataSetToUpdate = async () => {
    console.log(this.state.objectSet);
    console.log(this.props.detailsupdateGeneralDetails.updateGeneralDetails);
    let remoteJob = {
      ...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
      ...this.state.objectSet,
    };
    const formData = new FormData();
    formData.append("id", this.props?.details?.access_technologies_data?.id);
    formData.append("object", JSON.stringify(remoteJob));

    var res = await dataUpdateGeneralDetails(formData);

    if (res.success) {
      message.success("Successfully Update!");
      this.props.parentMethod();
      this.setState({
        switchStatus: true,
      });
    } else {
      message.warning(" Something went wrong!");
    }
  };

  render() {
    const { Option } = Select;
    const { Panel } = Collapse;
    const _ac = this.state.selectedElement;
    console.log("___AC");
    console.log(_ac);
    const columns2 = [
      {
        title: "Name",
        content:
          _ac !== "" ? (
            <Tag color="blue" style={{ margin: "0px" }}>
              <span>{_ac.name}</span>
            </Tag>
          ) : (
            <></>
          ),
        details: [],
      },
      {
        title: "Type",
        content: this.validatedTextLable(_ac?.type?.name),
        details: [],
      },
      {
        title: "Make",
        content: (
          <span class="badge badge-outline-info badge-pill">
            <i class="fas fa-hdd" style={{ paddingRight: "5px" }} />
            {_ac?.make?.name}
          </span>
        ),
        details: [
          {
            title: "Material Code of the AC",
            content: this.validatedTextLable(_ac.make?.materialCodeOfTheAc),
          },
          {
            title: "Capacity (Btu/hr)",
            content: this.validatedTextLable(_ac.make?.capacity),
            details: [],
          },
        ],
      },
      {
        title: "Power Consumption (A)",
        content: this.validatedTextLable(_ac.powerConsumption),
        details: [],
      },
      {
        title: "AC Temperature Setting ©",
        content: this.validatedTextLable(_ac.temperatureSetting),
        details: [],
      },
      {
        title: "Mode Of Operation",
        content: this.validatedTextLable(_ac.modeOfOperation),
        details: [],
      },
      {
        title: "Indoor Unit - Model",
        content: this.validatedTextLable(_ac.indoorModel),
        details: [],
      },
      {
        title: "Indoor Unit - Serial Number",
        content: this.validatedTextLable(_ac.serialNumber),
        details: [],
      },
      {
        title: "Indoor Unit - Warranty Expire Date",
        content: this.validatedTextLable(_ac.warrantyExpireDate),
        details: [],
      },
      {
        title: "Indoor Unit - Installed Date",
        content: this.validatedTextLable(_ac.installedDate),
        details: [],
      },
      {
        title: "Indoor Unit - Last Maintainance Date",
        content: this.validatedTextLable(_ac.lastMaintainanceDate),
        details: [],
      },
      {
        title: "Outdoor Unit - Model",
        content: this.validatedTextLable(_ac.outdoorModel),
        details: [],
      },
      {
        title: "Outdoor Unit - Serial Number",
        content: this.validatedTextLable(_ac.outdoorSerialNumber),
        details: [],
      },
      {
        title: "Outdoor Unit - Warranty Expire Date",
        content: this.validatedTextLable(_ac.outdoorWarrantyExpireDate),
        details: [],
      },
      {
        title: "Outdoor Unit - Installed Date",
        content: this.validatedTextLable(_ac.outdoorInstalledDate),
        details: [],
      },
      {
        title: "Outdoor Unit - Last Maintainance Date",
        content: this.validatedTextLable(_ac.outdoorLastMaintainanceDate),
        details: [],
      },
    ];

    return (
      <Fragment>
        <div className="row">
          <div class="col-lg-12">
            <div class="text-end">
              <ul class="list-inline">
                <li class="list-inline-item">

                </li>
              </ul>
            </div>
          </div>

          <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
            <div className="d-flex justify-content-end align-items-center" style={{ marginBottom: "10px", marginRight: "10px" }}>
              {this.state.switchStatus
                ? 
                checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                <Fragment>
                  <button type="button" class="btn btn-soft-primary btn-icon-square-sm"
                    style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                    onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline" style={{ color: 'white' }}></i></button>
                  {this.state.actionView ? (
                    <Button
                      type="button"
                      class="btn btn-soft-primary btn-sm"
                      style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginLeft: 10 }}
                      onClick={this.requestFromClose}
                      icon={<PlusCircleOutlined style={{ color: 'white' }} />}>
                      <span className="textStyles-small" style={{ color: 'white' }}>New Asset</span>
                    </Button>
                  ) : (
                    <></>
                  )}
                </Fragment>): <></>
                : <div class="d-flex justify-content-end align-items-center">
                  <button type="button" class="btn btn-soft-secondary  textStyles-small" onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
                  <button type="button" class="btn btn-soft-primary  textStyles-small" onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                </div>
              }
            </div>
            <ul class="list-group">
              <Row span={24}>
                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        <span className="textStyles-small">
                          No of Air Conditioners
                        </span>
                      </div>
                      {this.props.details?.access_technologies_data
                        ?.noOfAirConditioners != null ? (
                        <span class="badge badge-outline-success textStyles-small">
                          {
                            this.props.details2.air_conditioners.length
                          }
                        </span>
                      ) : (
                        <span class="badge badge-outline-warning textStyles-small">
                          Currently Not Set
                        </span>
                      )}
                    </li>
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                      <div>
                        <span className="textStyles-small">
                          Cooling Method
                        </span>
                      </div>
                      <div>
                        {this.state.switchStatus.toString() == "true" ? (
                          this.props?.details?.access_technologies_data
                            ?.airCoolingMethod ? (
                            <span class="badge badge-outline-success textStyles-small">
                              {
                                this.props?.details
                                  ?.access_technologies_data
                                  ?.airCoolingMethod
                              }
                            </span>
                          ) : (
                            <span class="badge badge-outline-danger textStyles-small">
                              Currently Not Set
                            </span>
                          )
                        ) : (
                          <Select
                            defaultValue={
                              this.props?.details?.access_technologies_data
                                ?.airCoolingMethod
                            }
                            style={{ width: '150px' }}
                            bordered={false}
                            className='borderedSelect'
                            onChange={(e) => {
                              this.state.objectSet.airCoolingMethod = e;
                            }}
                          >
                            {this.props.dropdownAC["objectDropdownAc"][
                              "airCoolingMethod"
                            ].map((x) => {
                              return <Option value={x}>{x}</Option>;
                            })}
                          </Select>
                        )}
                      </div>
                    </li>
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                      <div>
                        <span className="textStyles-small">
                          Ticket Number Of Pending Faulty Ticket
                        </span>
                      </div>
                      {this.state.switchStatus ? (
                        <>
                          {this.props?.details?.access_technologies_data
                            ?.airTicketNumberOfPendingFaultyTicket !=
                            null ? (
                            <span class="badge badge-outline-success textStyles-small">
                              {
                                this.props?.details
                                  ?.access_technologies_data
                                  ?.airTicketNumberOfPendingFaultyTicket
                              }
                            </span>
                          ) : (
                            <span class="badge badge-outline-warning textStyles-small">
                              Currently Not Set
                            </span>
                          )}
                        </>
                      ) : (
                        <InputNumber
                          min={0}
                          style={{ width: '150px' }}
                          className='borderedSelect'
                          value={
                            this.state.objectSet
                              ?.airTicketNumberOfPendingFaultyTicket
                          }
                          id="airCoolingMethod"
                          onChange={
                            this.handlairTicketNumberOfPendingFaultyTicket
                          }
                        />
                      )}
                    </li>
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        <span className="textStyles-small">
                          Planned AC's
                        </span>
                      </div>
                      <div>
                        {this.state.switchStatus.toString() === "true" ? (
                          this.props?.details?.access_technologies_data
                            ?.airPlannedACs != null ? (
                            this.props?.details?.access_technologies_data?.airPlannedACs.toLowerCase() ===
                              "yes" ? (
                              <span className="badge badge-outline-success textStyles-small">
                                Yes
                              </span>
                            ) : (
                              <span className="badge badge-outline-warning textStyles-small">
                                No
                              </span>
                            )
                          ) : (
                            <span className="badge badge-outline-danger textStyles-small">
                              Currently Not Set
                            </span>
                          )
                        ) : (
                          <Select
                            defaultValue={
                              this.props?.details?.access_technologies_data
                                ?.airPlannedACs
                            }
                            style={{ width: '150px' }}
                            bordered={false}
                            className='borderedSelect'
                            onChange={(e) => {
                              this.setState({
                                objectSet: {
                                  ...this.state.objectSet,
                                  airPlannedACs: e,
                                },
                              });
                            }}
                          >
                            <Option value={"Yes"}>Yes</Option>
                            <Option value={"No"}>No</Option>
                          </Select>
                        )}
                      </div>
                    </li>
                  </div>
                </Col>
              </Row>
            </ul>
          </div>


          <Row span={24} style={{ marginTop: 30 }}>
            {this.props.details2.air_conditioners.map((element, index) => (
              <Col span={8} key={index}>
                <Card
                  bordered={false}
                  style={{
                    width: '98%',
                    borderRadius: '10px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                    marginTop: 10
                  }}
                >
                  <div style={{ display: 'flex', marginBottom: 16, backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '10px' }}>
                    <div>
                      <HomeOutlined style={{ marginLeft: '3px', marginRight: '3px' }} /> &rarr;
                      <span style={{ marginLeft: '3px', marginRight: '3px', fontWeight: 'bold' }} className="textStyles-small">
                        {this.props?.detailsSite?.site_details.siteName}
                      </span>
                      &rarr;
                      <span style={{ marginLeft: '3px', color: '#BA183A', fontWeight: 'bold' }} className="textStyles-small">{element.name}</span>
                    </div>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small" style={{ fontSize: 12 }}>Power Consumption (A)</span>
                    <Tag color="default" style={{ float: 'right', borderRadius: 5 }}> <span className="textStyles-small">{element.powerConsumption} A</span></Tag>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small">AC Temperature Setting</span>
                    <Tag color="purple" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                      <span className="textStyles-small" style={{ fontSize: 11 }}>
                        {element.temperatureSetting} °C
                      </span></Tag>
                  </div>
                  <Divider style={{ marginTop: '3px' }} />
                  <Button
                    type="primary"
                    style={{ marginRight: 8, borderRadius: '5px' }}
                    size='small'
                    onClick={() => this.viewMoreDetails(element)}>
                    <span className="textStyles-small" style={{ fontSize: 10 }}> <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details</span>
                  </Button>
                  {checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                  <Button
                    icon={<EditOutlined style={{ color: 'white' }} />}
                    style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                    onClick={() => this.acUpdata(element.id)}
                    size='small'
                  />) : ('')}
                  <Popconfirm
                    title={<span className='textStyles-small' style={{ fontSize: 14 }}>Are you sure to delete this Asset?</span>}
                    description={<span className="textStyles-small">Are you sure to delete this Asset?</span>}
                    onConfirm={() => this.submitForm(element.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    {checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                        <Button
                          icon={<DeleteOutlined style={{ color: 'white' }} />}
                          danger
                          size='small'
                          style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#BA183A', borderColor: '#BA183A' }}
                        />
                      )
                      : ('')}
                  </Popconfirm>

                </Card>
              </Col>
            ))}
          </Row>

          <Drawer
            maskClosable={false}
            title={<span className="textStyles-small" style={{ fontSize: 14 }}>Air Conditioner Details</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onViewMoreDetailsDrawerClose()}
            visible={this.state.openViewMoreDetails}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <Collapse
              style={{ alignContent: "center", borderColor: "white" }}
            >
              {columns2.map((item, index) => (
                <Panel
                  style={{
                    borderColor: index % 2 === 0 ? "#f8f9fa" : "white",
                    backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white"
                  }}
                  header={
                    <li className="d-flex justify-content-between align-items-center"
                      style={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white" }}>
                      <div>
                        <span className="textStyles-small">{item.title}</span>
                      </div>
                      <span className="textStyles-small">{item.content}</span>
                    </li>
                  }
                  key={item.title}
                  showArrow={item.details.length > 0}
                  collapsible={item.details.length > 0 ? "" : "icon"}
                >
                  {item.details.map((detail) => (
                    <li
                      className="d-flex justify-content-between align-items-center me-2"
                      style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                      key={detail.title}
                    >
                      <div>
                        <span className='textStyles-small'>{detail.title}</span>
                      </div>
                      <span className="textStyles-small">{detail.content}</span>
                    </li>
                  ))}
                </Panel>
              ))}
            </Collapse>
          </Drawer>

          <Drawer
            maskClosable={false}
            title={<span className="textStyles-small" style={{ fontSize: 14 }}>New Air Conditioner</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.open}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card" style={{ borderColor: 'white' }}>
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Name <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <Input
                              className='borderedSelect'
                              style={{ height: '34px' }}
                              id="Name"
                              defaultValue={"Air Con-"}
                              onChange={this.handleName}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Name",
                                this.state.acName,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              AC Type <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="Make"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              onChange={this.onChangeACType}
                              className='borderedSelect'
                              bordered={false}
                              style={{ width: "100%" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              allowClear
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.acMasterData?.acMasterData?.acTypeList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item.id}>{item.name}</Option>
                                  );
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "AC Type",
                                this.state.selectedTypeId,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Make <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="Make"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              className='borderedSelect'
                              bordered={false}
                              onChange={this.onChangeMake}
                              style={{ width: "100%" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              allowClear
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.acMasterData?.acMasterData?.acMakeList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item.id}>{item.name}</Option>
                                  );
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Make",
                                this.state.selectedMakeId,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Power Consumption (A){" "}
                            </span>
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item>
                            <InputNumber
                              className='borderedSelect'
                              style={{ width: "100%", height: "34px" }}
                              id="ac_Capacity"
                              onChange={this.handleAcPowerConsumption}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Power Consumption (A)",
                                this.state.acPowerConsumption,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              AC Temperature Setting ©{" "}
                            </span>
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <InputNumber
                              style={{ width: "100%", height: "34px" }}
                              className='borderedSelect'
                              id="actemperatureSetting"
                              onChange={this.handleActemperatureSetting}
                            />
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "AC Temperature Setting © ",
                                this.state.acTemperatureSetting,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Mode Of Operation <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <div>
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handleAcModeOfOperation(e);
                                }}
                              >
                                {this.props.dropdownAC["objectDropdownAc"][
                                  "acModeOfOperation"
                                ].map((x) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Mode Of Operation",
                                this.state.acModeOfOperation,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Indoor Unit - Model{" "}
                            </span>
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item>
                            <Input
                              id="Indoor Unit - Model"
                              className='borderedSelect'
                              style={{ height: '34px' }}
                              onChange={this.handleindoorUnitModel}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Indoor Unit - Model",
                                this.state.indoor_unitModel,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Indoor Unit - Serial Number{" "}
                            </span>
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item>
                            <Input
                              className='borderedSelect'
                              style={{ height: '34px' }}
                              id="Indoor Unit - Serial Number"
                              onChange={this.handleindoor_AcSerialNumber}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Indoor Unit - Serial Number",
                                this.state.indoor_acSerialNumber,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Indoor Unit - Warranty Expire Date
                            </span>
                          </label>
                          <Form.Item required name=" Warranty Expired Date2">
                            <DatePicker
                              className='borderedSelect'
                              style={{ width: "100%", height: "34px" }}
                              onChange={this.onChangeWarrantyExpireDate}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Indoor Unit - Installed Date
                            </span>
                          </label>
                          <Form.Item>
                            <DatePicker
                              style={{ width: "100%", height: "34px" }}
                              className='borderedSelect'
                              onChange={this.onChangeInstalledDate}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Indoor Unit - Last Maintainance Date
                            </span>
                          </label>
                          <Form.Item>
                            <DatePicker
                              style={{ width: "100%", height: "34px" }}
                              className='borderedSelect'
                              onChange={this.onChangeLastMaintainanceDate}
                            />
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              <span className="textStyles-small">
                                Outdoor Unit - Model{" "}
                                <x style={{ color: "red" }}>*</x>
                              </span>
                            </span>
                          </label>
                          <Form.Item>
                            <Input
                              id="Outdoor Unit - Model"
                              onChange={this.handleOutdoorUnitModel}
                              className='borderedSelect'
                              style={{ height: '34px' }}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Outdoor Unit - Model",
                                this.state.outdoor_unitModel,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Outdoor Unit - Serial Number{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <Input
                              id="Outdoor Unit - Serial Number"
                              onChange={this.handleOutdoor_AcSerialNumber}
                              className='borderedSelect'
                              style={{ height: '34px' }}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Outdoor Unit - Serial Number",
                                this.state.outdoor_acSerialNumber,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Outdoor Unit - Warranty Expire Date
                            </span>
                          </label>
                          <Form.Item
                            required
                            name=" Outdoor Unit - Warranty Expire Date"
                          >
                            <DatePicker
                              style={{ width: "100%", height: "34px" }}
                              className='borderedSelect'
                              onChange={this.onChangeOutdoorWarrantyExpireDate}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Outdoor Unit - Installed Date
                          </label>
                          <Form.Item>
                            <DatePicker
                              style={{ width: "100%", height: "34px" }}
                              className='borderedSelect'
                              onChange={this.onChangeOutdoorInstalledDate}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Outdoor Unit - Last Maintainance Date
                            </span>
                          </label>
                          <Form.Item>
                            <DatePicker
                              style={{ width: "100%", height: "34px" }}
                              className='borderedSelect'
                              onChange={
                                this.onChangeOutdoorLastMaintainanceDate
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        <Button
                          style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}
                          className="btn btn-primary"
                          onClick={() => this.AllDataSubmit()}
                        >
                          <span className="textStyles-small" style={{ color: 'white' }}>
                            Add This Asset
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>

          <Drawer
            maskClosable={false}
            title={<span className="textStyles-small" style={{ fontSize: 14 }}>Edit Air Conditioner</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.openEdit}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card" style={{ borderColor: 'white' }}>
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Name <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <Input
                              id="Name"
                              className="borderedSelect"
                              style={{ height: '34px' }}
                              value={this.state.acName}
                              onChange={this.handleName}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "AC name",
                                this.state.acName,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              AC Type <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          {this.state.selectedTypeId != null ? (
                            <Form.Item
                              required
                              name="Make"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                defaultValue={this.state.selectedTypeId}
                                onChange={this.onChangeACType}
                                className="borderedSelect"
                                bordered={false}
                                style={{ width: "100%" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.acMasterData?.acMasterData?.acTypeList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                {this.validator.message(
                                  "Make",
                                  this.state.selectedTypeId,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                          {this.state.selectedTypeId == null ? (
                            <Form.Item
                              required
                              name="Make"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                className="borderedSelect"
                                bordered={false}
                                onChange={this.onChangeACType}
                                style={{ width: "100%" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.acMasterData?.acMasterData?.acTypeList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                {this.validator.message(
                                  "Make",
                                  this.state.selectedTypeId,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Make <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          {this.state.selectedMakeId != null ? (
                            <Form.Item
                              required
                              name="Make"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                defaultValue={this.state.selectedMakeId}
                                onChange={this.onChangeMake}
                                style={{ width: "100%" }}
                                className="borderedSelect"
                                bordered={false}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.acMasterData?.acMasterData?.acMakeList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                {this.validator.message(
                                  "Make",
                                  this.state.selectedMakeId,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                          {this.state.selectedMakeId == null ? (
                            <Form.Item
                              required
                              name="Make"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                onChange={this.onChangeMake}
                                style={{ width: "100%" }}
                                bordered={false}
                                className="borderedSelect"
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.acMasterData?.acMasterData?.acMakeList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                {this.validator.message(
                                  "Make",
                                  this.state.selectedMakeId,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Power Consumption (A){" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <InputNumber
                              className="borderedSelect"
                              style={{ width: "100%", height: 34 }}
                              value={this.state.acPowerConsumption}
                              id="ac_Capacity"
                              onChange={this.handleAcPowerConsumption}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Power Consumption (A)",
                                this.state.acPowerConsumption,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              AC Temperature Setting ©{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <InputNumber
                              className="borderedSelect"
                              style={{ width: "100%", height: 34 }}
                              id="actemperatureSetting"
                              value={this.state.acTemperatureSetting}
                              onChange={this.handleActemperatureSetting}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "AC Temperature Setting ©",
                                this.state.acTemperatureSetting,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Mode Of Operation <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <div>
                              <Select
                                className="borderedSelect"
                                style={{ width: "100%", height: 34 }}
                                bordered={false}
                                value={this.state.acModeOfOperation}
                                onChange={(e) => {
                                  this.handleAcModeOfOperation(e);
                                }}
                              >
                                {this.props.dropdownAC["objectDropdownAc"][
                                  "acModeOfOperation"
                                ].map((x) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                " Mode Of Operation",
                                this.state.acModeOfOperation,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Indoor Unit - Model{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <Input
                              id="Indoor Unit - Model"
                              value={this.state.indoor_unitModel}
                              onChange={this.handleindoorUnitModel}
                              className="borderedSelect"
                              style={{ width: "100%", height: 34 }}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                " Indoor Unit - Model",
                                this.state.indoor_unitModel,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Indoor Unit - Serial Number{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <Input
                              id="Indoor Unit - Serial Number"
                              value={this.state.indoor_acSerialNumber}
                              onChange={this.handleindoor_AcSerialNumber}
                              className="borderedSelect"
                              style={{ width: "100%", height: 34 }}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                " Indoor Unit - Serial Number",
                                this.state.indoor_acSerialNumber,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Indoor Unit - Warranty Expire Date
                            </span>
                          </label>
                          {this.state.indoor_acwarrantyExpireDate != null ? (
                            <Form.Item required name=" Warranty Expired Date2">
                              <DatePicker
                                defaultValue={
                                  this.state.indoor_acwarrantyExpireDate != ""
                                    ? moment(
                                      this.state.indoor_acwarrantyExpireDate
                                    )
                                    : ""
                                }
                                className="borderedSelect"
                                style={{ width: "100%", height: 34 }}
                                onChange={this.onChangeWarrantyExpireDate}
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Indoor Unit - Installed Date
                            </span>
                          </label>
                          {this.state.indoor_acInstalledDate != null ? (
                            <Form.Item>
                              <DatePicker
                                defaultValue={
                                  this.state.indoor_acInstalledDate != ""
                                    ? moment(this.state.indoor_acInstalledDate)
                                    : ""
                                }
                                className="borderedSelect"
                                style={{ width: "100%", height: 34 }}
                                onChange={this.onChangeInstalledDate}
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Indoor Unit - Last Maintainance Date
                            </span>
                          </label>
                          {this.state.indoor_acLastMaintainanceDate != null ? (
                            <Form.Item>
                              <DatePicker
                                defaultValue={
                                  this.state.indoor_acLastMaintainanceDate != ""
                                    ? moment(
                                      this.state.indoor_acLastMaintainanceDate
                                    )
                                    : ""
                                }
                                className="borderedSelect"
                                style={{ width: "100%", height: 34 }}
                                onChange={this.onChangeLastMaintainanceDate}
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Outdoor Unit - Model{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <Input
                              id="Outdoor Unit - Model"
                              value={this.state.outdoor_unitModel}
                              onChange={this.handleOutdoorUnitModel}
                              className="borderedSelect"
                              style={{ width: "100%", height: 34 }}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Outdoor Unit - Model",
                                this.state.outdoor_unitModel,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Outdoor Unit - Serial Number{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <Input
                              id="Outdoor Unit - Serial Number"
                              value={this.state.outdoor_acSerialNumber}
                              className="borderedSelect"
                              style={{ width: "100%", height: 34 }}
                              onChange={this.handleOutdoor_AcSerialNumber}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Outdoor Unit - Serial Number",
                                this.state.outdoor_acSerialNumber,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Outdoor Unit - Warranty Expire Date
                            </span>
                          </label>
                          {this.state.outdoor_acwarrantyExpireDate != null ? (
                            <Form.Item
                              required
                              name=" Outdoor Unit - Warranty Expire Date"
                            >
                              <DatePicker
                                defaultValue={
                                  this.state.outdoor_acwarrantyExpireDate != ""
                                    ? moment(
                                      this.state.outdoor_acwarrantyExpireDate
                                    )
                                    : ""
                                }
                                className="borderedSelect"
                                style={{ width: "100%", height: 34 }}
                                onChange={
                                  this.onChangeOutdoorWarrantyExpireDate
                                }
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Outdoor Unit - Installed Date
                            </span>
                          </label>
                          {this.state.outdoor_acInstalledDate != null ? (
                            <Form.Item>
                              <DatePicker
                                defaultValue={
                                  this.state.outdoor_acInstalledDate != ""
                                    ? moment(this.state.outdoor_acInstalledDate)
                                    : ""
                                }
                                className="borderedSelect"
                                style={{ width: "100%", height: 34 }}
                                onChange={this.onChangeOutdoorInstalledDate}
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Outdoor Unit - Last Maintainance Date
                            </span>
                          </label>
                          {this.state.outdoor_acLastMaintainanceDate != null ? (
                            <Form.Item>
                              <DatePicker
                                defaultValue={
                                  this.state.outdoor_acLastMaintainanceDate !=
                                    ""
                                    ? moment(
                                      this.state
                                        .outdoor_acLastMaintainanceDate
                                    )
                                    : ""
                                }
                                className="borderedSelect"
                                style={{ width: "100%", height: 34 }}
                                onChange={
                                  this.onChangeOutdoorLastMaintainanceDate
                                }
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        <Button
                          className="btn btn-primary"
                          onClick={() => this.AllDataSubmitUpdate()}
                          style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}
                        >
                          <span className="textStyles-small" style={{ color: 'white' }}>
                            Update This Asset
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </Fragment>
    );
  }
}
export default AirConditioners;
