import React, { Component, Fragment } from 'react';
import { HotKeys } from "react-hotkeys";
import { siteViewNew, siteViewNewhierarchy } from '../../api/api.config';
import {
  getFilterDetails,
  getAllRegionRelatedData,
  getAllSitesListByID
} from "../../api/index";
import GoogleMapReact from 'google-map-react';
import $ from 'jquery';
import { Popover, Row, Col, Button, Tag, Divider, Select, Form, Badge, Modal, Collapse, Spin, Table, message } from 'antd';
import { FilterOutlined, PlusOutlined, MinusOutlined, RollbackOutlined, ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import '../master-data/style.css';
import SiteLocation from './icons/siteLocation';
import axios from 'axios';
const { Option } = Select;
const { Panel } = Collapse;

const data = [];

class MapsView extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      sitesList: [],
      centerLat: 7.3,
      centerLng: 80.6,
      zoom: 7,
      clusters: data,
      level: 'province',
      previousClusters: [],
      popupInfo: null,
      staticalData: [],
      allSiteList: [],
      siteOwnerList: [],
      towerCategoryList: [],
      towerTypeList: [],
      siteTypeList: [],
      allRegions: [],
      allDepots: [],
      openFilter: false,
      loadFullSiteList: [],
      loading: false,
      filterBtnClick: false,
      showTable: false,
      showNav: true,
      selectedRegionID: [],
      selectedDepotID: [],
      mapQueryFilter: {
        siteIds: [],
        regionIds: [],
        depotIds: [],
        status: null,
        siteOwnerIds: [],
        siteTypeIds: [],
        towerCategoryIds: [],
        towerTypeIds: []
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        totalPages: 0,
        lastSeenId: 0,
        hasNextPage: true
      },
      isActiveFilter: true,
      selectedMapRegionCluster: {
        regionId: null,
        depots: []
      },
      selectedMapDepotCluster: {
        depotId: null,
        sites: []
      },
      iconCategory: 0,
      showReferences: false,
      newSiteList: [],
      userLocation : {
        lat: 0,
        lng: 0
      },
      singleView: false
    };
  }

  success = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    this.setState({
      userLocation: {
        lat: latitude,
        lng: longitude
      }
    });
  }
  
  error = () => {
    message.error("Unable to retrieve your location");
  }
  
  async componentDidMount() {
    this.getMapPreference();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.success, this.error);
    } else {
      console.log("Geolocation not supported");
    }
    
    try {
      const transportedData = localStorage.getItem('mapQuery');
      if (transportedData) {
        const data = JSON.parse(transportedData);
        console.log(data);
        this.setState({
          mapQueryFilter: {
            siteIds: data?.siteIds || [],
            regionIds: data?.regionIds || [],
            depotIds: data?.depotIds || [],
            status: data?.status || null,
            siteOwnerIds: data?.siteOwnerIds || [],
            siteTypeIds: data?.siteTypeIds || [],
            towerCategoryIds: data?.towerCategoryIds || [],
            towerTypeIds: data?.towerTypeIds || []
          },
          singleView: data?.singleView || false,
          level: data?.singleView ? 'city' : 'province',
        });
        await this.fetchSiteListFilter(
          data?.siteIds || [],
          data?.regionIds || [],
          data?.depotIds || [],
          data?.status || null,
          data?.siteOwnerIds || [],
          data?.siteTypeIds || [],
          data?.towerCategoryIds || [],
          data?.towerTypeIds || [],
          0,
          true
        );
        data?.singleView && this.fetchSiteBySiteID(data?.siteIds[0], data?.depotIds[0]);
      } else {
        await this.fetchSiteListFilter([], [], [], null, [], [], [], [], 0, true);
      }
      await Promise.all([
        this.fetchNewSiteList(),
        this.fetchTowerCategoryList(),
        this.fetchTowerTypeList(),
        this.fetchSiteTypeList(),
        this.fetchSiteOwnerList(),
        this.fetchStatisticsFunction(),
        this.fetchRegionList(),
        this.fetchDepotList(),
        this.fetchAllSiteListSet(),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Error fetching data');
    }
  }

  switchDataMapIDbyEnum = (preferenceType) => {
    if (preferenceType === 'NONE') {
      return 0;
    } else if (preferenceType === 'OPERATOR') {
      return 3;
    } else if (preferenceType === 'TOWER_CATEGORY') {
      return 2;
    } else if (preferenceType === 'POWER_SOLUTIONS') {
      return 1;
    } else if (preferenceType === 'TOWER_OWNER') {
      return 4;
    }
  }

  getMapPreference = async () => {
    try {
      const response = await axios.get(siteViewNewhierarchy + 'api/v1/auth/map-preference', {
        headers: {
          "userId": localStorage.getItem("userId")
        }
      });
      this.setState({
        iconCategory: this.switchDataMapIDbyEnum(response?.data?.results?.preferenceType)
      });
      
    } catch (error) {
      message.error(<span className="textStyles-small">Error fetching map preference</span>, 5);
    }
  }

  saveMapPreference = async (data) => {
    let requestBody = {
      preferenceType : data,
    }
    try {
      const response = await axios.post(siteViewNewhierarchy + 'api/v1/auth/map-preference', requestBody, {
        headers: {
          "userId": localStorage.getItem("userId")
        }
      });
      message.success(<span className="textStyles-small">Map preference saved successfully</span>, 2);
    } catch (error) {
      message.error(<span className="textStyles-small">Error saving map preference</span>, 2);
    }
  }

  fetchSiteBySiteID = async (siteID, depotID) => {
    let requestBody = {
      siteIds: [siteID],
      regionIds: [],
      depotIds: [],
      status: null,
      siteOwnerIds: [],
      siteTypeIds: [],
      towerCategoryIds: [],
      towerTypeIds: []
    }
    this.setState({
      loading: true
    })
    const response = await axios.post(siteViewNew + 'api/v1/site-view/all',
      requestBody,
      {
        headers: {
          "userId": localStorage.getItem("userId")
        }
      }
    );

    if (response?.data?.results) {
      this.setState({
        selectedMapDepotCluster: {
          depotId: depotID,
          sites: response.data.results ? response.data.results : []
        },
        zoom: 7,
        level: 'city',
        loading: false,
      })
    }
  }

  fetchSiteList = async () => {
    this.setState({
      loading: true,
      mapQuery: {
        siteIds: [],
        regionIds: [],
        depotIds: [],
        status: null,
        siteOwnerIds: [],
        siteTypeIds: [],
        towerCategoryIds: [],
        towerTypeIds: []
      }
    });
    let fetchData = [];
    const pageSize = 100;
    let hasNextPage = this.state.pagination.hasNextPage;
    let lastSeenId = this.state.pagination.lastSeenId;
    let apiCallCount = 0;
    while (hasNextPage && apiCallCount < 2) {
      try {
        const response = await axios.post(siteViewNew + 'api/v1/site-view',
          {
            siteIds: [],
            regionIds: [],
            depotIds: [],
            status: null,
            siteOwnerIds: [],
            siteTypeIds: [],
            towerCategoryIds: [],
            towerTypeIds: []
          },
          {
            params: {
              lastSeenId,
              pageSize
            },
            headers: {
              "userId": localStorage.getItem("userId")
            }
          },
        );
        if (response.data?.results?.data) {
          fetchData = [...fetchData, ...response.data.results.data];
        }
        lastSeenId = response?.data?.results?.lastSeenId;
        hasNextPage = response?.data?.results?.hasNextPage;
        this.setState({
          pagination: {
            ...this.state.pagination,
            lastSeenId,
            hasNextPage
          }
        });
        apiCallCount++;
        if (!hasNextPage) break;
      } catch (err) {
        console.error('Error fetching data:', err);
        break;
      }
    }
    this.setState({
      loading: false,
      allSiteList: [...this.state.allSiteList, ...fetchData]
    });
  };

  fetchNewSiteList = async () => {
    try {
      const response = await axios.get(siteViewNew + 'api/v1/site-view/list', {
        headers: { "userId": localStorage.getItem("userId")
        }});
      this.setState({
        newSiteList: response?.data?.results
      })
    } catch (error) {
      console.error("Error fetching site list:", error);
    }
  }

  all = () => {
    $("body").toggleClass("enlarge-menu");
    $('.button-menu-mobile').on('click', function (event) {
      event.preventDefault();
    });
  }

  processSiteData = (siteData) => {
    const mapData = siteData.reduce((acc, site) => {
      let region = acc.find(r => r.province === site.region);
      if (!region) {
        region = { province: site.region, districts: [] };
        acc.push(region);
      }

      let depot = region.districts.find(d => d.name === site.depot);
      if (!depot) {
        depot = { name: site.depot, cities: [] };
        region.districts.push(depot);
      }

      const cityData = {
        id: site.id,
        siteID: site.siteId,
        name: site.siteName,
        latitude: site.latitude,
        longitude: site.longitude,
        status: site.status,
        address: site.address,
        depotOfficer: site.depotOfficer,
        ownerName: site.siteOwnerName,
        region: site.region,
        depot: site.depot,
        towerCategory: site.towerCategoryName,
        towerHeight: site.towerHeight,
        depotOfficerPhone: site.contactNo,
        depotOfficerName: site.depotOfficer,
      };

      depot.cities.push(cityData);

      return acc;
    }, []);

    return mapData;
  };

  tableColumns = [
    {
      title: <span className="textStyles-small">Site ID</span>,
      dataIndex: 'siteId',
      key: 'siteId',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Site Name</span>,
      dataIndex: 'siteName',
      key: 'siteName',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Region</span>,
      dataIndex: 'region',
      key: 'region',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Depot</span>,
      dataIndex: 'depot',
      key: 'depot',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Status</span>,
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        let color = '#1FAD4B36';
        let fontColor = '#1FAD4B';
        let displayText = 'Active';
        if (text.toLowerCase() === 'inactive' || text.toLowerCase() === 'deactivated' || text.toLowerCase() === 'removed') {
          color = '#C4C4C452';
          fontColor = '#979797';
          displayText = 'Removed';
        } else if (text.toLowerCase() === 'live' || text.toLowerCase() === 'active') {
          color = '#1FAD4B36';
          fontColor = '#1FAD4B';
          displayText = 'Live';
        }
        return (
          <Tag color={color} style={{ padding: 5, borderRadius: 5 }} key={text} className="textStyles-small">
            <span style={{ color: fontColor, fontWeight: 550, fontSize: '12px' }} className="textStyles-small">
              {displayText}
            </span>
          </Tag>
        );
      }
    },
    {
      title: <span className="textStyles-small">Tower Category</span>,
      dataIndex: 'towerCategoryName',
      key: 'towerCategoryName',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Site Type</span>,
      dataIndex: 'siteType',
      key: 'siteType',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Depot Officer</span>,
      dataIndex: 'depotOfficer',
      key: 'depotOfficer',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Site Owner</span>,
      dataIndex: 'siteOwnerName',
      key: 'siteOwnerName',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
    {
      title: <span className="textStyles-small">Contact No</span>,
      dataIndex: 'contactNo',
      key: 'contactNo',
      render: (text) => <span className="textStyles-small">{text}</span>,
    },
  ];

  fetchAllSiteListSet = async () => {
    var responseAPI = await getAllSitesListByID();
    if (responseAPI.success) {
      this.setState({
        loadFullSiteList: responseAPI?.data,
      });
    }
  }

  fetchSiteOwnerList = async () => {
    const response = await getFilterDetails('siteOwner');
    console.log(response);
    if (response.success) {
      this.setState({
        siteOwnerList: response.data,
      });
    }
  };

  fetchTowerCategoryList = async () => {
    const response = await getFilterDetails('towerCategory');
    console.log(response);
    if (response.success) {
      this.setState({
        towerCategoryList: response.data,
      });
    }
  };

  fetchTowerTypeList = async () => {
    const response = await getFilterDetails('towerType');
    console.log(response);
    if (response.success) {
      this.setState({
        towerTypeList: response.data,
      });
    }
  };

  fetchSiteTypeList = async () => {
    const response = await getFilterDetails('siteType');
    console.log(response);
    if (response.success) {
      this.setState({
        siteTypeList: response.data,
      });
    }
  };

  fetchRegionList = async () => {
    const response = await getAllRegionRelatedData('permission-regions');
    if (response.success) {
      this.setState({
        allRegions: response?.data?.results ? response.data.results : [],
      });
    }
  }

  fetchDepotList = async () => {
    const response = await getAllRegionRelatedData('permission-depots');
    if (response.success) {
      this.setState({
        allDepots: response?.data?.results ? response?.data?.results : [],
      });
    }
  }

  fetchStatisticsFunction = async () => {
    try {
      const response = await axios.get(siteViewNew + 'api/v1/statistics');
      console.log(response?.data?.results);
      this.setState({
        staticalData: response?.data?.results,
      })
    } catch (err) {
      //message.error('Problem with the request');
    } finally {
      console.log('Problem With the request');
    }
  }

  handleProvinceClick = (regionID, lat, lng) => {
    const { mapQueryFilter, allDepots, newSiteList } = this.state;
    const filterDepots = mapQueryFilter?.depotIds || [];
    const filterSiteData = mapQueryFilter?.siteIds || [];
  
    let depotsInSelectedRegion = allDepots.filter((depot) => depot.parentRegionId === regionID);
    let depotsFetch = [];
  
    if (filterSiteData.length === 0 && filterDepots.length === 0) {
      depotsFetch = depotsInSelectedRegion.map((depot) => ({
        id: depot.id,
        name: depot.name,
        latitude: depot.latitude,
        longitude: depot.longitude,
        siteCount: depot.siteCount,
      }));
    } else if (filterSiteData.length === 0 && filterDepots.length > 0) {
      depotsFetch = depotsInSelectedRegion
        .filter((depot) => filterDepots.includes(depot.id))
        .map((depot) => ({
          id: depot.id,
          name: depot.name,
          latitude: depot.latitude,
          longitude: depot.longitude,
          siteCount: depot.siteCount,
        }));
    } else if (filterSiteData.length > 0) {
      const depotsInSelectedSites = newSiteList
        .filter((site) => filterSiteData.includes(site.id))
        .map((site) => site.depotId);
      const uniqueDepotIds = new Set(depotsInSelectedSites);
      depotsFetch = allDepots
        .filter((depot) => uniqueDepotIds.has(depot.id))
        .map((depot) => ({
          id: depot.id,
          name: depot.name,
          latitude: depot.latitude,
          longitude: depot.longitude,
          siteCount: depot.siteCount,
        }));
    }
  
    this.setState({
      selectedMapRegionCluster: {
        regionId: regionID,
        depots: depotsFetch,
      },
      zoom: 9,
      level: 'district',
      centerLat: lat,
      centerLng: lng,
    });
  };

  handleDistrictClick = (depotID, lat, lng) => {
    // this.setState((prevState) => ({
    //   centerLat: lat,
    //   centerLng: lng,
    //   zoom: 12, // Zoom into city level
    //   previousClusters: [...prevState.previousClusters, prevState.clusters], // Save the current clusters
    //   clusters: cities, // Show the cities of the selected district
    //   level: 'city', // Update the level
    // }));
    this.fetchSiteListByDepotID(this.state.selectedMapRegionCluster.regionId, depotID, this.state.mapQueryFilter);
    this.setState({
      centerLat: lat,
      centerLng: lng,
    });
  };

  fetchSiteListByDepotID = async (regionID, depotID, mapQueryFilter) => {
    let requestBody = {
      siteIds: mapQueryFilter.siteIds ? mapQueryFilter.siteIds : [],
      regionIds: mapQueryFilter.region ? mapQueryFilter.region : [],
      depotIds: [depotID],
      status: mapQueryFilter.status ? mapQueryFilter.status : null,
      siteOwnerIds: mapQueryFilter.siteOwner ? mapQueryFilter.siteOwner : [],
      siteTypeIds: mapQueryFilter.siteType ? mapQueryFilter.siteType : [],
      towerCategoryIds: mapQueryFilter.towerCategory ? mapQueryFilter.towerCategory : [],
      towerTypeIds: mapQueryFilter.towerType ? mapQueryFilter.towerType : []
    }
    this.setState({
      loading: true
    })
    const response = await axios.post(siteViewNew + 'api/v1/site-view/all',
      requestBody,
      {
        headers: {
          "userId": localStorage.getItem("userId")
        }
      }
    );

    if (response?.data?.results) {
      this.setState({
        selectedMapDepotCluster: {
          depotId: depotID,
          sites: response.data.results ? response.data.results : []
        },
        zoom: 12,
        level: 'city',
        loading: false
      })
    }
  }

  handleBackClick = () => {
    this.setState((prevState) => {
      const previousClusters = prevState.previousClusters.slice(0, -1);
      const clusters = prevState.previousClusters[prevState.previousClusters.length - 1];
      const level = prevState.level === 'city' ? 'district' : 'province';
      const zoom = prevState.level === 'city' ? 9 : 7;
      return {
        clusters,
        previousClusters,
        level,
        zoom,
      };
    });
  };

  handleZoomIn = () => {
    this.setState((prevState) => ({
      zoom: prevState.zoom + 1,
    }));
  };

  handleZoomOut = () => {
    this.setState((prevState) => ({
      zoom: prevState.zoom - 1,
    }));
  };

  handleMarkerClick = (info) => {
    this.setState({ popupInfo: info });
  };

  getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            centerLat: position.coords.latitude,
            centerLng: position.coords.longitude,
            zoom: 12,
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  handleTableChange = (pagination) => {
    console.log(this.state.mapQueryFilter);
    this.setState({
      pagination: {
        ...this.state.pagination,
        current: pagination.current,
        totalPages: Math.ceil(pagination.total / pagination.pageSize)
      },
    });
    if ((pagination.current > this.state.pagination.totalPages - 5) && this.state.pagination.hasNextPage) {
      let { hasNextPage, lastSeenId } = this.state.pagination;
      this.filterBtnClick(this.state.mapQueryFilter, lastSeenId, hasNextPage);
    }
  };

  filterBtnClick = (values, lastSeenId, hasNextPage) => {
    console.log(values);
    this.fetchSiteListFilter(
      values?.siteIds ? values.siteIds : [],
      values?.region ? values.region : [],
      values?.depot ? values.depot : [],
      values?.status ? values.status : null,
      values?.siteOwner ? values.siteOwner : [],
      values?.siteType ? values.siteType : [],
      values?.towerCategory ? values.towerCategory : [],
      values?.towerType ? values.towerType : [],
      lastSeenId ? lastSeenId : 0,
      hasNextPage ? hasNextPage : true
    );
  }

  fetchSiteListFilter = async (siteIdsP, regionIdsP, depotIdsP, statusP, siteOwnerIdsP, siteTypeIdsP, towerCategoryIdsP, towerTypeIdP, lastSeenId, hasNextPage) => {
    this.setState({
       loading: true,
    });
    const pageSize = 100;
    let apiCallCount = 0;
    let fetchData = [];
    const requestBody = {
      siteIds: siteIdsP,
      regionIds: regionIdsP,
      depotIds: depotIdsP,
      status: statusP,
      siteOwnerIds: siteOwnerIdsP,
      siteTypeIds: siteTypeIdsP,
      towerCategoryIds: towerCategoryIdsP,
      towerTypeIds: towerTypeIdP
    };

    try {
      while (hasNextPage && apiCallCount < 2) {
        const response = await axios.post(siteViewNew + 'api/v1/site-view',
          requestBody,
          {
            params: {
              lastSeenId,
              pageSize
            },
            headers: {
              "userId": localStorage.getItem("userId")
            }
          }
        );

        if (response.data?.results?.data) {
          fetchData = response.data.results.data;
        }
        lastSeenId = response?.data?.results?.lastSeenId;
        hasNextPage = response?.data?.results?.hasNextPage;
        apiCallCount++;

        if (!hasNextPage) break;
      }
    } catch (err) {
      message.error(<span className="textStyles-small">Error fetching data</span>, 5);
    }
    localStorage.setItem("mapQuery", JSON.stringify({
      siteIds: [],
      regionIds: [],
      depotIds: [],
      status: null,
      siteOwnerIds: [],
      siteTypeIds: [],
      towerCategoryIds: [],
      towerTypeIds: []
    }));
    let exisitingData = this.state.sitesList;
    this.setState({
      loading: false,
      sitesList: [...exisitingData, ...fetchData],
      openFilter: false,
      pagination: {
        ...this.state.pagination,
        lastSeenId,
        hasNextPage
      },
      singleView: false
    });
  };


  render() {
    const PlusMinusComponent = () => {
      return (
        <div style={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '5px', width: '45px', display: 'flex',
          flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '5px'
        }}>
          {this.state.level !== 'province' && (
            <>
              <div style={{ marginTop: 2, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                onClick={this.handleBackClick}>
                <RollbackOutlined style={{ fontSize: '20px', color: '#595959' }} />
              </div>
              <Divider style={{ margin: '5px 0' }} />
            </>
          )}
          <div style={{ marginTop: 2, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
            onClick={this.handleZoomIn}>
            <PlusOutlined style={{ fontSize: '20px', color: '#595959' }} />
          </div>
          <Divider style={{ margin: '5px 0' }} />
          <div style={{ marginTop: 2, width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
            onClick={this.handleZoomOut}>
            <MinusOutlined style={{ fontSize: '20px', color: '#595959' }} />
          </div>
        </div>
      );
    };

    const RegionComp = ({ count, name }) => {
      const containerStyle = {
        position: 'relative',
        width: '60px', // Width of the larger SVG
        height: '60px', // Height of the larger SVG
      };

      const svgStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };

      const overlayStyle = {
        width: '40px', // Width of the smaller SVG
        height: '40px', // Height of the smaller SVG
      };

      const textStyle = {
        fill: '#ffffff', // Text color
        fontSize: '16px',
        fontWeight: 'bold',
        textAnchor: 'middle',
        dominantBaseline: 'middle',
      };

      const popoverContent = (
        <div>
          <span className='textStyles-small' style={{ fontWeight: 600 }}>Region Name:
            <span className='textStyles-small' style={{ fontWeight: 500, marginLeft: 5 }}>{name}</span>
          </span><br />
          <span className='textStyles-small' style={{ fontWeight: 600, marginTop: 5 }}>Depot Count:
            <span className='textStyles-small' style={{ fontWeight: 500, marginLeft: 5 }}>{count}</span>
          </span>
        </div>
      );

      return (
        <Popover content={popoverContent}>
          <div style={containerStyle}>
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" style={svgStyle}>
              <circle cx="36.5" cy="36.5" r="36.5" fill="#1FAD4B" fillOpacity="0.21" />
            </svg>

            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...svgStyle, ...overlayStyle }}>
              <circle cx="25" cy="25" r="24.6265" fill="#1FAD4B" />
              <text x="25" y="25" style={textStyle}>{count}</text>
            </svg>
          </div>
        </Popover>
      );
    };

    const DepotComp = ({ count, name, doName, doNumber }) => {
      const containerStyle = {
        position: 'relative',
        width: '60px',
        height: '60px',
      };

      const svgStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };

      const overlayStyle = {
        width: '40px',
        height: '40px',
      };

      const textStyle = {
        fill: '#ffffff',
        fontSize: '16px',
        fontWeight: 'bold',
        textAnchor: 'middle',
        dominantBaseline: 'middle',
      };

      const popoverContent = (
        <div>
          <span className='textStyles-small' style={{ fontWeight: 600 }}>Depot Name:
            <span className='textStyles-small' style={{ fontWeight: 500, marginLeft: 5 }}>{name}</span>
          </span><br />
          <span className='textStyles-small' style={{ fontWeight: 600 }}>Depot Officer:
            <span className='textStyles-small' style={{ fontWeight: 500, marginLeft: 5 }}>{doName ? doName : 'N/A'}</span>
          </span><br />
          <span className='textStyles-small' style={{ fontWeight: 600 }}>Contact Number:
            <span className='textStyles-small' style={{ fontWeight: 500, marginLeft: 5 }}>{doNumber ? doNumber : 'N/A'}</span>
          </span><br />
          <span className='textStyles-small' style={{ fontWeight: 600, marginTop: 5 }}>Site Count:
            <span className='textStyles-small' style={{ fontWeight: 500, marginLeft: 5 }}>{count}</span>
          </span>
        </div>
      );
      return (
        <Popover content={popoverContent}>
          <div style={containerStyle}>
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" style={svgStyle}>
              <circle cx="36.5" cy="36.5" r="36.5" fill="#ccbbba" fillOpacity="0.21" />
            </svg>

            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...svgStyle, ...overlayStyle }}>
              <circle cx="25" cy="25" r="24.6265" fill="#F28603" />
              <text x="25" y="25" style={textStyle}>{count}</text>
            </svg>
          </div>
        </Popover>
      );
    }

    const staticDataMap = [
      { label: "Region", value: this.state.staticalData?.regionCount, highlighted: true , level: 'province'},
      { label: "Depot", value: this.state.staticalData?.depotCount, highlighted: false , level: 'district'},
      { label: "Site", value: this.state.staticalData?.siteCount, highlighted: false , level: 'city'},
    ];

    const switchDataMap = [
      { label: "Default", value: 0, highlighted: false, eNum: 0 },
      { label: "Operator", value: 3, highlighted: false, eNum: 1 },
      { label: "Tower Category", value: 2, highlighted: true, eNum: 3 },
      { label: "Power Solutions", value: 1, highlighted: false, eNum: 2 },
      { label: "Tower Owner", value: 4, highlighted: false, eNum: 4 },
    ];

    const powerSolutionsColorCodes = [
      { name: 'Own Commercial Power', color: '#1E88E5' },
      { name: 'Shared AC power', color: '#6AB7FF' },
      { name: 'Shared DC power', color: '#016190' },
      { name: 'Own FTG', color: '#1FAD4B' },
      { name: 'Hiring FTG', color: '#66BB6A' },
      { name: 'Shared FTG', color: '#2E7D32' },
      { name: 'Full Solar', color: '#FBC02D' },
      { name: 'Own Commercial Power + Partial Solar', color: '#FFEB3B' },
      { name: 'Shared AC power + Partial Solar', color: '#D28816' },
      { name: 'Shared DC power + Partial Solar', color: '#F68712' },
      { name: 'Own FTG + Partial Solar', color: '#F4511E' },
      { name: 'Hiring FTG + Partial Solar', color: '#FF0000' },
      { name: 'Shared FTG + Partial Solar', color: '#8E24AA' },
      { name: 'No Power Operation', color: '#D32F2F' },
      { name: 'Partial Solar', color: '#B86E9F' },
      { name: null, color: 'black' }
  ];

  const towerCategoryColorCodes = [
      { name: 'Ground Base', color: '#8D6E63' },
      { name: 'Roof Top', color: '#D43200' },
      { name: 'IBS', color: '#7986CB' },
      { name: 'Lamp Pole', color: '#EE950C' },
      { name: 'Other', color: '#9E9E9E' },
      { name: null, color: 'black' }
  ]

  const operatorCategoryColorCodes = [
      { name: 'Dialog', color: '#B01F88' },
  ]

  const towerOwner = [
    { name: 'DAP', color: '#8E24AA' },
    { name: 'ESLL', color: '#FFAC2C' },
    { name: 'Hutch', color: '#F4511E' },
    { name: 'Lanka Bell', color: '#BA183A' },
    { name: 'Mobitel', color: '#4CAF50' },
    { name: 'SLT', color: '#0792F6' },
    { name: 'MOD', color: '#6D4C41' },
    { name: 'Other', color: '#6D4C41' },
    { name: null, color: 'black' }
]

  const buttonStyles = (isActive) => ({
    borderColor: isActive ? "#F28603" : "white",
    backgroundColor: isActive ? "#F28603" : "white",
    borderRadius: "50px",
    width: "50px",
    height: "50px",
    marginLeft: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });
  
  const svgFillColor = (isActive) => (isActive ? "white" : "black");

    const regionByGivenSiteIDList = (siteArray) => {
      const uniqueRegionIds = new Set();
      siteArray.forEach((site) => {
        this.state.newSiteList?.forEach((newSite) => {
          if (site === newSite?.id) {
            uniqueRegionIds.add(newSite?.regionId);
          }
        });
      });
      return Array.from(uniqueRegionIds);
    };

    const regionByGivenSiteIDListDepot = (depotArry) => {
      const uniqueRegionIds = new Set();
      depotArry.forEach((depoID) => {
        this.state.allDepots?.forEach((depot) => {
          if (depoID === depot?.id) {
            uniqueRegionIds.add(depot?.parentRegionId);
          }
        });
      });
      return Array.from(uniqueRegionIds);
    }

    const renderRegions = () => {
      const { level, mapQueryFilter, allRegions, allDepots } = this.state;
      let filteredRegionIds = [];
      
      if (level === 'province') {
        const { regionIds = [], depotIds = [], siteIds = [] } = mapQueryFilter || {};
    
        // Case 1: regionIds = 0, depotIds = 0, siteIds = 0
        if (regionIds.length === 0 && depotIds.length === 0 && siteIds.length === 0) {
          filteredRegionIds = allRegions.map((region) => region.id);
        }
        // Case 2: regionIds = 0, depotIds = 0, siteIds ≠ 0
        else if (regionIds.length === 0 && depotIds.length === 0 && siteIds.length !== 0) {
          filteredRegionIds = regionByGivenSiteIDList(siteIds);
        }
        // Case 3: regionIds = 0, depotIds ≠ 0, siteIds = 0
        else if (regionIds.length === 0 && depotIds.length !== 0 && siteIds.length === 0) {
          filteredRegionIds = regionByGivenSiteIDListDepot(depotIds);
        }
        // Case 4: regionIds = 0, depotIds ≠ 0, siteIds ≠ 0
        else if (regionIds.length === 0 && depotIds.length !== 0 && siteIds.length !== 0) {
          const regionsFromDepots = regionByGivenSiteIDListDepot(depotIds);
          const regionsFromSites = regionByGivenSiteIDList(siteIds);
          filteredRegionIds = [...new Set([...regionsFromDepots, ...regionsFromSites])];
        }
        // Case 5: regionIds ≠ 0, depotIds = 0, siteIds = 0
        else if (regionIds.length !== 0 && depotIds.length === 0 && siteIds.length === 0) {
          filteredRegionIds = regionIds;
        }
        // Case 6: regionIds ≠ 0, depotIds = 0, siteIds ≠ 0
        else if (regionIds.length !== 0 && depotIds.length === 0 && siteIds.length !== 0) {
          const regionsFromSites = regionByGivenSiteIDList(siteIds);
          filteredRegionIds = [...new Set([...regionIds, ...regionsFromSites])];
        }
        // Case 7: regionIds ≠ 0, depotIds ≠ 0, siteIds = 0
        else if (regionIds.length !== 0 && depotIds.length !== 0 && siteIds.length === 0) {
          const regionsFromDepots = regionByGivenSiteIDListDepot(depotIds);
          filteredRegionIds = [...new Set([...regionIds, ...regionsFromDepots])];
        }
        // Case 8: regionIds ≠ 0, depotIds ≠ 0, siteIds ≠ 0
        else if (regionIds.length !== 0 && depotIds.length !== 0 && siteIds.length !== 0) {
          const regionsFromDepots = regionByGivenSiteIDListDepot(depotIds);
          const regionsFromSites = regionByGivenSiteIDList(siteIds);
          filteredRegionIds = [...new Set([...regionIds, ...regionsFromDepots, ...regionsFromSites])];
        }
      }
    
      const filteredRegions = allRegions.filter((region) =>
        filteredRegionIds.includes(region.id)
      );
    
      return filteredRegions.map((province, provinceIndex) => {
        const depotCount = allDepots.filter(
          (depot) => depot.parentRegionId === province.id
        ).length;
    
        return (
          <AnyReactComponent
            key={provinceIndex}
            lat={province.latitude || 0}
            lng={province.longitude || 0}
            onClick={() => this.handleProvinceClick(province.id, province.latitude, province.longitude)}
          >
            <RegionComp count={depotCount} name={province.name} />
          </AnyReactComponent>
        );
      });
    };
    
    return (
      <div>
        {
          this.state.showNav ? (
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row">
                    <div className="col">
                      <span className="textStyles-small" style={{ fontSize: '14px', fontWeight: 'bold' }}>Map View</span>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a className='textStyles-small'>Site</a></li>
                        <li className="breadcrumb-item"><a className='textStyles-small'>Map View</a></li>
                      </ol>
                    </div>
                    <div className="col-auto align-self-center">
                      <Button
                        type="button"
                        className="btn btn-soft-primary btn-sm"
                        style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', width: '100px', height: '34px', marginLeft: 5 }}
                        onClick={() => this.setState({ openFilter: true })}
                        icon={<FilterOutlined style={{ color: 'white' }} />}
                      >
                        <span className="textStyles-small" style={{ color: 'white', fontSize: 13 }}>Filter</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }

        <Spin spinning={this.state.loading} size='large'>
          <Col lg={24}>
            <div style={{
              position: 'relative',
              height: this.state.showTable ? 'calc(100vh - 200px)' : this.state.showNav ? '80vh' : 'calc(100vh - 50px)',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              overflow: 'hidden'
            }}>

              <div
                style={{
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  padding: '8px',
                  borderRadius: '5px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                  fontSize: '14px',
                  zIndex: 10,
                  width: '319px',
                  display: 'flex',
                  flexDirection: 'column', // Change to column to stack rows
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 'auto', // Adjust height dynamically
                }}
              >
                {/* First Row */}
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  {staticDataMap.map((data, index) => (
                    <Col span={8} key={index}>
                      <div
                        style={{
                          backgroundColor: this.state.level === data?.level ? 'black' : '#C4C4C452',
                          borderColor: this.state.level === data?.level ? 'black' : '#C4C4C452',
                          borderRadius: 5,
                          width: '96px',
                          height: '29px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: '0 10px',
                        }}
                      >
                        <span className="textStyles-small" style={{ fontWeight: 500, fontSize: 12 , color: this.state.level === data?.level ? 'white' : 'black'}}>{data?.label}</span>
                        <span className="textStyles-small"  style={{ fontWeight: 500, fontSize: 12, color: this.state.level === data?.level ? 'white' : 'black' }}>{data?.value}</span>
                      </div>
                    </Col>
                  ))}
                </Row>

                {/* Second Row */}
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '10px',
                    width: '100%',
                  }}
                >
                  {switchDataMap.map((data, index) => (
                    <Col
                      key={index}                                                                          
                      style={{
                        flex: '1 1 auto',
                        maxWidth: '20%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: this.state.iconCategory === data?.value ? 'black' : '#C4C4C452',
                          borderColor: this.state.iconCategory === data?.value ? 'black' : '#C4C4C452',
                          borderRadius: 5,
                          width: '57px',
                          height: '29px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '0 10px',
                          margin: '0 5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.setState({
                            iconCategory: data?.value,
                            showReferences: false
                          });
                          this.saveMapPreference(data?.eNum);
                        }}
                      >
                        <span className="textStyles-small" style={{ fontWeight: 500, fontSize: 10, color: this.state.iconCategory === data?.value ? 'white' : 'black' }}>{data?.label}</span>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>

              {
                this.state.iconCategory !== 0 ? (
                  <div
                  style={{
                    position: "absolute",
                    bottom: "65px",
                    left: "5px",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    zIndex: 10,
                    width: "fit-content",
                  }}
                > 
                  <Button
                    type="button"
                    className="btn btn-soft-primary btn-sm"
                    style={buttonStyles(this.state.showReferences)}
                    onClick={() => {
                      this.setState((prevState) => ({ showReferences : !prevState.showReferences }));
                      window.scrollTo({
                        top: this.state.showReferences ? 0 : document.body.scrollHeight,
                        behavior: "smooth",
                      });
                    }}
                  >
                      <svg width="32" height="32" viewBox="0 0 32 32" 
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" 
                        d="M14.8061 6.08133L10.2328 10.6507L8.72076 9.15733L11.4808 6.384H5.08076C4.66053 6.384 4.24442 6.46677 3.85617 6.62759C3.46793 6.7884 3.11517 7.02411 2.81802 7.32126C2.52087 7.61841 2.28516 7.97117 2.12435 8.35941C1.96353 8.74765 1.88076 9.16377 1.88076 9.584C1.88076 10.0042 1.96353 10.4203 2.12435 10.8086C2.28516 11.1968 2.52087 11.5496 2.81802 11.8467C3.11517 12.1439 3.46793 12.3796 3.85617 12.5404C4.24442 12.7012 4.66053 12.784 5.08076 12.784H6.26743V14.9173H5.09409C3.72493 14.8523 2.43336 14.2627 1.48739 13.2707C0.541414 12.2788 0.0136719 10.9607 0.0136719 9.59C0.0136719 8.2193 0.541414 6.90122 1.48739 5.90927C2.43336 4.91732 3.72493 4.32767 5.09409 4.26267H11.4941L8.71943 1.50667L10.2328 0L14.8061 4.576V6.08133ZM22.1594 32H9.35943L8.29276 30.9333V13.8827L9.35943 12.816H22.1594L23.2261 13.8827V30.9333L22.1594 32ZM10.4261 29.8667H21.0928V14.9493H10.4261V29.8667ZM17.8928 2.136H30.6928L31.7594 3.20267V20.2507L30.6928 21.3173H25.3594V19.184H29.6261V4.26667H18.9594V10.6493H16.8261V3.20133L17.8928 2.13467V2.136ZM18.9594 17.0507H12.5594V19.184H18.9594V17.0507ZM12.5594 21.3173H18.9594V23.4507H12.5594V21.3173ZM18.9594 25.584H12.5594V27.7173H18.9594V25.584ZM21.0928 6.384H27.4928V8.51733H21.0928V6.384ZM27.4928 14.9173H25.3594V17.0507H27.4928V14.9173ZM23.0421 10.6827V10.6507H27.4928V12.784H25.1434L23.0421 10.6827Z" 
                        fill={svgFillColor(this.state.showReferences)} />
                      </svg>

                  </Button>
                </div>
                ): null
              }

              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "5px",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  zIndex: 10,
                  width: "fit-content",
                }}
              >
                <Button
                  type="button"
                  className="btn btn-soft-primary btn-sm"
                  style={buttonStyles(this.state.showTable)}
                  onClick={() => {
                    this.setState((prevState) => ({ showTable: !prevState.showTable }));
                    window.scrollTo({
                      top: this.state.showTable ? 0 : document.body.scrollHeight,
                      behavior: "smooth",
                    });
                  }}
                >
                  <svg
                    width="30"
                    height="29"
                    viewBox="0 0 40 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 5.45455C0 2.44209 2.44209 0 5.45455 0H13.5245C16.0274 0 18.2091 1.70344 18.8162 4.13162L19.6015 7.27274H22.7273H34.5455C37.558 7.27274 40 9.71483 40 12.7273V16.3637C40 17.3678 39.186 18.1818 38.1819 18.1818C37.1777 18.1818 36.3637 17.3678 36.3637 16.3637V12.7273C36.3637 11.7231 35.5497 10.9091 34.5455 10.9091H22.7273H19.6015H5.45455C4.45041 10.9091 3.63637 11.7231 3.63637 12.7273V14.5455V30.9091C3.63637 31.9133 4.45041 32.7273 5.45455 32.7273H14.5455C15.5496 32.7273 16.3637 33.5413 16.3637 34.5455C16.3637 35.5497 15.5496 36.3637 14.5455 36.3637H5.45455C2.44209 36.3637 0 33.9217 0 30.9091V14.5455V12.7273V5.45455ZM34.8575 30.468C35.8093 29.0309 36.3637 27.3075 36.3637 25.4546C36.3637 20.4338 32.2935 16.3637 27.2728 16.3637C22.252 16.3637 18.1818 20.4338 18.1818 25.4546C18.1818 30.4753 22.252 34.5455 27.2728 34.5455C29.1257 34.5455 30.8491 33.9911 32.2862 33.0393L36.8962 37.6493C37.6062 38.3593 38.7575 38.3593 39.4675 37.6493C40.1775 36.9393 40.1775 35.788 39.4675 35.078L34.8575 30.468ZM21.8182 25.4546C21.8182 22.442 24.2602 20 27.2728 20C30.2853 20 32.7273 22.442 32.7273 25.4546C32.7273 28.4671 30.2853 30.9091 27.2728 30.9091C24.2602 30.9091 21.8182 28.4671 21.8182 25.4546Z"
                      fill={svgFillColor(this.state.showTable)}
                    />
                  </svg>
                </Button>
              </div>

              <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                <div style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  fontSize: '14px',
                  zIndex: 10,
                  width: 'fit-content',
                }}>
                  <PlusMinusComponent />
                </div>

                <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                  <div style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    fontSize: '14px',
                    zIndex: 10,
                    width: 'fit-content',
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '40px', height: '40px', backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '5px' }}
                      onClick={() => {
                        this.setState({ showNav: !this.state.showNav });
                        this.all();
                      }}>
                      {
                        this.state.showNav ? (
                          <ArrowsAltOutlined style={{ fontSize: '20px', color: '#595959' }} />
                        ) : (
                          <ShrinkOutlined style={{ fontSize: '20px', color: '#595959' }} />
                        )
                      }
                    </div>
                  </div>

                  {this.state.centerLat !== null && this.state.centerLng !== null ? (
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: "AIzaSyC7QjauaBLV9W__koQgy65PIuxYbcSPpfg" }}
                      center={{
                        lat: this.state.centerLat,
                        lng: this.state.centerLng
                      }}
                      zoom={this.state.zoom}
                      options={{
                        zoomControl: false,
                        fullscreenControl: false,
                      }}
                    >
                      
                      {renderRegions()}

                      {this.state.level === 'district' && this.state?.selectedMapRegionCluster?.depots.map((district, districtIndex) => (
                        <AnyReactComponent
                          key={district?.id}
                          lat={district?.latitude || 0}
                          lng={district?.longitude || 0}
                          onClick={() => this.handleDistrictClick(district.id, district.latitude, district.longitude)}
                        >
                          <DepotComp count={district?.siteCount} name={district.name} doName={district?.depotOfficerName} doNumber={district?.depotOfficerMobile} />
                        </AnyReactComponent>
                      ))}

                      {this.state.level === 'city' && this.state?.selectedMapDepotCluster?.sites?.map((city) => (
                        <SiteLocation
                          key={city.id}
                          lat={city.latitude}
                          lng={city.longitude}
                          text={`${city.siteName}`}
                          onClick={() => this.handleMarkerClick(city)}
                          details={city}
                          iconTypeID={this.state.iconCategory}
                          userLat={this.state.userLocation?.lat}
                          userLng={this.state.userLocation?.lng}
                        />
                      ))}
                    </GoogleMapReact>
                  ) : null}
                </div>
              </div>
            </div>
          </Col>
          {
            this.state.showTable && (
              <div style={{ width: '100%', padding: 20, backgroundColor: 'white', borderRadius: '10px', marginTop: 20 }}>
                <span className="textStyles-small" style={{ fontSize: 16, marginTop: 10, marginLeft: 10, fontWeight: 600 }}>Site Details</span>                             
              <Table
                size="small"
                className="table-striped-rows"
                style={{ marginTop: 20 }}
                columns={this.tableColumns}
                loading={this.state.loading}
                dataSource={this.state.sitesList}
                pagination={{
                  current: this.state.pagination.current,
                  pageSize: this.state.pagination.pageSize,
                  total: this.state.pagination.total,
                  showSizeChanger: false,
                }}
                onChange={this.handleTableChange}
              />
              </div>
            )
          }
          {
            this.state.showReferences ? (
              <div style={{ width: '100%', padding: 20, backgroundColor: 'white', borderRadius: '10px', marginTop: 20 }}>
                <span className="textStyles-small" style={{ fontSize: 14, marginTop: 10, fontWeight: 600 }}>Reference - {
                  switchDataMap.find((data) => data.value === this.state.iconCategory).label}</span>
                <Row style={{ marginTop: 15 }}>
                  {
                    this.state.iconCategory === 1 && powerSolutionsColorCodes.map((colorCode, index) => (
                      <Col span={6} key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                          <div style={{ width: 15, height: 15, backgroundColor: colorCode.color, borderRadius: '50%' }} />
                          <span className="textStyles-small" style={{ marginLeft: 5 }}>{colorCode.name ? colorCode.name : 'Others'}</span>
                        </div>
                      </Col>
                    ))
                  }
                  {
                    this.state.iconCategory === 2 && towerCategoryColorCodes.map((colorCode, index) => (
                      <Col span={6} key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                          <div style={{ width: 15, height: 15, backgroundColor: colorCode.color, borderRadius: '50%' }} />
                          <span className="textStyles-small" style={{ marginLeft: 5 }}>{colorCode.name ? colorCode.name : 'Others'}</span>
                        </div>
                      </Col>
                    ))
                  }
                  {
                    this.state.iconCategory === 3 && operatorCategoryColorCodes.map((colorCode, index) => (
                      <Col span={6} key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                          <div style={{ width: 15, height: 15, backgroundColor: colorCode.color, borderRadius: '50%' }} />
                          <span className="textStyles-small" style={{ marginLeft: 5 }}>{colorCode.name ? colorCode.name : 'Others'}</span>
                        </div>
                      </Col>
                    ))
                  }
                  {
                    this.state.iconCategory === 4 && towerOwner.map((colorCode, index) => (
                      <Col span={6} key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                          <div style={{ width: 15, height: 15, backgroundColor: colorCode.color, borderRadius: '50%' }} />
                          <span className="textStyles-small" style={{ marginLeft: 5 }}>{colorCode.name ? colorCode.name : 'Others'}</span>
                        </div>
                      </Col>
                    ))
                  }
                </Row>
              </div>
            ) : null
          }
        </Spin>

        {/* FILTER SECTION  */}
        <Modal
          title={<span className="textStyles-small" style={{ fontSize: 15 }}>Filter By</span>}
          open={this.state.openFilter}
          onCancel={() => this.setState({ openFilter: false })}
          onOk={() => this.setState({ openFilter: false })}
          destroyOnClose={false}
          width={350}
          footer={null}
          header={null}
          style={{
            position: 'fixed',
            top: 20,
            right: 20,
            overflow: 'auto',
          }}
        >
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(values) => {
              this.filterBtnClick(values);
              this.setState({
                isActiveFilter: true,
                allSiteList: [],
                pagination: {
                  current: 1,
                  pageSize: 10,
                  total: 0,
                  totalPages: 0,
                  lastSeenId: 0,
                  hasNextPage: true
                },
                mapQueryFilter: {
                  regionIds: values.region || [],
                  depotIds: values.depot || [],
                  siteIds: values.siteIds || [],
                  status: values.status || null,
                  operator: values.operator || null,
                  towerCategory: values.towerCategory || null,
                  powerSolution: values.powerSolution || null,
                },
                level: 'province',
                zoom: 7,
                centerLat: 7.3,
                centerLng: 80.6,
              });
            }}
          >
            <Collapse ghost={true} expandIconPosition="end" style={{ backgroundColor: 'white' }}>
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Region</span>} key="2">
                <Form.Item name="region">
                  <Select
                    placeholder="Select Region"
                    bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    className='borderedSelect'
                    mode='multiple'
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      this.setState({
                        selectedRegionID: value
                      })
                    }
                    }
                  >
                    {this.state.allRegions.map((region) => (
                      <Option className="textStyles-small" key={region?.id} value={region?.id}>
                        {region.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Depot</span>} key="3">
                <Form.Item name="depot">
                  <Select
                    placeholder="Select Depot"
                    bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    showSearch
                    mode='multiple'
                    className='borderedSelect'
                    onChange={(value) => {
                      this.setState({
                        selectedDepotID: value
                      })
                    }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      this.state.selectedRegionID.length === 0 ? (
                        this.state.allDepots.map((depot) => (
                          <Option className="textStyles-small" key={depot.id} value={depot.id}>
                            {depot.name}
                          </Option>
                        ))
                      ) : (
                        this.state.allDepots
                          .filter((depot) => this.state.selectedRegionID?.includes(depot?.parentRegionId))
                          .map((depot) => (
                            <Option className="textStyles-small" key={depot.id} value={depot.id}>
                              {depot.name}
                            </Option>
                          ))
                      )
                    }
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Site</span>} key="10">
                <Form.Item name="siteIds">
                  <Select placeholder="Select Status" bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    showSearch
                    mode='multiple'
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    className='borderedSelect'>

                    {
                      this.state.selectedRegionID.length === 0 && this.state.selectedDepotID.length === 0 ? (
                        this.state.newSiteList.map((site) => (
                          <Option className="textStyles-small" key={site.id} value={site.id}>
                            {site.siteName}
                          </Option>
                        ))
                      ) : null
                    }

                    {
                      this.state.selectedRegionID.length === 0 && this.state.selectedDepotID.length !== 0 ? (
                        this.state.newSiteList
                          .filter((site) => this.state.selectedDepotID.includes(site.depotId))
                          .map((site) => (
                            <Option className="textStyles-small" key={site.id} value={site.id}>
                              {site.siteName}
                            </Option>
                          ))
                      ) : null
                    }

                    {
                      this.state.selectedRegionID.length !== 0 && this.state.selectedDepotID.length === 0 ? (
                        this.state.newSiteList
                          .filter((site) => this.state.selectedRegionID.includes(site.regionId))
                          .map((site) => (
                            <Option className="textStyles-small" key={site.id} value={site.id}>
                              {site.siteName}
                            </Option>
                          ))
                      ) : null
                    }
                    {
                      this.state.selectedRegionID.length !== 0 && this.state.selectedDepotID.length !== 0 ? (
                        this.state.newSiteList
                          .filter((site) => this.state.selectedDepotID.includes(site.depotId))
                          .map((site) => (
                            <Option className="textStyles-small" key={site.id} value={site.id}>
                              {site.siteName}
                            </Option>
                          ))
                      ) : null
                    }
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Status</span>} key="4">
                <Form.Item name="status">
                  <Select placeholder="Select Status" bordered={false} defaultValue={null}
                    style={{ width: "100%", height: 'auto' }}
                    className='borderedSelect'>
                    <Option className="textStyles-small" value={null}><Badge color="blue" style={{ marginRight: 10 }} />All</Option>
                    <Option className="textStyles-small" value="Live"><Badge color="green" style={{ marginRight: 10 }} />Live</Option>
                    <Option className="textStyles-small" value="Removed"><Badge color="red" style={{ marginRight: 10 }} />Removed</Option>
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Site Owner</span>} key="5">
                <Form.Item name="siteOwner">
                  <Select
                    placeholder="Select Site Owner"
                    bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    mode='multiple'
                    className='borderedSelect'
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.siteOwnerList.map((siteOwner) => (
                      <Option className="textStyles-small" key={siteOwner.id} value={siteOwner.id}>
                        {siteOwner.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Site Type</span>} key="6">
                <Form.Item name="siteType">
                  <Select
                    placeholder="Select Site Type"
                    bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    showSearch
                    mode='multiple'
                    className='borderedSelect'
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.siteTypeList.map((siteType) => (
                      <Option className="textStyles-small" key={siteType.id} value={siteType.id}>
                        {siteType.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Tower Category</span>} key="7">
                <Form.Item name="towerCategory">
                  <Select
                    placeholder="Select Tower Category"
                    bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    showSearch
                    mode='multiple'
                    className='borderedSelect'
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.towerCategoryList.map((towerCategory) => (
                      <Option className="textStyles-small" key={towerCategory.id} value={towerCategory.id}>
                        {towerCategory.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Panel>
              <Divider />
              <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Tower Type</span>} key="8">
                <Form.Item name="towerType">
                  <Select
                    placeholder="Select Tower Type"
                    bordered={false}
                    style={{ width: "100%", height: 'auto' }}
                    showSearch
                    mode='multiple'
                    className='borderedSelect'
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.towerTypeList.map((towerType) => (
                      <Option className="textStyles-small" key={towerType.id} value={towerType.id}>
                        {towerType.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Panel>
              <Button
                type="button"
                class="btn btn-soft-primary btn-sm"
                htmlType="submit"
                style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', width: '100%', height: '34px', marginTop: 10 }}
              >
                <span className="textStyles-small" style={{ color: 'white', fontSize: 13 }}>Filter</span>
              </Button>

              <Button
                type="button"
                class="btn btn-soft-secondary btn-sm"
                htmlType='reset'
                style={{ borderColor: '#CCCCCC', backgroundColor: '#CCCCCC', borderRadius: '5px', width: '100%', height: '34px', marginTop: 10 }}
              >
                <span className="textStyles-small" style={{ color: 'black', fontSize: 13 }}>Reset</span>
              </Button>
            </Collapse>
          </Form>
        </Modal>
      </div>
    );
  }
}

const AnyReactComponent = ({ text, onClick, children }) => (
  <div onClick={onClick} style={{ cursor: 'pointer' }}>
    {text && <div>{text}</div>}
    {children}
  </div>
);

export default MapsView;