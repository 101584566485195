import React, { Component, Fragment } from "react";
import checkPermissions from "../../utils/permissionManager";
import {
  Table,
  Tag,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  InputNumber,
  DatePicker,
  Popconfirm,
  message,
  Collapse,
  Switch,
  Modal,
  Row,
  Col,
  Card,
  Divider
} from "antd";
import { PlusCircleOutlined, DeleteOutlined, HomeOutlined, EditOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import {
  createRectifier,
  getRectifierDetails,
  UpdateRectifier,
  getReMasterData,
  dataUpdateGeneralDetails,
  getAllDropdowns,
} from "../../api/index";
import moment from "moment";
import AssetDelete from "../site_components/asset_delete";
import { checkServiceType } from "../../actions/service-type";
const { Option } = Select;

class Rectifiers extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

    this.state = {
      title: "",
      open: false,
      openEdit: false,
      openViewMoreDetails: false,
      selectedElement: "",
      tabaleLoading: false,
      actionView: true,
      selectedAssetId: null,
      AssetType: [],
      selectedMakeId: null,
      makeType: [],
      selectedModelId: null,
      modelType: [],
      rectifierControllerSerialNumber: "",
      rectifierMaterialCode: "",
      rectifierControllerSoftwareVersion: null,
      rectifierModulModel: "",
      rectifiermaterialCodeOfRectifierModul: "",
      rectifierMaximumCapacity: "",
      rectifiernoOfModuleSlot: "",
      rectifierEfficiency: "",
      rectifierModulesSerialNumbers: "",
      rectifierL1Current: "",
      rectifierL2Current: "",
      rectifierL3Current: "",
      rectifierL1Voltage: "",
      rectifierL2Voltage: "",
      rectifierL3Voltage: "",
      rectifierTotalDCLoad: "",
      rectifierPeakDCLoad: "",
      rectifierFreeBreakerAvailability: "",
      rectifierUsedBreakerCapacity: null,
      rectifierInstalledDate: null,
      rectifierNoOfBreakerSpacesAvailable: "",
      rectifierNoOfBreakerSpacesAvailable100A: "",
      rectifiernoOfPhases: "",
      rectifiernoOfModulesAtSite: "",
      rectifierNoOfPoweredRecModules: "",
      rectifierNoOfUnpoweredRecModules: "",
      rectifierControllerModel: "",
      rectifierMominalOperatingVoltage: "",
      AssetManagementPermission: false,
      noOfPhases: "",

      cabinet: null,
      rectifierModuleModel: null,
      rectifierName: "",
      rmsintegratedStatus: "",
      rmsinbandOutband: "",
      rmsConnectionMethod: "",
      rmsrelatedSimNumber: "",
      rectifierModuleModelId: null,
      cabinetId: null,
      switchStatus: true,
      objectSet: {
        rticketNumberOfPendingRectifierFaultyTickets: 0,
        rplannedFromOperationalTeam: false,
        rplannedFromPlanninglTeam: false,
        rwcuPlannedFromOperationalTeam: false,
        rwcuPlannedFromPlanninglTeam: false,
        rmPlannedFromOperationalTeam: false,
        rmPlannedFromPlanninglTeam: false,
      },
      dropDownObjectSet: {
        rectifierPlannedFromOperationalTeam: [],
        rectifierPlannedFromPlanninglTeam: [],
        rectifierWithCabinetPlannedFromOperationalTeam: [],
        rectifierWithCabinetPlannedFromPlanninglTeam: [],
        rectifierModulesPlannedFromOperationalTeam: [],
        rectifierModulesPlannedFromPlanninglTeam: [],
        rectifierControllerModel: [],
        rectifierControllerSoftwareVersion: [],
        rectifiernoOfModulesAtSite: [],
        rectifierNoOfPoweredRecModules: [],
        rmsintegratedStatus: [],
        rmsinbandOutband: [],
        rectifierNoOfBreakerSpacesAvailable: [],
        rectifierNoOfBreakerSpacesAvailable100A: [],
      },
    };
    this.requestFromClose = this.requestFromClose.bind(this);
    this.handleRectifierControllerSerialNumber =
      this.handleRectifierControllerSerialNumber.bind(this);
    this.handleMaterialCode = this.handleMaterialCode.bind(this);
    this.handlerectifierModulesSerialNumbers =
      this.handlerectifierModulesSerialNumbers.bind(this);
    this.handleRMSIntegratedStatus = this.handleRMSIntegratedStatus.bind(this);
    this.handleRMSInbandOutband = this.handleRMSInbandOutband.bind(this);
    this.handleRMSConnectionMethod = this.handleRMSConnectionMethod.bind(this);
    this.handleRMSRelatedSIMnumber = this.handleRMSRelatedSIMnumber.bind(this);
    this.handlerectifierControllerModel =
      this.handlerectifierControllerModel.bind(this);
    this.handlerectifierControllerSoftwareVersion =
      this.handlerectifierControllerSoftwareVersion.bind(this);
    this.handlerectifierModulModel = this.handlerectifierModulModel.bind(this);
    this.handlematerialCodeOfRectifierModul =
      this.handlematerialCodeOfRectifierModul.bind(this);
    this.handleMaximumCapacity = this.handleMaximumCapacity.bind(this);
    this.handlemoduleCapacity = this.handlemoduleCapacity.bind(this);
    this.handlenoOfModuleSlot = this.handlenoOfModuleSlot.bind(this);
    this.handleRectifierEfficiency = this.handleRectifierEfficiency.bind(this);
    this.handleRectifierL1Current = this.handleRectifierL1Current.bind(this);
    this.handleRectifierL2Current = this.handleRectifierL2Current.bind(this);
    this.handleRectifierL3Current = this.handleRectifierL3Current.bind(this);
    this.handleRectifierL1Voltage = this.handleRectifierL1Voltage.bind(this);
    this.handleRectifierL2Voltage = this.handleRectifierL2Voltage.bind(this);
    this.handleRectifierL3Voltage = this.handleRectifierL3Voltage.bind(this);
    this.handleRectifierTotalDCLoad =
      this.handleRectifierTotalDCLoad.bind(this);
    this.handleRectifierMominalOperatingVoltage =
      this.handleRectifierMominalOperatingVoltage.bind(this);
    this.handleRectifierPeakDCLoad = this.handleRectifierPeakDCLoad.bind(this);
    this.handleRectifierFreeBreakerAvailability =
      this.handleRectifierFreeBreakerAvailability.bind(this);
    this.handleRectifierUsedBreakerCapacity =
      this.handleRectifierUsedBreakerCapacity.bind(this);
    this.handleRectifierNoOfBreakerSpacesAvailable =
      this.handleRectifierNoOfBreakerSpacesAvailable.bind(this);
    this.handleRectifierNoOfBreakerSpacesAvailable80A =
      this.handleRectifierNoOfBreakerSpacesAvailable80A.bind(this);
    this.handleRectifierNoOfBreakerSpacesAvailable100A =
      this.handleRectifierNoOfBreakerSpacesAvailable100A.bind(this);
    this.handleRectifiernoOfPhases = this.handleRectifiernoOfPhases.bind(this);
    this.handleRectifiernoOfModulesAtSite =
      this.handleRectifiernoOfModulesAtSite.bind(this);
    this.handleRectifierNoOfPoweredRecModules =
      this.handleRectifierNoOfPoweredRecModules.bind(this);
    this.handleRectifierNoOfUnpoweredRecModules =
      this.handleRectifierNoOfUnpoweredRecModules.bind(this);
    this.handlerectifierName = this.handlerectifierName.bind(this);
  }

  componentDidMount() {
    //   this.getRectifierDropDownData();
    this.getReMasterData();
  }

  getReMasterData = async () => {
    var res = await getReMasterData();
    if (res.success) {
    }
  };

  getRectifierDropDownData = async () => {
    var res = await getAllDropdowns();
    if (res.success) {
      this.setState({
        dropDownObjectSet: {
          rectifierPlannedFromOperationalTeam:
            res.data["Rectifiers"]["Planned from Operational team"],
          rectifierPlannedFromPlanninglTeam:
            res.data["Rectifiers"]["Planned from Planning team"],
          rectifierWithCabinetPlannedFromOperationalTeam:
            res.data["Rectifier with Cabinet units"][
            "Planned from Operational team"
            ],
          rectifierWithCabinetPlannedFromPlanninglTeam:
            res.data["Rectifier with Cabinet units"][
            "Planned from Planningl team"
            ],
          rectifierModulesPlannedFromOperationalTeam:
            res.data["Rectifier modules"]["Planned from Operational team"],
          rectifierModulesPlannedFromPlanninglTeam:
            res.data["Rectifier modules"]["Planned from Planningl team"],
          rectifierControllerModel:
            res.data["Rectifier 1"]["Rectifier Controller Model"],
          rectifierControllerSoftwareVersion:
            res.data["Rectifier 1"]["Rectifier Controller software Version"],
          rectifiernoOfModulesAtSite:
            res.data["Rectifier 1"]["No. of modules at site"],
          rectifierNoOfPoweredRecModules:
            res.data["Rectifier 1"]["No. of Powered Rec Modules"],
          rmsintegratedStatus: res.data["Rectifier 1"]["RMS integrated status"],
          rmsinbandOutband: res.data["Rectifier 1"]["RMS inband/outband"],
          rectifierNoOfBreakerSpacesAvailable:
            res.data["Rectifier 1"][
            "No of breaker spaces available (size 80A or below)"
            ],
          rectifierNoOfBreakerSpacesAvailable100A:
            res.data["Rectifier 1"][
            "No of breaker spaces available (size 100A or above)"
            ],
        },
      });
    } else {
      message.warning(
        "Issue With Dropdown Data Fetching! : " + res.data.message
      );
    }
  };

  viewMoreDetails(element) {
    this.setState({
      showModal2: true,
      openViewMoreDetails: true,
      selectedElement: element,
    });
  }
  rectifierUpdata(id) {
    this.getDataRectifierDetails(id);
  }

  getDataRectifierDetails = async (id) => {
    var res = await getRectifierDetails(id);
    console.log(
      "getDataRectifierDetails-----------------------------------------------------------------------------------------------------"
    );
    console.log(res);
    console.log(res);
    if (res.success) {
      this.setState({
        rectifierEdit: res.data,
        rectifierId: res.data.id,
        selectedMakeId: res.data.make.id,
        makeType: res.data.make,
        selectedModelId: res.data.model.id,
        modelType: res.data.model,
        rectifierControllerSerialNumber:
          res.data.rectifierControllerSerialNumber,
        rectifierEfficiency: res.data.efficiency,
        rectifierFreeBreakerAvailability: res.data.freeBreakerAvailability,
        rectifierL1Current: res.data.l1Current,
        rectifierL1Voltage: res.data.l1Voltage,
        rectifierL2Current: res.data.l2Current,
        rectifierL2Voltage: res.data.l2Voltage,
        rectifierL3Current: res.data.l3Current,
        rectifierL3Voltage: res.data.l3Voltage,
        rectifierMaterialCode: res.data.materialCode,
        rectifiermaterialCodeOfRectifierModul:
          res.data.materialCodeOfRectifierModul,
        rectifierMaximumCapacity: res.data.maximumCapacity,
        rectifierModuleCapacity: res.data.moduleCapacity,
        rectifierNoOfBreakerSpacesAvailable:
          res.data.noOfBreakerSpacesAvailable,
        rectifierNoOfBreakerSpacesAvailable80A:
          res.data.noOfBreakerSpacesAvailable80A,
        rectifierNoOfBreakerSpacesAvailable100A:
          res.data.noOfBreakerSpacesAvailable100A,
        rectifiernoOfModuleSlot: res.data.noOfModuleSlot,
        rectifiernoOfModulesAtSite: res.data.noOfModulesAtSite,
        rectifiernoOfPhases: res.data.rectifiernoOfPhases,
        rectifierNoOfPoweredRecModules: res.data.noOfPoweredRecModules,
        rectifierNoOfUnpoweredRecModules: res.data.noOfUnpoweredRecModules,
        rectifierMominalOperatingVoltage: res.data.nominalOperatingVoltage,
        rectifierPeakDCLoad: res.data.peakDCLoad,
        rectifierControllerModel: res.data.rectifierControllerModel,
        rectifierControllerSerialNumber:
          res.data.rectifierControllerSerialNumber,
        rectifierControllerSoftwareVersion:
          res.data.rectifierControllerSoftwareVersion,
        rectifierModulModel: res.data.rectifierModulModel,
        rectifierModulesSerialNumbers: res.data.rectifierModulesSerialNumbers,
        rectifierTotalDCLoad: res.data.totalDCLoad,
        rectifierUsedBreakerCapacity: res.data.usedBreakerCapacity,
        rectifierName: res.data.name,
        rmsintegratedStatus: res.data.rMSIntegratedStatus,
        rmsinbandOutband: res.data.rMSinbandOutband,
        rmsConnectionMethod: res.data.rmsConnectionMethod,
        rmsrelatedSimNumber: res.data.rMSRelatedSimNumber,
        rectifierModuleModel: res.data.rectifierModuleModel,
        rectifierModuleModelId: res.data.rectifierModuleModel.id,
        noOfPhases: res.data.noOfPhases,
      });

      if (res.data.cabinet != null && res.data.cabinet.delete == false) {
        this.setState({
          cabinet: res.data.cabinet,
          cabinetId: res.data.cabinet.id,
        });
      } else {
        this.setState({
          cabinet: null,
          cabinetId: null,
        });
      }
      if (res.data?.installedDate != null) {
        this.setState({
          rectifierInstalledDate: res.data?.installedDate,
        });
      } else {
        this.setState({
          rectifierInstalledDate: "",
        });
      }
      this.setState({ openEdit: true, showModal1: true });
    }
  };

  handleRectifierNoOfUnpoweredRecModules(e) {
    // console.log(e)
    this.setState({ rectifierNoOfUnpoweredRecModules: e });
  }
  handleRectifierNoOfPoweredRecModules(e) {
    // console.log(e)
    this.setState({ rectifierNoOfPoweredRecModules: e });
  }
  handleRectifiernoOfModulesAtSite(e) {
    //console.log(e)
    this.setState({ rectifiernoOfModulesAtSite: e });
  }
  handleRectifiernoOfPhases(e) {
    // console.log(e)
    this.setState({ rectifiernoOfPhases: e });
  }
  handleRectifierNoOfBreakerSpacesAvailable100A(e) {
    // console.log(e)
    this.setState({ rectifierNoOfBreakerSpacesAvailable100A: e });
  }
  handleRectifierNoOfBreakerSpacesAvailable80A(e) {
    // console.log(e)
    this.setState({ rectifierNoOfBreakerSpacesAvailable80A: e });
  }
  handleRectifierNoOfBreakerSpacesAvailable(e) {
    //  console.log(e)
    this.setState({ rectifierNoOfBreakerSpacesAvailable: e });
  }
  handleRectifierUsedBreakerCapacity(e) {
    //  console.log(e)
    this.setState({ rectifierUsedBreakerCapacity: e });
  }
  handleRectifierFreeBreakerAvailability(e) {
    // console.log(e)
    this.setState({ rectifierFreeBreakerAvailability: e });
  }
  handleRectifierPeakDCLoad(e) {
    // console.log(e)
    this.setState({ rectifierPeakDCLoad: e });
  }
  handleRectifierMominalOperatingVoltage(e) {
    // console.log(e)
    this.setState({ rectifierMominalOperatingVoltage: e });
  }
  handleRectifierTotalDCLoad(e) {
    // console.log(e)
    this.setState({ rectifierTotalDCLoad: e });
  }
  handleRectifierL3Voltage(e) {
    // console.log(e)
    this.setState({ rectifierL3Voltage: e });
  }
  handleRectifierL2Voltage(e) {
    //  console.log(e)
    this.setState({ rectifierL2Voltage: e });
  }
  handleRectifierL1Voltage(e) {
    //  console.log(e)
    this.setState({ rectifierL1Voltage: e });
  }
  handleRectifierL3Current(e) {
    //  console.log(e)
    this.setState({ rectifierL3Current: e });
  }
  handleRectifierL2Current(e) {
    //  console.log(e)
    this.setState({ rectifierL2Current: e });
  }
  handleRectifierL1Current(e) {
    //console.log(e)
    this.setState({ rectifierL1Current: e });
  }
  handleRectifierEfficiency(e) {
    // console.log(e)
    this.setState({ rectifierEfficiency: e });
  }
  handlenoOfModuleSlot(e) {
    // console.log(e)
    this.setState({ rectifiernoOfModuleSlot: e });
  }
  handlemoduleCapacity(e) {
    // console.log(e)
    this.setState({ rectifierModuleCapacity: e });
  }
  handleMaximumCapacity(e) {
    // console.log(e)
    this.setState({ rectifierMaximumCapacity: e });
  }
  handlematerialCodeOfRectifierModul(e) {
    // console.log(e.target.value)
    this.setState({ rectifiermaterialCodeOfRectifierModul: e.target.value });
  }
  handlerectifierModulModel(e) {
    // console.log(e.target.value)
    this.setState({ rectifierModulModel: e.target.value });
  }
  handlerectifierControllerSoftwareVersion(e) {
    // console.log(e.target.value)
    this.setState({ rectifierControllerSoftwareVersion: e });
  }
  handlerectifierControllerModel(e) {
    this.setState({ rectifierControllerModel: e });
  }
  handlerectifierNoOfPhases(e) {
    this.setState({ rectifierNoPhases: e });
  }
  handleRMSRelatedSIMnumber(e) {
    // console.log(e.target.value)
    this.setState({ rmsrelatedSimNumber: e.target.value });
  }
  handleRMSConnectionMethod(e) {
    // console.log(e.target.value)
    this.setState({ rmsConnectionMethod: e });
  }
  handleRMSInbandOutband(e) {
    // console.log(e.target.value)
    this.setState({ rmsinbandOutband: e });
  }
  handleRMSIntegratedStatus(e) {
    // console.log(e.target.value)
    this.setState({ rmsintegratedStatus: e });
  }
  handlerectifierModulesSerialNumbers(e) {
    // console.log(e.target.value)
    this.setState({ rectifierModulesSerialNumbers: e.target.value });
  }
  handleMaterialCode(e) {
    // console.log(e.target.value)
    this.setState({ rectifierMaterialCode: e.target.value });
  }
  handleRectifierControllerSerialNumber(e) {
    // console.log(e.target.value)
    this.setState({ rectifierControllerSerialNumber: e.target.value });
  }
  handlerectifierName(e) {
    // console.log(e.target.value)
    this.setState({ rectifierName: e.target.value });
  }
  onChangeMake = (value) => {
    // console.log("onChangeMake");
    //console.log(value);
    this.setState({
      selectedMakeId: value,
      makeType: {
        id: value,
      },
    });
  };
  onChangeAsset = (value) => {
    // console.log("onChangeAsset");
    //console.log(value);
    this.setState({
      selectedAssetId: value,
      AssetType: {
        id: value,
      },
    });
  };
  onChangeModel = (value) => {
    // console.log("onChangeModel");
    //console.log(value);
    this.setState({
      selectedModelId: value,
      modelType: {
        id: value,
      },
    });
  };
  onChangeInstalledDate = (date, dateString) => {
    // console.log(date);
    // console.log(dateString);
    this.setState({
      rectifierInstalledDate: dateString,
    });
  };
  onDrawerClose = () => {
    this.formRef.current.resetFields();
    this.validator.hideMessages();
    this.setState({
      open: false,
      openEdit: false,
      selectedAssetId: null,
      AssetType: [],
      selectedMakeId: null,
      makeType: [],
      selectedModelId: null,
      modelType: [],
      rectifierControllerSerialNumber: "",
      rectifierMaterialCode: "",
      rectifierControllerSoftwareVersion: null,
      rectifierModulModel: "",
      rectifiermaterialCodeOfRectifierModul: "",
      rectifierMaximumCapacity: "",
      rectifiernoOfModuleSlot: "",
      rectifierEfficiency: "",
      rectifierModulesSerialNumbers: "",
      rmsintegratedStatus: "",
      rmsinbandOutband: "",
      rmsConnectionMethod: "",
      rmsrelatedSimNumber: "",
      rectifierL1Current: "",
      rectifierL2Current: "",
      rectifierL3Current: "",
      rectifierL1Voltage: "",
      rectifierL2Voltage: "",
      rectifierL3Voltage: "",
      rectifierTotalDCLoad: "",
      rectifierPeakDCLoad: "",
      rectifierFreeBreakerAvailability: "",
      rectifierUsedBreakerCapacity: "",
      rectifierInstalledDate: null,
      rectifierNoOfBreakerSpacesAvailable: "",
      rectifierNoOfBreakerSpacesAvailable100A: "",
      rectifiernoOfPhases: "",
      rectifiernoOfModulesAtSite: "",
      rectifierNoOfPoweredRecModules: "",
      rectifierNoOfUnpoweredRecModules: "",
      rectifierControllerModel: "",
      rectifierMominalOperatingVoltage: "",
      cabinet: null,
      rectifierModuleModel: null,
      rectifierName: "",
      rectifierModuleModelId: null,
      cabinetId: null,
      noOfPhases: "",
    });
  };
  requestFromClose() {
    // console.log('dsdsd')
    this.setState({ showModal: true, open: true });
  }
  componentDidMount() {
    if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
      const { history } = this.props;

      history.push("/");
    } else {
      this.getSetPageData(
        JSON.parse(localStorage.getItem("serviceTypeSet")),
        38
      );
    }
    checkServiceType(38).then((data) =>
      this.setState(
        {
          AssetManagementPermission: data,
        },
        () => { }
      )
    );
    this.setState({
      objectSet: {
        rticketNumberOfPendingRectifierFaultyTickets:
          this.props.details?.access_technologies_data
            ?.rticketNumberOfPendingRectifierFaultyTickets,
        rplannedFromOperationalTeam:
          this.props.details?.access_technologies_data
            ?.rplannedFromOperationalTeam,
        rplannedFromPlanninglTeam:
          this.props.details?.access_technologies_data
            ?.rplannedFromPlanninglTeam,
        rwcuPlannedFromOperationalTeam:
          this.props.details?.access_technologies_data
            ?.rwcuPlannedFromOperationalTeam,
        rwcuPlannedFromPlanninglTeam:
          this.props.details?.access_technologies_data
            ?.rwcuPlannedFromPlanninglTeam,
        rmPlannedFromOperationalTeam:
          this.props.details?.access_technologies_data
            ?.rmPlannedFromOperationalTeam,
        rmPlannedFromPlanninglTeam:
          this.props.details?.access_technologies_data
            ?.rmPlannedFromPlanninglTeam,
      },
    });
  }
  getSetPageData = (serviceTypeIs, Pageid) => {
    if (
      serviceTypeIs.serviceType.filter(
        (checkuser) => checkuser?.id == Pageid
      )[0] != null
    ) {
      this.setState({
        actionView: true,
      });
    }
  };
  AllDataSubmitUpdate = async () => {
    if (this.validator.allValid()) {
      var dataSet;
      dataSet = {
        id: this.state.rectifierId,

        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        make: this.state.makeType,
        model: this.state.modelType,
        rectifierControllerSerialNumber:
          this.state.rectifierControllerSerialNumber,
        rectifierControllerSoftwareVersion:
          this.state.rectifierControllerSoftwareVersion,
        rectifierModulModel: this.state.rectifierModulModel,
        rectifierModulesSerialNumbers: this.state.rectifierModulesSerialNumbers,
        l1Current: this.state.rectifierL1Current,
        l2Current: this.state.rectifierL2Current,
        l3Current: this.state.rectifierL3Current,
        l1Voltage: this.state.rectifierL1Voltage,
        l2Voltage: this.state.rectifierL2Voltage,
        l3Voltage: this.state.rectifierL3Voltage,
        totalDCLoad: this.state.rectifierTotalDCLoad,
        nominalOperatingVoltage: this.state.rectifierMominalOperatingVoltage,
        peakDCLoad: this.state.rectifierPeakDCLoad,
        freeBreakerAvailability: this.state.rectifierFreeBreakerAvailability,
        usedBreakerCapacity: this.state.rectifierUsedBreakerCapacity,
        installedDate: this.state.rectifierInstalledDate,
        noOfBreakerSpacesAvailable:
          this.state.rectifierNoOfBreakerSpacesAvailable,
        noOfBreakerSpacesAvailable80A:
          this.state.rectifierNoOfBreakerSpacesAvailable80A,
        noOfBreakerSpacesAvailable100A:
          this.state.rectifierNoOfBreakerSpacesAvailable100A,
        noOfModulesAtSite: this.state.rectifiernoOfModulesAtSite,
        noOfPoweredRecModules: this.state.rectifierNoOfPoweredRecModules,
        rectifierControllerModel: this.state.rectifierControllerModel,
        noOfUnpoweredRecModules: this.state.rectifierNoOfUnpoweredRecModules,

        cabinet: this.state.cabinet,
        rectifierModuleModel: this.state.rectifierModuleModel,
        name: this.state.rectifierName,
        rmsintegratedStatus: this.state.rmsintegratedStatus,
        rmsinbandOutband: this.state.rmsinbandOutband,
        rmsConnectionMethod: this.state.rmsConnectionMethod,
        rmsrelatedSimNumber: this.state.rmsrelatedSimNumber,
        noOfPhases: this.state.noOfPhases,
      };

      var res = await UpdateRectifier(dataSet);
      if (res.success) {
        message.success("Successfully Rectifier Edited!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,
          openEdit: false,
          selectedAssetId: null,
          AssetType: [],
          selectedMakeId: null,
          makeType: [],
          selectedModelId: null,
          modelType: [],
          rectifierControllerSerialNumber: "",
          rectifierMaterialCode: "",
          rectifierControllerSoftwareVersion: null,
          rectifierModulModel: "",
          rectifiermaterialCodeOfRectifierModul: "",
          rectifierMaximumCapacity: "",
          rectifiernoOfModuleSlot: "",
          rectifierEfficiency: "",
          rectifierModulesSerialNumbers: "",
          rmsintegratedStatus: "",
          rmsinbandOutband: "",
          rmsConnectionMethod: "",
          rmsrelatedSimNumber: "",
          rectifierL1Current: "",
          rectifierL2Current: "",
          rectifierL3Current: "",
          rectifierL1Voltage: "",
          rectifierL2Voltage: "",
          rectifierL3Voltage: "",
          rectifierTotalDCLoad: "",
          rectifierPeakDCLoad: "",
          rectifierFreeBreakerAvailability: "",
          rectifierUsedBreakerCapacity: "",
          rectifierInstalledDate: null,
          rectifierNoOfBreakerSpacesAvailable: "",
          rectifierNoOfBreakerSpacesAvailable100A: "",
          rectifiernoOfPhases: "",
          rectifiernoOfModulesAtSite: "",
          rectifierNoOfPoweredRecModules: "",
          rectifierNoOfUnpoweredRecModules: "",
          rectifierControllerModel: "",
          rectifierMominalOperatingVoltage: "",
          cabinet: null,
          rectifierModuleModel: null,
          rectifierName: "",
          rectifierModuleModelId: null,
          cabinetId: null,
          noOfPhases: "",
        });
        this.props.parentMethod();
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  AllDataSubmit = async () => {
    if (this.validator.allValid()) {
      var dataSet;
      dataSet = {
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        make: this.state.makeType,
        model: this.state.modelType,
        rectifierControllerSerialNumber:
          this.state.rectifierControllerSerialNumber,
        rectifierControllerSoftwareVersion:
          this.state.rectifierControllerSoftwareVersion,
        rectifierModulModel: this.state.rectifierModulModel,
        rectifierModulesSerialNumbers: this.state.rectifierModulesSerialNumbers,
        l1Current: this.state.rectifierL1Current,
        l2Current: this.state.rectifierL2Current,
        l3Current: this.state.rectifierL3Current,
        l1Voltage: this.state.rectifierL1Voltage,
        l2Voltage: this.state.rectifierL2Voltage,
        l3Voltage: this.state.rectifierL3Voltage,
        totalDCLoad: this.state.rectifierTotalDCLoad,
        nominalOperatingVoltage: this.state.rectifierMominalOperatingVoltage,
        peakDCLoad: this.state.rectifierPeakDCLoad,
        freeBreakerAvailability: this.state.rectifierFreeBreakerAvailability,
        usedBreakerCapacity: this.state.rectifierUsedBreakerCapacity,
        installedDate: this.state.rectifierInstalledDate,
        noOfBreakerSpacesAvailable:
          this.state.rectifierNoOfBreakerSpacesAvailable,
        noOfBreakerSpacesAvailable80A:
          this.state.rectifierNoOfBreakerSpacesAvailable80A,
        noOfBreakerSpacesAvailable100A:
          this.state.rectifierNoOfBreakerSpacesAvailable100A,
        noOfModulesAtSite: this.state.rectifiernoOfModulesAtSite,
        noOfPoweredRecModules: this.state.rectifierNoOfPoweredRecModules,
        rectifierControllerModel: this.state.rectifierControllerModel,
        noOfUnpoweredRecModules: this.state.rectifierNoOfUnpoweredRecModules,

        cabinet: this.state.cabinet,
        rectifierModuleModel: this.state.rectifierModuleModel,
        name:
          this.state.rectifierName != ""
            ? this.state.rectifierName
            : "Rectifier-",
        rmsintegratedStatus: this.state.rmsintegratedStatus,
        rmsinbandOutband: this.state.rmsinbandOutband,
        rmsConnectionMethod: this.state.rmsConnectionMethod,
        rmsrelatedSimNumber: this.state.rmsrelatedSimNumber,
        noOfPhases: this.state.noOfPhases,
      };

      var res = await createRectifier(dataSet);
      if (res.success) {
        message.success("Successfully Rectifier Added!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,
          openEdit: false,
          selectedAssetId: null,
          AssetType: [],
          selectedMakeId: null,
          makeType: [],
          selectedModelId: null,
          modelType: [],
          rectifierControllerSerialNumber: "",
          rectifierMaterialCode: "",
          rectifierControllerSoftwareVersion: null,
          rectifierModulModel: "",
          rectifiermaterialCodeOfRectifierModul: "",
          rectifierMaximumCapacity: "",
          rectifiernoOfModuleSlot: "",
          rectifierEfficiency: "",
          rectifierModulesSerialNumbers: "",
          rmsintegratedStatus: "",
          rmsinbandOutband: "",
          rmsConnectionMethod: "",
          rmsrelatedSimNumber: "",
          rectifierL1Current: "",
          rectifierL2Current: "",
          rectifierL3Current: "",
          rectifierL1Voltage: "",
          rectifierL2Voltage: "",
          rectifierL3Voltage: "",
          rectifierTotalDCLoad: "",
          rectifierPeakDCLoad: "",
          rectifierFreeBreakerAvailability: "",
          rectifierUsedBreakerCapacity: "",
          rectifierInstalledDate: null,
          rectifierNoOfBreakerSpacesAvailable: "",
          rectifierNoOfBreakerSpacesAvailable100A: "",
          rectifiernoOfPhases: "",
          rectifiernoOfModulesAtSite: "",
          rectifierNoOfPoweredRecModules: "",
          rectifierNoOfUnpoweredRecModules: "",
          rectifierControllerModel: "",
          rectifierMominalOperatingVoltage: "",
          cabinet: null,
          rectifierModuleModel: null,
          rectifierName: "",
          rectifierModuleModelId: null,
          cabinetId: null,
          noOfPhases: "",
        });
        this.props.parentMethod();
        // const { history } = this.props;
        // history.push("/site");
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  getDataDetailsForDelete = async (id) => {
    var res = await getRectifierDetails(id);
    if (res.success) {
      if (
        res.data?.renewableEnergyWind.filter((name) => name.delete == false)
          .length == 0 &&
        res.data?.renewableEnergySolar.filter((name) => name.delete == false)
          .length == 0 &&
        res.data?.batteryBank.filter((name) => name.delete == false).length == 0
      ) {
        var respons = AssetDelete.Action(
          res.data,
          this.props?.detailsSite?.site_details.id,
          UpdateRectifier
        );
        respons.then((value) => {
          if (value == 1) {
            message.success("Successfully Delete!");
            this.props.parentMethod();
          } else {
            message.warning(" Something went wrong!");
          }
        });
      } else {
        message.error(
          "You can not delete this Rectifier because the related RE-Solars or RE-Winds or Battery Banks!"
        );
      }
    }
  };

  submitForm = async (item) => {
    this.getDataDetailsForDelete(item);
  };

  onChangeCabinet = (value) => {
    if (value != undefined) {
      this.setState({
        cabinet: { id: value },
      });
    } else {
      this.setState({
        cabinet: null,
      });
    }
  };

  onChangeRectifierModuleModel = (value) => {
    this.setState({
      rectifierModuleModel: { id: value },
    });
  };

  onDrawerCloseForEdit = () => {
    this.setState({
      showModal1: false,
      openViewMoreDetails: false,
    });
  };

  onViewMoreDetailsDrawerClose = () => {
    this.setState({
      showModal2: false,
      openViewMoreDetails: false,
    });
  };

  validatedTextLable = (field) => {
    return field !== null && field !== "" ? (
      <span class="badge badge-outline-secondary text-muted">{field}</span>
    ) : (
      <span class="badge text-danger">Currently Not Set</span>
    );
  };

  dataUpdate() {
    this.setState({
      switchStatus: false,
    });
  }

  close = async () => {
    this.props.parentMethod();
    this.setState({
      objectSet: {
        rticketNumberOfPendingRectifierFaultyTickets:
          this.props.details?.access_technologies_data
            ?.rticketNumberOfPendingRectifierFaultyTickets,
        rplannedFromOperationalTeam:
          this.props?.details?.access_technologies_data
            ?.rplannedFromOperationalTeam,
        rplannedFromPlanninglTeam:
          this.props.details?.access_technologies_data
            ?.rplannedFromPlanninglTeam,
        rwcuPlannedFromOperationalTeam:
          this.props.details?.access_technologies_data
            ?.rwcuPlannedFromOperationalTeam,
        rwcuPlannedFromPlanninglTeam:
          this.props.details?.access_technologies_data
            ?.rwcuPlannedFromPlanninglTeam,
        rmPlannedFromOperationalTeam:
          this.props.details?.access_technologies_data
            ?.rmPlannedFromOperationalTeam,
        rmPlannedFromPlanninglTeam:
          this.props.details?.access_technologies_data
            ?.rmPlannedFromPlanninglTeam,
      },
    });
    this.setState({
      switchStatus: true,
    });
  };
  dataSetToUpdate = async () => {
    console.log(this.state.objectSet);
    console.log(this.props.detailsupdateGeneralDetails.updateGeneralDetails);
    let remoteJob = {
      ...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
      ...this.state.objectSet,
    };

    console.log(remoteJob);
    const formData = new FormData();
    formData.append("id", this.props.details?.access_technologies_data?.id);
    formData.append("object", JSON.stringify(remoteJob));

    var res = await dataUpdateGeneralDetails(formData);
    console.log(res);

    if (res.success) {
      message.success("Successfully Update!");
      this.props.parentMethod();
      this.setState({
        switchStatus: true,
      });
    } else {
      message.warning(" Something went wrong!");
    }
  };
  handlrticketNumberOfPendingRectifierFaultyTickets = (e) => {
    this.setState(
      {
        objectSet: {
          ...this.state.objectSet,
          rticketNumberOfPendingRectifierFaultyTickets: e,
        },
      },
      () => {
        console.log(this.state.objectSet);
      }
    );
  };
  switchrmPlannedFromPlanninglTeam() {
    this.state.objectSet["rmPlannedFromPlanninglTeam"] =
      !this.state.objectSet["rmPlannedFromPlanninglTeam"];
  }
  switchrmPlannedFromOperationalTeam() {
    this.state.objectSet["rmPlannedFromOperationalTeam"] =
      !this.state.objectSet["rmPlannedFromOperationalTeam"];
  }
  switchrwcuPlannedFromPlanninglTeam() {
    this.state.objectSet["rwcuPlannedFromPlanninglTeam"] =
      !this.state.objectSet["rwcuPlannedFromPlanninglTeam"];
  }
  switchTorwcuPlannedFromOperationalTeam() {
    this.state.objectSet["rwcuPlannedFromOperationalTeam"] =
      !this.state.objectSet["rwcuPlannedFromOperationalTeam"];
  }
  switchTorplannedFromPlanninglTeam() {
    this.state.objectSet["rplannedFromPlanninglTeam"] =
      !this.state.objectSet["rplannedFromPlanninglTeam"];
  }
  switchTorplannedFromOperationalTeam() {
    this.state.objectSet["rplannedFromOperationalTeam"] =
      !this.state.objectSet["rplannedFromOperationalTeam"];
  }

  render() {
    const { Option } = Select;
    const { Panel } = Collapse;
    const _rectifier = this.state.selectedElement;

    const columns2 = [
      {
        title: "Rectifier Name",
        details: [],
        content:
          _rectifier !== "" ? (
            <Tag color="blue" style={{ margin: "0px" }}>
              <span>{_rectifier.name}</span>
            </Tag>
          ) : (
            <></>
          ),
      },
      {
        title: "Make",
        content:
          _rectifier !== "" ? (
            <span class="badge bg-secondary">{_rectifier.make?.name}</span>
          ) : (
            <></>
          ),
        details: [],
      },
      {
        title: "Rectifier Model",
        content:
          _rectifier !== "" ? (
            <span class="badge badge-outline-info badge-pill">
              <i class="fas fa-hdd" style={{ paddingRight: "5px" }} />
              {_rectifier.model?.name}
            </span>
          ) : (
            <></>
          ),
        details: [
          {
            title: "Material Code of the Rectifier",
            content: this.validatedTextLable(_rectifier.model?.materialCode),
          },
          {
            title: "Maximum Capacity(kW)",
            content: this.validatedTextLable(_rectifier.model?.maximumCapacity),
          },
          {
            title: "No. of Module Slots",
            content: this.validatedTextLable(_rectifier.model?.noOfModuleSlot),
          },
        ],
      },
      {
        title: "Rectifier Controller Model",
        details: [],
        content: this.validatedTextLable(_rectifier.rectifierControllerModel),
      },
      {
        title: "Rectifier Controller Serial Number",
        content: (
          <Tag color="gray" style={{ margin: "0px" }}>
            <span>{_rectifier.rectifierControllerSerialNumber}</span>
          </Tag>
        ),
        details: [],
      },
      {
        title: "Rectifier Controller Software Version",
        details: [],
        content: this.validatedTextLable(
          _rectifier.rectifierControllerSoftwareVersion
        ),
      },
      {
        title: "No of phases",
        details: [],
        content: this.validatedTextLable(_rectifier.noOfPhases),
      },
      {
        title: "Rectifier Module Model",
        content: this.validatedTextLable(_rectifier.rectifierModuleModel?.name),
        details: [
          {
            title: "Material Code of the rectifier module",
            content: this.validatedTextLable(
              _rectifier.rectifierModuleModel?.materialCodeOfRectifierModul
            ),
          },
          {
            title: "Module Capacity(kW)",
            content: this.validatedTextLable(
              _rectifier.rectifierModuleModel?.moduleCapacity
            ),
          },
          {
            title: "Module Efficiency",
            content: this.validatedTextLable(
              _rectifier.rectifierModuleModel?.efficiency
            ),
          },
        ],
      },
      // {
      //     title: "Maximum Capacity (kW)",
      //     details: [],
      //     content: this.validatedTextLable(_rectifier.rectifierModuleModel?.maximumCapacity)
      // },
      // {
      //     title: "Module Capacity (kW)",
      //     details: [],
      //     content: this.validatedTextLable(_rectifier.rectifierModuleModel?.moduleCapacity)
      // },
      {
        title: "No. of Module At Site",
        content: this.validatedTextLable(_rectifier.noOfModulesAtSite),
        details: [],
      },
      {
        title: "No. of Powered Rec Modules",
        details: [],
        content: this.validatedTextLable(_rectifier.noOfPoweredRecModules),
      },
      {
        title: "No. of Unpowered Rec Module Slots",
        details: [],
        content: this.validatedTextLable(_rectifier.noOfUnpoweredRecModules),
      },
      {
        title: "Rectifier Modules Serial Numbers",
        details: [],
        content: this.validatedTextLable(
          _rectifier.rectifierModulesSerialNumbers
        ),
      },
      {
        title: "L1 Current (A)",
        details: [],
        content: this.validatedTextLable(_rectifier.l1Current),
      },
      {
        title: "L2 Current (A)",
        details: [],
        content: this.validatedTextLable(_rectifier.l2Current),
      },
      {
        title: "L3 Current (A)",
        details: [],
        content: this.validatedTextLable(_rectifier.l3Current),
      },
      {
        title: "L1 Voltage (V)",
        details: [],
        content: this.validatedTextLable(_rectifier.l1Voltage),
      },
      {
        title: "L2 Voltage (V)",
        details: [],
        content: this.validatedTextLable(_rectifier.l2Voltage),
      },
      {
        title: "L3 Voltage (V)",
        details: [],
        content: this.validatedTextLable(_rectifier.l3Voltage),
      },
      {
        title: "Total DC load (A)",
        details: [],
        content: this.validatedTextLable(_rectifier.totalDCLoad),
      },
      {
        title: "Nominal Operating voltage (V)",
        details: [],
        content: this.validatedTextLable(_rectifier.nominalOperatingVoltage),
      },
      {
        title: "Peak DC load (A)",
        details: [],
        content: this.validatedTextLable(_rectifier.peakDCLoad),
      },
      {
        title: "Free Breaker Availability",
        details: [],
        content: this.validatedTextLable(_rectifier.freeBreakerAvailability),
      },
      {
        title: "Used Breaker Capacity",
        details: [],
        content: this.validatedTextLable(_rectifier.usedBreakerCapacity),
      },
      {
        title: "No of Breaker Spaces Available (Size 80A Or Below)",
        details: [],
        content: this.validatedTextLable(
          _rectifier.noOfBreakerSpacesAvailable80A
        ),
      },
      {
        title: "No of Breaker Spaces Available (size 100A or above)",
        details: [],
        content: this.validatedTextLable(
          _rectifier.noOfBreakerSpacesAvailable100A
        ),
      },
      {
        title: "RMS Integrated Status",
        details: [],
        content: this.validatedTextLable(_rectifier.rMSIntegratedStatus),
      },
      {
        title: "RMS Inband/Outband",
        details: [],
        content: this.validatedTextLable(_rectifier.rMSinbandOutband),
      },
      {
        title: "RMS Connection Method",
        details: [],
        content: this.validatedTextLable(_rectifier.rmsConnectionMethod),
      },
      {
        title: "RMS related SIM number",
        details: [],
        content: this.validatedTextLable(_rectifier.rMSRelatedSimNumber),
      },
      {
        title: "Installed Date",
        details: [],
        content: this.validatedTextLable(_rectifier.installedDate),
      },
      // --------------------------------------------------------------------------------------------
      {
        title: "Battery Banks",
        content:
          _rectifier !== "" ? (
            _rectifier.batteryBank.filter((name) => name.delete == false)
              .length != 0 ? (
              <div class="d-flex justify-content-end align-items-center">
                {_rectifier.batteryBank
                  ?.filter((name) => name.delete == false)
                  .map((e) => (
                    <span
                      class="badge badge-soft-success fw-semibold"
                      style={{ marginLeft: "5px" }}
                    >
                      {e.name}
                    </span>
                  ))}
              </div>
            ) : (
              <span class="badge badge-outline-secondary text-muted">No</span>
            )
          ) : (
            <></>
          ),
        details: [],
      },
      {
        title: "Renewable Energy Solars",
        content:
          _rectifier !== "" ? (
            _rectifier.renewableEnergySolar.filter(
              (name) => name.delete == false
            ).length != 0 ? (
              <div class="d-flex justify-content-end align-items-center">
                {_rectifier.renewableEnergySolar
                  ?.filter((name) => name.delete == false)
                  .map((e) => (
                    <span
                      class="badge badge-soft-pink fw-semibold"
                      style={{ marginLeft: "5px" }}
                    >
                      {e.id}
                    </span>
                  ))}
              </div>
            ) : (
              <span class="badge badge-outline-secondary text-muted">No</span>
            )
          ) : (
            <></>
          ),
        details: [],
      },
      {
        title: "Renewable Energy Winds",
        content:
          _rectifier !== "" ? (
            _rectifier.renewableEnergyWind.filter(
              (name) => name.delete == false
            ).length != 0 ? (
              <div class="d-flex justify-content-end align-items-center">
                {_rectifier.renewableEnergyWind
                  ?.filter((name) => name.delete == false)
                  .map((e) => (
                    <span
                      class="badge badge-soft-warning fw-semibold"
                      style={{ marginLeft: "5px" }}
                    >
                      {e.windPotential}
                    </span>
                  ))}
              </div>
            ) : (
              <span class="badge badge-outline-secondary text-muted">No</span>
            )
          ) : (
            <></>
          ),
        details: [],
      },
      {
        title: "Cabinet",
        content:
          _rectifier !== "" && _rectifier.cabinet?.delete == false ? (
            <span
              class="badge badge-soft-pink fw-semibold"
              style={{ marginLeft: "5px" }}
            >
              {_rectifier.cabinet?.name}
            </span>
          ) : (
            <></>
          ),
        details: [],
      },
    ];

    return (
      <>
        <div className="row">
          <div className="row">
            <div class="col-lg-12">
              <div class="text-end">
                <ul class="list-inline">
                  <li class="list-inline-item">

                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
            <div className="d-flex justify-content-end align-items-center" style={{ marginBottom: "10px", marginRight: "10px" }}>
              {this.state.switchStatus
                ? 
                checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                <Fragment>
                    <button 
                    style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }} 
                    type="button" 
                    class="btn btn-soft-primary btn-icon-square-sm" 
                    onClick={() => this.dataUpdate()}> 
                    <i class="mdi mdi-pencil-outline" style={{color: 'white'}}></i></button>
                    {this.state.actionView ? (
                      <Button
                        type="button"
                        class="btn btn-soft-primary btn-sm"
                        style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginLeft: 10 }}
                        onClick={this.requestFromClose}
                        icon={<PlusCircleOutlined style={{ color: 'white' }} />}>
                        <span className="textStyles-small" style={{ color: 'white' }}>New Asset</span>
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Fragment>) : null
                : <div class="d-flex justify-content-end align-items-center">
                  <button type="button" class="btn btn-soft-secondary  textStyles-small" onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
                  <button type="button" class="btn btn-soft-primary  textStyles-small" onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                </div>
              }
            </div>
            <ul class="list-group">

              <Row span={24}>
                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        <span className="textStyles-small">
                          No of Rectifiers
                        </span>
                      </div>
                      {this.props.details?.access_technologies_data
                        ?.noOfRectifiers != null ? (
                        <span class="badge badge-outline-success textStyles-small">
                          {
                            this.props.details2.rectifiers_data.length
                          }
                        </span>
                      ) : (
                        <span class="badge badge-outline-warning textStyles-small">
                          Currently Not Set
                        </span>
                      )}
                    </li>
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                      <div>
                        <span className="textStyles-small">
                          Ticket number of Pending Rectifier Faulty Tickets
                        </span>
                      </div>

                      {this.state.switchStatus ? (
                        <>
                          {this.props.details?.access_technologies_data
                            ?.rticketNumberOfPendingRectifierFaultyTickets !=
                            null ? (
                            <span class="badge badge-outline-success textStyles-small">
                              {
                                this.props.details?.access_technologies_data
                                  ?.rticketNumberOfPendingRectifierFaultyTickets
                              }
                            </span>
                          ) : (
                            <span class="badge badge-outline-warning textStyles-small">
                              Currently Not Set
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          <InputNumber
                            min={0}
                            style={{ width: '120px', height: 34 }}
                            className='borderedSelect'
                            value={
                              this.state.objectSet
                                ?.rticketNumberOfPendingRectifierFaultyTickets
                            }
                            id="cnoOfCabinetsWithEquipmentInstalled"
                            onChange={
                              this
                                .handlrticketNumberOfPendingRectifierFaultyTickets
                            }
                          />
                        </>
                      )}
                    </li>
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                      <div>
                        <span className="textStyles-small">
                          Planned From Operational Team
                        </span>
                      </div>
                      <div>
                        {this.state.switchStatus ? (
                          this.props.details?.access_technologies_data
                            ?.rplannedFromOperationalTeam != null ||
                            this.props.details?.access_technologies_data
                              ?.rplannedFromOperationalTeam != undefined ? (
                            <span className="badge badge-outline-success textStyles-small">
                              {this.props.details?.access_technologies_data?.rplannedFromOperationalTeam.toString()}
                            </span>
                          ) : (
                            <span className="badge badge-outline-danger textStyles-small">
                              Not Defined Yet
                            </span>
                          )
                        ) : (
                          <Select
                            defaultValue={
                              this.props.details?.access_technologies_data
                                ?.rplannedFromOperationalTeam
                            }
                            style={{ width: '120px' }}
                            bordered={false}
                            className='borderedSelect'
                            onChange={(e) => {
                              this.state.objectSet.rplannedFromOperationalTeam =
                                e;
                            }}
                          >
                            {this.props.dropDownInfoRectifier[
                              "dropDownObjectSetRectifier"
                            ]["rectifierPlannedFromOperationalTeam"].map(
                              (x, y) => {
                                return <Option value={x}>{x}</Option>;
                              }
                            )}
                          </Select>
                        )}
                      </div>
                    </li>
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        <span className="textStyles-small">
                          Planned From Planning Team
                        </span>
                      </div>

                      <div>
                        {this.state.switchStatus ? (
                          this.props.details?.access_technologies_data
                            ?.rplannedFromPlanninglTeam != null ||
                            this.props.details?.access_technologies_data
                              ?.rplannedFromPlanninglTeam != undefined ? (
                            <span className="badge badge-outline-success textStyles-small">
                              {this.props.details?.access_technologies_data?.rplannedFromPlanninglTeam.toString()}
                            </span>
                          ) : (
                            <span className="badge badge-outline-danger textStyles-small">
                              Not Defined Yet
                            </span>
                          )
                        ) : (
                          <Select
                            defaultValue={
                              this.props.details?.access_technologies_data
                                ?.rplannedFromPlanninglTeam
                            }
                            style={{ width: '120px' }}
                            bordered={false}
                            className='borderedSelect'
                            onChange={(e) => {
                              this.state.objectSet.rplannedFromPlanninglTeam =
                                e;
                            }}
                          >
                            {this.props.dropDownInfoRectifier[
                              "dropDownObjectSetRectifier"
                            ]["rectifierPlannedFromPlanninglTeam"].map(
                              (x, y) => {
                                return <Option value={x}>{x}</Option>;
                              }
                            )}
                          </Select>
                        )}
                      </div>
                    </li>
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        <span className="textStyles-small">
                          Rectifier With Cabinet Units Planned From Operational Team
                        </span>
                      </div>
                      <div>
                        {this.state.switchStatus ? (
                          this.props.details?.access_technologies_data
                            ?.rwcuPlannedFromOperationalTeam != null ||
                            this.props.details?.access_technologies_data
                              ?.rwcuPlannedFromOperationalTeam !=
                            undefined ? (
                            <span className="badge badge-outline-success textStyles-small">
                              {this.props.details?.access_technologies_data?.rwcuPlannedFromOperationalTeam.toString()}
                            </span>
                          ) : (
                            <span className="badge badge-outline-danger textStyles-small">
                              Not Defined Yet
                            </span>
                          )
                        ) : (
                          <Select
                            defaultValue={
                              this.props.details?.access_technologies_data
                                ?.rwcuPlannedFromOperationalTeam
                            }
                            style={{ width: '120px' }}
                            bordered={false}
                            className='borderedSelect'
                            onChange={(e) => {
                              this.state.objectSet.rwcuPlannedFromOperationalTeam =
                                e;
                            }}
                          >
                            {this.props.dropDownInfoRectifier[
                              "dropDownObjectSetRectifier"
                            ][
                              "rectifierWithCabinetPlannedFromOperationalTeam"
                            ].map((x, y) => {
                              return <Option value={x}>{x}</Option>;
                            })}
                          </Select>
                        )}
                      </div>
                    </li>
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                      <div>
                        <span className="textStyles-small">
                          Rectifier with Cabinet units Planned From Planning
                          Team </span>
                      </div><div>
                        {this.state.switchStatus ? (
                          this.props.details?.access_technologies_data
                            ?.rwcuPlannedFromPlanninglTeam != null ||
                            this.props.details?.access_technologies_data
                              ?.rwcuPlannedFromPlanninglTeam != undefined ? (
                            <span className="badge badge-outline-success textStyles-small">
                              {this.props.details?.access_technologies_data?.rwcuPlannedFromPlanninglTeam.toString()}
                            </span>
                          ) : (
                            <span className="badge badge-outline-danger textStyles-small">
                              Not Defined Yet
                            </span>
                          )
                        ) : (
                          <Select
                            defaultValue={
                              this.props.details?.access_technologies_data
                                ?.rwcuPlannedFromPlanninglTeam
                            }
                            style={{ width: '120px' }}
                            bordered={false}
                            className='borderedSelect'
                            onChange={(e) => {
                              this.state.objectSet.rwcuPlannedFromPlanninglTeam =
                                e;
                            }}
                          >
                            {this.props.dropDownInfoRectifier[
                              "dropDownObjectSetRectifier"
                            ][
                              "rectifierWithCabinetPlannedFromPlanninglTeam"
                            ].map((x, y) => {
                              return <Option value={x}>{x}</Option>;
                            })}
                          </Select>
                        )}
                      </div>
                    </li>
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                      <div>
                        <span className="textStyles-small">
                          Rectifier modules Planned From Operational Team
                        </span>
                      </div>
                      <div>
                        {this.state.switchStatus ? (
                          this.props.details?.access_technologies_data
                            ?.rmPlannedFromOperationalTeam != null ||
                            this.props.details?.access_technologies_data
                              ?.rmPlannedFromOperationalTeam != undefined ? (
                            <span className="badge badge-outline-success textStyles-small">
                              {this.props.details?.access_technologies_data?.rmPlannedFromOperationalTeam.toString()}
                            </span>
                          ) : (
                            <span className="badge badge-outline-danger textStyles-small">
                              Not Defined Yet
                            </span>
                          )
                        ) : (
                          <Select
                            defaultValue={
                              this.props.details?.access_technologies_data
                                ?.rmPlannedFromOperationalTeam
                            }
                            style={{ width: '120px' }}
                            bordered={false}
                            className='borderedSelect'
                            onChange={(e) => {
                              this.state.objectSet.rmPlannedFromOperationalTeam =
                                e;
                            }}
                          >
                            {this.props.dropDownInfoRectifier[
                              "dropDownObjectSetRectifier"
                            ][
                              "rectifierModulesPlannedFromOperationalTeam"
                            ].map((x, y) => {
                              return <Option value={x}>{x}</Option>;
                            })}
                          </Select>
                        )}
                      </div>
                    </li>
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        <span className="textStyles-small">
                          Rectifier Modules Planned From Planning Team
                        </span>
                      </div>
                      <div>
                        {this.state.switchStatus ? (
                          this.props.details?.access_technologies_data
                            ?.rmPlannedFromPlanninglTeam != null ||
                            this.props.details?.access_technologies_data
                              ?.rmPlannedFromPlanninglTeam != undefined ? (
                            <span className="badge badge-outline-success textStyles-small">
                              {this.props.details?.access_technologies_data?.rmPlannedFromPlanninglTeam.toString()}
                            </span>
                          ) : (
                            <span className="badge badge-outline-danger textStyles-small">
                              Not Defined Yet
                            </span>
                          )
                        ) : (
                          <Select
                            defaultValue={
                              this.props.details?.access_technologies_data
                                ?.rmPlannedFromPlanninglTeam
                            }
                            style={{ width: '120px' }}
                            bordered={false}
                            className='borderedSelect'
                            onChange={(e) => {
                              this.state.objectSet.rmPlannedFromPlanninglTeam =
                                e;
                            }}
                          >
                            {this.props.dropDownInfoRectifier[
                              "dropDownObjectSetRectifier"
                            ][
                              "rectifierModulesPlannedFromPlanninglTeam"
                            ].map((x, y) => {
                              return <Option value={x}>{x}</Option>;
                            })}
                          </Select>
                        )}
                      </div>
                    </li>
                  </div>
                </Col>
              </Row>
            </ul>
          </div>

          <Row span={24} style={{ marginTop: 30 }}>
            {this.props.details2.rectifiers_data.map((element, index) => (
              <Col span={8} key={index}>
                <Card
                  bordered={false}
                  style={{
                    width: '98%',
                    borderRadius: '10px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                    marginTop: 10
                  }}
                >
                  <div style={{ display: 'flex', marginBottom: 16, backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '10px' }}>
                    <div>
                      <HomeOutlined style={{ marginLeft: '3px', marginRight: '3px' }} /> &rarr;
                      <span style={{ marginLeft: '3px', marginRight: '3px', fontWeight: 'bold' }} className="textStyles-small">
                        {element.cabinet?.name.substring(0, 10)}
                      </span>
                      &rarr;
                      <span style={{ marginLeft: '3px', color: '#BA183A', fontWeight: 'bold' }} className="textStyles-small">{element?.name?.substring(0, 18)}</span>
                    </div>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small" style={{ fontSize: 12 }}>Make</span>
                    <Tag color="default" style={{ float: 'right', borderRadius: 5 }}> <span className="textStyles-small">{element.make?.name}</span></Tag>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small">Serial Number</span>
                    <Tag color="purple" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                      <span className="textStyles-small" style={{ fontSize: 11 }}>
                        {element.rectifierControllerSerialNumber}
                      </span></Tag>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small">Rectifier Model</span>
                    <Tag color="red" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                      <span className="textStyles-small" style={{ fontSize: 11 }}>
                        {element.model?.name}
                      </span></Tag>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small">Rectifier Controller Model</span>
                    <Tag color="blue" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                      <span className="textStyles-small" style={{ fontSize: 11 }}>
                        {element.rectifierControllerModel}
                      </span></Tag>
                  </div>
                  <Divider style={{ marginTop: '3px' }} />
                  <Button
                    type="primary"
                    style={{ marginRight: 8, borderRadius: '5px' }}
                    size='small'
                    onClick={() => this.viewMoreDetails(element)}>
                    <span className="textStyles-small" style={{ fontSize: 10 }}> <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details</span>
                  </Button>

                  {
                    checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                  <Button
                    icon={<EditOutlined style={{ color: 'white' }} />}
                    style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                    onClick={() => this.rectifierUpdata(element.id)}
                    size='small'
                  />) : null
                  }

                  <Popconfirm
                    title={<span className='textStyles-small' style={{ fontSize: 14 }}>Are you sure to delete this Asset?</span>}
                    description={<span className="textStyles-small">Are you sure to delete this Asset?</span>}
                    onConfirm={() => this.submitForm(element.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    {checkPermissions('SITE_VIEW_ADMIN_USER')
                      ? (
                        <Button
                          icon={<DeleteOutlined style={{ color: 'white' }} />}
                          danger
                          size='small'
                          style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#BA183A', borderColor: '#BA183A' }}
                        />
                      )
                      : ('')}
                  </Popconfirm>
                </Card>
              </Col>
            ))}
          </Row>

          <Drawer
            maskClosable={false}
            title={<span style={{ fontSize: 14 }} className="textStyles-small">Rectifier Details</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onViewMoreDetailsDrawerClose()}
            visible={this.state.openViewMoreDetails}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            {/* <Collapse
              style={{ borderColor: "lavender", alignContent: "center" }}
            >
              {columns2.map((item) => (
                <Panel
                  style={{ borderColor: "lavender" }}
                  header={
                    <li class="d-flex justify-content-between align-items-center">
                      <div>
                        {item.details.length == 0 ? (
                          <i
                            class="la la-hand-o-right text-primary"
                            style={{ paddingLeft: "5px", paddingRight: "5px" }}
                          />
                        ) : (
                          <></>
                        )}
                        <span
                          class="fw-normal m-2"
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {item.title}
                        </span>
                      </div>
                      {item.content}
                    </li>
                  }
                  key={item.title}
                  showArrow={item.details.length > 0}
                  collapsible={item.details.length > 0 ? "" : "icon"}
                >
                  {item.details.map((detail) => (
                    <li
                      class="d-flex justify-content-between align-items-center me-2"
                      style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                    >
                      <div>
                        <i class="la la-circle text-primary me-2"></i>{" "}
                        <span>{detail.title}</span>
                      </div>
                      {detail.content}
                    </li>
                  ))}
                </Panel>
              ))}
            </Collapse> */}
            <Collapse
              style={{ alignContent: "center", borderColor: "white" }}
            >
              {columns2.map((item, index) => (
                <Panel
                  style={{
                    borderColor: index % 2 === 0 ? "#f8f9fa" : "white",
                    backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white"
                  }}
                  header={
                    <li className="d-flex justify-content-between align-items-center"
                      style={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white" }}>
                      <div>
                        <span className="textStyles-small">{item.title}</span>
                      </div>
                      <span className="textStyles-small">{item.content}</span>
                    </li>
                  }
                  key={item.title}
                  showArrow={item.details.length > 0}
                  collapsible={item.details.length > 0 ? "" : "icon"}
                >
                  {item.details.map((detail) => (
                    <li
                      className="d-flex justify-content-between align-items-center me-2"
                      style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                      key={detail.title}
                    >
                      <div>
                        <span className='textStyles-small'>{detail.title}</span>
                      </div>
                      <span className="textStyles-small">{detail.content}</span>
                    </li>
                  ))}
                </Panel>
              ))}
            </Collapse>
          </Drawer>

          <Drawer
            maskClosable={false}
            title={<span style={{ fontSize: 14 }} className="textStyles-small">Add Rectifiers</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.open}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card" style={{ borderColor: 'white' }}>
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Rectifier Name <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <Input
                              id="rectifierName"
                              defaultValue={"Rectifier-"}
                              className='borderedSelect'
                              style={{ width: "100%", height: 34 }}
                              onChange={this.handlerectifierName}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Rectifier Name",
                                this.state.rectifierName,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Make <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              onChange={this.onChangeMake}
                              style={{ width: "100%" }}
                              className='borderedSelect'
                              bordered={false}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.rectifiersMasterData?.rectifiersMasterData?.rectifiersMakeList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item?.id}>
                                      {item?.name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Make",
                                this.state.selectedMakeId,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Rectifier Model <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              onChange={this.onChangeModel}
                              style={{ width: "100%" }}
                              className='borderedSelect'
                              bordered={false}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.rectifiersMasterData?.rectifiersMasterData?.rectifiersModelList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item?.id}>
                                      {item?.name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Model",
                                this.state.selectedModelId,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Rectifier Controller Model{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="rectifierControllerModel"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                onChange={(e) => {
                                  this.handlerectifierControllerModel(e);
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rectifierControllerModel"]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Rectifier Controller Model",
                                this.state.rectifierControllerModel,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Rectifier Controller SerialNumber{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="rectifierControllerSerialNumber"
                            tooltip="This is a required field"
                          >
                            <Input
                              id="rectifierControllerSerialNumber"
                              className='borderedSelect'
                              style={{ width: "100%", height: 34 }}
                              onChange={
                                this.handleRectifierControllerSerialNumber
                              }
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Rectifier Controller SerialNumber",
                                this.state.rectifierControllerSerialNumber,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Rectifier Controller Software Version
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="rectifierControllerSoftwareVersion"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                showSearch
                                className='borderedSelect'
                                bordered={false}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handlerectifierControllerSoftwareVersion(
                                    e
                                  );
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rectifierControllerSoftwareVersion"]?.map(
                                  (x, y) => {
                                    return <Option value={x}>{x}</Option>;
                                  }
                                )}
                              </Select>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              No of phases<x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="rectifierControllerModel"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                onChange={(e) => {
                                  this.setState({ noOfPhases: e });
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rectifierNoOfPhases"]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Rectifier No of phases",
                                this.state.noOfPhases,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Rectifier Module Model{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              onChange={this.onChangeRectifierModuleModel}
                              style={{ width: "100%" }}
                              className='borderedSelect'
                              bordered={false}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.rectifiersMasterData?.rectifiersMasterData?.rectifierModuleModelList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item?.id}>
                                      {item?.name != null ? item?.name : ""}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Rectifier Module Model",
                                this.state.rectifierModuleModel,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              No Of Modules At Site{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="noOfModulesAtSite"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                onChange={(e) => {
                                  this.handleRectifiernoOfModulesAtSite(e);
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rectifiernoOfModulesAtSite"]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "No Of Modules At Site",
                                this.state.rectifiernoOfModulesAtSite,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              No Of Powered Rec Modules{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="noOfPoweredRecModules"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                onChange={(e) => {
                                  this.handleRectifierNoOfPoweredRecModules(e);
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rectifierNoOfPoweredRecModules"]?.map(
                                  (x, y) => {
                                    return <Option value={x}>{x}</Option>;
                                  }
                                )}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "No Of Powered Rec Modules",
                                this.state.rectifierNoOfPoweredRecModules,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Rectifier Modules SerialNumbers{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="rectifierModulesSerialNumbers"
                            tooltip="This is a required field"
                          >
                            <Input
                              id="rectifierModulesSerialNumbers"
                              className='borderedSelect'
                              style={{ width: "100%", height: 34 }}
                              onChange={
                                this.handlerectifierModulesSerialNumbers
                              }
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Rectifier Modules SerialNumbers",
                                this.state.rectifierModulesSerialNumbers,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              L1 Current (A) <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="l1Current"
                            tooltip="This is a required field"
                          >
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              id="l1Current"
                              className='borderedSelect'
                              onChange={this.handleRectifierL1Current}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "L1 Current",
                                this.state.rectifierL1Current,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              L2 Current (A) <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="l2Current"
                            tooltip="This is a required field"
                          >
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              id="l2Current"
                              onChange={this.handleRectifierL2Current}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "L2 Current",
                                this.state.rectifierL2Current,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              L3 Current (A) <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="l3Current"
                            tooltip="This is a required field"
                          >
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              id="l3Current"
                              onChange={this.handleRectifierL3Current}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "L3 Current",
                                this.state.rectifierL3Current,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              L1 Voltage (V)<x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="l1Voltage"
                            tooltip="This is a required field"
                          >
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              id="l1Voltage"
                              onChange={this.handleRectifierL1Voltage}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "L1 Voltage",
                                this.state.rectifierL1Voltage,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              L2 Voltage (V)<x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="l2Voltage"
                            tooltip="This is a required field"
                          >
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              id="l2Voltage"
                              onChange={this.handleRectifierL2Voltage}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "L2 Voltage",
                                this.state.rectifierL2Voltage,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              L3 Voltage (V)<x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="l3Voltage"
                            tooltip="This is a required field"
                          >
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              id="l3Voltage"
                              className='borderedSelect'
                              onChange={this.handleRectifierL3Voltage}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "L3 Voltage",
                                this.state.rectifierL3Voltage,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Total DC Load (A)<x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="totalDCLoad"
                            tooltip="This is a required field"
                          >
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              id="l3Voltage"
                              onChange={this.handleRectifierTotalDCLoad}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Total DC Load",
                                this.state.rectifierTotalDCLoad,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Nominal Operating Voltage (V){" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="nominalOperatingVoltage"
                            tooltip="This is a required field"
                          >
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              id="nominalOperatingVoltage"
                              onChange={
                                this.handleRectifierMominalOperatingVoltage
                              }
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Nominal Operating Voltage",
                                this.state.rectifierMominalOperatingVoltage,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Peak DC Load (A)
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="peakDCLoad"
                            tooltip="This is a required field"
                          >
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              id="peakDCLoad"
                              onChange={this.handleRectifierPeakDCLoad}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Free Breaker Availability
                            </span>
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item
                            required
                            name="freeBreakerAvailability"
                            tooltip="This is a required field"
                          >
                            <Input
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              id="freeBreakerAvailability"
                              onChange={(e) => {
                                this.setState({
                                  rectifierFreeBreakerAvailability:
                                    e.target.value,
                                });
                              }}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Free Breaker Availability",
                                this.state.rectifierFreeBreakerAvailability,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Used Breaker Capacity{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="usedBreakerCapacity"
                            tooltip="This is a required field"
                          >
                            <Input
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              id="usedBreakerCapacity"
                              onChange={(e) => {
                                this.setState({
                                  rectifierUsedBreakerCapacity: e.target.value,
                                });
                              }}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Used Breaker Capacity",
                                this.state.rectifierUsedBreakerCapacity,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">

                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              No Of Breaker Spaces Available (Size 80A Or Below)
                            </span>
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item
                            required
                            name="noOfBreakerSpacesAvailable80A"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                style={{ width: "100%", height: 34 }}
                                className='borderedSelect'
                                bordered={false}
                                onChange={(e) => {
                                  this.handleRectifierNoOfBreakerSpacesAvailable80A(
                                    e
                                  );
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rectifierNoOfBreakerSpacesAvailable"]?.map(
                                  (x, y) => {
                                    return <Option value={x}>{x}</Option>;
                                  }
                                )}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "No Of Breaker Spaces Available 80A",
                                this.state
                                  .rectifierNoOfBreakerSpacesAvailable80A,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              No Of Breaker Spaces Available (Size 100A Or Above)
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="noOfBreakerSpacesAvailable100A"
                            tooltip="This is a required field"
                          >
                            <Select
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              bordered={false}
                              onChange={(e) => {
                                this.handleRectifierNoOfBreakerSpacesAvailable100A(
                                  e
                                );
                              }}
                            >
                              {this.props.dropDownInfoRectifier[
                                "dropDownObjectSetRectifier"
                              ]["rectifierNoOfBreakerSpacesAvailable100A"]?.map(
                                (x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "No Of Breaker Spaces Available 100A",
                                this.state
                                  .rectifierNoOfBreakerSpacesAvailable100A,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              RMS Integrated Status
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="RMS integrated status"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                bordered={false}
                                className='borderedSelect'
                                onChange={(e) => {
                                  this.handleRMSIntegratedStatus(e);
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rmsintegratedStatus"]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              RMS inband/outband
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="RMS inband/outband"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                bordered={false}
                                className='borderedSelect'
                                onChange={(e) => {
                                  this.handleRMSInbandOutband(e);
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rmsinbandOutband"]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              RMS Connection Method
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="RMS connection method"
                            tooltip="This is a required field"
                          >
                            <Select
                              style={{ width: "100%" }}
                              bordered={false}
                              className='borderedSelect'
                              onChange={(e) => {
                                this.handleRMSConnectionMethod(e);
                              }
                              }>
                              {this.props.dropDownInfoRectifier["dropDownObjectSetRectifier"]["rectifierRMSConnectionMode"]?.map((x, y) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              RMS Related SIM Number
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="RMS related SIM number"
                            tooltip="This is a required field"
                          >
                            <Input
                              id="RMS related SIM number"
                              className='borderedSelect'
                              style={{ width: "100%", height: 34 }}
                              onChange={this.handleRMSRelatedSIMnumber}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Installed Date
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="installedDate"
                            tooltip="This is a required field"
                          >
                            <DatePicker
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              onChange={this.onChangeInstalledDate}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Cabinet
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              onChange={this.onChangeCabinet}
                              style={{ width: "100%" }}
                              bordered={false}
                              className='borderedSelect'
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              allowClear
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.rectifiersMasterData?.rectifiersMasterData?.cabinetList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item?.id}>
                                      {item.name +
                                        "      (" +
                                        item.model?.make?.name +
                                        ")"}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>

                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        <Button className="btn btn-primary"
                          style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}
                          onClick={() => this.AllDataSubmit()}>
                          <span className="textStyles-small" style={{ color: 'white' }}>
                            Add This Asset
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>

          <Drawer
            maskClosable={false}
            title={<span style={{ fontSize: "14px" }} className="textStyles-small">Edit Rectifier</span>}
            width={"60%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.openEdit}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card" style={{ borderColor: 'white' }}>
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Rectifier Name <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <Input
                              id="rectifierName"
                              value={this.state.rectifierName}
                              onChange={this.handlerectifierName}
                              className='borderedSelect'
                              style={{ width: "100%", height: 34 }}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Rectifier Name",
                                this.state.rectifierName,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Make <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          {this.state.selectedMakeId != null ? (
                            <Form.Item
                              required
                              name="Is_active"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                onChange={this.onChangeMake}
                                defaultValue={this.state.selectedMakeId}
                                style={{ width: "100%" }}
                                bordered={false}
                                className='borderedSelect'
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.rectifiersMasterData?.rectifiersMasterData?.rectifiersMakeList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item?.id}>
                                        {item?.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                                {this.validator.message(
                                  "Make",
                                  this.state.selectedMakeId,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Rectifier Model <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          {this.state.selectedModelId != null ? (
                            <Form.Item
                              required
                              name="Is_active"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                onChange={this.onChangeModel}
                                defaultValue={this.state.selectedModelId}
                                style={{ width: "100%" }}
                                bordered={false}
                                className='borderedSelect'
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.rectifiersMasterData?.rectifiersMasterData?.rectifiersModelList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item?.id}>
                                        {item?.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                                {this.validator.message(
                                  "Model",
                                  this.state.selectedModelId,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Rectifier Controller Model
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <div>
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                bordered={false}
                                className='borderedSelect'
                                defaultValue={
                                  this.state.rectifierControllerModel
                                }
                                onChange={(e) => {
                                  this.handlerectifierControllerModel(e);
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rectifierControllerModel"]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Rectifier Controller Model",
                                this.state.rectifierControllerModel,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Rectifier Controller Serial Number
                            </span>
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <Input
                              id="rectifierControllerSerialNumber"
                              value={this.state.rectifierControllerSerialNumber}
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              onChange={
                                this.handleRectifierControllerSerialNumber
                              }
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Rectifier Controller SerialNumber",
                                this.state.rectifierControllerSerialNumber,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Rectifier Controller Software Version
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <div>
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                bordered={false}
                                className='borderedSelect'
                                defaultValue={
                                  this.state.rectifierControllerSoftwareVersion
                                }
                                onChange={(e) => {
                                  this.handlerectifierControllerSoftwareVersion(
                                    e
                                  );
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rectifierControllerSoftwareVersion"]?.map(
                                  (x, y) => {
                                    return <Option value={x}>{x}</Option>;
                                  }
                                )}
                              </Select>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              No of phases<x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="rectifierControllerModel"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                value={this.state.noOfPhases}
                                style={{ width: "100%" }}
                                bordered={false}
                                className='borderedSelect'
                                onChange={(e) => {
                                  this.setState({ noOfPhases: e });
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rectifierNoOfPhases"]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Rectifier No of phases",
                                this.state.noOfPhases,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Rectifier Module Model
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          {this.state.rectifierModuleModelId != null ? (
                            <Form.Item
                              required
                              name="Is_active"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                defaultValue={this.state.rectifierModuleModelId}
                                onChange={this.onChangeRectifierModuleModel}
                                style={{ width: "100%" }}
                                bordered={false}
                                className='borderedSelect'
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.rectifiersMasterData?.rectifiersMasterData?.rectifierModuleModelList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item?.id}>
                                        {item?.name != null ? item?.name : ""}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                                {this.validator.message(
                                  "Rectifier Module Model",
                                  this.state.rectifierModuleModel,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              No Of Modules At Site{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                bordered={false}
                                className='borderedSelect'
                                defaultValue={
                                  this.state.rectifiernoOfModulesAtSite
                                }
                                onChange={(e) => {
                                  this.handleRectifiernoOfModulesAtSite(e);
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rectifiernoOfModulesAtSite"]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "No Of Modules At Site",
                                this.state.rectifiernoOfModulesAtSite,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              No Of Powered Rec Modules{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            {/* <InputNumber style={{ width: '100%' }} value={this.state.rectifierNoOfPoweredRecModules} id="noOfPoweredRecModules" onChange={this.handleRectifierNoOfPoweredRecModules} /> */}
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                bordered={false}
                                className='borderedSelect'
                                defaultValue={
                                  this.state.rectifierNoOfPoweredRecModules
                                }
                                onChange={(e) => {
                                  this.handleRectifierNoOfPoweredRecModules(e);
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rectifierNoOfPoweredRecModules"]?.map(
                                  (x, y) => {
                                    return <Option value={x}>{x}</Option>;
                                  }
                                )}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "No Of Powered Rec Modules",
                                this.state.rectifierNoOfPoweredRecModules,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Rectifier Modules SerialNumbers{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <Input
                              id="rectifierModulesSerialNumbers"
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={this.state.rectifierModulesSerialNumbers}
                              onChange={
                                this.handlerectifierModulesSerialNumbers
                              }
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Rectifier Modules SerialNumbers",
                                this.state.rectifierModulesSerialNumbers,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              L1 Current (A) <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={this.state.rectifierL1Current}
                              id="l1Current"
                              onChange={this.handleRectifierL1Current}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "L1 Current",
                                this.state.rectifierL1Current,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              L2 Current (A) <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={this.state.rectifierL2Current}
                              id="l2Current"
                              onChange={this.handleRectifierL2Current}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "L2 Current",
                                this.state.rectifierL2Current,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              L3 Current (A) <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={this.state.rectifierL3Current}
                              id="l3Current"
                              onChange={this.handleRectifierL3Current}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "L3 Current",
                                this.state.rectifierL3Current,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              L1 Voltage (V)<x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={this.state.rectifierL1Voltage}
                              id="l1Voltage"
                              onChange={this.handleRectifierL1Voltage}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "L1 Voltage",
                                this.state.rectifierL1Voltage,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              L2 Voltage (V)<x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={this.state.rectifierL2Voltage}
                              id="l2Voltage"
                              onChange={this.handleRectifierL2Voltage}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "L2 Voltage",
                                this.state.rectifierL2Voltage,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              L3 Voltage (V)<x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              id="l3Voltage"
                              value={this.state.rectifierL3Voltage}
                              onChange={this.handleRectifierL3Voltage}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "L3 Voltage",
                                this.state.rectifierL3Voltage,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Total DC Load (A)<x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={this.state.rectifierTotalDCLoad}
                              id="l3Voltage"
                              onChange={this.handleRectifierTotalDCLoad}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Total DC Load",
                                this.state.rectifierTotalDCLoad,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Nominal Operating Voltage (V)
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={
                                this.state.rectifierMominalOperatingVoltage
                              }
                              id="nominalOperatingVoltage"
                              onChange={
                                this.handleRectifierMominalOperatingVoltage
                              }
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Nominal Operating Voltage",
                                this.state.rectifierMominalOperatingVoltage,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Peak DC Load (A)
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={this.state.rectifierPeakDCLoad}
                              id="peakDCLoad"
                              onChange={this.handleRectifierPeakDCLoad}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Free Breaker Availability{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <Input
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={
                                this.state.rectifierFreeBreakerAvailability
                              }
                              id="freeBreakerAvailability"
                              onChange={(e) => {
                                this.setState({
                                  rectifierFreeBreakerAvailability:
                                    e.target.value,
                                });
                              }}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Free Breaker Availability",
                                this.state.rectifierFreeBreakerAvailability,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Used Breaker Capacity{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <Input
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={this.state.rectifierUsedBreakerCapacity}
                              id="usedBreakerCapacity"
                              onChange={(e) => {
                                this.setState({
                                  rectifierUsedBreakerCapacity: e.target.value,
                                });
                              }}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Used Breaker Capacity",
                                this.state.rectifierUsedBreakerCapacity,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">

                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              No Of Breaker Spaces Available (Size 80A Or Below)
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <div>
                              <Select
                                style={{ width: "100%", height: 34 }}
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.state
                                    .rectifierNoOfBreakerSpacesAvailable80A
                                }
                                onChange={(e) => {
                                  this.handleRectifierNoOfBreakerSpacesAvailable80A(
                                    e
                                  );
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rectifierNoOfBreakerSpacesAvailable"]?.map(
                                  (x, y) => {
                                    return <Option value={x}>{x}</Option>;
                                  }
                                )}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "No Of Breaker Spaces Available 80A",
                                this.state
                                  .rectifierNoOfBreakerSpacesAvailable80A,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              No Of Breaker Spaces Available (Size 100A Or Above)
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.state
                                    .rectifierNoOfBreakerSpacesAvailable100A
                                }
                                onChange={(e) => {
                                  this.handleRectifierNoOfBreakerSpacesAvailable100A(
                                    e
                                  );
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ][
                                  "rectifierNoOfBreakerSpacesAvailable100A"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "No Of Breaker Spaces Available 100A",
                                this.state
                                  .rectifierNoOfBreakerSpacesAvailable100A,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              RMS integrated status
                            </span>
                          </label>
                          <Form.Item>
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={this.state.rmsintegratedStatus}
                                onChange={(e) => {
                                  this.handleRMSIntegratedStatus(e);
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rmsintegratedStatus"]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              RMS Inband/Outband
                            </span>
                          </label>
                          <Form.Item>
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={this.state.rmsinbandOutband}
                                onChange={(e) => {
                                  this.handleRMSInbandOutband(e);
                                }}
                              >
                                {this.props.dropDownInfoRectifier[
                                  "dropDownObjectSetRectifier"
                                ]["rmsinbandOutband"]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              RMS Connection Method
                            </span>
                          </label>
                          <Form.Item>
                            <Select
                              style={{ width: "100%" }}
                              className='borderedSelect'
                              bordered={false}
                              value={this.state.rmsConnectionMethod}
                              onChange={(e) => {
                                this.handleRMSConnectionMethod(e);
                              }}
                            >
                              {this.props.dropDownInfoRectifier[
                                "dropDownObjectSetRectifier"
                              ]["rectifierRMSConnectionMode"]?.map((x, y) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              RMS Related SIM Number
                            </span>
                          </label>
                          <Form.Item>
                            <Input
                              id="RMS related SIM number"
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={this.state.rmsrelatedSimNumber}
                              onChange={this.handleRMSRelatedSIMnumber}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Installed Date
                            </span>
                          </label>
                          {this.state.rectifierInstalledDate != null ? (
                            <Form.Item>
                              <DatePicker
                                defaultValue={
                                  this.state.rectifierInstalledDate != ""
                                    ? moment(this.state.rectifierInstalledDate)
                                    : ""
                                }
                                style={{ width: "100%", height: 34 }}
                                className='borderedSelect'
                                onChange={this.onChangeInstalledDate}
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Cabinet
                            </span>
                          </label>
                          {this.state.cabinetId != null ? (
                            <Form.Item
                              required
                              name="Is_active"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                defaultValue={this.state.cabinetId}
                                onChange={this.onChangeCabinet}
                                style={{ width: "100%" }}
                                bordered={false}
                                className='borderedSelect'
                                placeholder="Search to Select"
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.rectifiersMasterData?.rectifiersMasterData?.cabinetList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item?.id}>
                                        {item.name +
                                          "      (" +
                                          item.model?.make?.name +
                                          ")"}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                          {this.state.cabinetId == null ? (
                            <Form.Item
                              required
                              name="Is_active"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                onChange={this.onChangeCabinet}
                                style={{ width: "100%" }}
                                bordered={false}
                                className='borderedSelect'
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.rectifiersMasterData?.rectifiersMasterData?.cabinetList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item?.id}>
                                        {item.name +
                                          "      (" +
                                          item.model?.make?.name +
                                          ")"}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        <Button className="btn btn-primary"
                          style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}
                          onClick={() => this.AllDataSubmitUpdate()}>
                          <span className="textStyles-small" style={{ color: 'white' }}>
                            Update This Asset
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </>
    );
  }
}
export default Rectifiers;
