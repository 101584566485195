import React, { Component } from 'react'
import { Select, DatePicker, Table, Image, Avatar, List, Tag, Badge, Descriptions, Empty, Card, Row, Col } from 'antd';
import { FileImageOutlined, FileProtectOutlined, AlignRightOutlined, ClockCircleOutlined, ExceptionOutlined, CheckSquareOutlined, CalendarOutlined } from '@ant-design/icons';

import { Link } from "react-router-dom";

import ImageZoom from "react-image-zooom";

import { getPisHistoryDetailsById, getPisHistoryGenaralDetailsById } from "../../../api/index";
import Modal from 'react-modal';

import { THE_FAMILY_ID } from '@azure/msal-common/dist/utils/Constants';
import Item from 'antd/lib/list/Item';
import ReactLoading from 'react-loading';
import { tupleNum } from 'antd/lib/_util/type';
import { Collapse } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import PisChecklistItem from '../../../components/pis/pis_checklist_item';

import moment from 'moment';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
const image_url = "https://storage.googleapis.com/dns_storage_v2/requestimages/";


class PisRequestDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itemDetails: [],
            showModal: false,
            selectedItem: {},
            mainLoading: false,
            generalDetails: []
        };

    }

    componentDidMount() {
        this.getData()

    }

    getData = async () => {
        this.setState({
            mainLoading: true
        })
        var url = "";
        var url2 = "";
        console.log('type');
        console.log(this.props.match.params.type);
        switch (this.props.match.params.type) {
            case "1":
                url = "/genServiceRequest/getGenChecklistDetailsById";
                url2 = "/genServiceRequest/getRequestDetailsByIdLite";
                break;
            case "2":
                url = "/acServiceRequest/getAcChecklistDetailsById";
                url2 = "/acServiceRequest/getRequestDetailsByIdLite";
                break;
            case "3":
                url = "/siteCleaningService/getScChecklistDetailsById";
                url2 = "/siteCleaningService/getRequestDetailsByIdLite";
                break;
            case "4":
                url = "/civilRoutineService/getCrChecklistDetailsById";
                url2 = "/civilRoutineService/getRequestDetailsByIdLite";

                break;
            default:
                break
        }
        var formdata2 = new FormData();
        formdata2.append("requestId", this.props.match.params.id)
        var res2 = await getPisHistoryGenaralDetailsById(url2, formdata2);
        console.log('getPisHistoryGenaralDetailsById');
        console.log(res2);
        if (res2.success) {
            this.setState({
                generalDetails: res2.data
            })
        }




        var formdata = new FormData();
        formdata.append("Request", "{\"id\":" + this.props.match.params.id + "}")
        var res = await getPisHistoryDetailsById(url, formdata);


        if (res.success) {
            console.log('res')
            console.log(res)
            this.setState({
                itemDetails: res.data,
                mainLoading: false
            })
        }


    }

    handleCloseFullModal = () => {
        this.setState({
            showModal: false
        })
    }

    showImage = (item) => {

        this.setState({
            showModal: true,
            selectedItem: item
        })
    }

    // Columns = [
    //     {
    //       title: 'Section Name',
    //       dataIndex: 'sectionName',
    //       key: 'sectionName',
    //         render: (text, record) => {
    //             return (
    //             <div>
    //                 <b style={{color : 'gray'}}>{text}</b>
    //             </div>
    //             );
    //         }
    //     },
    //     {
    //       title: 'Answer',
    //       dataIndex: 'answer',
    //       key: 'answer',
    //       render: (text, record) => {
    //         return (
    //           <div>
    //             <b>{text}</b>
    //           </div>
    //         );
    //       }
    //     },
    // ];

    render() {

        // const allCheckLists = this.state?.itemDetails?.length || 0;
        // const allCheckListData = [];

        // for (let i = 0; i < allCheckLists; i++) {
        //     for (let j = 0; j < this.state?.itemDetails[i]?.data.length; j++) {
        //         allCheckListData.push({
        //             key: this.state?.itemDetails[i]?.data[j]?.id,
        //             mainSectionName: this.state?.itemDetails[i]?.section?.name ? this.state?.itemDetails[i]?.section?.name : this.state?.itemDetails[i]?.section?.name,
        //             sectionName: this.state?.itemDetails[i]?.data[j]?.name ? this.state?.itemDetails[i]?.data[j]?.name : 'N/A',
        //             itemName: this.state?.itemDetails[i]?.data[j]?.name ? this.state?.itemDetails[i]?.data[j]?.name : 'N/A',
        //             answer: this.state?.itemDetails[i]?.data[j]?.value ? this.state?.itemDetails[i]?.data[j]?.value : 'N/A',
        //             type : this.state?.itemDetails[i]?.data[j]?.type ? this.state?.itemDetails[i]?.data[j]?.type : 'N/A',

        //             conditions : this.state?.itemDetails[i]?.data[j]?.conditions ? this.state?.itemDetails[i]?.data[j]?.conditions : 'N/A',
        //             actionType : this.state?.itemDetails[i]?.data[j]?.actionType ? this.state?.itemDetails[i]?.data[j]?.actionType : 'N/A',
        //             remark : this.state?.itemDetails[i]?.data[j]?.remark ? this.state?.itemDetails[i]?.data[j]?.remark : 'N/A',
        //         });
        //     }
        // }

        // const sectionDataMap = {};
        // allCheckListData.forEach((item) => {
        //   const mainSectionName = item.mainSectionName;

        //   if (!sectionDataMap[mainSectionName]) {
        //     sectionDataMap[mainSectionName] = [];
        //   }

        //   sectionDataMap[mainSectionName].push(item);
        // });

        const dataWithSectionNameImages = this.state?.itemDetails?.filter(item => item?.section?.name?.includes('IMAGES'));
        if (dataWithSectionNameImages?.length > 0) {
            const allImagesData = dataWithSectionNameImages.flatMap(item => item?.data || []);
            const allImageValues = allImagesData.map(image => image?.value);
            console.log("All Image Values:", allImageValues);
        } else {
            console.log("No data found with section name 'IMAGES'");
        }

        const renderImagesSection = dataWithSectionNameImages?.map((section) => {
            const imagesData = section?.data;
            return (
                <div style={{ padding: 10, backgroundColor: 'white', borderRadius: 5, marginTop: 20, width: '100%' }}>
                    <h5 className='textStyle-small' style={{ fontSize: 14 }}><FileImageOutlined /> {section?.section?.name}</h5>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {imagesData?.map((item) => (
                            <div key={item?.id} style={{ margin: '10px' }}>
                                <h6 style={{ textTransform: 'capitalize', fontSize: 12, fontWeight: 'bold' }}>
                                    <FileImageOutlined /> {item?.name.replace(/_/g, ' ')}</h6>
                                <Image
                                    width={200}
                                    height={200}
                                    src={item?.value ? image_url + item?.value : "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"}
                                    style={{ cursor: 'pointer', borderRadius: 10 }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            );
        });

        const allCheckLists = this.state?.itemDetails?.length || 0;
        const allCheckListData = [];
        const imageCheckListData = [];

        for (let i = 0; i < allCheckLists; i++) {
            for (let j = 0; j < this.state?.itemDetails[i]?.data.length; j++) {
                const type = this.state?.itemDetails[i]?.data[j]?.type;

                if (type === 'image') {
                    imageCheckListData.push({
                        key: this.state?.itemDetails[i]?.data[j]?.id,
                        mainSectionName: this.state?.itemDetails[i]?.section?.name ? this.state?.itemDetails[i]?.section?.name : 'N/A',
                        sectionName: this.state?.itemDetails[i]?.data[j]?.name ? this.state?.itemDetails[i]?.data[j]?.name : 'N/A',
                        itemName: this.state?.itemDetails[i]?.data[j]?.name ? this.state?.itemDetails[i]?.data[j]?.name : 'N/A',
                        answer: this.state?.itemDetails[i]?.data[j]?.value ? this.state?.itemDetails[i]?.data[j]?.value : 'N/A',
                        type: type ? type : 'N/A',
                        conditions: this.state?.itemDetails[i]?.data[j]?.conditions ? this.state?.itemDetails[i]?.data[j]?.conditions : 'N/A',
                        actionType: this.state?.itemDetails[i]?.data[j]?.actionType ? this.state?.itemDetails[i]?.data[j]?.actionType : 'N/A',
                        remark: this.state?.itemDetails[i]?.data[j]?.remark ? this.state?.itemDetails[i]?.data[j]?.remark : 'N/A',
                    });
                } else if (type !== 'image') {
                    allCheckListData.push({
                        key: this.state?.itemDetails[i]?.data[j]?.id,
                        mainSectionName: this.state?.itemDetails[i]?.section?.name ? this.state?.itemDetails[i]?.section?.name : 'N/A',
                        sectionName: this.state?.itemDetails[i]?.data[j]?.name ? this.state?.itemDetails[i]?.data[j]?.name : 'N/A',
                        itemName: this.state?.itemDetails[i]?.data[j]?.name ? this.state?.itemDetails[i]?.data[j]?.name : 'N/A',
                        answer: this.state?.itemDetails[i]?.data[j]?.value ? this.state?.itemDetails[i]?.data[j]?.value : 'N/A',
                        type: type ? type : 'N/A',
                        conditions: this.state?.itemDetails[i]?.data[j]?.conditions ? this.state?.itemDetails[i]?.data[j]?.conditions : 'N/A',
                        actionType: this.state?.itemDetails[i]?.data[j]?.actionType ? this.state?.itemDetails[i]?.data[j]?.actionType : 'N/A',
                        remark: this.state?.itemDetails[i]?.data[j]?.remark ? this.state?.itemDetails[i]?.data[j]?.remark : 'N/A',
                    });
                }
            }
        }

        const sectionDataMap = {};
        allCheckListData.forEach((item) => {
            const mainSectionName = item.mainSectionName;

            if (!sectionDataMap[mainSectionName]) {
                sectionDataMap[mainSectionName] = [];
            }
            sectionDataMap[mainSectionName].push(item);
        });

        const imageSectionDataMap = {};
        imageCheckListData.forEach((item) => {
            const mainSectionName = item.mainSectionName;
            if (!imageSectionDataMap[mainSectionName]) {
                imageSectionDataMap[mainSectionName] = [];
            }

            imageSectionDataMap[mainSectionName].push(item);
        });

        const columns = [
            {
                title: <span className='textStyle-small'>Section Name</span>,
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => (
                    <div>
                        <span className='textStyle-small' style={{ textTransform: 'capitalize', fontSize: 12 }}>
                            {text?.replace(/_/g, ' ')}
                        </span>
                    </div>
                ),
                width: 150,
            },
            {
                title: <span className='textStyle-small'>Answer</span>,
                dataIndex: 'value',
                key: 'value',
                render: (text, record) => (
                    <div>
                        {text === 'N/A' && record.type !== 'Image' ? (
                            <span className="mt-1">
                                <span>
                                    <b>Action Type :</b> <Tag color="red">{record?.actionType}</Tag>
                                </span> <br /> <br />
                                <span>
                                    <b>Conditions :</b> <Tag color="blue">{record?.conditions}</Tag>
                                </span> <br /> <br />
                                <span className='mt-2' style={{ marginTop: 10 }}>
                                    <b>Remark :</b> <Tag color="green">{record?.remark}</Tag>
                                </span>
                            </span>
                        ) : (
                            text === '' ? (
                                <Tag color='#C4C4C452' style={{ padding: 5, borderRadius: 5 }} key={text} className="textStyles-small">
                                    <span style={{ color: '#979797', fontWeight: 550, fontSize: '12px' }} className="textStyles-small">
                                        No Answer Provided
                                    </span>
                                </Tag>
                            ) : (
                                <Tag color='#1FAD4B36' style={{ padding: 5, borderRadius: 5 }} key={text} className="textStyles-small">
                                    <span style={{ color: '#1FAD4B', fontWeight: 550, fontSize: '12px' }} className="textStyles-small">
                                        {text}
                                    </span>
                                </Tag>
                            )
                        )}
                    </div>
                ),
                width: 200,
            },
        ];

        console.log(sectionDataMap);

        for (let key of Object.keys(sectionDataMap)) {
            if (key.endsWith(' IMAGES')) {
                delete sectionDataMap[key];
            }
        }
        const combineFix = Object.keys(sectionDataMap).map((mainSectionName) => {
            const dataSource = sectionDataMap[mainSectionName];
            const imageDataSource = imageSectionDataMap[mainSectionName] || [];

            return (
                <div key={mainSectionName} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 10,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    <h5><b><FileProtectOutlined /> {mainSectionName}</b></h5>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        size='small'
                    /> <br></br>
                    <br></br>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {imageDataSource.map((item) => (
                            <div key={item.key} style={{ margin: '10px' }}>
                                <h6 style={{
                                    textTransform: 'capitalize', fontSize: 12, fontWeight: 'bold'
                                }}> <FileImageOutlined /> {item.itemName.replace(/_/g, ' ')}</h6>
                                <Image
                                    width={200}
                                    height={200}
                                    src={image_url + (item.answer ? item.answer : "error")}
                                    style={{ cursor: 'pointer', borderRadius: 10 }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            );
        });

        const combinedRender = (
            <div>
                {combineFix}
                {renderImagesSection}
            </div>
        );


        return (
            <>
                <div class="container-fluid" >
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="card" style={{ borderRadius: 12, borderColor: 'white' }}>
                                <div class="card-body">
                                    <div class="dastone-profile">
                                        <div class="row">
                                            <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                                                <div class="dastone-profile-main">
                                                    <div class="dastone-profile-main-pic">
                                                        <img src={'https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG'} alt="" height="110" class="rounded-circle"></img>
                                                    </div>
                                                    <div class="dastone-profile_user-detail">
                                                        <h5 className='textStyles-small' style={{ fontSize: 18, fontWeight: 550 }}>{this.state.generalDetails?.siteName}</h5>
                                                        <Tag color='#C4C4C452' style={{ padding: 5, borderRadius: 5, marginTop: 3 }} className="textStyles-small">
                                                            <span style={{ color: '#979797', fontWeight: 550, fontSize: '14px' }} className="textStyles-small">
                                                                {this.state.generalDetails?.siteId}
                                                            </span>
                                                        </Tag>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 ms-auto align-self-center">
                                                <ul class="list-unstyled personal-detail mb-0">
                                                    <li className="mt-2"><AlignRightOutlined style={{ marginRight: 2 }} /> <span className='textStyles-small' style={{ fontWeight: 600, marginRight: 5 }}>Code: </span>
                                                        <span className='textStyles-small'>
                                                            {this.state.generalDetails?.requestCode}
                                                        </span></li>
                                                    <li className="mt-2"><ClockCircleOutlined style={{ marginRight: 2 }} /> <span className='textStyles-small' style={{ fontWeight: 600, marginRight: 5 }}>Status: </span>
                                                        <Tag color={this.state?.generalDetails.colorCode?.replace("0xFF", "#")} style={{ padding: 5, borderRadius: 5 }} className="textStyles-small">
                                                            <span style={{ color: 'white', fontWeight: 550, fontSize: '12px' }} className="textStyles-small">
                                                                {this.state.generalDetails.status}
                                                            </span>
                                                        </Tag>
                                                    </li>
                                                    <li className="mt-2"><ExceptionOutlined style={{ marginRight: 2 }} /> <span className='textStyles-small' style={{ fontWeight: 600, marginRight: 5 }}>
                                                        Request Type:
                                                    </span><span className='textStyles-small'>
                                                            {this.state.generalDetails?.requestType}
                                                        </span></li>
                                                </ul>
                                            </div>
                                            <div class="col-lg-3 align-self-center">
                                                <div className='row'>
                                                    <ul class="list-unstyled personal-detail mb-0">
                                                        <li className="mt-1"> <CheckSquareOutlined style={{ marginRight: 3 }} /><span className='textStyles-small' style={{ fontWeight: 600, marginRight: 5 }}>Created Date:</span><span className='textStyles-small'>
                                                            {moment(this.state.generalDetails?.createdDate).format('YYYY-MM-DD HH:mm')}</span></li>
                                                        <li className="mt-1"><CalendarOutlined style={{ marginRight: 3 }} /><span className='textStyles-small' style={{ fontWeight: 600, marginRight: 5 }}>Request Date:</span><span className='textStyles-small'> {moment(this.state.generalDetails?.requestDate).format('YYYY-MM-DD')}</span></li>
                                                        <li className="mt-1"><CalendarOutlined style={{ marginRight: 3 }} /><span className='textStyles-small' style={{ fontWeight: 600, marginRight: 5 }}>Service Date:</span><span className='textStyles-small'> {moment(this.state.generalDetails?.serviceDate).format('YYYY-MM-DD')}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.itemDetails.filter(item => !item?.section?.name?.includes('IMAGES')).map((section, index) => {
                            const sectionData = section?.data;
                            const dataArrayWithoutImages = sectionData.filter(item => item?.type !== 'image');
                            const dataArrayWithImages = sectionData.filter(item => item?.type === 'image');
                            return (
                                <div key={index} style={{ padding: 15, backgroundColor: 'white', borderRadius: 12, marginTop: 10, width: '100%' }}>
                                    <h5 className='textStyle-small' style={{ fontSize: 14 }}><FileProtectOutlined /> {section?.section?.name}</h5>
                                    {
                                        dataArrayWithoutImages.length !== 0 && (
                                            <Table
                                                dataSource={dataArrayWithoutImages}
                                                columns={columns}
                                                className="table-striped-rows"
                                                pagination={false}
                                                size='small'
                                                style={{ marginBottom: 10, marginTop: 15 }}
                                            />
                                        )
                                    }
                                    {
                                        dataArrayWithImages.length > 0 && (
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {
                                                    dataArrayWithImages.map((item) => (
                                                        <div key={item?.key} style={{ margin: '10px' }}>
                                                            <h6 style={{ textTransform: 'capitalize', fontSize: 12 }} className='textStyle-small'>
                                                                <FileImageOutlined /> {item.name.replace(/_/g, ' ')}
                                                            </h6>
                                                            <Image
                                                                width={200}
                                                                height={200}
                                                                src={image_url + (item?.value ? item?.value : "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg")}
                                                                style={{ cursor: 'pointer', borderRadius: 10 }}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            );
                        })
                    }
                    {
                        this.state.itemDetails
                            .filter(item => item?.section?.name?.includes('IMAGES'))
                            .map((section, index) => {
                                const imagesData = section?.data;
                                return (
                                    <div key={index} style={{ padding: 10, backgroundColor: 'white', borderRadius: 12, marginTop: 20, width: '100%' }}>
                                        <h5 className='textStyle-small' style={{ fontSize: 14 }}><FileImageOutlined /> {section?.section?.name}</h5>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {imagesData?.map((item) => (
                                                <div key={item?.id} style={{ margin: '10px' }}>
                                                    <h6 style={{ textTransform: 'capitalize', fontSize: 12 }} className='textStyle-small'>
                                                        <FileImageOutlined /> {item?.name.replace(/_/g, ' ')}
                                                    </h6>
                                                    <Image
                                                        width={200}
                                                        height={200}
                                                        src={item?.value ? image_url + item?.value : "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"}
                                                        style={{ cursor: 'pointer', borderRadius: 10 }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </>
        )
    }

}
function mapStateToProps(state) {

}
export default withRouter(connect(mapStateToProps, {})(PisRequestDetails));


