import React, { Component, Fragment } from 'react'
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, DatePicker, Popconfirm, message, Collapse, Switch, Row, Col, Card, Divider } from 'antd';
import { RightOutlined, DeleteOutlined, PlusCircleOutlined, HomeOutlined, EditOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { createCabinet, getCabinetDetails, UpdateCabinet, getupdateGeneralDetailsById, dataUpdateGeneralDetails } from "../../api/index";
import checkPermissions from '../../utils/permissionManager';
import moment from 'moment';
import AssetDelete from "../site_components/asset_delete";
import { checkServiceType } from "../../actions/service-type";

class Cabinet extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            title: "",
            tabaleLoading: false,
            openEdit: false,
            open: false,
            openViewMoreDetails: false,
            selectedElement: "",

            actionView: false,
            selectedAssetId: null,
            AssetType: [],
            selectedMakeId: null,
            makeType: null,
            selectedModelId: null,
            modelType: null,
            cabinetType: null,
            cabinetmaterialCodeOfTheCabinet: '',
            cabinetSerialNumber: '',
            cabinetcoolingMethodEquipmentCompartment: null,
            cabinetcoolingMethodBatteryCompartment: null,
            cabinetutilizedEquipmentSpace: null,
            cabinetutilizedBatterySpace: null,
            cabinetbatterySpace: '',
            cabineinstallBatteryCapacity: '',
            cabinetInstalledDate: null,
            AssetManagementPermission: false,
            cabinetName: "",
            selectedCabinetTypeId: null,
            sec1Item1: '',
            sec1Item2: true,
            sec1Item3: 'show',
            sec2Item1: 'collapsed',
            sec2Item2: false,
            sec2Item3: '',

            switchStatus: true,

            objectSet: {
                "noOfCabinets": 0,
                "cnoOfCabinetsWithEquipmentInstalled": 0,
                "cticketNumbersOfPendingFaultyTickets": 0,
                "cPlannedFromOperationalTeam": false,
                "cPlannedFromPlanninglTeam": false,
            },

            updateGeneralDetails: [],

            objSetDropdownCabinets: {
                "cabinetutilizedEquipmentSpace": "",
                "cabinetutilizedBatterySpace": "",
                "cnoOfCabinetsWithEquipmentInstalled": "",
                "cplannedFromPlanninglTeam": "",
                "cplannedFromOperationalTeam": "",
            }
        };
        this.requestFromClose = this.requestFromClose.bind(this);
        this.handleCabinetmaterialCodeOfTheCabinet = this.handleCabinetmaterialCodeOfTheCabinet.bind(this);
        this.handleCabinetSerialNumber = this.handleCabinetSerialNumber.bind(this);
        this.handlebatterySpace = this.handlebatterySpace.bind(this);
        this.handleinstallBatteryCapacity = this.handleinstallBatteryCapacity.bind(this);
        this.handleCabinetName = this.handleCabinetName.bind(this);
        this.handlecabinetutilizedEquipmentSpace = this.handlecabinetutilizedEquipmentSpace.bind(this);
        this.handlecabinetutilizedBatterySpace = this.handlecabinetutilizedBatterySpace.bind(this);

    }
    SiteInfoSection(element) {
        if (element == 1) {
            this.setState({
                sec1Item1: this.state.sec1Item1 != '' ? '' : 'collapsed',
                sec1Item2: this.state.sec1Item2 ? false : true,
                sec1Item3: this.state.sec1Item3 != '' ? '' : 'show',
            }, () => {

                if (this.state.sec2Item2 == true) {
                    this.setState({
                        sec2Item1: this.state.sec2Item1 != '' ? '' : 'collapsed',
                        sec2Item2: this.state.sec2Item2 ? false : true,
                        sec2Item3: this.state.sec2Item3 != '' ? '' : 'show',
                    })
                }
            })

        }

        if (element == 2) {
            this.setState({
                sec2Item1: this.state.sec2Item1 != '' ? '' : 'collapsed',
                sec2Item2: this.state.sec2Item2 ? false : true,
                sec2Item3: this.state.sec2Item3 != '' ? '' : 'show',
            }, () => {
                if (this.state.sec1Item2 == true) {
                    this.setState({
                        sec1Item1: this.state.sec1Item1 != '' ? '' : 'collapsed',
                        sec1Item2: this.state.sec1Item2 ? false : true,
                        sec1Item3: this.state.sec1Item3 != '' ? '' : 'show',
                    })

                }
            })
        }



    }
    handlecabinetutilizedEquipmentSpace(e) {
        this.setState({ cabinetutilizedEquipmentSpace: e });
    }
    handlecabinetutilizedBatterySpace(e) {
        console.log(e)
        this.setState({ cabinetutilizedBatterySpace: e });
    }
    handleinstallBatteryCapacity(e) {
        console.log(e)
        this.setState({ cabineinstallBatteryCapacity: e });
    }
    handlebatterySpace(e) {
        console.log(e)
        this.setState({ cabinetbatterySpace: e });
    }
    handleCabinetSerialNumber(e) {
        console.log(e.target.value)
        this.setState({ cabinetSerialNumber: e.target.value });
    }
    handleCabinetmaterialCodeOfTheCabinet(e) {
        console.log(e.target.value)
        this.setState({ cabinetmaterialCodeOfTheCabinet: e.target.value });
    }
    handleCabinetName(e) {
        console.log(e.target.value)
        this.setState({ cabinetName: e.target.value });
    }
    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();
        this.validator.hideMessages();
        this.setState({
            open: false,
            openEdit: false,
            selectedAssetId: null,
            AssetType: [],
            selectedMakeId: null,
            makeType: null,
            selectedModelId: null,
            modelType: null,
            cabinetType: '',
            cabinetmaterialCodeOfTheCabinet: '',
            cabinetSerialNumber: '',
            cabinetcoolingMethodEquipmentCompartment: null,
            cabinetcoolingMethodBatteryCompartment: null,
            cabinetutilizedEquipmentSpace: null,
            cabinetutilizedBatterySpace: null,
            cabinetbatterySpace: '',
            cabineinstallBatteryCapacity: '',
            cabinetInstalledDate: null,
            cabinetName: '',

        })

    }
    requestFromClose() {
        //console.log('dsdsd')
        this.setState({ showModal: true, open: true });
    }
    componentDidMount() {

        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;

            history.push("/");

        } else {
            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 38)
        }
        checkServiceType(38).then((data) => this.setState({
            AssetManagementPermission: data
        }, () => {
        }),

        )
        this.setState({
            objectSet: {
                "noOfCabinets": this.props.details?.access_technologies_data?.noOfCabinets,
                "cnoOfCabinetsWithEquipmentInstalled": this.props.details?.access_technologies_data?.cnoOfCabinetsWithEquipmentInstalled,
                "cticketNumbersOfPendingFaultyTickets": this.props?.details?.access_technologies_data?.cticketNumbersOfPendingFaultyTickets,
                "cplannedFromOperationalTeam": this.props?.details?.access_technologies_data?.cplannedFromOperationalTeam,
                "cplannedFromPlanninglTeam": this.props?.details?.access_technologies_data?.cplannedFromPlanninglTeam,

            }
        }, () => {

            console.log('rtrtrtrtrtrt')

            console.log(this.props)
            //  this.getupdateGeneralDetailsById(this.props.details?.access_technologies_data?.id)
        })
    }
    getSetPageData = (serviceTypeIs, Pageid) => {

        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] != null) {
            console.log('fddf');
            this.setState({
                actionView: true,
            })
        }

    };
    onChangeutilizedBatterySpace = (value) => {
        //console.log("onChangecoolingMethodBatteryCompartment");

        this.setState({
            cabinetutilizedBatterySpace: value,


        })


    };
    onChangeutilizedEquipmentSpace = (value) => {
        //console.log("onChangecoolingMethodBatteryCompartment");

        this.setState({
            cabinetutilizedEquipmentSpace: value,


        })


    };
    onChangecoolingMethodBatteryCompartment = (value) => {
        //console.log("onChangecoolingMethodBatteryCompartment");

        this.setState({
            cabinetcoolingMethodBatteryCompartment: value,


        })


    };
    onChangecoolingMethodEquipmentCompartment = (value) => {
        //console.log("onChangecoolingMethodEquipmentCompartment");
        this.setState({
            cabinetcoolingMethodEquipmentCompartment: value,


        })
    };
    onChangeInstalledDate = (date, dateString) => {
        console.log(date);
        console.log(dateString);
        this.setState({
            cabinetInstalledDate: dateString,
        });
    };
    onChangeCabinetType = (value) => {
        this.setState({
            cabinetType: {
                "id": value
            },

        })
    };
    onChangeModel = (value) => {
        console.log("onChangeModel");
        this.setState({
            selectedModelId: value,
            modelType: {
                "id": value
            },

        })
    };
    onChangeMake = (value) => {
        console.log("onChangeMake");
        this.setState({
            selectedMakeId: value,
            makeType: {
                "id": value
            },
        });
    };

    cabinetUpdata(id) {
        this.getDataCabinetDetails(id);
    }

    getupdateGeneralDetailsById = async (id) => {
        const formData = new FormData();
        formData.append('id', id);

        var res = await getupdateGeneralDetailsById(formData);

        if (res.success) {
            this.setState({
                updateGeneralDetails: res?.data
            })

        }

    }

    getDataCabinetDetails = async (id) => {
        var res = await getCabinetDetails(id);

        if (res.success) {
            this.setState({
                cabinetEdit: res.data,
                cabinetId: res.data.id,


                cabinetType: res.data?.type,
                cabinetSerialNumber: res.data?.cabinetSerialNumber,
                cabineinstallBatteryCapacity: res.data?.installBatteryCapacity,
                cabinetutilizedBatterySpace: res.data?.utilizedBatterySpace,
                cabinetutilizedEquipmentSpace: res.data?.utilizedEquipmentSpace,
                cabinetName: res.data?.name,
                selectedCabinetTypeId: res.data?.type?.id
            })
            if (res.data.model?.make != null) {
                this.setState({
                    selectedMakeId: res.data.model?.make?.id,
                    makeType: res.data.model?.make,
                }, () => {

                    console.log('ok')
                    console.log(this.state.selectedMakeId)
                })

            } else {
                this.setState({
                    selectedMakeId: null,
                    makeType: null,
                }, () => {
                    console.log('No')
                    console.log(this.state.selectedMakeId)
                })
            }

            if (res.data?.model != null) {
                this.setState({
                    selectedModelId: res.data?.model?.id,
                    modelType: res.data?.model,
                })

            } else {
                this.setState({
                    selectedModelId: null,
                    modelType: null,
                })
            }

            if (res.data?.installedDate != null) {

                this.setState({
                    cabinetInstalledDate: res.data?.installedDate
                })
            } else {

                this.setState({
                    cabinetInstalledDate: '',
                })
            }
            this.setState({
                openEdit: true,
                showModal: true
            });
        }
    }
    AllDataSubmitUpdate = async () => {
        if (this.validator.allValid()) {
            var dataSet;
            dataSet = {
                "id": this.state.cabinetId,
                "site": { "id": this.props?.detailsSite?.site_details.id },
                "asset": {
                    "id": 2
                },
                "make": this.state.makeType,
                "model": this.state.modelType,
                "type": this.state.cabinetType,
                "materialCodeOfTheCabinet": this.state.cabinetmaterialCodeOfTheCabinet,
                "cabinetSerialNumber": this.state.cabinetSerialNumber,
                "coolingMethodEquipmentCompartment": this.state.cabinetcoolingMethodEquipmentCompartment,
                "coolingMethodBatteryCompartment": this.state.cabinetcoolingMethodBatteryCompartment,
                "utilizedEquipmentSpace": this.state.cabinetutilizedEquipmentSpace,
                "utilizedBatterySpace": this.state.cabinetutilizedBatterySpace,
                "batterySpace": this.state.cabinetbatterySpace,
                "installBatteryCapacity": this.state.cabineinstallBatteryCapacity,
                "installedDate": this.state.cabinetInstalledDate,
                "name": this.state.cabinetName
            }
            var res = await UpdateCabinet(dataSet);
            if (res.success) {
                message.success('Successfully Cabinet Edited!')
                this.formRef.current.resetFields();

                this.setState({
                    showModal: false,
                    open: false,
                    openEdit: false,

                    selectedAssetId: null,
                    AssetType: [],
                    selectedMakeId: null,
                    makeType: [],
                    selectedModelId: null,
                    modelType: [],
                    cabinetType: '',
                    cabinetmaterialCodeOfTheCabinet: '',
                    cabinetSerialNumber: '',
                    cabinetcoolingMethodEquipmentCompartment: null,
                    cabinetcoolingMethodBatteryCompartment: null,
                    cabinetutilizedEquipmentSpace: null,
                    cabinetutilizedBatterySpace: null,
                    cabinetbatterySpace: '',
                    cabineinstallBatteryCapacity: '',
                    cabinetInstalledDate: null

                })
                this.props.parentMethod();
            } else {
                message.warning(' Something went wrong!')
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    AllDataSubmit = async () => {
        if (this.validator.allValid()) {
            var dataSet;
            dataSet = {

                "site": { "id": this.props?.detailsSite?.site_details.id },
                "asset": {
                    "id": 2
                },
                "make": this.state.makeType,
                "model": this.state.modelType,
                "type": this.state.cabinetType,
                "materialCodeOfTheCabinet": this.state.cabinetmaterialCodeOfTheCabinet,
                "cabinetSerialNumber": this.state.cabinetSerialNumber,
                "coolingMethodEquipmentCompartment": this.state.cabinetcoolingMethodEquipmentCompartment,
                "coolingMethodBatteryCompartment": this.state.cabinetcoolingMethodBatteryCompartment,
                "utilizedEquipmentSpace": this.state.cabinetutilizedEquipmentSpace,
                "utilizedBatterySpace": this.state.cabinetutilizedBatterySpace,
                "batterySpace": this.state.cabinetbatterySpace,
                "installBatteryCapacity": this.state.cabineinstallBatteryCapacity,
                "installedDate": this.state.cabinetInstalledDate,
                "name": this.state.cabinetName != "" ? this.state.cabinetName : "Cabinet-"
            }
            var res = await createCabinet(dataSet);
            if (res.success) {
                message.success('Successfully Cabinet Added!')
                this.formRef.current.resetFields();

                this.setState({
                    showModal: false,
                    open: false,
                    openViewMoreDetails: false,
                    selectedElement: "",
                    selectedAssetId: null,
                    AssetType: [],
                    selectedMakeId: null,
                    makeType: [],
                    selectedModelId: null,
                    modelType: [],
                    cabinetType: '',
                    cabinetmaterialCodeOfTheCabinet: '',
                    cabinetSerialNumber: '',
                    cabinetcoolingMethodEquipmentCompartment: null,
                    cabinetcoolingMethodBatteryCompartment: null,
                    cabinetutilizedEquipmentSpace: null,
                    cabinetutilizedBatterySpace: null,
                    cabinetbatterySpace: '',
                    cabineinstallBatteryCapacity: '',
                    cabinetInstalledDate: null
                })
                this.props.parentMethod();
                // const { history } = this.props;
                // history.push("/site");
            } else {
                message.warning(' Something went wrong!')
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    getDataDetailsForDelete = async (id) => {
        var res = await getCabinetDetails(id);
        if (res.success) {
            if ((res.data?.rectifier?.filter(name => name.delete == false).length == 0) && (res.data?.batteryBank?.filter(name => name.delete == false).length == 0)) {
                var respons = AssetDelete.Action(res.data, this.props?.detailsSite?.site_details.id, UpdateCabinet)
                respons.then((value) => {
                    if (value == 1) {
                        message.success('Successfully Delete!')
                        this.props.parentMethod();
                    } else {
                        message.warning(' Something went wrong!')
                    }
                });
            } else {
                message.error('You can not delete this Cabinet because the related Rectifiers or Battery Banks!')
            }
        }
    }

    submitForm = async (item) => {
        this.getDataDetailsForDelete(item);
    }

    viewMoreDetails(element) {
        this.setState({
            showModal2: true,
            openViewMoreDetails: true,
            selectedElement: element
        });
    }
    handlcticketNumbersOfPendingFaultyTickets = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                cticketNumbersOfPendingFaultyTickets: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })

    }
    handlcnoOfCabinetsWithEquipmentInstalled = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                cnoOfCabinetsWithEquipmentInstalled: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })

    }
    switchTo2() {
        this.state.objectSet['cplannedFromPlanninglTeam'] = !this.state.objectSet['cplannedFromPlanninglTeam'];
    }
    switchTo() {
        this.state.objectSet['cplannedFromOperationalTeam'] = !this.state.objectSet['cplannedFromOperationalTeam'];
    }

    onViewMoreDetailsDrawerClose = () => {
        this.setState({
            showModal2: false,
            openViewMoreDetails: false,
        })
    }

    validatedTextLable = (field) => {
        return (field !== null && field !== '')
            ? <span class="badge badge-outline-secondary text-muted">{(field)}</span>
            : <span class="badge text-danger">Currently Not Set</span>;
    }
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "noOfCabinets": this.props.details?.access_technologies_data?.noOfCabinets,
                "cnoOfCabinetsWithEquipmentInstalled": this.props.details?.access_technologies_data?.cnoOfCabinetsWithEquipmentInstalled,
                "cticketNumbersOfPendingFaultyTickets": this.props?.details?.access_technologies_data?.cticketNumbersOfPendingFaultyTickets,
                "cplannedFromOperationalTeam": this.props?.details?.access_technologies_data?.cplannedFromOperationalTeam,
                "cplannedFromPlanninglTeam": this.props?.details?.access_technologies_data?.cplannedFromPlanninglTeam,

            }
        });
        this.setState({
            switchStatus: true
        });
    }

    dataSetToUpdate = async () => {
        let remoteJob = {
            ...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
            ...this.state.objectSet
        };
        const formData = new FormData();
        formData.append('id', this.props.details?.access_technologies_data?.id);
        formData.append('object', JSON.stringify(remoteJob));

        var res = await dataUpdateGeneralDetails(formData);

        if (res.success) {
            message.success('Successfully Update!')
            this.props.parentMethod();
            this.setState({
                switchStatus: true
            })
        } else {
            message.warning(' Something went wrong!')
        }
    }

    render() {
        const { Option } = Select;
        const { Panel } = Collapse;
        const _cabinet = this.state.selectedElement;

        const columns2 = [
            {
                title: "Cabinet Name",
                content: _cabinet !== ""
                    ? <Tag color="blue" style={{ margin: "0px" }}><span>{_cabinet.name}</span></Tag> : <></>,
                details: []
            },
            {
                title: "Type",
                content: _cabinet !== ""
                    ? <span class="badge badge-soft-warning fw-semibold">{_cabinet.type?.name}</span>
                    : <></>,
                details: []
            },
            {
                title: "Make",
                content: _cabinet !== ""
                    ? <Tag color="gray" style={{ margin: "0px" }}><span>{_cabinet.model?.make?.name}</span></Tag> : <></>,
                details: []
            },
            {
                title: "Material code of the Cabinet",
                content: this.validatedTextLable(_cabinet.model?.materialCodeOfTheCabinet),
                details: []
            },
            {
                title: "Cabinet Serial Number",
                content: this.validatedTextLable(_cabinet.cabinetSerialNumber),
                details: []
            },
            {
                title: "Model",
                content: _cabinet !== ""
                    ? <span class="badge badge-outline-info badge-pill"><i class="fas fa-hdd" style={{ paddingRight: "5px" }} />{_cabinet.model?.name}</span>
                    : <></>,
                details: [
                    {
                        title: "Cooling method (Equipment compartment)",
                        content: this.validatedTextLable(_cabinet.model?.coolingMethodEquipmentCompartment),
                    },
                    {
                        title: "Cooling method (Battery compartment)",
                        content: this.validatedTextLable(_cabinet.model?.coolingMethodBatteryCompartment),
                    },
                    {
                        title: "Cooling Capacity (Equipment compartment)",
                        content: this.validatedTextLable(_cabinet.model?.coolingCapacityEquipmentCompartment),
                    },
                    {
                        title: "Cooling Capacity (Battery compartment)",
                        content: this.validatedTextLable(_cabinet.model?.coolingCapacityBatteryCompartment),
                    },
                    {
                        title: "Equipment Space (U) ",
                        content: this.validatedTextLable(_cabinet.model?.equipmentSpace),
                    },
                    {
                        title: "Battery Space (U) ",
                        content: this.validatedTextLable(_cabinet.model?.batterySpace),
                    },
                ]
            },
            {
                title: "Utilized Equipment space (U)",
                content: this.validatedTextLable(_cabinet.utilizedEquipmentSpace),
                details: []
            },
            {
                title: "Utilized Battery Space (U)",
                content: this.validatedTextLable(_cabinet.utilizedBatterySpace),
                details: []
            },
            {
                title: "Install Battery Capacity  (Ah)",
                content: this.validatedTextLable(_cabinet.installBatteryCapacity),
                details: []
            },
            {
                title: "Installed date",
                content: this.validatedTextLable(_cabinet.installedDate),
                details: []
            },
        ];

        return (
            <>
                <div className='row'>
                    <div className='row'>
                        <div class="col-lg-12">
                            <div class="text-end">

                            </div>
                        </div>
                    </div>

                    <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px", marginTop: 15 }}>
                        <div className="d-flex justify-content-end align-items-center" style={{ marginBottom: "10px", marginRight: "10px" }}>
                            {this.state.switchStatus
                                ?
                                checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                <Fragment>
                                    <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}
                                        style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}> <i class="mdi mdi-pencil-outline" style={{ color: 'white' }}></i></button>
                                    <Button
                                        type="button"
                                        class="btn btn-soft-primary btn-sm"
                                        style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginLeft: '10px' }}
                                        onClick={this.requestFromClose}
                                        icon={<PlusCircleOutlined style={{ color: 'white' }} />}>
                                        <span className="textStyles-small" style={{ color: 'white' }}>New Asset</span>
                                    </Button>
                                </Fragment>) : null
                                : <div class="d-flex justify-content-end align-items-center">
                                    <button type="button" class="btn btn-soft-secondary  textStyles-small" onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
                                    <button type="button" class="btn btn-soft-primary  textStyles-small" onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                                </div>
                            }
                        </div>
                        <ul class="list-group">
                            <Row span={24}>
                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className="textStyles-small">No. Of Cabinets</span>
                                            </div>
                                            {this.props.details?.access_technologies_data?.noOfCabinets != null ?
                                                (<span class="badge badge-outline-success textStyles-small">{this.props.details2.cabinet_data.length}</span>) :
                                                (<span class="badge badge-outline-warning textStyles-small">Currently Not Set</span>)}
                                        </li>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                            <div>
                                                <span className="textStyles-small">No Of Cabinets With Equipment Installed</span>
                                            </div>
                                            <div>
                                                {this.state.switchStatus.toString() == "true" ? (
                                                    this.props?.details?.access_technologies_data?.cnoOfCabinetsWithEquipmentInstalled ? (
                                                        <span class="badge badge-outline-success textStyles-small">
                                                            {this.props?.details?.access_technologies_data?.cnoOfCabinetsWithEquipmentInstalled}</span>
                                                    ) : (
                                                        <span class="badge badge-outline-danger textStyles-small">Currently Not Set</span>
                                                    )
                                                ) : (
                                                    <Select
                                                        defaultValue={this.props.details.access_technologies_data?.cnoOfCabinetsWithEquipmentInstalled}
                                                        style={{ width: '120px' }}
                                                        className='borderedSelect'
                                                        bordered={false}
                                                        onChange={(e) => {
                                                            this.state.objectSet.cnoOfCabinetsWithEquipmentInstalled = e
                                                        }}>
                                                        {this.props.cabinetDropdown["objSetDropdownCabinets"]["cnoOfCabinetsWithEquipmentInstalled"]?.map((x) => {
                                                            return <Option value={x}>{x}</Option>
                                                        })}
                                                    </Select>
                                                )}
                                            </div>
                                        </li>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                            <div>
                                                <span className="textStyles-small">
                                                    Ticket Numbers of pending Faulty Tickets
                                                </span>
                                            </div>
                                            {this.state.switchStatus ? (<>{this.props?.details?.access_technologies_data?.cticketNumbersOfPendingFaultyTickets != null ? (
                                                <span class="badge badge-outline-success textStyles-small">{this.props?.details?.access_technologies_data?.cticketNumbersOfPendingFaultyTickets}</span>) : (
                                                <span class="badge badge-outline-warning textStyles-small">Currently Not Set</span>)}</>) : (<>
                                                    <InputNumber min={0}
                                                        style={{ width: '120px', height: 34 }}
                                                        className='borderedSelect'
                                                        value={this.state.objectSet?.cticketNumbersOfPendingFaultyTickets} id="cnoOfCabinetsWithEquipmentInstalled" onChange={this.handlcticketNumbersOfPendingFaultyTickets} />  </>)}
                                        </li>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className="textStyles-small">
                                                    Planned from Operational team
                                                </span>
                                            </div>
                                            <div>
                                                {this.state.switchStatus.toString() === "true" ? (
                                                    this.props?.details?.access_technologies_data?.cplannedFromOperationalTeam == null ||
                                                        this.props?.details?.access_technologies_data?.cplannedFromOperationalTeam == "" ? (
                                                        <span className="badge badge-outline-danger textStyles-small">Currently Not Set</span>
                                                    ) : (
                                                        this.props?.details?.access_technologies_data?.cplannedFromOperationalTeam === "true" ||
                                                            this.props?.details?.access_technologies_data?.cplannedFromOperationalTeam === "Yes" ? (
                                                            <span className="badge badge-outline-success textStyles-small">Yes</span>
                                                        ) : (
                                                            <span className="badge badge-outline-danger textStyles-small">No</span>
                                                        )
                                                    )
                                                ) : (
                                                    <Select
                                                        defaultValue={this.props.details.access_technologies_data?.cplannedFromOperationalTeam}
                                                        style={{ width: '120px' }}
                                                        className='borderedSelect'
                                                        bordered={false}
                                                        onChange={(e) => {
                                                            this.setState({ objectSet: { ...this.state.objectSet, cplannedFromOperationalTeam: e } });
                                                        }}
                                                    >
                                                        {this.props.cabinetDropdown["objSetDropdownCabinets"]["cplannedFromOperationalTeam"]?.map((x) => (
                                                            <Option key={x} value={x}>
                                                                {x}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </div>
                                        </li>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className="textStyles-small">
                                                    Planned From Planningl Team
                                                </span>
                                            </div>
                                            <div>
                                                {this.state.switchStatus.toString() === "true" ? (
                                                    this.props?.details?.access_technologies_data?.cplannedFromPlanninglTeam == null ||
                                                        this.props?.details?.access_technologies_data?.cplannedFromPlanninglTeam === "" ? (
                                                        <span className="badge badge-outline-danger">Currently Not Set</span>
                                                    ) : (
                                                        ["true", "Yes", "yes"].includes(this.props?.details?.access_technologies_data?.cplannedFromPlanninglTeam) ? (
                                                            <span className="badge badge-outline-success textStyles-small">Yes</span>
                                                        ) : (
                                                            <span className="badge badge-outline-danger textStyles-small">No</span>
                                                        )
                                                    )
                                                ) : (
                                                    <Select
                                                        defaultValue={this.props.details.access_technologies_data?.cplannedFromPlanninglTeam}
                                                        style={{ width: '120px' }}
                                                        className='borderedSelect'
                                                        bordered={false}
                                                        onChange={(e) => {
                                                            this.setState({ objectSet: { ...this.state.objectSet, cplannedFromPlanninglTeam: e } });
                                                        }}
                                                    >
                                                        {this.props.cabinetDropdown["objSetDropdownCabinets"]["cplannedFromPlanninglTeam"]?.map((x) => (
                                                            <Option key={x} value={x}>
                                                                {x}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </div>
                                        </li>
                                    </div>
                                </Col>
                            </Row>
                        </ul>
                    </div>

                    <Row span={24} style={{ marginTop: 10 }}>
                        {this.props.details2.cabinet_data.map((element, index) => (
                            <Col span={8} key={index}>
                                <Card
                                    bordered={false}
                                    style={{
                                        width: '98%',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                        position: 'relative',
                                        marginTop: 10
                                    }}
                                >
                                    <div style={{ display: 'flex', marginBottom: 16, backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '10px' }}>
                                        <div>
                                            <HomeOutlined style={{ marginLeft: '3px', marginRight: '3px' }} /> &rarr;
                                            <span style={{ marginLeft: '3px', marginRight: '3px', fontWeight: 'bold' }} className="textStyles-small">
                                                {this.props?.detailsSite?.site_details?.siteName?.substring(0, 11)}
                                            </span>
                                            &rarr;
                                            <span style={{ marginLeft: '3px', color: '#BA183A', fontWeight: 'bold' }} className="textStyles-small">{element?.name?.slice(0, 18)}</span>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 8 }}>
                                        <span className="textStyles-small" style={{ fontSize: 12 }}>Make</span>
                                        <Tag color="default" style={{ float: 'right', borderRadius: 5 }}> <span className="textStyles-small">{element.model?.make?.name}</span></Tag>
                                    </div>
                                    <div style={{ marginBottom: 8 }}>
                                        <span className="textStyles-small" style={{ fontSize: 12 }}>Serial Number</span>
                                        <Tag color="purple" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                                            <span className="textStyles-small" style={{ fontSize: 11 }}>
                                                {element.cabinetSerialNumber}
                                            </span></Tag>
                                    </div>

                                    <div style={{ marginBottom: 8 }}>
                                        <span className="textStyles-small" style={{ fontSize: 12 }}>Model</span>
                                        <Tag color="purple" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                                            <span className="textStyles-small" style={{ fontSize: 11 }}>
                                                {element.model?.name.slice(0, 30)}
                                            </span></Tag>
                                    </div>

                                    <div style={{ marginBottom: 8 }}>
                                        <span className="textStyles-small" style={{ fontSize: 12 }}>Type</span>
                                        <Tag color="purple" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                                            <span className="textStyles-small" style={{ fontSize: 11 }}>
                                                {element.type?.name}
                                            </span></Tag>
                                    </div>

                                    <Divider style={{ marginTop: '3px' }} />
                                    <Button
                                        type="primary"
                                        style={{ marginRight: 8, borderRadius: '5px' }}
                                        size='small'
                                        onClick={() => this.viewMoreDetails(element)}>
                                        <span className="textStyles-small" style={{ fontSize: 10 }}>
                                            <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details</span>
                                    </Button>
                                    {
                                        checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                    <Button
                                        icon={<EditOutlined style={{ color: 'white' }} />}
                                        style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                        onClick={() => this.cabinetUpdata(element.id)}
                                        size='small'
                                    />) : ('')
                                    }
                                    <Popconfirm
                                        title={<span className='textStyles-small' style={{ fontSize: 14 }}>Are you sure to delete this Asset?</span>}
                                        description={<span className="textStyles-small">Are you sure to delete this Asset?</span>}
                                        onConfirm={() => this.submitForm(element.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        {checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                                <Button
                                                    icon={<DeleteOutlined style={{ color: 'white' }} />}
                                                    danger
                                                    size='small'
                                                    style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#BA183A', borderColor: '#BA183A' }}
                                                />
                                            )
                                            : ('')}
                                    </Popconfirm>

                                </Card>
                            </Col>
                        ))}
                    </Row>

                    <Drawer maskClosable={false} title={<span className="textStyles-small" style={{ fontSize: 14 }}>Cabinet Details</span>}
                        width={"50%"} placement="right"
                        onClose={() => this.onViewMoreDetailsDrawerClose()}
                        visible={this.state.openViewMoreDetails}
                        closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm"
                            style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <Collapse
                            style={{ alignContent: "center", borderColor: "white" }}
                        >
                            {columns2.map((item, index) => (
                                <Panel
                                    style={{
                                        borderColor: index % 2 === 0 ? "#f8f9fa" : "white",
                                        backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white"
                                    }}
                                    header={
                                        <li className="d-flex justify-content-between align-items-center"
                                            style={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white" }}>
                                            <div>
                                                <span className="textStyles-small">{item.title}</span>
                                            </div>
                                            <span className="textStyles-small">{item.content}</span>
                                        </li>
                                    }
                                    key={item.title}
                                    showArrow={item.details.length > 0}
                                    collapsible={item.details.length > 0 ? "" : "icon"}
                                >
                                    {item.details.map((detail) => (
                                        <li
                                            className="d-flex justify-content-between align-items-center me-2"
                                            style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                                            key={detail.title}
                                        >
                                            <div>
                                                <span className='textStyles-small'>{detail.title}</span>
                                            </div>
                                            <span className="textStyles-small">{detail.content}</span>
                                        </li>
                                    ))}
                                </Panel>
                            ))}
                        </Collapse>

                    </Drawer>

                    <Drawer maskClosable={false}
                        title={<span className="textStyles-small" style={{ fontSize: 14 }}>New Cabinets</span>}
                        width={"50%"}
                        placement="right" onClose={() => this.onDrawerClose()}
                        visible={this.state.open} closeIcon={<button type="button"
                            class="btn btn-soft-danger btn-icon-square-sm"
                            style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card" style={{ borderColor: 'white' }}>
                                    <div className="card-body" style={{ borderColor: 'white' }}>
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        <span className="textStyles-small">Cabinet Name <x style={{ color: "red" }}>*</x></span>
                                                    </label>
                                                    <Form.Item name="cabinetName" tooltip="This is a required field">
                                                        <Input style={{ width: '100%', height: 34 }} className='borderedSelect'
                                                            id="cabinetName" defaultValue={"Cabinet-"} onChange={this.handleCabinetName} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Cabinet Name', this.state.cabinetName, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        <span className="textStyles-small">
                                                            Cabinet Type <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item required name="Model" tooltip="This is a required field">
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangeCabinetType}
                                                            style={{ width: "100%" }}
                                                            className='borderedSelect'
                                                            bordered={false}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.cabinetMasterData?.cabinetMasterData?.cabinetTypeList?.map((item, index) => {
                                                                return <Option value={item.id}>{item.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Cabinet Type', this.state.cabinetType, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        <span className="textStyles-small">
                                                            Make <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item required name="Make" tooltip="This is a required field">
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangeMake}
                                                            style={{ width: "100%" }}
                                                            className='borderedSelect'
                                                            bordered={false}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.cabinetMasterData?.cabinetMasterData?.cabinetMakeList?.map((item, index) => {
                                                                return <Option value={item.id}>{item.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Make', this.state.selectedMakeId, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        <span className="textStyles-small">
                                                            Cabinet Serial Number <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item required name="cabinetSerialNumber" tooltip="This is a required field">
                                                        <Input id="cabinetSerialNumber" onChange={this.handleCabinetSerialNumber} style={{ width: '100%', height: 34 }} className='borderedSelect' />
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Cabinet Serial Number', this.state.cabinetSerialNumber, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                {
                                                    this.state.selectedMakeId != null ? (
                                                        <div className="col-md-6">
                                                            <label className="form-label" for="depot">
                                                                <span className="textStyles-small">
                                                                    Model <x style={{ color: "red" }}>*</x>
                                                                </span>
                                                            </label>
                                                            <Form.Item required name="Model" tooltip="This is a required field">
                                                                <Select
                                                                    className='borderedSelect'
                                                                    bordered={false}
                                                                    showSearch
                                                                    disabled={this.state.selectedMakeId == null}
                                                                    onChange={this.onChangeModel}
                                                                    style={{ width: "100%" }}
                                                                    placeholder="Search to Select"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    filterSort={(optionA, optionB) =>
                                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                    }
                                                                >
                                                                    {this.props.cabinetMasterData?.cabinetMasterData?.cabinetModelList?.map((item, index) => {
                                                                        if (this.state.selectedMakeId != null && item.make?.id == this.state.selectedMakeId) {
                                                                            return <Option value={item.id}>{item.name}</Option>;
                                                                        }
                                                                    })}
                                                                </Select>
                                                                <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                                    {this.validator.message('Model', this.state.selectedModelId, 'required')}</p>
                                                            </Form.Item>
                                                        </div>
                                                    ) : (<></>)
                                                }
                                                <div className="col-md-6">
                                                    <label className="form-label" for="ats">
                                                        <span className="textStyles-small">
                                                            Utilized Equipment Space (U) <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item required name="installBatteryCapacity" >
                                                        <div>
                                                            <Select
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                id="cabinetutilizedEquipmentSpace"
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handlecabinetutilizedEquipmentSpace(e)
                                                                }}>
                                                                {this.props.cabinetDropdown["objSetDropdownCabinets"]["cabinetutilizedEquipmentSpace"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Utilized Equipment Space', this.state.cabinetutilizedEquipmentSpace, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="ats">
                                                        <span className="textStyles-small">
                                                            Utilized Battery Space (U) <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item required name="installBatteryCapacity" >
                                                        <div>
                                                            <Select
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                id="cabinetutilizedBatterySpace"
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handlecabinetutilizedBatterySpace(e)
                                                                }}>
                                                                {this.props.cabinetDropdown["objSetDropdownCabinets"]["cabinetutilizedBatterySpace"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Utilized Battery Space', this.state.cabinetutilizedBatterySpace, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        <span className="textStyles-small">
                                                            Install Battery Capacity (Ah) <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item required name="installBatteryCapacity" >
                                                        <InputNumber style={{ width: '100%', height: 34 }} id="installBatteryCapacity" onChange={this.handleinstallBatteryCapacity} className='borderedSelect' />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Install Battery Capacity', this.state.cabineinstallBatteryCapacity, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        <span className="textStyles-small">
                                                            Installed Date
                                                        </span>
                                                    </label>
                                                    <Form.Item name="installedDate" tooltip="This is a required field">
                                                        <DatePicker
                                                            className='borderedSelect'
                                                            style={{ width: "100%", height: 34 }}
                                                            onChange={this.onChangeInstalledDate}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <br></br>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >
                                                <Button style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}
                                                    className="btn btn-primary" onClick={() => this.AllDataSubmit()} >
                                                    <span className="textStyles-small" style={{ color: 'white' }}>Add This Asset</span>
                                                </Button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>

                    <Drawer maskClosable={false}
                        title={<span className="textStyles-small" style={{ fontSize: 14 }}>Edit Cabinets</span>}
                        width={"50%"}
                        placement="right"
                        onClose={() => this.onDrawerClose()}
                        visible={this.state.openEdit}
                        closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm"
                            style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card" style={{ borderColor: 'white' }}>
                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        <span className="textStyles-small">
                                                            Cabinet Name <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item tooltip="This is a required field">
                                                        <Input id="cabinetName" value={this.state.cabinetName} onChange={this.handleCabinetName} style={{ width: '100%', height: 34 }} className='borderedSelect' />
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Cabinet Name', this.state.cabinetName, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        <span className="textStyles-small">
                                                            Cabinet Type <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item required name="Model" tooltip="This is a required field">
                                                        <Select
                                                            className='borderedSelect'
                                                            bordered={false}
                                                            showSearch
                                                            onChange={this.onChangeCabinetType}
                                                            defaultValue={this.state.selectedCabinetTypeId}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.cabinetMasterData?.cabinetMasterData?.cabinetTypeList?.map((item, index) => {
                                                                return <Option value={item.id}>{item.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Cabinet Type', this.state.cabinetType, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        <span className="textStyles-small">
                                                            Make <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    {this.state.selectedMakeId != null ? (<>  <Form.Item required tooltip="This is a required field">
                                                        <Select
                                                            className='borderedSelect'
                                                            bordered={false}
                                                            showSearch
                                                            onChange={this.onChangeMake}
                                                            defaultValue={this.state.selectedMakeId}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.cabinetMasterData?.cabinetMasterData?.cabinetMakeList?.map((item, index) => {
                                                                return <Option value={item.id}>{item.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Make', this.state.selectedMakeId, 'required')}</p>
                                                    </Form.Item></>) : ('')}
                                                    {this.state.selectedMakeId == null ? (<>  <Form.Item required tooltip="This is a required field">
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangeMake}
                                                            className='borderedSelect'
                                                            bordered={false}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.cabinetMasterData?.cabinetMasterData?.cabinetMakeList?.map((item, index) => {
                                                                return <Option value={item.id}>{item.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Make', this.state.selectedMakeId, 'required')}</p>
                                                    </Form.Item></>) : ('')}

                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        <span className="textStyles-small">
                                                            Cabinet Serial Number <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item tooltip="This is a required field">
                                                        <Input id="cabinetSerialNumber" value={this.state.cabinetSerialNumber} onChange={this.handleCabinetSerialNumber} style={{ width: '100%', height: 34 }} className='borderedSelect' />
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Cabinet Serial Number', this.state.cabinetSerialNumber, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        <span className="textStyles-small">
                                                            Model <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    {this.state.selectedModelId != null ?
                                                        (<> <Form.Item required name="model" tooltip="This is a required field">
                                                            <Select
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                showSearch
                                                                onChange={this.onChangeModel}
                                                                defaultValue={this.state.selectedModelId}
                                                                style={{ width: "100%" }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {this.props.cabinetMasterData?.cabinetMasterData?.cabinetModelList?.map((item, index) => {
                                                                    if (this.state.selectedMakeId != null && item.make?.id == this.state.selectedMakeId) {
                                                                        return <Option value={item.id}>{item.name}</Option>;
                                                                    }
                                                                })}
                                                            </Select>
                                                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                                {this.validator.message('Model', this.state.selectedModelId, 'required')}</p>
                                                        </Form.Item> </>) : (<>  <Form.Item required name="Model" tooltip="This is a required field">
                                                            <Select
                                                                showSearch
                                                                onChange={this.onChangeModel}
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                style={{ width: "100%" }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {this.props.cabinetMasterData?.cabinetMasterData?.cabinetModelList?.map((item, index) => {
                                                                    if (this.state.selectedMakeId != null && item.make?.id == this.state.selectedMakeId) {
                                                                        return <Option value={item.id}>{item.name}</Option>;
                                                                    }
                                                                })}
                                                            </Select>
                                                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                                {this.validator.message('Model', this.state.selectedModelId, 'required')}</p>
                                                        </Form.Item></>)}
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="ats">
                                                        <span className="textStyles-small">
                                                            Utilized Equipment Space (U) <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item >
                                                        <div>
                                                            <Select
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                id="cabinetutilizedEquipmentSpace"
                                                                value={this.state.cabinetutilizedEquipmentSpace}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handlecabinetutilizedEquipmentSpace(e)
                                                                }}>
                                                                {this.props.cabinetDropdown["objSetDropdownCabinets"]["cabinetutilizedEquipmentSpace"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Utilized Equipment Space', this.state.cabinetutilizedEquipmentSpace, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="ats">
                                                        <span className="textStyles-small">
                                                            Utilized Battery Space (U) <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item >
                                                        <div>
                                                            <Select
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                id="cabinetutilizedBatterySpace"
                                                                value={this.state.cabinetutilizedBatterySpace}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handlecabinetutilizedBatterySpace(e)
                                                                }}>
                                                                {this.props.cabinetDropdown["objSetDropdownCabinets"]["cabinetutilizedBatterySpace"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Utilized Battery Space', this.state.cabinetutilizedBatterySpace, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        <span className="textStyles-small">
                                                            Install Battery Capacity (Ah) <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item  >
                                                        <InputNumber style={{ width: '100%', height: 34 }} value={this.state.cabineinstallBatteryCapacity} id="installBatteryCapacity" onChange={this.handleinstallBatteryCapacity} className='borderedSelect' />
                                                        <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                                                            {this.validator.message('Install Battery Capacity', this.state.cabineinstallBatteryCapacity, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                {this.state.cabinetInstalledDate != null ? (
                                                    <div className="col-md-6">
                                                        <label className="form-label" for="dcdu">
                                                            <span className="textStyles-small">
                                                                Installed Date
                                                            </span>
                                                        </label>
                                                        <Form.Item required name=" Warranty Expired Date2" >
                                                            <DatePicker
                                                                defaultValue={this.state.cabinetInstalledDate != '' ? (moment(this.state.cabinetInstalledDate)) : ("")}
                                                                style={{ width: "100%" }}
                                                                className='borderedSelect'
                                                                onChange={this.onChangeInstalledDate}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                ) : (<></>)}
                                            </div>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >
                                                <Button className="btn btn-primary" onClick={() => this.AllDataSubmitUpdate()} style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}>
                                                    <span className="textStyles-small" style={{ color: 'white' }}>Update This Asset</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </div>
            </>
        )
    }
}
export default Cabinet;
