import React, { Component } from 'react'
import { Select, DatePicker, Table, Image, Avatar, Input, Tag, Badge, Descriptions, Space, Row, Col, Button } from 'antd';
import { Link } from "react-router-dom";
import { getPGStatusSummary, getLastThreeRequestDetails, getUserSites, getPisHistoryData, getSitesWithFilter, getPisHistoryDetailsById, getAllPisStatus, getPGDataView } from "../../api/index";
import Modal from 'react-modal';
import { THE_FAMILY_ID } from '@azure/msal-common/dist/utils/Constants';
import Item from 'antd/lib/list/Item';
import ReactLoading from 'react-loading';
import { tupleNum } from 'antd/lib/_util/type';
import { Collapse } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import '../master-data/style.css';
import moment from 'moment';
import { EyeOutlined } from '@ant-design/icons';


class PgHistoryView extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            showModal: false,
            isOpenFillter: true,
            allList: null,
            allSite_loading: true,
            allSite: [],
            depotList: [],
            statusList: [],
            regionsList: [],
            allDataList: [],
            startDate: "",
            endDate: "",
            selectSiteId: null,
            selectSiteName: null,
            selectComptName: null,
            comptId: null,
            selectImagesDetails: null,
            regionIds: '',
            depotIds: '',
            siteIds: '',
            statusId: '',
            enterReqCode: '',
            fullPageCount: 0,
            loopPageCount: 0,
            recodesPerPage: 50,
            itemDetails: [],
            selectedServiceTypeId: 1,
            selectedDataUrl: "/genServiceRequest/getAllPisGenServiceRequests",
            selectedDetailUrl: "",
            recodesPerPage2: 1,
            pageNo2: 0,
            filterVisibility: false
        };

    }
    viewFilterClick = () => {
        this.setState({
            isOpenFillter: !this.state.isOpenFillter
        })
    }

    previousBtnClick2 = () => {
        if (this.state.pageNo2 > 0) {
            var aa = this.state.pageNo2 - 1
            this.setState({
                pageNo2: aa
            }, () => {
                this.getPisDataList();
            })
        }
    }
    nextBtnClick2 = () => {
        if (this.state.recodesPerPage2 > this.state.pageNo2 + 1) {
            var aa = this.state.pageNo2 + 1
            this.setState({
                pageNo2: aa
            }, () => {
                this.getPisDataList();
            })

        }

    }

    // getPGStatusSummary
    getPGStatusDataByID = async (id) => {
        try {
            const formData = new FormData();
            formData.append('serviceRequestId', id);
            const data = await getPGStatusSummary(formData);
            console.log(data);
        } catch (error) {
            console.error('Error fetching PG status data:', error);
        }
    }


    componentDidMount() {
        this.getAllDataPisStatus();
        this.getDataSitesByuser()


        var _depotList = [];
        var _regionsList = [];

        this.props.depots.map((depot) => {
            _depotList.push({ value: depot.id, label: depot.name })
        })
        this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((regions) => {
            _regionsList.push({ value: regions.id, label: regions.name })
        })
        this.setState({
            depotList: _depotList,
            regionsList: _regionsList
        })
    }

    getDataSitesByuser = async () => {
        var res = await getUserSites();
        var _allSite = [];
        console.log("getUserSites");
        //console.log(res.data);
        if (res.success) {
            res.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,
            });



        }
    };

    getAllDataPisStatus = async () => {
        var res = await getAllPisStatus();
        console.log('getAllPisStatus')
        console.log(res);
        if (res.success) {
            var _statudList = [];
            res.data.map((depot) => {
                _statudList.push({ value: depot.id, label: depot.name })
            })
            this.setState({
                statusList: _statudList,
            })
        }
    }

    getPisDataList = async () => {
        this.setState({
            loading: true
        })

        var data_response = [];

        var data = new FormData();
        data.append("pageNo", this.state.pageNo2);
        data.append("rowCountPerPage", this.state.recodesPerPage);
        data.append('fromDate', this.state.startDate);
        data.append('toDate', this.state.endDate);
        data.append('regionIds', this.state.regionIds);
        data.append('depotIds', this.state.depotIds);
        data.append('siteIds', this.state.siteIds);
        // data.append('status', this.state.statusId);
        data.append('serviceRequestCode', this.state.enterReqCode);
        var res = await getPGDataView(data);
        var pageCount = res.data.pageCount;

        for (var i = 1; i <= pageCount; i++) {
            if (i === 1) {
                data_response.push(res.data.data);
            } else {
                var data = new FormData();
                data.append("pageNo", i - 1);
                data.append("rowCountPerPage", this.state.recodesPerPage);
                data.append('fromDate', this.state.startDate);
                data.append('toDate', this.state.endDate);
                data.append('regionIds', this.state.regionIds);
                data.append('depotIds', this.state.depotIds);
                data.append('siteIds', this.state.siteIds);
                // data.append('status', this.state.statusId);
                data.append('serviceRequestCode', this.state.enterReqCode);
                // this.getRemainingData(data);
                data_response.push(res.data.data);
            }
        }

        console.log(data_response);

        this.setState({
            loading: false,
            tabaleLoading: false,
            allDataList: data_response,
        });
    }

    //  get remaing data 
    getRemainingData = async (data) => {
        var res = await getPGDataView(data);
        if (res.success) {
            this.setState({
                loading: false,
                tabaleLoading: false,
                allDataList: res.data.content.reverse(),
                recodesPerPage2: res.data?.totalPages
            })
        }
    }



    clearBtnClick = () => {
        window.location.reload()
    }

    buttonClickFunction = (item) => {
        console.log(item);
    }

    searchRegionmChange = (event) => {
        var dd = event
        var subRegion = [];
        var _depotList1 = [];
        var subRegionIdArray = [];
        this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)).map((subR) => {
            subRegionIdArray.push(subR.id)
        })
        this.setState({
            regionIds: subRegionIdArray
        }, () => { this.getDataSitesWithFilter() });
        subRegion = this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id));
        this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)).map((depot) => {
            _depotList1.push({ value: depot.id, label: depot.name })
        })
        this.setState({
            depotList: _depotList1
        })
    }

    searchDepottemChange = (event) => {
        this.setState({
            depotIds: event
        }, () => this.getDataSitesWithFilter());
    }

    getDataSitesWithFilter = async () => {
        this.setState({
            allSite_loading: true,
        })
        var _regionList = [];
        var _depotList = [];
        var _allSite = [];
        if (this.state.regionIds !== '') {
            this.state.regionIds?.map((x, y) => {
                _regionList.push({ "id": x })
            }, () => {

            })
        }
        if (this.state.depotIds !== '') {
            this.state.depotIds?.map((a, b) => {
                _depotList.push({ "id": a })
            }, () => {

            })
        }


        var data = new FormData();
        // data.append('regions','[{"id":6},{"id":7}]');
        data.append('regions', JSON.stringify(_regionList));
        data.append('depots', JSON.stringify(_depotList));
        var res = await getSitesWithFilter(data);
        if (res.success) {
            res.data.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,
            })
        }
    }

    checkFilterDisplay = () => {
        if (this.state.startDate != "" && this.state.endDate != "") {
            this.setState({
                filterVisibility: true
            })
        } else {
            this.setState({
                filterVisibility: false,
                regionIds: '',
                depotIds: '',
                siteIds: '',
                statusId: '',
            })
        }
    }

    onChangeDate = (date, dateString) => {
        this.setState({
            startDate: dateString,
        }, () => {
            this.checkFilterDisplay();
        });
    };

    onChangeDate2 = (date, dateString) => {
        this.setState({
            endDate: dateString,
        }, () => {
            this.checkFilterDisplay();
        });
    };

    searchStatusChange = (event) => {
        this.setState({
            statusId: event
        });
    }

    searchSiteemChange = (event) => {
        this.setState({
            siteIds: event
        });
    }

    filterBtnClick = async () => {
        this.getPisDataList();
    }

    getPisDataListByRequestCode = async (code, url) => {
        this.setState({
            loading: true
        })
        var data = new FormData();
        data.append("serviceRequestIds", code);
        var res = await getPisHistoryData(url, data);
        console.log('getPisDataListByRequestCode')
        console.log(res)
        if (res.success) {
            this.setState({
                loading: false,
                tabaleLoading: false,
                allDataList: res.data.content.reverse(),
                recodesPerPage2: res.data?.totalPages
            })
        }



    }
    serviceTypesChange = (e, serviceTypes) => {
        this.setState({
            selectedServiceTypeId: e,
            selectedDataUrl: serviceTypes[e - 1].data_url
        })
        console.log(serviceTypes[e - 1].data_url);
        console.log(e);
    }

    searchFormReqCode = (event) => {
        this.setState({
            enterReqCode: event.target.value
        })


    }



    render() {
        const { Option } = Select;

        const columns = [
            {
                title: "Request Code",
                dataIndex: "request_code",
                key: "request_code",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Region Name",
                dataIndex: "region_name",
                key: "region_name",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Request Date",
                dataIndex: "request_date",
                key: "request_date",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Site Id",
                dataIndex: "site_id",
                key: "site_id",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Depot Name",
                dataIndex: "depot_name",
                key: "depot_name",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Site Name",
                dataIndex: "site_name",
                key: "site_name",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Action",
                dataIndex: "id",
                key: "id",
                render: (item) => <Link to={`/pg/details/${item}`}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '25px', height: '25px', borderRadius: '50%', backgroundColor: '#f0f0f0' }}>
                        <EyeOutlined style={{ fontSize: '15px', color: '#1890ff' }} />
                    </div>
                </Link>,
            }
        ];

        return (

            <div class="container-fluid" >
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <span className="textStyles-small" style={{ fontSize: '14px', fontWeight: 'bold' }}>Data View</span>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a className='textStyles-small'>PG</a></li>
                                        <li className="breadcrumb-item"><a className='textStyles-small'>Data View</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="card" style={{ padding: 10, borderRadius: 10 }}>
                        <Row style={{ width: '100%' }}>
                            <Col span={6}>
                                <DatePicker
                                    className='borderedSelect'
                                    placeholder="Start Date *"
                                    style={{ width: "98%", height: '34px', marginTop: 10 }}
                                    onChange={this.onChangeDate}
                                />
                            </Col>

                            <Col span={6}>
                                <DatePicker
                                    className='borderedSelect'
                                    placeholder="End Date *"
                                    style={{ width: "98%", height: '34px', marginTop: 10 }}
                                    onChange={this.onChangeDate2}
                                />
                            </Col>

                            {this.state.filterVisibility ? (<>
                                <Col span={6}>
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        name="regionId"
                                        onChange={(e) => this.searchRegionmChange(e)}
                                        placeholder="Region"
                                        style={{ width: "98%", height: 'auto', marginTop: 10 }}
                                        className='borderedSelect'
                                        bordered={false}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {this.state.regionsList?.map((item, index) => {
                                            return <Option value={item.value}>{item.label}</Option>;
                                        })}
                                    </Select>
                                </Col>

                                <Col span={6}>
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        name="depotId"
                                        onChange={(e) => this.searchDepottemChange(e)}
                                        placeholder="Depot"
                                        style={{ width: "98%", height: 'auto', marginTop: 10 }}
                                        className='borderedSelect'
                                        bordered={false}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {this.state.depotList?.map((item, index) => {
                                            return <Option value={item.value}>{item.label}</Option>;
                                        })}
                                    </Select>
                                </Col>

                                <Col span={6}>
                                    <Select
                                        mode="multiple"
                                        placeholder="Site"
                                        style={{ width: "98%", height: 'auto', marginTop: 10 }}
                                        className='borderedSelect'
                                        bordered={false}
                                        loading={this.state.allSite_loading}
                                        name="siteId"
                                        onChange={(e) => this?.searchSiteemChange(e)}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase())
                                        }
                                    >
                                        {this.state.allSite.map((item, index) => {
                                            return <Option value={item.value}>{item.label}</Option>;
                                        })}
                                    </Select>
                                </Col>

                                <Col span={6}>
                                    <Select
                                        mode="multiple"
                                        placeholder="Status"
                                        style={{ width: "98%", height: 'auto', marginTop: 10 }}
                                        className='borderedSelect'
                                        bordered={false}
                                        name="statusId"
                                        onChange={(e) => this.searchStatusChange(e)}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {this.state.statusList.map((item, index) => {
                                            return <Option value={item.value}>{item.label}</Option>;
                                        })}
                                    </Select>
                                </Col>
                            </>) : ('')}

                            <Col span={6}>
                                <Input
                                    className='borderedSelect'
                                    style={{ width: "98%", height: '34px', marginTop: 10 }}
                                    placeholder="Request Code"
                                    onChange={(e) => this.searchFormReqCode(e)} />
                            </Col>
                            <Col span={6}>
                                <Button type="button" class="btn btn-primary" onClick={() => this.filterBtnClick()}
                                    style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', width: '120px', height: '34px', marginTop: 10 }}>
                                    <i class="fas fa-search" style={{color: 'white', marginRight: 3}}/> <span className='textStyles-small' style={{color: 'white', marginRight: 3}}>Search</span>
                                </Button>
                            </Col>
                        </Row>
                        <Table
                            style={{ marginTop: 20 }}
                            size='small'
                            className='table-striped-rows textStyles-small'
                            columns={columns}
                            pagination={true}
                            loading={this.state.loading}
                            dataSource={this.state.allDataList[0]}
                        />
                    </div>
                </div>
            </div>

        )
    }

}
function mapStateToProps(state) {
    return {
        depots: state.depots,
        regions: state.regions
    };
}
export default withRouter(connect(mapStateToProps, {})(PgHistoryView));


