import React, { Component, Fragment } from 'react'
import { Table, Input, InputNumber, Tag, Space, Button, Row, Col, Drawer, Select, Spin, Switch, message, Modal } from "antd";
import { updateSbPower, getAllDropdowns } from "../../api/index";
import checkPermissions from '../../utils/permissionManager';
const { Option } = Select;

class SharedBackupPower extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchStatus: true,

            objectSet: {
                "sbpSharedFrom": "",
                "sbpNoOfPhases": "",
                "sbpCapacityPerPhase": 0,
                "sbpBackupPowerOperatingStatus": false,
            },
            dropDownObjectSet: {
                "sbpSharedFrom": [],
                "sbpNoOfPhases": [],
                "sbpCapacityPerPhase": [],
                "sbpBackupPowerOperatingStatus": []
            }
        }
        this.handlesbpSharedFrom = this.handlesbpSharedFrom.bind(this);
        this.handlsbpCapacityPerPhase = this.handlsbpCapacityPerPhase.bind(this);
        this.handlsbpNoOfPhases = this.handlsbpNoOfPhases.bind(this);
    }

    getSharedBackupPowerDropDownData = async () => {
        var res = await getAllDropdowns();
        if (res.success) {
            this.setState({
                dropDownObjectSet: {
                    "sbpSharedFrom": res.data["Shared Backup Power"]["Shared From"],
                    "sbpNoOfPhases": res.data["Shared Backup Power"]["No of Phases"],
                    "sbpCapacityPerPhase": res.data["Shared Backup Power"]["Capacity per phase (A)"],
                    "sbpBackupPowerOperatingStatus": res.data["Shared Backup Power"]["Backup Power Operating status"],
                }
            })
        } else {
            message.warning('Issue With Dropdown Data Fetching! : ' + res.data.message);
        }
    }

    handlesbpSharedFrom(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                sbpSharedFrom: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }

    handlsbpCapacityPerPhase(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                sbpCapacityPerPhase: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handlsbpNoOfPhases(e) {
        console.log(e)


        this.setState({
            objectSet: {
                ...this.state.objectSet,
                sbpNoOfPhases: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    switchTo() {
        this.state.objectSet['sbpBackupPowerOperatingStatus'] = !this.state.objectSet['sbpBackupPowerOperatingStatus'];
    }


    componentDidMount() {
        console.log(this.props.details)
        this.setState({
            objectSet: {
                "sbpSharedFrom": this.props.details.access_technologies_data?.sbpSharedFrom,
                "sbpNoOfPhases": this.props.details.access_technologies_data?.sbpNoOfPhases,
                "sbpCapacityPerPhase": this.props.details.access_technologies_data?.sbpCapacityPerPhase,
                "sbpBackupPowerOperatingStatus": this.props.details.access_technologies_data?.sbpBackupPowerOperatingStatus,
            }
        })
        this.getSharedBackupPowerDropDownData();
    }


    showUpdateConfirm = () => {
        const { confirm } = Modal;
        confirm({
            title: 'Are You Want To Update These Data, Related To Shared Backup Power?',
            onOk: async () => {
                this.dataSetToUpdate();
            },
            onCancel() { },
        });
    };

    dataSetToUpdate = async () => {
        var res = await updateSbPower(this.props.details2.site_details.id, this.state.objectSet);
        if (res.success) {
            message.success('Successfully Updated!');
            this.props.parentMethod();
            this.setState({
                switchStatus: true
            })
        } else {
            message.warning(' Something went wrong!')
        }
    }

    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "sbpSharedFrom": this.props.details.access_technologies_data?.sbpSharedFrom,
                "sbpNoOfPhases": this.props.details.access_technologies_data?.sbpNoOfPhases,
                "sbpCapacityPerPhase": this.props.details.access_technologies_data?.sbpCapacityPerPhase,
                "sbpBackupPowerOperatingStatus": this.props.details.access_technologies_data?.sbpBackupPowerOperatingStatus,
            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        const { details } = this.props.details
        return (
            <Fragment>
                {this.props.details.access_technologies_data != null ? (
                    <Fragment>
                        <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
                            <div className="d-flex justify-content-end align-items-center" style={{ marginBottom: "10px", marginRight: "10px" }}>
                                {this.state.switchStatus ? (
                                    checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                        <button
                                            type="button"
                                            className="btn btn-soft-primary btn-icon-square-sm"
                                            onClick={() => this.dataUpdate()}
                                            style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                        >
                                            <i className="mdi mdi-pencil-outline" style={{ color: 'white' }}></i>
                                        </button>
                                    ) : null
                                ) : (
                                    <div className="d-flex justify-content-end align-items-center">
                                        <button
                                            type="button"
                                            className="btn btn-soft-secondary"
                                            onClick={() => this.close()}
                                            style={{ marginRight: "10px" }}
                                        >
                                            <span className="textStyles-small">Cancel</span>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-soft-primary"
                                            onClick={() => this.showUpdateConfirm()}
                                        >
                                            <span className="textStyles-small">Submit to Update</span>
                                        </button>
                                    </div>
                                )}
                            </div>
                            <ul class="list-group">
                                <Row>
                                    <Col span={12}>
                                        <div style={{ width: "99%" }}>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <span className="textStyles-small">Shared From</span>
                                                </div>
                                                <div>
                                                    {this.state.switchStatus.toString() == "true" ? (
                                                        this.props.details.access_technologies_data?.sbpSharedFrom != null ||
                                                            this.props.details.access_technologies_data?.sbpSharedFrom != undefined ? (
                                                            <span className="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.sbpSharedFrom}</span>
                                                        ) : (
                                                            <span className="badge badge-outline-danger textStyles-small">Not Defined Yet</span>
                                                        )
                                                    ) : (
                                                        <Select
                                                            className='borderedSelect'
                                                            bordered={false}
                                                            defaultValue={this.props.details.access_technologies_data?.sbpSharedFrom}
                                                            style={{ width: 120 }}
                                                            onChange={(e) => {
                                                                this.state.objectSet.sbpSharedFrom = e;
                                                            }}>
                                                            {this.state.dropDownObjectSet.sbpSharedFrom.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                </div>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#FAFAFA' }}>
                                                <div>
                                                    <span className="textStyles-small">No of Phases</span>
                                                </div>
                                                <div>
                                                    {this.state.switchStatus.toString() == "true" ? (
                                                        this.props.details.access_technologies_data?.sbpNoOfPhases != null ||
                                                            this.props.details.access_technologies_data?.sbpNoOfPhases != undefined ? (
                                                            <span className="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.sbpNoOfPhases}</span>
                                                        ) : (
                                                            <span className="badge badge-outline-danger textStyles-small">Not Defined Yet</span>
                                                        )
                                                    ) : (
                                                        <Select
                                                            className='borderedSelect'
                                                            bordered={false}
                                                            defaultValue={this.props.details.access_technologies_data?.sbpNoOfPhases}
                                                            style={{ width: 120 }}
                                                            onChange={(e) => {
                                                                this.state.objectSet.sbpNoOfPhases = e;
                                                            }}>
                                                            {this.state.dropDownObjectSet.sbpNoOfPhases.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                </div>
                                            </li>
                                        </div>
                                    </Col>

                                    <Col span={12}>
                                        <div style={{ width: "99%" }}>
                                            <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#FAFAFA' }}>
                                                <div>
                                                    <span className="textStyles-small">Capacity per phase (A)</span>
                                                </div>
                                                <div>
                                                    {this.state.switchStatus.toString() == "true" ? (
                                                        this.props.details.access_technologies_data?.sbpCapacityPerPhase != null ||
                                                            this.props.details.access_technologies_data?.sbpCapacityPerPhase != undefined ? (
                                                            <span className="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.sbpCapacityPerPhase}</span>
                                                        ) : (
                                                            <span className="badge badge-outline-danger textStyles-small">Not Defined Yet</span>
                                                        )
                                                    ) : (
                                                        <Select
                                                            className='borderedSelect'
                                                            bordered={false}
                                                            defaultValue={this.props.details.access_technologies_data?.sbpCapacityPerPhase}
                                                            style={{ width: 120 }}
                                                            onChange={(e) => {
                                                                this.state.objectSet.sbpCapacityPerPhase = e;
                                                            }}>
                                                            {this.state.dropDownObjectSet.sbpCapacityPerPhase.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                </div>
                                            </li>

                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <span className="textStyles-small">Backup Power Operating status</span>
                                                </div>
                                                <div>
                                                    {this.state.switchStatus.toString() == "true" ? (
                                                        this.props.details.access_technologies_data?.sbpBackupPowerOperatingStatus != null ||
                                                            this.props.details.access_technologies_data?.sbpBackupPowerOperatingStatus != undefined ? (
                                                            <span className="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.sbpBackupPowerOperatingStatus.toString()}</span>
                                                        ) : (
                                                            <span className="badge badge-outline-danger textStyles-small">Not Defined Yet</span>
                                                        )
                                                    ) : (
                                                        <Select
                                                            className='borderedSelect'
                                                            bordered={false}
                                                            defaultValue={this.props.details.access_technologies_data?.sbpBackupPowerOperatingStatus}
                                                            style={{ width: 120 }}
                                                            onChange={(e) => {
                                                                this.state.objectSet.sbpBackupPowerOperatingStatus = e;
                                                            }}>
                                                            {this.state.dropDownObjectSet.sbpBackupPowerOperatingStatus.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                </div>
                                            </li>
                                        </div>
                                    </Col>
                                </Row>
                            </ul>
                        </div>
                    </Fragment>
                ) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 style={{ color: "#c5c5c5" }}>No Dat Found Shared Backup Power</h4></div>)}
            </Fragment>
        )
    }
}
export default SharedBackupPower;
