import React, { Component, Fragment } from 'react'
import { InputNumber, message, Input, DatePicker, Select, Row, Col } from 'antd';
import checkPermissions from '../../utils/permissionManager';
import {updateEarthing} from "../../api/index";
import moment from 'moment';
const { Option } = Select;

class Earthing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchStatus: true,
            objectSet: {
                "earERvalue": 0,
                "earSRValueMeasureDate": "",
                "earSR1m": 0,
                "earSR3m": 0,
                "earAverageSRValue": 0,
                "earERImprovementStatus": "",
                "latestERMeasuredDate": ""
            }
        }
        this.handlearERvalue = this.handlearERvalue.bind(this);
        this.handlearSRValueMeasureDate = this.handlearSRValueMeasureDate.bind(this);
        this.handlearSR1m = this.handlearSR1m.bind(this);
        this.handlearSR3m = this.handlearSR3m.bind(this);
        this.handlearAverageSRValue = this.handlearAverageSRValue.bind(this);
        this.handlearERImprovementStatus = this.handlearERImprovementStatus.bind(this);
        this.handlelatestERMeasuredDate = this.handlelatestERMeasuredDate.bind(this);        
    }

    handlearERvalue(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                earERvalue: e
            }
        })
    }

    handlearSRValueMeasureDate(date, dateString) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                earSRValueMeasureDate: dateString
            }
        })
    }

    handlearSR1m(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                earSR1m: e
            }
        })
    }

    handlearSR3m(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                earSR3m: e
            }

        })
    }
    handlearAverageSRValue(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                earAverageSRValue: e
            }

        })
    }
    handlearERImprovementStatus(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                earERImprovementStatus: e.target.value
            }

        })
    }
    handlelatestERMeasuredDate(date, dateString) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                latestERMeasuredDate: dateString
            }

        })
    }
    componentDidMount() {
        console.log(this.props.details)
        console.log('powerCon')
        this.setState({
            objectSet: {
                "earERvalue": this.props.details.access_technologies_data?.earERvalue,
                "earSRValueMeasureDate": this.props.details.access_technologies_data?.earSRValueMeasureDate,
                "earSR1m": this.props.details.access_technologies_data?.earSR1m,
                "earSR3m": this.props.details.access_technologies_data?.earSR3m,
                "earAverageSRValue": this.props.details.access_technologies_data?.earAverageSRValue,
                "earERImprovementStatus": this.props.details.access_technologies_data?.earERImprovementStatus,
                "latestERMeasuredDate": this.props.details.access_technologies_data?.latestERMeasuredDate
            }

        }, () => {
            console.log("opopop")
            console.log(this.state.objectSet)
        })
    }
    dataSetToUpdate = async () => {

        var res = await updateEarthing(this.props.details2.site_details.id, this.state.objectSet);

        if(res.success){
           message.success('Successfully Update!')
           this.props.parentMethod();
           this.setState({
             switchStatus: true
         })
        }else{
         message.warning(' Something went wrong!')
        }

    }
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () =>{
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "earERvalue": this.props.details.access_technologies_data?.earERvalue,
                "earSRValueMeasureDate": this.props.details.access_technologies_data?.earSRValueMeasureDate,
                "earSR1m": this.props.details.access_technologies_data?.earSR1m,
                "earSR3m": this.props.details.access_technologies_data?.earSR3m,
                "earAverageSRValue": this.props.details.access_technologies_data?.earAverageSRValue,
                "earERImprovementStatus": this.props.details.access_technologies_data?.earERImprovementStatus,
                "latestERMeasuredDate": this.props.details.access_technologies_data?.latestERMeasuredDate
            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        return (
            <Fragment>
                {this.props?.details?.access_technologies_data != null ? (
                    <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
                        <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                            {this.state.switchStatus ? (
                                checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                    <div className="d-flex justify-content-end align-items-center">
                                        <button
                                            type="button"
                                            className="btn btn-soft-primary btn-icon-square-sm"
                                            onClick={() => this.dataUpdate()}
                                            style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                        >
                                            <i className="mdi mdi-pencil-outline" style={{ color: 'white' }}></i>
                                        </button>
                                    </div>) : null
                            ) : (
                                <div className="d-flex justify-content-end align-items-center">
                                    <button
                                        type="button"
                                        className="btn btn-soft-secondary"
                                        onClick={() => this.close()}
                                        style={{ marginRight: "10px" }}
                                    >
                                        <span className="textStyles-small">Cancel</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-soft-primary"
                                        onClick={() => this.dataSetToUpdate()}
                                    >
                                        <span className="textStyles-small">Submit to Update</span>
                                    </button>
                                </div>
                            )}
                        </div>
                        <ul class="list-group">
                            <Row>
                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className='textStyles-small'>Latest  ER Value (Ohms)</span>
                                            </div>
                                            {this.state.switchStatus ? this.props.details.access_technologies_data?.earERvalue ?
                                                (<span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.earERvalue}</span>) :
                                                (<span class="badge badge-outline-warning textStyles-small">{this.props.details.access_technologies_data.earERvalue}</span>) :
                                                <InputNumber className='borderedSelect' style={{ width: '150px' }} value={this.state.objectSet?.earERvalue} id="mpcNoOfPhases" onChange={this.handlearERvalue} />}
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                            <div>
                                                <span className='textStyles-small'>Latest ER Measured Date</span>
                                            </div>
                                            {this.state.switchStatus ? this.props.details.access_technologies_data?.latestERMeasuredDate != null ?
                                                (<span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.latestERMeasuredDate}</span>) :
                                                (<span class="badge badge-outline-warning textStyles-small">Currently Not Set</span>) :
                                                <DatePicker className='borderedSelect' style={{ width: '150px', height: '33px' }} defaultValue={(this.state.objectSet?.latestERMeasuredDate != '' && this.state.objectSet?.latestERMeasuredDate != null) ? (moment(this.state.objectSet?.latestERMeasuredDate)) : ("")} id="mpcNoOfPhases" onChange={this.handlelatestERMeasuredDate} />}
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className='textStyles-small'>SR Value Measure Date</span>
                                            </div>
                                            {this.state.switchStatus ? this.props.details.access_technologies_data?.earSRValueMeasureDate != null ?
                                                (<span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.earSRValueMeasureDate}</span>) :
                                                (<span class="badge badge-outline-warning textStyles-small">Currently Not Set</span>) :
                                                <DatePicker className='borderedSelect' style={{ width: '150px', height: '33px' }} defaultValue={(this.state.objectSet?.earSRValueMeasureDate != '' && this.state.objectSet?.earSRValueMeasureDate != null) ? (moment(this.state.objectSet?.earSRValueMeasureDate)) : ("")} id="mpcNoOfPhases" onChange={this.handlearSRValueMeasureDate} />}
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                            <div>
                                                <span className='textStyles-small'>SR_1m</span>
                                            </div>
                                            {this.state.switchStatus ? this.props.details.access_technologies_data?.earSR1m ?
                                                (<span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.earSR1m}</span>) :
                                                (<span class="badge badge-outline-warning textStyles-small">{this.props.details.access_technologies_data.earSR1m}</span>) :
                                                <InputNumber className='borderedSelect' style={{ width: '150px' }}  value={this.state.objectSet?.earSR1m} id="mpcNoOfPhases" onChange={this.handlearSR1m} />}
                                        </li>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                            <div>
                                                <span className='textStyles-small'>SR_3m</span>
                                            </div>
                                            {this.state.switchStatus ? this.props.details.access_technologies_data?.earSR3m ?
                                                (<span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.earSR3m}</span>) :
                                                (<span class="badge badge-outline-warning textStyles-small">{this.props.details.access_technologies_data.earSR3m}</span>) :
                                                <InputNumber style={{ width: '150px' }} className='borderedSelect' 
                                                value={this.state.objectSet?.earSR3m} id="mpcNoOfPhases" onChange={this.handlearSR3m} />}
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className='textStyles-small'>Average SR Value</span>
                                            </div>
                                            {this.state.switchStatus ? this.props.details.access_technologies_data?.earAverageSRValue ?
                                                (<span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.earAverageSRValue}</span>) :
                                                (<span class="badge badge-outline-warning textStyles-small">{this.props.details.access_technologies_data.earAverageSRValue}</span>) :
                                                <InputNumber style={{ width: '150px' }}  className='borderedSelect' 
                                                value={this.state.objectSet?.earAverageSRValue} id="mpcNoOfPhases" onChange={this.handlearAverageSRValue} />}
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                            <div>
                                                <span className='textStyles-small'>ER Improvement Status (WIP/Completed)</span>
                                            </div>
                                            <div>
                                                {this.state.switchStatus.toString() == "true" ? (
                                                    this.props.details.access_technologies_data?.earERImprovementStatus ? (
                                                        <span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data?.earERImprovementStatus}</span>
                                                    ) : (
                                                        <span class="badge badge-outline-danger textStyles-small">Currently Not Set</span>
                                                    )
                                                ) : (
                                                    <Select
                                                        defaultValue={this.props.details.access_technologies_data?.earERImprovementStatus}
                                                        style={{ width: '150px' }}
                                                        className='borderedSelect'
                                                        bordered={false}
                                                        onChange={(e) => {
                                                            this.state.objectSet.earERImprovementStatus = e;
                                                        }}>
                                                        {this.props.dropdoenEAR["dropdownObjEarthing"]["earERImprovementStatus"]?.map((x) => {
                                                            return <Option value={x}>{x}</Option>
                                                        })}
                                                    </Select>
                                                )}
                                            </div>
                                        </li>
                                    </div>
                                </Col>
                            </Row>





                        </ul>
                    </div>
                ) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 style={{ color: "#c5c5c5" }}>No Dat Found Earthing</h4></div>)}

            </Fragment>
        )
    }
}
export default Earthing;
