const auth = btoa("OAUTH_DEMO_CLIENT_ID:hello");



//Here list down all development parameters , revamp by Withanage Aruna
var _baseUrl = "https://dns.eimsky.com";
var _serviceOne = "/request";
var _refuelserviceOne = "/refuelService";
var _siteAccessService = "/siteAccessService";
var _serviceUser = "/userService";
var _serviceCommon = "/common";
var _imageCapturingService = "/imageCapturingService";
var _serviceFinance = "/finance";
var _serviceVehicle = "/vehicle";
var _servicePIS = "/pisService";
var _serviceRouting = "/routingService";
var _imageBaseUrl = "https://dns.eimsky.com";
var _azureRedirectUri = "http://localhost:3000/";
var _vehicleRW = "/vehiclerw";
var _hierarchy = "/hierarchy";
var _accessrw = "/accessrw";
var _headers = {
  "Content-Type": "application/json",
  "cache-control": "no-cache,no-store, must-revalidate",
  "logged-user-email": localStorage.getItem("logged_user_email"),
  "userId": localStorage.getItem("userId")
};
var _ruleEngine = "https://ruleengine.eimsky.com/";
var _siteViewNew = "/site";
var _siteViewNewhierarchy = "/hierarchy";
var _version = "2.0.0";

//Here list down all production parameters , revamp by Withanage Aruna
if (true) {
// if (process.env.NODE_ENV == "production") {

  // uat start********************************
  // _headers = {
  //   "Content-Type": "application/json", "cache-control": "no-cache",
  //   "Authorization": "Bearer " + localStorage.getItem("access_token") + "",
  //   "logged-user-email": localStorage.getItem("logged_user_email"),
  //   "userId": localStorage.getItem("userId")
  // };
  // _baseUrl = "https://dnsuatapi.eimsky.com";
  // _serviceOne = "/request";
  // _refuelserviceOne = "/refuelService";
  // _siteAccessService = "/siteAccessService";
  // _serviceUser = "/userService";
  // _serviceCommon = "/common";
  // _imageCapturingService = "/imageCapturingService";

  // _serviceFinance = "/finance";
  // _serviceVehicle = "/vehicle";
  // _servicePIS = "/pisService";
  // _serviceRouting = "/routingService";
  // _imageBaseUrl = "https://dnsuatapi.eimsky.com";
  // _vehicleRW = "/vehiclerw";
  // _hierarchy = "/hierarchy";
  // _azureRedirectUri = "https://dnsuat.eimsky.com/";
  // _ruleEngine = "https://ruleengine.eimsky.com/";
  // _siteViewNew = "https://dnsuatapi.eimsky.com/site/";
  // _siteViewNewhierarchy = "https://dnsuatapi.eimsky.com/hierarchy/";
  // _version = "2.2.0";
  // uat End************************


  // Google VM start*************************
  _headers = {
    "Content-Type": "application/json", "cache-control": "no-cache",
    "Authorization": "Bearer " + localStorage.getItem("access_token") + "",
    "logged-user-email": localStorage.getItem("logged_user_email"),
    "userId": localStorage.getItem("userId")
  };
  _baseUrl = "https://dnsliveapi.eimsky.com";
  _serviceOne = "/request";
  _refuelserviceOne = "/refuelService";
  _siteAccessService = "/siteAccessService";
  _serviceUser = "/userService";
  _serviceCommon = "/common";
  _imageCapturingService = "/imageCapturingService";

  _serviceFinance = "/finance";
  _serviceVehicle = "/vehicle";
  _servicePIS = "/pisService";
  _serviceRouting = "/routingService";
  _imageBaseUrl = "https://dnsliveapi.eimsky.com";
  _vehicleRW = "/vehiclerw";
  _hierarchy = "/hierarchy";
  _accessrw = "/accessrw";
  _siteViewNew = "https://dns.devapi.eimsky.com/site/";
  _azureRedirectUri = "https://dnslive.eimsky.com/";
  _ruleEngine = "https://ruleenginetest.eimsky.com/";
  _version = "2.0.3";
  // Google VM End************


  // dev-env start*************************
  // _headers = {
  //   "Content-Type": "application/json", "cache-control": "no-cache",
  //   "Authorization": "Bearer " + localStorage.getItem("access_token") + "",
  //   "logged-user-email": localStorage.getItem("logged_user_email"),
  //   "userId": localStorage.getItem("userId")
  // };
  // _baseUrl = "https://dns.devapi.eimsky.com";
  // _serviceOne = "/request";
  // _refuelserviceOne = "/refuelService";
  // _siteAccessService = "/siteAccessService";
  // _serviceUser = "/userService";
  // _serviceCommon = "/common";
  // _imageCapturingService = "/imageCapturingService";

  // _serviceFinance = "/finance";
  // _serviceVehicle = "/vehicle";
  // _servicePIS = "/pisService";
  // _serviceRouting = "/routingService";
  // _imageBaseUrl = "https://dns.dev.eimsky.com/";
  // _vehicleRW = "/vehiclerw";
  // _hierarchy = "/hierarchy";
  // _accessrw = "/accessrw";
  // _siteViewNew = "https://dns.devapi.eimsky.com/site/";
  // _siteViewNewhierarchy = "https://dns.devapi.eimsky.com/hierarchy/";
  // _azureRedirectUri = "https://dns.dev.eimsky.com/";
  // _ruleEngine = "https://ruleengine.eimsky.com/";
  // _version = "2.0.8";
  // dev-env End************************

}

//export variables
export const baseUrl = _baseUrl;
export const serviceOne = _serviceOne;
export const refuelserviceOne = _refuelserviceOne;
export const siteAccessService = _siteAccessService;
export const serviceUser = _serviceUser;
export const serviceCommon = _serviceCommon;
export const imageCapturingService = _imageCapturingService;
export const serviceFinance = _serviceFinance;
export const serviceVehicle = _serviceVehicle;
export const serviceRouting = _serviceRouting;
export const imageBaseUrl = _imageBaseUrl;
export const headers = _headers;
export const azureRedirectUri = _azureRedirectUri;
export const pisService = _servicePIS
/////exceptional-dshboard
export const BASE_URL = "http://40.71.221.154/";
export const DEV_GET_INCOMPLETE_SCHEDULES = "fms/index.php/dashboard/GetIncompleteSchedulesForDashboard";
export const GET_INCOMPLETE_SCHEDULES = "fms/index.php/dashboard/GetIncompleteSchedulesForDashboard";

// export new rewamp api list
export const vehicleRewamp = _vehicleRW;
export const hierarchy = _hierarchy;
export const accessrw = _accessrw;
export const ruleEngine = _ruleEngine;
export const siteViewNew = _siteViewNew;
export const webVersion = _version;
export const siteViewNewhierarchy = _siteViewNewhierarchy;