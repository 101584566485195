import React, { Component } from 'react'
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, message, Popconfirm, Collapse, Row, Col, Divider, Card } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, HomeOutlined, EditOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import checkPermissions from '../../utils/permissionManager';
import { createNewATs, getAtsDetails, UpdateAts, getRefData } from "../../api/index";
import AssetDelete from "../site_components/asset_delete";
import { checkServiceType } from "../../actions/service-type";



class Ats extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            showModal: false,
            open: false,
            openEdit: false,
            title: "",
            tabaleLoading: false,
            actionView: false,
            selectedAssetId: null,
            AssetType: [],
            selectedMakeId: null,
            makeType: [],
            selectedModelId: null,
            modelType: [],
            atsType: '',
            atsLowVoltageFunctionality: null,
            atsCapacity: '',
            AssetManagementPermission: false,
            openViewMoreDetails: false,
            selectedElement: "",
            atsMake: '',
            refData: [],

            dropdownATS : {
                "atsLowVoltageFunctionality": "",
            }
        };
        this.requestFromClose = this.requestFromClose.bind(this);
        this.handleAtsType = this.handleAtsType.bind(this);
        this.handleMake = this.handleMake.bind(this);
    }
    handleAtsType(e) {
        console.log(e)
        this.setState({ atsCapacity: e });
    }

    handleMake(e) {
        console.log(e)
        this.setState({ atsMake: e });
    }
    
    componentDidMount() {
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;
            history.push("/");
        } else {
            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 38)
        }
        checkServiceType(38).then((data) => this.setState({
            AssetManagementPermission: data
        }, () => {
        }),
        )
    }


    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();

        this.validator.hideMessages();
        this.setState({
            open: false,
            openEdit: false,
            selectedAssetId: null,
            AssetType: [],
            selectedMakeId: null,
            makeType: [],
            selectedModelId: null,
            modelType: [],
            atsType: '',
            atsLowVoltageFunctionality: null,
            atsCapacity: '',
            atsMake: ''

        })

    }
    requestFromClose() {
        console.log('dsdsd')
        this.setState({ showModal: true, open: true });
    }
    getSetPageData = (serviceTypeIs, Pageid) => {

        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] != null) {
            console.log('fddf');
            this.setState({
                actionView: true,
            })
        }

    };
    onChangeAsset = (value) => {
        console.log("onChangeAsset");
        //console.log(value);
        this.setState({
            selectedAssetId: value,
            AssetType: {
                "id": value
            },

        })
    };
    onChangeModel = (value) => {
        console.log("onChangeModel");
        //console.log(value);
        this.setState({
            selectedModelId: value,
            modelType: {
                "id": value
            },

        })
    };
    onChangeislowVoltageFunctionality = (value) => {
        //console.log("onChangeislowVoltageFunctionality");
        console.log(value);
        if (value == 1) {
            this.setState({
                atsLowVoltageFunctionality: parseInt(value),


            })
        } else {
            this.setState({
                atsLowVoltageFunctionality: parseInt(value),


            })
        }

    };
    onChangeMake = (value) => {
        console.log("onChangeMake");
        //console.log(value);
        this.setState({
            selectedMakeId: value,
            makeType: {
                "id": value
            },

        })
    };
    atsUpdata(id) {
        console.log('dfdfd')
        console.log(id)
        this.getDataAtsDetails(id);
        this.setState({ openEdit: true });
    }
    getDataAtsDetailsForDelete = async (id) => {

        var res = await getAtsDetails(id);
        console.log(res);
        if (res.success) {
            var respons = AssetDelete.Action(res.data, this.props?.detailsSite?.site_details.id, UpdateAts)



            respons.then((value) => {
                if (value == 1) {
                    message.success('Successfully Delete!')
                    this.props.parentMethod();
                } else {
                    message.warning(' Something went wrong!')
                }


            });



        }
    }
    getDataAtsDetails = async (id) => {
        var res = await getAtsDetails(id);
        console.log('serfrtrtrtrtrtrtr');
        console.log(res);
        if (res.success) {
            this.setState({
                atsEdit: res.data,
                atsId: res.data.id,
                atsCapacity: res.data.capacity,
                //  atsLowVoltageFunctionality:res.data.atsLowVoltageFunctionality,
                // selectedMakeId: res.data.make.id,
                makeType: res.data.make,
                selectedModelId: res.data?.model?.id,
                modelType: res.data.model,
                atsMake: res.data.make
            })
            if (res.data?.lowVoltageFunctionality == false) {
                console.log('000')
                this.setState({
                    atsLowVoltageFunctionality: 0
                })

            } else {
                console.log('111')

                this.setState({
                    atsLowVoltageFunctionality: 1
                })
            }

        }

    }
    AllDataSubmitUpdate = async () => {
        if (this.validator.allValid()) {
            var dataSet;
            dataSet = {
                "id": this.state.atsId,
                "site": { "id": this.props?.detailsSite?.site_details.id },
                "asset": {
                    "id": 2
                },
                "make": this.state.atsMake,
                "model": this.state.modelType,
                "capacity": this.state.atsCapacity,
                "lowVoltageFunctionality": this.state.atsLowVoltageFunctionality,



            }
            console.log(dataSet)
            var res = await UpdateAts(dataSet);
            console.log(res)
            if (res.success) {
                message.success('Successfully ATS Edited!');
                this.formRef.current.resetFields();

                this.setState({
                    showModal: false,
                    open: false,
                    openEdit: false,

                    selectedAssetId: null,
                    AssetType: [],
                    selectedMakeId: null,
                    makeType: [],
                    selectedModelId: null,
                    modelType: [],
                    atsType: '',
                    atsLowVoltageFunctionality: null,
                    atsCapacity: '',
                    atsMake: ''

                })
                this.props.parentMethod();

            } else {
                message.warning(' Something went wrong!');
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    AllDataSubmit = async () => {
        if (this.validator.allValid()) {
            var dataSet;
            dataSet = {

                "site": { "id": this.props?.detailsSite?.site_details.id },
                "asset": {
                    "id": 2
                },
                "make": this.state.atsMake,
                "model": this.state.modelType,
                "capacity": this.state.atsCapacity,
                "lowVoltageFunctionality": this.state.atsLowVoltageFunctionality,



            }
            console.log(dataSet)
            var res = await createNewATs(dataSet);
            if (res.success) {
                message.success('Successfully ATS Added!');
                this.formRef.current.resetFields();

                this.setState({
                    showModal: false,
                    open: false,
                    selectedAssetId: null,
                    AssetType: [],
                    selectedMakeId: null,
                    makeType: [],
                    selectedModelId: null,
                    modelType: [],
                    atsType: '',
                    atsLowVoltageFunctionality: null,
                    atsCapacity: '',
                    atsMake: ''
                })
                this.props.parentMethod();
                // const { history } = this.props;
                // history.push("/site");
            } else {
                message.warning(' Something went wrong!');
            }

        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    submitForm = async (item) => {
        this.getDataAtsDetailsForDelete(item);

    }

    viewMoreDetails(element) {
        this.setState({
            showModal2: true,
            openViewMoreDetails: true,
            selectedElement: element
        });
    }

    onViewMoreDetailsDrawerClose = () => {
        this.setState({
            showModal2: false,
            openViewMoreDetails: false,
        })
    }

    validatedTextLable = (field) => {
        return (field !== null && field !== '')
            ? <span class="badge badge-outline-secondary text-muted">{(field)}</span>
            : <span class="badge text-danger">Currently Not Set</span>;
    }

    render() {
        const { Option } = Select;
        const { Panel } = Collapse;
        const _ats = this.state.selectedElement
        const columns2 = [
            {
                title: "Make",
                details: [],
                content: _ats !== ""
                    ? <Tag color="gray" style={{ margin: "0px" }}><span>{_ats.make}</span></Tag> : <></>,
            },
            {
                title: "Model",
                content: _ats !== "" ? <span class="badge badge-outline-info badge-pill"><i class="fas fa-hdd" style={{ paddingRight: "5px" }} />{_ats.model?.name}</span> : <></>,
                details: [
                    {
                        title: "Capacity (A)",
                        content: this.validatedTextLable(_ats.model?.capacity),
                    },
                    {
                        title: "Low Voltage Functionality",
                        content: _ats.model?.lowVoltageFunctionality
                            ? <span class="badge bg-success">true</span>
                            : <span class="badge bg-danger">false</span>,
                    },
                ],
            },

        ];

        return (
            <>

                <div className='row'>
                    <div class="col-lg-12">
                        <div class="text-end">
                            <ul class="list-inline">
                                <li class="list-inline-item">
                                {checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                        <Button type="primary"
                                        style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px' }}
                                        onClick={this.requestFromClose} 
                                        icon={<PlusCircleOutlined />}>
                                            <span className="textStyles-small">New Asset</span>
                                        </Button>) : ('')
                                }
                                </li>
                            </ul>
                        </div>
                    </div>

                    <Row span={24}>
                        {this.props.details2.ats_data.map((element, index) => (
                            <Col span={8} key={index}>
                            <Card
                                bordered={false}
                                style={{
                                    width: '98%',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                    position: 'relative'
                                }}
                            >
                                <div style={{ display: 'flex', marginBottom: 16, backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '10px' }}>
                                    <div>
                                        <HomeOutlined style={{ marginLeft: '3px', marginRight: '3px' }} /> &rarr;
                                        <span style={{ marginLeft: '3px', marginRight: '3px', fontWeight: 'bold' }} className="textStyles-small">
                                        {this.props?.detailsSite?.site_details.siteName}
                                        </span>
                                        &rarr;
                                        <span style={{ marginLeft: '3px', color: '#BA183A', fontWeight: 'bold' }} className="textStyles-small">{element.make}</span>
                                    </div>
                                </div>
                                <div style={{ marginBottom: 8 }}>
                                    <span className="textStyles-small" style={{ fontSize: 12 }}>ATS Make</span>
                                    <Tag color="default" style={{ float: 'right', borderRadius: 5 }}> <span className="textStyles-small">{element.make}</span></Tag>
                                </div>
                                <div style={{ marginBottom: 8 }}>
                                    <span className="textStyles-small">Model</span>
                                    <Tag color="magenta" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                                        <span className="textStyles-small" style={{ fontSize: 11 }}>
                                        {element.model?.name}
                                        </span></Tag>
                                </div>
                                <div style={{ marginBottom: 8 }}>
                                    <span className="textStyles-small">Capacity</span>
                                    <Tag color="magenta" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                                        <span className="textStyles-small" style={{ fontSize: 11 }}>
                                        {element.model?.capacity != null ? element.model?.capacity : "Currently Not Set"} A
                                        </span></Tag>
                                </div>
                                <div style={{ marginBottom: 8 }}>
                                    <span className="textStyles-small">Low Voltage Functionality</span>
                                    {
                                         element.model?.lowVoltageFunctionality ? 
                                         <Tag color="green" style={{ float: 'right', borderRadius: 5, fontSize: 10 }}><span className="textStyles-small" style={{ fontSize: 11 }}>True</span></Tag> : 
                                         <Tag color="red" style={{ float: 'right', borderRadius: 5, fontSize: 10 }}><span className="textStyles-small" style={{ fontSize: 11 }}>False</span></Tag>
                                    }
                                </div>
                                <Divider style={{ marginTop: '3px' }} />
                                <Button
                                    type="primary"
                                    style={{ marginRight: 8, borderRadius: '5px' }}
                                    size='small'
                                    onClick={() => this.viewMoreDetails(element)}>
                                    <span className="textStyles-small" style={{ fontSize: 10 }}> <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details</span>
                                </Button>
                                {checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                <Button
                                    icon={<EditOutlined style={{ color: 'white' }} />}
                                    style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                    onClick={() => this.atsUpdata(element.id)}
                                    size='small'
                                /> ) : ('')}
                                <Popconfirm
                                    title={<span className='textStyles-small' style={{ fontSize: 14 }}>Are you sure to delete this Asset?</span>}
                                    description={<span className="textStyles-small">Are you sure to delete this Asset?</span>}
                                    onConfirm={() => this.submitForm(element.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    {checkPermissions('SITE_VIEW_ADMIN_USER') 
                                        ? (
                                            <Button
                                                icon={<DeleteOutlined style={{ color: 'white' }} />}
                                                danger
                                                size='small'
                                                style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#BA183A', borderColor: '#BA183A' }}
                                            />
                                        )
                                        : ('')}
                                </Popconfirm>

                            </Card>
                        </Col>
                        ))}
                    </Row>

                    <Drawer maskClosable={false}
                        title={<span className="textStyles-small" style={{ fontSize: "14px" }}>ATS Details</span>}
                        width={"50%"} placement="right"
                        onClose={() => this.onViewMoreDetailsDrawerClose()}
                        visible={this.state.openViewMoreDetails}
                        closeIcon={<button type="button"
                            class="btn btn-soft-danger btn-icon-square-sm"
                            style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <Collapse
                            style={{ alignContent: "center", borderColor: "white" }}
                        >
                            {columns2.map((item, index) => (
                                <Panel
                                    style={{
                                        borderColor: index % 2 === 0 ? "#f8f9fa" : "white",
                                        backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white"
                                    }}
                                    header={
                                        <li className="d-flex justify-content-between align-items-center"
                                            style={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white" }}>
                                            <div>
                                                <span className="textStyles-small">{item.title}</span>
                                            </div>
                                            <span className="textStyles-small">{item.content}</span>
                                        </li>
                                    }
                                    key={item.title}
                                    showArrow={item.details.length > 0}
                                    collapsible={item.details.length > 0 ? "" : "icon"}
                                >
                                    {item.details.map((detail) => (
                                        <li
                                            className="d-flex justify-content-between align-items-center me-2"
                                            style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                                            key={detail.title}
                                        >
                                            <div>
                                                <span className='textStyles-small'>{detail.title}</span>
                                            </div>
                                            <span className="textStyles-small">{detail.content}</span>
                                        </li>
                                    ))}
                                </Panel>
                            ))}
                        </Collapse>
                    </Drawer>

                    <Drawer maskClosable={false}
                        title={<span className="textStyles-small" style={{ fontSize: "14px" }}>Add ATS</span>}
                        width={"50%"}
                        placement="right"
                        onClose={() => this.onDrawerClose()} visible={this.state.open}
                        closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card" style={{ borderColor: 'white' }}>

                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        <span className="textStyles-small">
                                                            Make <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item required name="Make" >
                                                        <Select
                                                            showSearch
                                                            onChange={(e) => this.handleMake(e)}
                                                            style={{ width: "100%" }}
                                                            className='borderedSelect'
                                                            bordered={false}
                                                            placeholder="Search to Select"
                                                        >
                                                            {this.props?.allMakes?.allATSMakes?.map((item, index) => {
                                                                return <Option value={item?.name}>{item?.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Make', this.state.atsMake, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        <span className="textStyles-small">
                                                            Model <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item required name="Is_active" tooltip="This is a required field">
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangeModel}
                                                            style={{ width: "100%" }}
                                                            className='borderedSelect'
                                                            bordered={false}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            allowClear

                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.atsMasterData?.atsMasterData?.atsModelList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}

                                                        </Select>
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Model', this.state.selectedModelId, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >
                                                <Button
                                                    className="btn btn-primary"
                                                    onClick={() => this.AllDataSubmit()}
                                                    style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}
                                                >
                                                    <span className="textStyles-small" style={{ color: 'white' }}>Add This Asset</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>

                    <Drawer maskClosable={false} title={"Edit ATS"} width={"50%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.openEdit} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card" style={{ borderColor: 'white' }}>

                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        <span className="textStyles-small">
                                                            Make <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item  >
                                                        {/* <Input id="name" value={this.state.atsMake} onChange={this.handleMake} /> */}
                                                        <Select
                                                            showSearch
                                                            onChange={(e) => this.handleMake(e)}
                                                            className='borderedSelect'
                                                            bordered={false}
                                                            style={{ width: "100%" }}
                                                            value={this.state?.atsMake}
                                                            placeholder="Search to Select"
                                                        >
                                                            {this.props?.allMakes?.allATSMakes?.map((item, index) => {
                                                                return <Option value={item?.name}>{item?.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                            {this.validator.message('Make', this.state.atsMake, 'required')}
                                                        </p>
                                                    </Form.Item>
                                                </div>
                                                {this.state.selectedModelId != null ? (
                                                    <div className="col-md-6">
                                                        <label className="form-label" for="depot">
                                                            <span className="textStyles-small">
                                                                Model <x style={{ color: "red" }}>*</x>
                                                            </span>
                                                        </label>
                                                        <Form.Item >
                                                            <Select
                                                                showSearch
                                                                onChange={this.onChangeModel}
                                                                defaultValue={this.state.selectedModelId}
                                                                style={{ width: "100%" }}
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {this.props.atsMasterData?.atsMasterData?.atsModelList?.map((item, index) => {
                                                                    return <Option value={item?.id}>{item?.name}</Option>;
                                                                })}

                                                            </Select>
                                                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                                                {this.validator.message('Model', this.state.selectedModelId, 'required')}</p>

                                                        </Form.Item>
                                                    </div>
                                                ) : ('')}
                                            </div>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >
                                                <Button
                                                    className="btn btn-primary"
                                                    style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}
                                                    onClick={() => this.AllDataSubmitUpdate()} >
                                                    <span className="textStyles-small" style={{ color: 'white' }}>Edit This Asset</span>
                                                </Button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </div>


            </>
        )
    }
}
export default Ats;
