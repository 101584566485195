import React, { Component } from 'react';
import { InputNumber, Form, Button, Drawer, message, Collapse, Select, Row, Col, Divider, Card, Tag } from "antd";
import { PlusCircleOutlined, HomeOutlined, EditOutlined } from '@ant-design/icons';
import checkPermissions from '../../utils/permissionManager';
import { Createcreatebulcktank, getBulckTankBySiteId, getBulckTankById, updateBulckTank } from "../../api/index";
import SimpleReactValidator from "simple-react-validator";
import { checkServiceType } from "../../actions/service-type";
const { Option } = Select;

class BulckTankDetails extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            open: false,
            title: "",
            showModal: false,
            maxFillingLimit: '',
            capacity: '',
            bulckTankEdit: null,
            bulckTankList: [],
            actionView: false,
            AssetManagementPermission: false,
            openViewMoreDetails: false,
            selectedElement: "",

            objBulktankDropdown: {
                "capacity": "",
            }
        };
        this.requestFromClose = this.requestFromClose.bind(this);
        this.requestFromClose3 = this.requestFromClose3.bind(this);
        this.requestFromClose4 = this.requestFromClose4.bind(this);
        this.handleMaxFillingLimit = this.handleMaxFillingLimit.bind(this);
        this.handleCapacity = this.handleCapacity.bind(this);
    }
    componentDidMount() {
        checkServiceType(38).then((data) => this.setState({
            AssetManagementPermission: data
        }, () => {
        }),
        )
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;
            history.push("/");
        } else {
            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 38)
        }
        this.getDataBulckTankBySiteId();
    }

    getSetPageData = (serviceTypeIs, Pageid) => {
        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] != null) {
            this.setState({
                actionView: true,
            })
        } else {
            console.log("No permission")
        }
    };

    grnUpdata(id) {
        this.getDataBulckTankById(id);
        this.setState({ showModal1: true, });
    }

    getDataBulckTankById = async (id) => {
        var dataset = {
            'id': id
        }
        var res = await getBulckTankById(dataset);
        if (res.success) {
            this.setState({ bulckTankEdit: res.data }, () => {
                this.setState({
                    maxFillingLimit: res.data?.maxFillingLimit,
                    capacity: res.data?.capacity,

                }, () => {
                    this.setState({ open: true, })
                })
            })
        }
    }

    getDataBulckTankBySiteId = async () => {
        var dataset = {
            'id': this.props.details.site_details.id
        }
        var res = await getBulckTankBySiteId(dataset);
        if (res.success) {
            this.setState({
                bulckTankList: res.data
            })
        }
    };

    requestFromClose() {
        this.setState({
            showModal: true,
            open: true,
            maxFillingLimit: '',
            capacity: '',
            bulckTankEdit: null,
        });
    }
    requestFromClose4() {
        this.setState({ showModal1: false, });
    }

    requestFromClose3() {
        this.setState({ showModal: false, });
    }
    handleMaxFillingLimit(e) {
        //console.log(e)
        this.setState({ maxFillingLimit: e });
    }
    handleCapacity(e) {
        //console.log(e)
        this.setState({ capacity: e });
    }
    AllDataSubmitToUpdate = async () => {
        console.log(this.state.bulckTankEdit);
        if (this.validator.allValid()) {
            if (this.state.bulckTankEdit == null) {

                var dataSet = {
                    "maxFillingLimit": this.state.maxFillingLimit,
                    "capacity": this.state.capacity,
                    "site": { "id": this.props.details.site_details.id },
                }
                //console.log(dataSet)
                var res = await Createcreatebulcktank(dataSet);
                if (res.success) {
                    message.success('Successfully Bulk Tank Added!')
                    this.getDataBulckTankBySiteId();
                    this.setState({
                        maxFillingLimit: '',
                        capacity: '',
                        bulckTankEdit: null,
                        showModal: false,
                        open: false
                    })
                    // const { history } = this.props;
                    // history.push("/site");
                } else {
                    message.warning(' Something went wrong!')
                }
            } else {
                var dataSet = {
                    "id": this.state?.bulckTankEdit?.id,
                    "maxFillingLimit": this.state.maxFillingLimit,
                    "capacity": this.state.capacity,
                    "site": { "id": this.props.details.site_details.id },
                }
                //console.log(dataSet)
                var res = await updateBulckTank(dataSet);
                if (res.success) {
                    message.success('Successfully Bulk Tank Edited!')
                    this.getDataBulckTankBySiteId();
                    this.setState({
                        maxFillingLimit: '',
                        capacity: '',
                        bulckTankEdit: null,
                        showModal: false,
                        open: false
                    })
                    // const { history } = this.props;
                    // history.push("/site");
                } else {
                    message.warning(' Something went wrong!')
                }
            }


        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    onDrawerClose = () => {
        this.setState({
            open: false
        })

    }
    viewMoreDetails(element) {
        this.setState({
            openViewMoreDetails: true,
            selectedElement: element
        });
    }
    onViewMoreDetailsDrawerClose = () => {
        this.setState({
            showModal2: false,
            openViewMoreDetails: false,
        })
    }
    render() {
        const { Panel } = Collapse;
        const _bulk = this.state.selectedElement
        const columns2 = [
            {
                title: "Last Filling Quantity",
                details: [],
                content: _bulk !== "" ? <span class="badge bg-secondary">{_bulk.lastFillingQuantity}</span> : <></>,
            },
            {
                title: "Max Filling Limit",
                details: [],
                content: _bulk !== "" ? <span class="badge badge-soft-pink fw-semibold">{_bulk.maxFillingLimit}</span> : <></>,
            },
            {
                title: "Capacity",
                details: [],
                content: _bulk !== "" ? <span class="badge badge-soft-success fw-semibold">{_bulk.capacity}</span> : <></>,
            },
        ];

        return (
            <div className="row">

                <div class="col-lg-12">
                    <div class="text-end">
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                {checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ?
                                    <Button
                                        type="button"
                                        class="btn btn-soft-primary btn-sm"
                                        style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px' }}
                                        onClick={this.requestFromClose}
                                        icon={<PlusCircleOutlined style={{ color: 'white' }} />}>
                                        <span className="textStyles-small" style={{ color: 'white' }}>New Asset</span>
                                    </Button>
                                    : (<></>)
                                }
                            </li>
                        </ul>
                    </div>
                </div>

                <Row span={24}>
                    {this.state.bulckTankList.map((element, index) => (
                        <Col span={8} key={index}>
                            <Card
                                bordered={false}
                                style={{
                                    width: '98%',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                    position: 'relative',
                                    marginTop: 10
                                }}
                            >
                                <div style={{ display: 'flex', marginBottom: 16, backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '10px' }}>
                                    <div>
                                        <HomeOutlined style={{ marginLeft: '3px', marginRight: '3px' }} /> &rarr;
                                        <span style={{ marginLeft: '3px', marginRight: '3px', fontWeight: 'bold' }} className="textStyles-small">
                                            {this.props.details.site_details.siteName}
                                        </span>
                                        &rarr;
                                        <span style={{ marginLeft: '3px', color: '#BA183A', fontWeight: 'bold' }} className="textStyles-small">Bulk Tank</span>
                                    </div>
                                </div>
                                <div style={{ marginBottom: 8 }}>
                                    <span className="textStyles-small" style={{ fontSize: 12 }}>Last Filling Quantity</span>
                                    <Tag color="default" style={{ float: 'right', borderRadius: 5 }}> <span className="textStyles-small">
                                        {element.lastFillingQuantity}
                                    </span></Tag>
                                </div>
                                <div style={{ marginBottom: 8 }}>
                                    <span className="textStyles-small">Max Filling Limit</span>
                                    <Tag color="purple" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                                        <span className="textStyles-small" style={{ fontSize: 11 }}>
                                            {element.maxFillingLimit != null ? element.maxFillingLimit : "Currently Not Set"}
                                        </span></Tag>
                                </div>
                                <div style={{ marginBottom: 8 }}>
                                    <span className="textStyles-small">Capacity</span>
                                    <Tag color="magenta" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                                        <span className="textStyles-small" style={{ fontSize: 11 }}>
                                            {element.capacity != null ? element.capacity : "Currently Not Set"}
                                        </span></Tag>
                                </div>
                                <Divider style={{ marginTop: '3px' }} />
                                <Button
                                    type="primary"
                                    style={{ marginRight: 8, borderRadius: '5px' }}
                                    size='small'
                                    onClick={() => this.viewMoreDetails(element)}>
                                    <span className="textStyles-small" style={{ fontSize: 10 }}> <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details</span>
                                </Button>

                                {(checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER')) && (
                                    <Button
                                        icon={<EditOutlined style={{ color: 'white' }} />}
                                        style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                        onClick={() => this.grnUpdata(element.id)}
                                        size='small'
                                    />
                                )}
                            </Card>
                        </Col>
                    ))}
                </Row>

                <Drawer maskClosable={false} title={"Bulk Tanks Details"} width={"50%"} placement="right"
                    onClose={() => this.onViewMoreDetailsDrawerClose()} visible={this.state.openViewMoreDetails}
                    closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}>
                        <i class="mdi dripicons-cross"></i></button>}>
                    <Collapse
                        style={{ alignContent: "center", borderColor: "white" }}
                    >
                        {columns2.map((item, index) => (
                            <Panel
                                style={{
                                    borderColor: index % 2 === 0 ? "#f8f9fa" : "white",
                                    backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white"
                                }}
                                header={
                                    <li className="d-flex justify-content-between align-items-center"
                                        style={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white" }}>
                                        <div>
                                            <span className="textStyles-small">{item.title}</span>
                                        </div>
                                        <span className="textStyles-small">{item.content}</span>
                                    </li>
                                }
                                key={item.title}
                                showArrow={item.details.length > 0}
                                collapsible={item.details.length > 0 ? "" : "icon"}
                            >
                                {item.details.map((detail) => (
                                    <li
                                        className="d-flex justify-content-between align-items-center me-2"
                                        style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                                        key={detail.title}
                                    >
                                        <div>
                                            <span className='textStyles-small'>{detail.title}</span>
                                        </div>
                                        <span className="textStyles-small">{detail.content}</span>
                                    </li>
                                ))}
                            </Panel>
                        ))}
                    </Collapse>
                </Drawer>

                <Drawer maskClosable={false} title={this.state.bulckTankEdit !== null ?
                    <span className="textStyles-small" style={{ fontSize: 14 }}>Bulk Tank Update</span> :
                    <span className="textStyles-small" style={{ fontSize: 14 }}>New Bulk Tank</span>}
                    placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                    <div className='row'>
                        <div className="col-md-12">
                            <label className="form-label" for="depot">
                                <span className="textStyles-small">
                                    Max Filling Limit <x style={{ color: "red" }}>*</x>
                                </span>
                            </label>
                            <Form.Item>
                                <InputNumber className='borderedSelect'
                                    style={{ width: '100%', height: 34 }} id="max_Filling_Limit" value={this.state.maxFillingLimit} onChange={this.handleMaxFillingLimit} />
                                <p style={{ color: "red", marginTop: 5 }} className='textStyles-small'>
                                    {this.validator.message("Max Filling Limit", this.state.maxFillingLimit, "required")}</p>
                            </Form.Item>
                        </div>
                        <div className="col-md-12">
                            <label className="form-label" for="depot2">
                                <span className="textStyles-small">
                                    Bulk Tank Capacity (l) <x style={{ color: "red" }}>*</x>
                                </span>
                            </label>
                            <Form.Item>
                                <Select
                                    value={this.state?.capacity}
                                    style={{ width: '100%' }}
                                    className='borderedSelect'
                                    bordered={false}
                                    onChange={(e) => {
                                        this.handleCapacity(e);
                                    }}>
                                    {
                                        this.props?.dropdownObj["bulckTankDropdown"] != null && (
                                            this.props?.dropdownObj["bulckTankDropdown"]["capacity"] ?? []
                                        ).map((x) => (
                                            <Option value={x}>{x}</Option>
                                        ))
                                    }
                                </Select>
                                <p style={{ color: "red", marginTop: 5 }} className='textStyles-small'>
                                    {this.validator.message("Capacity", this.state.capacity, "required")}</p>
                            </Form.Item>
                            <Button
                                className="btn btn-primary"
                                onClick={() => this.AllDataSubmitToUpdate()}
                                style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}
                            >
                                <span className="textStyles-small" style={{ color: 'white' }}>{this.state.bulckTankEdit !== null ? 'Update This Asset' : 'Add This Asset'}</span>
                            </Button>
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default BulckTankDetails;