import React, { Component } from 'react'
import { Select, DatePicker, Table, Image, Avatar, List, message, Row, Col, Button, Tag, Collapse, Card, Badge  } from 'antd';
import { LeftOutlined, RightOutlined, CloseOutlined, FileImageOutlined } from '@ant-design/icons';


import { Link } from "react-router-dom";
import componentsimg from "../../icon/components-svgrepo-com.svg";

import GeneratorDetails from '../../components/image-capture/image-details'
import Popup from 'reactjs-popup';

import $ from 'jquery';

import subCompomentd from "../../icon/sub-compomentd.svg";
import { getLastThreeRequestDetails, getLastBySiteId, getHistoryBySiteId, getLastestImgBySiteId, getHistoryBySiteIdAndsubComId } from "../../api/index";
import Modal from 'react-modal';
import moment from 'moment';


import { THE_FAMILY_ID } from '@azure/msal-common/dist/utils/Constants';
import Item from 'antd/lib/list/Item';
import ReactLoading from 'react-loading';
import { tupleNum } from 'antd/lib/_util/type';

const { Panel } = Collapse;



class index extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            loadingData: false,
            countSet: 0,

            showModal: false,
            allList: null,
            allSite_loading: true,
            allSite: [],
            selectSiteId: null,
            selectSiteName: null,
            selectComptName: null,
            selectImageSet: null,
            comptId: null,
            Data_list: null,
            selectImagesDetails: null,
            hiSelectSubId: null,
            compList: [
                { "id": "1", "description": "19\" Rack ", "name": "19\" Rack " },
                { "id": "2", "description": "Access Antenna", "name": "Access Antenna" },
                { "id": "3", "description": "Access Equipment", "name": "Access Equipment" },
                { "id": "4", "description": "ACDB", "name": "ACDB" },
                { "id": "5", "description": "ATS ", "name": "ATS " },
                { "id": "6", "description": "Battery ", "name": "Battery " },
                { "id": "7", "description": "Combine & Filters ", "name": "Combine & Filters " },
                { "id": "8", "description": "Bulkhead", "name": "Bulkhead" },
                { "id": "9", "description": "Cooling System", "name": "Cooling System" },
                { "id": "10", "description": "Franklin Rod", "name": "Franklin Rod" },
                { "id": "11", "description": "SPD ", "name": "SPD " },
                { "id": "12", "description": "Generator ", "name": "Generator " },
                { "id": "13", "description": "MW Link ", "name": "MW Link " },
                { "id": "14", "description": "RRU ", "name": "RRU " },
                { "id": "15", "description": "Transport Equipment ", "name": "Transport Equipment " },
                { "id": "16", "description": "Tower Light", "name": "Tower Light" },
                { "id": "17", "description": "Rectifier", "name": "Rectifier" },
                { "id": "18", "description": "Tower", "name": "Tower" },
                { "id": "19", "description": "Cabin\/Cabinets", "name": "Cabin\/Cabinets" }
            ]
        };
        this.handleCloseFullModal = this.handleCloseFullModal.bind(this);

    }
    handleCloseFullModal() {
        this.setState({ showModal: false });
    }
    componentDidMount() {


        this.setState({
            selectSiteId: this.props.details.site_details.id,
            selectSiteName: this.props.details.site_details.siteName

        }, () => {
            this.getDataLastThreeRequestDetails(0);
            //this.getDataHistoryBySiteId();
        })
        // this.getDataLastThreeRequestDetails()
    }
    getDataSet(data, inx) {


        if (data[inx]) {
            this.setState({
                comptId: data[inx].id,
                selectImageSet: data[inx]?.imageFields,
                loadingData: false
            })
        } else {
            this.setState({
                comptId: inx + 1,
                selectImageSet: [],
                loadingData: false

            })
        }



    }
    getDataHistoryBySiteIdBack = async (subId) => {

        if (this.state.hiSelectSubId == null) {


        } else {

            if (this.state.hiSelectSubId == subId) {
                console.log('==')
                if (this.state.countSet!==0) {
                    this.setState({
                        hiSelectSubId: subId,
                        countSet: --this.state.countSet
                    }, () => {
                        console.log(this.state.countSet)
                        this.getHistoryAPICall(subId)
                    })
                 }else{
                    this.getHistoryAPICall(subId)
                 }
              
            } else {
                console.log('!==')

                this.setState({
                    hiSelectSubId: subId,
                    countSet: 0
                }, () => {
                    console.log(this.state.countSet)
                    this.getHistoryAPICall(subId)

                })
            }
        }
    }
    getDataHistoryBySiteId = async (subId) => {

        if (this.state.hiSelectSubId == null) {
            console.log('dsdsdllllllll')

            this.setState({
                hiSelectSubId: subId,
                countSet: 0
            }, () => {
                console.log(this.state.countSet)

                this.getHistoryAPICall(subId)
            })

        } else {

            if (this.state.hiSelectSubId == subId) {
                console.log('==')

                this.setState({
                    hiSelectSubId: subId,
                    countSet: ++this.state.countSet
                }, () => {
                    console.log(this.state.countSet)
                    this.getHistoryAPICall(subId)
                })
            } else {
                console.log('!==')

                this.setState({
                    hiSelectSubId: subId,
                    countSet: 0
                }, () => {
                    console.log(this.state.countSet)
                    this.getHistoryAPICall(subId)

                })
            }
        }
    }
    getHistoryAPICall = async (subId) => {

        var data = new FormData();
        data.append('siteId', this.state.selectSiteId);
        data.append('subComId', subId);
        data.append('recodeNo', this.state.countSet);
        var res = await getHistoryBySiteIdAndsubComId(data);
        console.log('restydfdfd')
        console.log(res)
        if (res.success) {

            if (res.data.images !== null) {
                if (res.data.images.length > 0) {
                    const newState = this.state.selectImageSet.map(obj => {
                        // 👇️ if id equals 2, update country property
                        if (obj.field.id === subId) {
                            return { ...obj, images: res.data.images };
                        }

                        // 👇️ otherwise return the object as is
                        return obj;
                    }, () => {

                    });
                    this.setState({ selectImageSet: newState }, () => {
                        console.log("new array")
                        console.log(this.state.selectImageSet)
                    })

                    console.log(newState)

                    document.getElementById("imge" + subId).src = "https://storage.googleapis.com/dns_storage_v2/requestimages/" + res.data?.images[0]?.imageName;

                    document.getElementById("imgeCode" + subId).innerHTML = res.data?.images[0]?.request?.requestCode;
                    document.getElementById("imgeReDate" + subId).innerHTML = "Request Date : " + res.data?.images[0]?.request?.requestDate;
                    document.getElementById("imgeCreUser" + subId).innerHTML = "Created User :  " + res.data?.images[0]?.request?.createdUser.name;
                    document.getElementById("imgeStatus" + subId).innerHTML = res.data?.images[0]?.request?.serviceStatus.name;
                    document.getElementById("imgeCreData" + subId).innerHTML = "Created Data : " + moment(res.data?.images[0]?.request?.createdTime).format('YYYY-MM-DD HH:mm:ss');

                }else{this.setState({countSet:--this.state.countSet},()=>{
                    message.success('Finished !')
                })}



            }else{this.setState({countSet:--this.state.countSet},()=>{
                message.success('Finished !')
            })}
        }

    }

    getDataLastThreeRequestDetails = async (inx) => {

        console.log("getLastThreeRequestDetails1");
        if (this.state.selectSiteId != null) {
            console.log("getLastThreeRequestDetails2");

            this.setState({
                loading: true,
                loadingData:true

            })
            var data = new FormData();
            data.append('siteId', this.state.selectSiteId);


            var res = await getLastestImgBySiteId(data);



            if (res.success) {
                if (res.data && res.data.length) {

                    console.log(res.data)
                    this.setState({
                        loading: false,
                        loadingData:false,
                        Data_list: res.data

                    }, () => {
                        if (res?.data !== '') {

                            this.getDataSet(this.state?.Data_list, inx)
                        }
                    })
                }else{
                    this.setState({
                        loading: false,
                        loadingData:false
        
                    })
                }

            }else{
                this.setState({
                    loading: false,
                    loadingData:false
    
                })
            }

        } else {
            alert('Please select a Site')
        }

    };

    onChangeUserSites = (value) => {
        //console.log("onChangeUserSites");
        //console.log(value);
        var siteName = this.state.allSite.filter(person => person.id == value)

        this.setState({
            selectSiteId: value,
            selectSiteName: siteName[0].siteName

        }, () => {
            this.getDataLastThreeRequestDetails();
        })

    };

    onChangeAllCopmt() {

        this.setState({
            comptId: null,
            selectComptName: null

        }, () => {
            this.getDataLastThreeRequestDetails();
        })

    }
    onChangeAllDetails(item) {

        //console.log(item);
        this.setState({
            selectImagesDetails: item
        }, () => {
            this.setState({
                showModal: true
            })
        })

    }
    onChangeHistory(subId) {



        this.getDataHistoryBySiteId(subId)

    }
    onChangeHistoryBack(subId) {



        this.getDataHistoryBySiteIdBack(subId)

    }

    onChangeImages(id, key, imageName, imgId, imgarray, arraySize) {

        document.getElementById("imge" + id).src = "https://storage.googleapis.com/dns_storage_v2/requestimages/" + imageName;
        document.getElementById("imgeCode" + id).innerHTML = imgarray[arraySize].request.requestCode;
        document.getElementById("imgeReDate" + id).innerHTML = "Request Date : " + imgarray[arraySize].request.requestDate;
        document.getElementById("imgeCreUser" + id).innerHTML = "Created User :  " + imgarray[arraySize].request.createdUser.name;
        document.getElementById("imgeStatus" + id).innerHTML = imgarray[arraySize].request.serviceStatus.name;
        document.getElementById("imgeCreData" + id).innerHTML = "Created Data : " + moment(imgarray[arraySize].request.createdTime).format('YYYY-MM-DD HH:mm:ss');
        //  document.getElementById("imge" + id).value = "https://storage.googleapis.com/dns_storage_v2/requestimages/" + imageName;
        //  document.getElementById("imgePopup" + id).src = "https://storage.googleapis.com/dns_storage_v2/requestimages/" + imageName;
        var index = imgarray.filter(item => item.id != imgId)

        index?.map((a, t) => {


            $("#imagLi" + a.id).attr('class', 'list-group-item');


        })


        $("#imagLi" + imgId).toggleClass("active");

        console.log(index)



    }
    onChangeSetImages(e) {

        this.setState({
            popupImg: document.getElementById(e).src
        })

    }
    onChangeCopmt(id, name) {
        this.setState({
            selectImageSet: [],
            loadingData: true,
        })
        this.getDataLastThreeRequestDetails(id - 1);

    }

    clearBtnClick = () => {
        window.location.reload()
    }
    render() {
        const { Option } = Select;
        
        return (
            <div id='viewHome'>

                <div class="row" >
                    <div className='col-lg-3' >
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Component</h4>
                                <p class="text-muted mb-0">Images on Components</p>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <ul class="list-group">
                                            {this.state.compList.map((item, index) => {
                                                return (
                                                    <li style={{ backgroundColor: this.state.comptId == item.id ? ('#00cf49') : (''), borderColor: this.state.comptId == item.id ? ('#00cf49') : ('') }} class={this.state.comptId == item.id ? ("list-group-item active") : ("list-group-item")}><a href='#viewHome' style={{ color: this.state.comptId == item.id ? ("white") : ("#1d2c48") }} role='tab' onClick={() => this.onChangeCopmt(item?.id, item?.name)}><i class="la la-arrow-right text-secondary me-2"></i>{item?.name}</a></li>
                                                )

                                            })}


                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    {/* NAVIGATION SECTION */}
                    <div style={{ backgroundColor: 'white', padding: 2, marginTop: 10, borderRadius: 10, marginBottom: 20, zIndex: 10 }}>
                        <Collapse defaultActiveKey={['1']} ghost={true}>
                            <Panel header={<span className='textStyles-small' style={{ fontSize: 13, fontWeight: 'bold' }}> {this.state.compList.map((item, index) => {
                                if (this.state.comptId == item.id) {
                                    return item.name
                                }
                            }
                            )}</span>} key="1">
                                <Row span={24}>
                                    <Col>
                                        {
                                            this.state.compList.map((item, index) => {
                                                return (
                                                    <Button
                                                        onClick={() => this.onChangeCopmt(item?.id, item?.name)}
                                                        style={{
                                                            backgroundColor: this.state.comptId == item.id ? '#F5E5E8' : 'white',
                                                            borderColor: this.state.comptId == item.id ? '#B9183A' : 'gray',
                                                            color: 'black',
                                                            fontSize: 15,
                                                            width: '200px',
                                                            height: '40px',
                                                            borderRadius: '5px',
                                                            margin: '5px'
                                                        }}
                                                    >
                                                        <span className='textStyles-small' style={{
                                                            fontSize: 11,
                                                            color: this.state.comptId == item.id ? '#B9183A' : 'black'
                                                        }}>
                                                            {item.name}
                                                        </span>
                                                    </Button>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </div>

                    <div className='col-lg-9'>
                        <div className='row'>
                            {this.state.selectImageSet?.map((item, idx) => {
                                return (
                                    <div class="col-lg-6" style={{ display: 'grid' }}>
                                        <div class="card">

                                                                                  <Image
                                        width={200}
                                        height={300}
                                        src={'https://storage.googleapis.com/dns_storage_v2/requestimages/' + item?.images[0]?.imageName}
                                        style={{ cursor: 'pointer', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                                        id={'imge' + item?.field?.id}
                                      />
                                            <div class="card-header" >
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <h4 class="card-title" style={{ fontSize: 12 }}>{item?.field?.name}</h4>
                                                    </div>

                                                    <div class="col-auto">

                                                        <button onClick={() => this.onChangeHistoryBack(item?.field?.id)} style={{ padding: 0, border: 0 }} type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="mdi mdi-chevron-left"></i>
                                                        </button> <span class="badge badge-outline-light"> <span class="badge bg-success" id={'imgeCreData2' + item?.field?.id} >  {moment(item?.images[0]?.request?.createdTime).format('YYYY-MM-DD')}</span> </span>
                                                        <button onClick={() => this.onChangeHistory(item?.field?.id)} style={{ padding: 0, border: 0 }} type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="mdi mdi-chevron-right"></i>
                                                        </button>


                                                    </div>
                                                    <p class="card-text text-muted " id={'imgeCode' + item?.field?.id} style={{ marginBottom: 0 }}> {item?.images[0]?.request?.requestCode}</p>
                                                    <p class="card-text text-muted " id={'imgeReDate' + item?.field?.id} style={{ marginBottom: 0 }}>Request Date : {item?.images[0]?.request?.requestDate}</p>
                                                    <p class="card-text text-muted " id={'imgeCreUser' + item?.field?.id} style={{ marginBottom: 0 }} >Created User : {item?.images[0]?.request?.createdUser?.name}</p>
                                                    <p class="card-text text-muted " id={'imgeCreData' + item?.field?.id} style={{ marginBottom: 0 }} >Created Data : {moment(item?.images[0]?.request?.createdTime).format('YYYY-MM-DD HH:mm:ss')}</p>
                                                    <p class="card-text text-muted " id={'imgeCreData2' + item?.field?.id} style={{ marginBottom: 0 }} >{moment(item?.images[0]?.request?.createdTime).format('YYYY-MM-DD')}</p>
                                                    <p class="card-text text-muted " >Status : <span class="badge bg-success" id={'imgeStatus' + item?.field?.id} >  {item?.images[0]?.request?.serviceStatus?.name}</span></p>
                                                </div>
                                            </div>

                                            <Col span={15}>
                                      <div>
                                        <div style={{ marginTop: 13 }}>
                                          <span className="textStyles-small" style={{ fontSize: 16, margin: 10, fontWeight: 600 }}>{item?.field?.name}</span>
                                        </div>
                                        <List
                                          bordered={false}
                                          itemLayout="vertical"
                                          style={{ marginTop: 10, color: 'gray', marginLeft: 10 }}
                                        >
                                          <List.Item style={{ padding: 5 }}>
                                            <span className="textStyles-small" style={{ color: 'gray' }}>Request Code: </span>
                                            <span id={'imgeCode' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                              {item?.images[0]?.request?.requestCode}
                                            </span>
                                          </List.Item>
                                          <List.Item style={{ padding: 5 }}>
                                            <span className="textStyles-small" style={{ color: 'gray' }}>Request Date: </span>
                                            <span id={'imgeReDate' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                              {item?.images[0]?.request?.requestDate}
                                            </span>
                                          </List.Item>
                                          <List.Item style={{ padding: 5 }}>
                                            <span className="textStyles-small" style={{ color: 'gray' }}>Created User: </span>
                                            <span id={'imgeCreUser' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                              {item?.images[0]?.request?.createdUser?.name}
                                            </span>
                                          </List.Item>
                                          <List.Item style={{ padding: 5 }}>
                                            <span className="textStyles-small" style={{ color: 'gray' }}>Created Date: </span>
                                            <span id={'imgeCreData' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                              {moment(item?.images[0]?.request?.createdTime).format('YYYY-MM-DD HH:mm')}
                                            </span>
                                          </List.Item>
                                          <List.Item style={{ padding: 5 }}>
                                            <span className="textStyles-small" style={{ color: 'gray' }}>Formatted Date: </span>
                                            <span id={'imgeCreData2' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                              {moment(item?.images[0]?.request?.createdTime).format('YYYY-MM-DD')}
                                            </span>
                                          </List.Item>
                                          <List.Item style={{ padding: 5 }}>
                                            <span className="textStyles-small" style={{ color: 'gray' }}>Status: </span>
                                            <Badge color="green" text={<span style={{ color: 'black' }}>{item?.images[0]?.request?.serviceStatus?.name}</span>} />
                                          </List.Item>
                                        </List>
                                        </div>

                                        </Col>
                                        
                                            
                                            
                                            
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-12">

                                                        <ul class="list-group">



                                                            {item?.images.map((x, y) => {




                                                                return (


                                                                    <li id={'imagLi' + x.id} style={{ padding: 4 }} class={this.state.imageComid == null && y == 0 ? ("list-group-item active") : ("list-group-item")} ><a role='tab' onClick={() => this.onChangeImages(item?.field?.id, y, x.imageName, x.id, item?.images, y)}><i class="la la-arrow-right text-secondary me-2"></i>Image {y + 1}</a>

                                                                    </li>
                                                                )

                                                            })}
                                                        </ul>




                                                    </div>

                                                </div>
                                                <br></br>

                                                <a href="#" class="btn btn-primary btn-sm">More Info..</a>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {this.state.selectImageSet == null || this.state.selectImageSet[0] == null ? (<div className='row' style={{ textAlign: 'center' }}>

                                <div className="row">
                                    {this.state.loadingData ? (<div class="col-lg-12" style={{ textAlign: 'center' }}>

                                        <div class="spinner-border spinner-border-custom-2 text-primary" role="status"></div>



                                    </div>) : (<h3 class="font-25 text-muted mb-5">Images NotFound</h3>)}


                                </div>

                            </div>) : ('')}
                        </div>

                    </div>


                </div>

                {/* modal start */}
                <Modal

                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example"
                    style={{
                        overlay: {

                            zIndex: 10000
                        },
                        content: {
                            right: '15%',
                            left: '15%',
                            top: '9px',
                            bottom: '9px'
                        }
                    }}
                //    className="modal"
                >
                    <div class="col-lg-12 mx-auto">


                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Image List:</h4>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <GeneratorDetails details={this.state.selectImagesDetails} siteName1={this.state.selectSiteName} comptName={this.state.selectComptName} />




                                </div>



                            </div>
                        </div>








                        <div class="row d-flex justify-content-center">

                            <div class="col-lg-12">
                                <div class="float-end d-print-none">

                                    <button class="btn btn-primary btn-sm" onClick={this.handleCloseFullModal} >Close</button>
                                </div>
                            </div>
                        </div>


                    </div>


                </Modal>

                {/* modal end */}


                <div>
                    <Row span={24}>
                        {this.state.selectImageSet?.map((item, idx) => {
                            return (
                                <Col id='viewHome' key={idx} span={12}>
                                    <div className="card" style={{ marginBottom: 10, width: '99%', borderRadius: '10px' }}>
                                        <Row span={24}>
                                            <Col span={9}>
                                                <Image
                                                    width={200}
                                                    height={300}
                                                    src={'https://storage.googleapis.com/dns_storage_v2/requestimages/' + item?.images[0]?.imageName}
                                                    style={{ cursor: 'pointer', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                                                    id={'imge' + item?.field?.id}
                                                />
                                            </Col>

                                            <Col span={15}>
                                                <div>
                                                    <div style={{ marginTop: 13 }}>
                                                        <span className="textStyles-small" style={{ fontSize: 16, margin: 10, fontWeight: 600 }}>{item?.field?.name}</span>
                                                    </div>
                                                    <List
                                                        bordered={false}
                                                        itemLayout="vertical"
                                                        style={{ marginTop: 10, color: 'gray', marginLeft: 10 }}
                                                    >
                                                        <List.Item style={{ padding: 5 }}>
                                                            <span className="textStyles-small" style={{ color: 'gray' }}>Request Code: </span>
                                                            <span id={'imgeCode' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                                                {item?.images[0]?.request?.requestCode}
                                                            </span>
                                                        </List.Item>
                                                        <List.Item style={{ padding: 5 }}>
                                                            <span className="textStyles-small" style={{ color: 'gray' }}>Request Date: </span>
                                                            <span id={'imgeReDate' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                                                {item?.images[0]?.request?.requestDate}
                                                            </span>
                                                        </List.Item>
                                                        <List.Item style={{ padding: 5 }}>
                                                            <span className="textStyles-small" style={{ color: 'gray' }}>Created User: </span>
                                                            <span id={'imgeCreUser' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                                                {item?.images[0]?.request?.createdUser?.name}
                                                            </span>
                                                        </List.Item>
                                                        <List.Item style={{ padding: 5 }}>
                                                            <span className="textStyles-small" style={{ color: 'gray' }}>Created Date: </span>
                                                            <span id={'imgeCreData' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                                                {moment(item?.images[0]?.request?.createdTime).format('YYYY-MM-DD HH:mm')}
                                                            </span>
                                                        </List.Item>
                                                        <List.Item style={{ padding: 5 }}>
                                                            <span className="textStyles-small" style={{ color: 'gray' }}>Formatted Date: </span>
                                                            <span id={'imgeCreData2' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                                                {moment(item?.images[0]?.request?.createdTime).format('YYYY-MM-DD')}
                                                            </span>
                                                        </List.Item>
                                                        <List.Item style={{ padding: 5 }}>
                                                            <span className="textStyles-small" style={{ color: 'gray' }}>Status: </span>
                                                            <Badge color="green" text={<span style={{ color: 'black' }}>{item?.images[0]?.request?.serviceStatus?.name}</span>} />
                                                        </List.Item>
                                                        <List.Item style={{ padding: 5 }}>
                                                            <Row span={24}>
                                                                <Col span={12}>
                                                                    {item?.images.map((x, y) => {
                                                                        return (
                                                                            <li id={'imagLi' + x.id} style={{ padding: 4 }} 
                                                                            class={this.state.imageComid == null && y == 0 ? ("list-group-item active") : 
                                                                                ("list-group-item")} ><a role='tab'
                                                                             onClick={() => this.onChangeImages(item?.field?.id, y, x.imageName, x.id, item?.images, y)}><i class="la la-arrow-right text-secondary me-2"></i>Image {y + 1}</a>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </Col>
                                                                <Col span={12}>
                                                                    <div>
                                                                        <button onClick={() => this.onChangeHistoryBack(item?.field?.id)} style={{ padding: 0, border: 0 }} type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="mdi mdi-chevron-left"></i>
                                                                        </button>
                                                                        <span className="badge badge-outline-light">
                                                                            <span className="badge bg-success" id={'imgeCreData2' + item?.field?.id}>
                                                                                {moment(item?.images[0]?.request?.createdTime).format('YYYY-MM-DD')}
                                                                            </span>
                                                                        </span>
                                                                        <button onClick={() => this.onChangeHistory(item?.field?.id)} style={{ padding: 0, border: 0 }} type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="mdi mdi-chevron-right"></i>
                                                                        </button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </List.Item>
                                                    </List>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            );
                        })}
                        {this.state.selectImageSet == null || this.state.selectImageSet[0] == null ? (
                            <div className='row' style={{ textAlign: 'center' }}>
                                <div className="row">
                                    {this.state.loadingData ? (
                                        <div className="col-lg-12" style={{ textAlign: 'center' }}>
                                            <div className="spinner-border spinner-border-custom-2 text-primary" role="status"></div>
                                        </div>
                                    ) : (
                                        <h3 className="font-25 text-muted mb-5">Images NotFound</h3>
                                    )}
                                </div>
                            </div>
                        ) : ('')}
                    </Row>
                </div>
    



            </div>
        )
    }
}
export default index;

