import React, { Component } from 'react';
import { Table, Input, InputNumber, Tag, Space, Select, Form, DatePicker, TimePicker, Button, Switch, Steps, message, Divider } from "antd";
import ReactLoading from 'react-loading';
import { getAllSiteType, getSiteDetails, getSiteAccessBaseData, updateSite, createSite, getAllDistrict, getAllEnergyCategory, getAllSiteCategory, getAllSiteOwner, getAllEntity, getAllTowerCategory, getAllTowerType, getAllCoolingMethods, getAllShelterType, getAllGenHutType } from "../../api/index";
import { connect } from "react-redux";
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import SimpleReactValidator from "simple-react-validator";

import { getDonationCategories, getData } from "../../actions/index";
import { getAllDropdowns } from "../../api/index";
import { withRouter } from 'react-router-dom'
const format = 'YYYY-MM-DD';

class SiteNewEdit extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            current: 0,
            items: [],
            ObjectId: null,
            allSite: [],
            siteName: null,
            siteId: null,
            allSiteType: [],
            allDistrict: [],
            allEnergyCategory: [],
            allSiteOwner: [],
            selectedDistrictId: null,
            siteType: [],
            selectedSiteTypeId: null,
            selectedDepotTypeId: null,
            selectedEnergyCategory: null,
            selectedSiteCategoryId: null,
            selectedSiteOwnerId: null,
            selectedtowerCategoryId: null,
            selectedTowerTypeId: null,
            coolingMethodsTypeId: null,
            selectedGeneratorHut1Typ: null,
            selectedGeneratorHut2Typ: null,
            selectedSiteEntityId: null,
            accessPermitionGroupId: null,
            depot_list: [],
            depotType: [],
            district: [],
            accessPermitionGroupType: [],

            siteOwner: [],
            towerCategory: [],
            towerType: [],
            energyCategory: [],
            siteEntity: [],
            generatorHut1Typ: null,
            generatorHut2Typ: null,
            siteCategory: [],
            coolingMethods: [],
            allSiteCategory: [],
            GenHutType: [],
            allSiteEntity: [],
            allTowerCategory: [],
            allTowerType: [],
            allCoolingMethods: [],
            allShelterType: [],
            accessPermitionGroup: null,
            isStandbyGeneratorAvailable: null,
            manualTransport: null,
            manualDistanceMeter: '',
            refuelManualDistance: '',
            pgPossibility: null,
            siteLatitude: '',
            siteLongitude: '',
            siteAddress: '',
            towerOwnerCategory: '',
            towerHeight: '',
            siteStatus: '',
            siteOnAirDate: null,
            tenants: '',
            powerSharedTenants: '',
            noOfPhases: '',
            towerLegsCount: '',
            indoorOutdoor: '',
            siteElevation: '',
            ambientTemperature: '',
            dimension: '',
            travelTimeFromDepot: '',
            isIdleGeneratorHutAvaialable: null,
            isSpaceAvailableNewGen: null,
            workingDaysAccessibleHours: '',
            yearoftheJFS: '',
            freeLandAvailable: '',
            shelterSize: '',
            nonShadingSpaceAvailabilityOnshelter: '',
            nonShadingSpaceAvailabilityGround: '',
            dependentCellCount: '',
            distancefromRegionalOffice: '',
            accessPermissionProvidingParty: '',
            activePassive: '',
            siteTopologyTerrainMapping: '',
            vehicleType: '',
            timeRestrictedReason: '',
            isAccessPermissionRequired: null,
            isSiteKeyRequired: null,
            portablegenConnectingSetupAvailability: null,
            indoorOutdoorid: null,
            indoorOutdoorType: null,
            pisVendor: '',
            landAreainPerch: '',

            keyLocation: '',
            sOwnerName: '',
            sOwnerAddress: '',

            dropdownDataSet: {
                "noOftenants": [],
            },

        };

        this.handleSiteName = this.handleSiteName.bind(this);
        this.handleSiteId = this.handleSiteId.bind(this);
        this.handleManualDistanceMeter = this.handleManualDistanceMeter.bind(this);
        this.handleYearoftheJFS = this.handleYearoftheJFS.bind(this);
        this.handleRefuelManualDistanc = this.handleRefuelManualDistanc.bind(this);
        this.handleDistancefromRegionalOffice = this.handleDistancefromRegionalOffice.bind(this);
        this.handleSiteLatitude = this.handleSiteLatitude.bind(this);
        this.handleSiteLongitude = this.handleSiteLongitude.bind(this);
        this.handleSiteAddress = this.handleSiteAddress.bind(this);
        this.handleTowerOwnerCategory = this.handleTowerOwnerCategory.bind(this);
        this.handleTowerHeight = this.handleTowerHeight.bind(this);
        this.handleStatus = this.handleStatus.bind(this);
        this.handleTenants = this.handleTenants.bind(this);
        this.handlePowerSharedTenants = this.handlePowerSharedTenants.bind(this);
        this.handleNoOfPhases = this.handleNoOfPhases.bind(this);
        this.handleTowerLegsCount = this.handleTowerLegsCount.bind(this);
        this.handleIndoorOutdoor = this.handleIndoorOutdoor.bind(this);
        this.handleSiteElevation = this.handleSiteElevation.bind(this);
        this.handleAmbientTemperature = this.handleAmbientTemperature.bind(this);
        this.handleDimension = this.handleDimension.bind(this);
        this.handleFreeLandAvailable = this.handleFreeLandAvailable.bind(this);
        this.handleshelterSize = this.handleshelterSize.bind(this);
        this.handleNonShadingSpaceAvailabilityOnshelter = this.handleNonShadingSpaceAvailabilityOnshelter.bind(this);
        this.handleNonShadingSpaceAvailabilityGround = this.handleNonShadingSpaceAvailabilityGround.bind(this);
        this.handleDependentCellCount = this.handleDependentCellCount.bind(this);
        this.handleTravelTimeFromDepot = this.handleTravelTimeFromDepot.bind(this);
        this.handleWorkingDaysAccessibleHours = this.handleWorkingDaysAccessibleHours.bind(this);
        this.handleWeekendsOrHolidaysAccessibleHours = this.handleWeekendsOrHolidaysAccessibleHours.bind(this);
        this.handleAccessPermissionProvidingParty = this.handleAccessPermissionProvidingParty.bind(this);
        this.handleactivePassive = this.handleactivePassive.bind(this);
        this.handlesiteTopologyTerrainMapping = this.handlesiteTopologyTerrainMapping.bind(this);
        this.handlevehicleType = this.handlevehicleType.bind(this);
        this.handletimeRestrictedReason = this.handletimeRestrictedReason.bind(this);
        this.handlePISvendor = this.handlePISvendor.bind(this);
        this.handlelandAreainPerch = this.handlelandAreainPerch.bind(this);

        this.handleSOwnerName = this.handleSOwnerName.bind(this);
        this.handleSOwnerAddress = this.handleSOwnerAddress.bind(this);
        this.handleKeyLocation = this.handleKeyLocation.bind(this);

    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            window.location.reload(false);
        } else {
            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 13)
        }
        this.props.getData();
        this.getDataAllSiteType();
        this.getDataAllDistrict();
        this.getDataAllEnergyCategory();
        this.getDataAllSiteCategory();
        this.getDataAllSiteOwner();
        this.getDataAllEntity();
        this.getDataAllTowerCategory();
        this.getDataAllTowerType();
        this.getDataAllGenHutType();
        this.getDataAllCoolingMethods();
        this.getDataAllShelterType();
        this.getDataSiteAccessBaseData();
        this.getDataSiteDetails(this.props.match.params?.id);
        this.setState({
            depot_list: this.props.depots,
        })
        this.getDropDownData();
    }

    getDropDownData = async () => {
        var res = await getAllDropdowns();
        if (res.success) {
            this.setState({
                dropdownDataSet: {
                    "noOftenants": res.data["General Details"]["No. of tenants"],
                }
            })
            console.log(this.state.dropdownDataSet)
        } else {
            message.warning('Issue With Dropdown Data Fetching! : ' + res.data.message);
        }
    }

    getSetPageData = (serviceTypeIs, Pageid) => {
        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] == null) {
            const { history } = this.props;
            history.push("/not-allowed");
        }
    };

    getDataSiteDetails = async (id) => {
        var res = await getSiteDetails(id);
        console.log("getSiteDetails");
        console.log(res.data);
        if (res.success) {
            this.setState({
                ObjectId: res.data?.id,
                siteName: res.data?.siteName,
                siteId: res.data?.siteId,

                selectedDepotTypeId: res.data?.depot?.id,
                depotType: {
                    "id": res.data?.depot?.id
                },
                selectedDistrictId: res.data?.district?.id,
                district: {
                    "id": res.data?.district?.id,
                },
                selectedEnergyCategory: res.data?.energyCategory?.id,
                energyCategory: {
                    "id": res.data?.energyCategory?.id,
                },
                selectedSiteCategoryId: res.data?.siteCategory?.id,
                siteCategory: {
                    "id": res.data?.siteCategory?.id,
                },
                selectedSiteOwnerId: res.data?.siteOwner?.id,
                siteOwner: {
                    "id": res.data?.siteOwner.id
                },
                selectedSiteEntityId: res.data?.entity?.id,
                siteEntity: {
                    "id": res.data?.entity?.id,
                },

                selectedSiteTypeId: res.data?.siteType?.id,
                siteType: {
                    "id": res.data?.siteType.id
                },
                selectedtowerCategoryId: res.data?.towerCategory.id,
                towerCategory: {
                    "id": res.data?.towerCategory.id,
                },


                selectedGeneratorHut1Typ: res.data?.availableGeneratorHut1Type?.id,
                generatorHut1Typ: {
                    "id": res.data?.availableGeneratorHut1Type?.id,
                },
                selectedGeneratorHut2Typ: res.data?.availableGeneratorHut2Type?.id,
                generatorHut2Typ: {
                    "id": res.data?.availableGeneratorHut2Type?.id,
                },


                selectedTowerTypeId: res.data?.towerType.id,
                towerType: {
                    "id": res.data?.towerType.id,
                },

                manualDistanceMeter: res.data?.manualDistanceMeter,
                refuelManualDistance: res.data?.refuelManualDistance,
                //  distancefromRegionalOffice:res.data?.distancefromRegionalOffice
                distancefromRegionalOffice: res.data.regionToSiteDistance,

                pgPossibility: res.data?.pgPossibility,
                siteLongitude: res.data?.siteAddressList[0]?.longitude,
                siteLatitude: res.data?.siteAddressList[0]?.latitude,
                siteAddress: res.data?.siteAddressList[0]?.address,
                towerOwnerCategory: res.data?.towerOwnerCategory,
                towerHeight: res.data?.towerHeight,
                siteStatus: res.data?.status,
                tenants: res.data?.tenants,
                powerSharedTenants: res.data?.powerSharedTenants,
                noOfPhases: res.data?.noOfPhases,
                towerLegsCount: res.data?.towerLegsCount,
                indoorOutdoor: res.data?.indoorOutdoor,
                siteElevation: res.data?.siteElevation,
                ambientTemperature: res.data?.ambientTemperature,
                dimension: res.data?.dimension,
                freeLandAvailable: res.data?.freeLandAvailable,
                shelterSize: res.data?.shelterSize,
                pisVendor: res.data?.pisVendor,
                landAreainPerch: res.data?.landAreainPerch,
                nonShadingSpaceAvailabilityOnshelter: res.data?.nonShadingSpaceAvailabilityOnshelter,
                dependentCellCount: res.data?.dependentCellCount,
                travelTimeFromDepot: res.data?.travelTimeFromDepot,
                workingDaysAccessibleHours: res.data?.workingDaysAccessibleHours,
                weekendsOrHolidaysAccessibleHours: res.data?.weekendsOrHolidaysAccessibleHours,
                yearoftheJFS: res.data?.yearOfTheJfs,
                accessPermissionProvidingParty: res.data?.accessPermissionProvidingParty,
                activePassive: res.data?.activePassive,
                siteTopologyTerrainMapping: res.data?.siteTopologyTerrainMapping,
                vehicleType: res.data?.vehicleType,
                timeRestrictedReason: res.data?.timeRestrictedReason,
                // isAccessPermissionRequired:res.data?.accessPermissionRequired

                nonShadingSpaceAvailabilityGround: res.data?.nonShadingSpaceAvailabilityGround,

                // accessAddress
                // accessName
                sOwnerAddress: res.data?.siteOwnerAddressForAccess,
                sOwnerName: res.data?.siteOwnerNameForAccess,
                keyLocation: res.data?.keyLocation,
            })

            if (res.data?.indoorOutdoor != null) {

                this.setState({
                    indoorOutdoorid: res.data?.indoorOutdoor.id,
                    indoorOutdoorType: res.data?.indoorOutdoor
                })
            } else {
                this.setState({
                    indoorOutdoorid: null,
                    indoorOutdoorType: null,
                })

            }
            if (res.data?.accessPermitionGroup != null) {

                this.setState({
                    accessPermitionGroupId: res.data?.accessPermitionGroup?.id,
                    accessPermitionGroup: {
                        "id": res.data?.accessPermitionGroup?.id
                    },
                })
            } else {
                this.setState({
                    accessPermitionGroupId: '',

                })
            }
            if (res.data?.siteOnAirDate != null) {
                this.setState({
                    siteOnAirDate: res.data?.siteOnAirDate
                })
            } else {
                this.setState({
                    siteOnAirDate: '',
                })
            }
            if (res.data?.isSpaceAvailableNewGen != null) {
                this.setState({
                    isSpaceAvailableNewGen: res.data?.isSpaceAvailableNewGen
                })

            } else {
                this.setState({
                    isSpaceAvailableNewGen: '',
                })
            }
            if (res.data?.portablegenConnectingSetupAvailability != null) {
                if (res.data?.portablegenConnectingSetupAvailability == false) {
                    this.setState({
                        portablegenConnectingSetupAvailability: 0
                    })
                }
                if (res.data?.portablegenConnectingSetupAvailability == true) {
                    this.setState({
                        portablegenConnectingSetupAvailability: 1
                    })
                }


            } else {
                this.setState({
                    portablegenConnectingSetupAvailability: ''
                })
            }
            if (res.data?.siteKeyRequired != null) {
                if (res.data?.siteKeyRequired == false) {
                    this.setState({
                        isSiteKeyRequired: 0
                    })
                }
                if (res.data?.siteKeyRequired == true) {
                    this.setState({
                        isSiteKeyRequired: 1
                    })
                }


            } else {
                this.setState({
                    isSiteKeyRequired: ''
                })
            }
            if (res.data?.accessPermissionRequired != null) {
                if (res.data?.accessPermissionRequired == false) {
                    this.setState({
                        isAccessPermissionRequired: 0
                    })
                }
                if (res.data?.accessPermissionRequired == true) {
                    this.setState({
                        isAccessPermissionRequired: 1
                    })
                }


            } else {
                this.setState({
                    isAccessPermissionRequired: ''
                })
            }
            if (res.data?.isIdleGeneratorHutAvaialable != null) {
                this.setState({
                    isIdleGeneratorHutAvaialable: res.data?.isIdleGeneratorHutAvaialable
                })

            } else {
                this.setState({
                    isIdleGeneratorHutAvaialable: ''
                })
            }

            if (res.data?.standbyGeneratorAvailable == false) {
                this.setState({
                    isStandbyGeneratorAvailable: 0
                })

            } else {
                this.setState({
                    isStandbyGeneratorAvailable: 1
                })
            }
            if (res.data?.manualTransport != null) {
                this.setState({
                    manualTransport: res.data?.manualTransport
                })

            } else {
                this.setState({
                    manualTransport: ''
                })
            }

            if (res.data?.coolingMethods != null) {
                this.setState({
                    coolingMethodsTypeId: res.data?.coolingMethods?.id,
                    coolingMethods: {
                        "id": res.data?.coolingMethods?.id,
                    },

                })

            } else {
                this.setState({
                    coolingMethodsTypeId: ''
                })
            }



            if (res.data?.shelterType != null) {
                this.setState({
                    shelterTypeId: res.data?.shelterType?.id,
                    shelterType: {
                        "id": res.data?.shelterType?.id,
                    },

                })

            } else {
                this.setState({
                    shelterTypeId: ''
                })
            }

        }

    };

    handleKeyLocation(e) {
        this.setState({ keyLocation: e.target.value });
    }

    handleSOwnerName(e) {
        this.setState({ sOwnerName: e.target.value });
    }

    handleSOwnerAddress(e) {
        this.setState({ sOwnerAddress: e.target.value });
    }

    handlePISvendor(e) {
        //console.log(e.target.value)
        this.setState({ pisVendor: e.target.value });
    }
    handlelandAreainPerch(e) {
        //console.log(e.target.value)
        this.setState({ landAreainPerch: e });
    }
    handleDependentCellCount(e) {
        //console.log(e)
        this.setState({ dependentCellCount: e });
    }
    handleNonShadingSpaceAvailabilityGround(e) {
        //console.log(e.target.value)
        this.setState({ nonShadingSpaceAvailabilityGround: e.target.value });
    }
    handleNonShadingSpaceAvailabilityOnshelter(e) {
        //console.log(e.target.value)
        this.setState({ nonShadingSpaceAvailabilityOnshelter: e.target.value });
    }
    handlevehicleType(e) {
        //console.log(e.target.value)
        this.setState({ vehicleType: e.target.value });
    }
    handleshelterSize(e) {
        //console.log(e.target.value)
        this.setState({ shelterSize: e.target.value });
    }
    handleFreeLandAvailable(e) {
        //console.log(e.target.value)
        this.setState({ freeLandAvailable: e.target.value });
    }
    handleWeekendsOrHolidaysAccessibleHours(e) {
        //console.log(e.target.value)
        this.setState({ weekendsOrHolidaysAccessibleHours: e.target.value });
    }
    handleWorkingDaysAccessibleHours(e) {
        //console.log(e.target.value)
        this.setState({ workingDaysAccessibleHours: e.target.value });
    }
    handleTravelTimeFromDepot(e) {
        //console.log(e.target.value)
        this.setState({ travelTimeFromDepot: e.target.value });
    }
    handletimeRestrictedReason(e) {
        //console.log(e.target.value)
        this.setState({ timeRestrictedReason: e.target.value });
    }
    handleDimension(e) {
        //console.log(e.target.value)
        this.setState({ dimension: e.target.value });
    }

    handleAmbientTemperature(e) {
        //console.log(e.target.value)
        this.setState({ ambientTemperature: e.target.value });
    }
    handleSiteElevation(e) {
        //console.log(e.target.value)
        this.setState({ siteElevation: e.target.value });

    }
    handlesiteTopologyTerrainMapping(e) {
        //console.log(e.target.value)
        this.setState({ siteTopologyTerrainMapping: e.target.value });
    }
    handleactivePassive(e) {
        //console.log(e.target.value)
        this.setState({ activePassive: e.target.value });
    }
    handleAccessPermissionProvidingParty(e) {
        //console.log(e.target.value)
        this.setState({ accessPermissionProvidingParty: e.target.value });
    }

    handleStatus(e) {
        //console.log(e.target.value)
        this.setState({ siteStatus: e.target.value });
    }
    handleTowerHeight(e) {
        //console.log(e.target.value)
        this.setState({ towerHeight: e.target.value });
    }
    handleIndoorOutdoor(e) {
        //console.log(e.target.value)
        this.setState({ indoorOutdoor: e.target.value });
    }
    handleSiteName(e) {
        //console.log(e.target.value)
        this.setState({ siteName: e.target.value });
    }
    handleSiteAddress(e) {
        //console.log(e.target.value)
        this.setState({ siteAddress: e.target.value });
    }
    handleTowerOwnerCategory(e) {
        //console.log(e.target.value)
        this.setState({ towerOwnerCategory: e.target.value });
    }
    handleSiteId(e) {
        //console.log(e.target.value)
        this.setState({ siteId: e.target.value });
    }
    handleYearoftheJFS(e) {
        //console.log(e)
        this.setState({ yearoftheJFS: e });
    }
    handleManualDistanceMeter(e) {
        //console.log(e)
        this.setState({ manualDistanceMeter: e });
    }
    handleTowerLegsCount(e) {
        //console.log(e)
        this.setState({ towerLegsCount: e });
    }
    handleSiteLongitude(e) {
        //console.log(e)
        this.setState({ siteLongitude: e });
    }
    handleTenants(e) {
        //console.log(e)
        this.setState({ tenants: e });
    }
    handleNoOfPhases(e) {
        //console.log(e)
        this.setState({ noOfPhases: e });
    }
    handlePowerSharedTenants(e) {
        //console.log(e)
        this.setState({ powerSharedTenants: e });
    }
    handleSiteLatitude(e) {
        //console.log(e)
        this.setState({ siteLatitude: e });
    }
    handleDistancefromRegionalOffice(e) {
        //console.log(e)
        this.setState({ distancefromRegionalOffice: e });
    }
    handleRefuelManualDistanc(e) {
        //console.log(e)
        this.setState({ refuelManualDistance: e });
    }
    getDataSiteAccessBaseData = async () => {
        var res = await getSiteAccessBaseData();
        //console.log("getSiteAccessBaseData");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                accessPermitionGroupType: res.data?.accessPermitionGroups,
            })
        }
    };
    getDataAllShelterType = async () => {
        var res = await getAllShelterType();
        //console.log("getAllShelterType");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allShelterType: res.data,
            })
        }
    };
    getDataAllGenHutType = async () => {
        var res = await getAllGenHutType();
        console.log("getAllGenHutType");
        console.log(res.data);
        if (res.success) {
            this.setState({
                GenHutType: res.data,
            })
        }
    };
    getDataAllTowerType = async () => {
        var res = await getAllTowerType();
        //console.log("getAllTowerType");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allTowerType: res.data,
            })
        }
    };
    getDataAllCoolingMethods = async () => {
        var res = await getAllCoolingMethods();
        //console.log("getAllCoolingMethods");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allCoolingMethods: res.data,
            })
        }
    };
    getDataAllTowerCategory = async () => {
        var res = await getAllTowerCategory();
        //console.log("getAllTowerCategory");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allTowerCategory: res.data,
            })
        }
    };
    getDataAllEntity = async () => {
        var res = await getAllEntity();
        //console.log("getAllEntity");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allSiteEntity: res.data,
            })
        }
    };
    getDataAllSiteOwner = async () => {
        var res = await getAllSiteOwner();
        //console.log("getAllSiteOwner");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allSiteOwner: res.data,
            })
        }
    };

    getDataAllSiteCategory = async () => {
        var res = await getAllSiteCategory();
        //console.log("getAllSiteCategory");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allSiteCategory: res.data,
            })
        }
    };
    getDataAllEnergyCategory = async () => {
        var res = await getAllEnergyCategory();
        //console.log("getAllEnergyCategory");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allEnergyCategory: res.data,
            })
        }
    };

    getDataAllDistrict = async () => {
        var res = await getAllDistrict();
        //console.log("getAllDistrict");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allDistrict: res.data,
            })
        }
    };
    getDataAllSiteType = async () => {
        var res = await getAllSiteType();
        //console.log("getAllSiteType");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allSiteType: res.data,
            })
        }
    };
    onChangeSiteOnAirDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            siteOnAirDate: dateString,
        });
    };
    onChangeEnergyCategory = (value) => {
        //console.log("onChangeEnergyCategory");
        //console.log(value);
        this.setState({
            selectedEnergyCategory: value,
            energyCategory: {
                "id": value
            },

        })
    };
    onChangeSiteCategory = (value) => {
        //console.log("onChangeSiteCategory");
        //console.log(value);
        this.setState({
            selectedSiteCategoryId: value,
            siteCategory: {
                "id": value
            },

        })
    };
    onChangeDistrict = (value) => {
        //console.log("onChangeDistrict");
        //console.log(value);
        this.setState({
            selectedDistrictId: value,
            district: {
                "id": value
            },

        })
    };
    onChangeInDoorOutDoor = (value) => {
        //console.log("onChangeManualTransport");
        //console.log(value);
        this.setState({
            indoorOutdoorid: value,
            indoorOutdoorType: {
                "id": value
            },


        })
    };
    onChangeDepot = (value) => {
        //console.log("onChangeSiteType");
        //console.log(value);
        this.setState({
            selectedDepotTypeId: value,
            depotType: {
                "id": value
            },

        })
    };
    onChangePGPossibility = (value) => {
        //console.log("onChangePGPossibility");
        //console.log(value);
        this.setState({
            pgPossibility: value,


        })
    };
    onChangeisAccessPermissionRequired = (value) => {
        //console.log("onChangeisAccessPermissionRequired");
        //console.log(value);
        this.setState({
            isAccessPermissionRequired: parseInt(value),


        })
    };
    onChangeaccessPermitionGroup = (value) => {
        //console.log("onChangeaccessPermitionGroup");
        //console.log(value);
        this.setState({
            accessPermitionGroupId: value,
            accessPermitionGroup: {
                "id": value
            },

        })
    };
    onChangeisSiteKeyRequired = (value) => {
        //console.log("onChangeisSiteKeyRequired");
        //console.log(value);
        this.setState({
            isSiteKeyRequired: parseInt(value),


        })
    };
    onportablegenConnectingSetupAvailability = (value) => {
        //console.log("onChangeisSiteKeyRequired");
        //console.log(value);
        this.setState({
            portablegenConnectingSetupAvailability: parseInt(value),


        })
    };
    onChangeManualTransport = (value) => {
        //console.log("onChangeManualTransport");
        //console.log(value);
        this.setState({
            manualTransport: value,


        })
    };
    onChangeisSpaceAvailableNewGen = (value) => {
        //console.log("onChangeisSpaceAvailableNewGen");
        //console.log(value);
        this.setState({
            isSpaceAvailableNewGen: value,


        })
    };
    onChangeisidlegeneratorhutavaialability = (value) => {
        //console.log("onChangeisidlegeneratorhutavaialability");
        //console.log(value);
        this.setState({
            isIdleGeneratorHutAvaialable: value,


        })
    };
    onChangeisStandbyGeneratorAvailable = (value) => {
        //console.log("onChangeisStandbyGeneratorAvailable");
        //console.log(value);
        this.setState({
            isStandbyGeneratorAvailable: parseInt(value),


        })
    };
    onChangeShelterType = (value) => {
        //console.log("onChangeShelterType");
        //console.log(value);
        this.setState({
            shelterTypeId: value,
            shelterType: {
                "id": value
            },

        })
    };
    onChangeCoolingMethods = (value) => {
        //console.log("onChangeCoolingMethods");
        //console.log(value);
        this.setState({
            coolingMethodsTypeId: value,
            coolingMethods: {
                "id": value
            },

        })
    };
    onChangeGeneratorHut1Typ = (value) => {
        //console.log("onChangeTowerType");
        //console.log(value);
        this.setState({
            selectedGeneratorHut1Typ: value,
            generatorHut1Typ: {
                "id": value
            },

        })
    };
    onChangeGeneratorHut2Typ = (value) => {
        //console.log("onChangeTowerType");
        //console.log(value);
        this.setState({
            selectedGeneratorHut2Typ: value,
            generatorHut2Typ: {
                "id": value
            },

        })
    };
    onChangeTowerType = (value) => {
        //console.log("onChangeTowerType");
        //console.log(value);
        this.setState({
            selectedTowerTypeId: value,
            towerType: {
                "id": value
            },

        })
    };
    onChangeTowerCategory = (value) => {
        //console.log("onChangeTowerCategory");
        //console.log(value);
        this.setState({
            selectedtowerCategoryId: value,
            towerCategory: {
                "id": value
            },

        })
    };
    onChangeSiteEntity = (value) => {
        //console.log("onChangeSiteEntity");
        //console.log(value);
        this.setState({
            selectedSiteEntityId: value,
            siteEntity: {
                "id": value
            },

        })
    };
    onChangeSiteOwner = (value) => {
        //console.log("onChangeSiteOwner");
        //console.log(value);
        this.setState({
            selectedSiteOwnerId: value,
            siteOwner: {
                "id": value
            },

        })
    };
    onChangeSiteType = (value) => {
        //console.log("onChangeSiteType");
        //console.log(value);
        this.setState({
            selectedSiteTypeId: value,
            siteType: {
                "id": value
            },

        })
    };
    AllDataSubmit = async () => {
        if (this.validator.allValid()) {
            var dataSet;

            dataSet = {
                "depot": this.state.depotType,
                "id": this.state.ObjectId,
                "user": { "id": localStorage.getItem("userId") },
                "district": this.state.district,
                "energyCategory": this.state.energyCategory,
                "siteType": this.state.siteType,
                "siteCategory": this.state.siteCategory,
                "coolingMethods": {
                    "id": 1
                },
                "availableGeneratorHut1Type": this.state.generatorHut1Typ,
                "availableGeneratorHut2Type": this.state.generatorHut2Typ,
                "siteOwner": this.state.siteOwner,
                "shelterType": this.state.shelterType,
                "towerType": this.state.towerType,
                "towerCategory": this.state.towerCategory,
                "entity": this.state.siteEntity,
                "siteId": this.state.siteId,
                "siteName": this.state.siteName,
                "status": this.state.siteStatus,
                "towerLegsCount": this.state.towerLegsCount,
                "towerHeight": this.state.towerHeight,
                "siteElevation": this.state.siteElevation,
                "indoorOutdoor": this.state.indoorOutdoorType,
                "ambientTemperature": this.state.ambientTemperature,
                "tenants": this.state.tenants,
                "powerSharedTenants": this.state.powerSharedTenants,
                "dimension": this.state.dimension,
                "freeLandAvailable": this.state.freeLandAvailable,
                "shelterSize": this.state.shelterSize,
                "pisVendor": this.state.pisVendor,
                "landAreainPerch": this.state.landAreainPerch,
                "nonShadingSpaceAvailabilityOnshelter": this.state.nonShadingSpaceAvailabilityOnshelter,
                "nonShadingSpaceAvailabilityGround": this.state.nonShadingSpaceAvailabilityGround,
                "dependentCellCount": this.state.dependentCellCount,
                //"manualDistance": "test",
                //"manualDelivery": "test",
                "travelTimeFromDepot": this.state.travelTimeFromDepot,
                "workingDaysAccessibleHours": this.state.workingDaysAccessibleHours,
                "weekendsOrHolidaysAccessibleHours": this.state.weekendsOrHolidaysAccessibleHours,
                "yearOfTheJfs": this.state.yearoftheJFS,
                "isIdleGeneratorHutAvaialable": this.state.isIdleGeneratorHutAvaialable,
                "isSpaceAvailableNewGen": this.state.isSpaceAvailableNewGen,
                "siteOnAirDate": this.state.siteOnAirDate,
                //"pgMannualDistance": "test",
                "isStandbyGeneratorAvailable": this.state.isStandbyGeneratorAvailable,
                "noOfPhases": this.state.noOfPhases,
                "manualTransport": this.state.manualTransport,
                "pgPossibility": this.state.pgPossibility,
                "towerOwnerCategory": this.state.towerOwnerCategory,
                "siteAddressList": [{ "longitude": this.state.siteLongitude, "latitude": this.state.siteLatitude, "address": this.state.siteAddress }],
                "manualDistanceMeter": this.state.manualDistanceMeter,
                "manualDistance": this.state.manualDistanceMeter,

                "refuelManualDistance": this.state.refuelManualDistance,
                "regionToSiteDistance": this.state.distancefromRegionalOffice,
                "accessPermissionProvidingParty": this.state.accessPermissionProvidingParty,
                "activePassive": this.state.activePassive,
                "siteTopologyTerrainMapping": this.state.siteTopologyTerrainMapping,
                "vehicleType": this.state.vehicleType,
                "timeRestrictedReason": this.state.timeRestrictedReason,
                "accessPermissionRequired": this.state.isAccessPermissionRequired,
                "siteKeyRequired": this.state.isSiteKeyRequired,
                "portablegenConnectingSetupAvailability": this.state.portablegenConnectingSetupAvailability,
                "accessPermitionGroup": this.state.accessPermitionGroup,

                "keyLocation": this.state.keyLocation,
                "siteOwnerNameForAccess": this.state.sOwnerName,
                "siteOwnerAddressForAccess": this.state.sOwnerAddress,

            }

            var res = await updateSite(dataSet);
            localStorage.removeItem("siteInfo");
            if (res.success) {
                //console.log("
                const { history } = this.props;
                history.push('/site/new/' + this.state.ObjectId);
            }


        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();

        }

        //console.log("All danta sub");
    }

    render() {
        const steps = [
            {
                title: 'First',
                content: 'First-content',
            },
            {
                title: 'Second',
                content: 'Second-content',
            },
            {
                title: 'Last',
                content: 'Last-content',
            },
            {
                title: 'Last',
                content: 'Last-content',
            },
            {
                title: 'Last',
                content: 'Last-content',
            },
            {
                title: 'Last',
                content: 'Last-content',
            },
            {
                title: 'Last',
                content: 'Last-content',
            },
        ];
        const next = () => {
            const current = this.state.current + 1;
            this.setState({ current });

        };
        const prev = () => {
            const current = this.state.current - 1;
            this.setState({ current });
        };
        const items = steps.map((item) => ({
            key: item.title,
            title: item.title,
        }));
        const description = 'This is a description.';
        const { Option } = Select;
        const Step = Steps.Step;
        return (
            <div class="container-fluid" >
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title"><span class="textStyles-small" style={{fontSize: 16}}>Site Update</span></h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);"><span class="textStyles-small" style={{fontSize: 12}}>{this.state.siteName}</span></a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);"><span class="textStyles-small" style={{fontSize: 12}}>Update</span></a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'white', borderRadius: 15 }}>
                    <div style={{ padding: '20px 20px 0px 20px' }}>
                    <Steps
                        current={this.state.current}
                        progressDot={(dot, { index }) => (
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '35px',
                                    height: '35px',
                                    borderRadius: '50%',
                                    backgroundColor: index === this.state.current ? '#F5E5E8' : 'white',
                                    color: 'white',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '24px',
                                        height: '24px',
                                        borderRadius: '50%',
                                        backgroundColor: index === this.state.current ? '#F5325C' : '#F5E5E8',
                                        color: 'white',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <span className='textStyles-small'
                                        style={{ color: index === this.state.current ? 'white' : 'gary' }}
                                    >{index + 1}</span>
                                </div>
                            </div>

                        )}
                        size='small'
                        labelPlacement='vertical'
                        items={[
                            {
                                title: <span className='textStyles-small' style={{fontSize: 13}}>Primary Details -1</span>,
                            },
                            {
                                title: <span className='textStyles-small' style={{fontSize: 13}}>Primary Details -2</span>,
                            },
                            {
                                title: <span className='textStyles-small' style={{fontSize: 13}}>Secondary Details</span>,
                            },
                            {
                                title: <span className='textStyles-small' style={{fontSize: 13}}>Tower Details</span>,
                            },
                            {
                                title: <span className='textStyles-small' style={{fontSize: 13}}>Distance Details</span>,
                            },
                            {
                                title: <span className='textStyles-small' style={{fontSize: 13}}>Site Address</span>,
                            },
                            {
                                title: <span className='textStyles-small' style={{fontSize: 13}}>Other Details</span>,
                            },
                        ]}
                    />
                    </div>
                    <Divider />
                    <div class="card-body" style={{ padding: '0px 30px 30px 30px' }}>
                        {this.state.current == 0 ? (
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Site Name<x style={{ color: "red" }}>*</x>
                                        </label>
                                        <Form.Item required name="site_name" tooltip="This is a required field">
                                            <Input
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                id="site_name" value={this.state.siteName} onChange={this.handleSiteName} />
                                            <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p>
                                        </Form.Item>

                                    </div>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Site ID<x style={{ color: "red" }}>*</x>
                                        </label>
                                        <Form.Item required name="site_id" tooltip="This is a required field">
                                            <Input
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                id="site_id" value={this.state.siteId} onChange={this.handleSiteId} />
                                            <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('site_id', this.state.siteId, 'required')}</p>

                                        </Form.Item>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Depot<x style={{ color: "red" }}>*</x>
                                        </label>
                                        {this.state.selectedDepotTypeId != null ? (<Select
                                            showSearch
                                            onChange={this.onChangeDepot}
                                            defaultValue={this.state.selectedDepotTypeId}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {this.state.depot_list.map((item, index) => {
                                                return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                            })}

                                        </Select>) : ("")}

                                        <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('depot', this.state.selectedDepotTypeId, 'required')}</p>

                                    </div>


                                </div>

                                <div className='row'>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            District<x style={{ color: "red" }}>*</x>
                                        </label>
                                        {this.state.selectedDistrictId != null ? (<Select
                                            showSearch
                                            onChange={this.onChangeDistrict}
                                            defaultValue={this.state.selectedDistrictId}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {this.state.allDistrict.map((item, index) => {
                                                return <Option value={item.id}>{item.nameEn}</Option>;
                                            })}
                                        </Select>) : ("")}
                                        <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('district', this.state.selectedDistrictId, 'required')}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Energy Category<x style={{ color: "red" }}>*</x>
                                        </label>
                                        {this.state.selectedEnergyCategory != null ? (<Select
                                            showSearch
                                            onChange={this.onChangeEnergyCategory}
                                            defaultValue={this.state.selectedEnergyCategory}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {this.state.allEnergyCategory.map((item, index) => {
                                                return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                            })}

                                        </Select>) : ("")}

                                        <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('energy category', this.state.selectedEnergyCategory, 'required')}</p>

                                    </div>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Site Category<x style={{ color: "red" }}>*</x>
                                        </label>
                                        {this.state.selectedSiteCategoryId != null ? (<Select
                                            showSearch
                                            onChange={this.onChangeSiteCategory}
                                            defaultValue={this.state.selectedSiteCategoryId}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {this.state.allSiteCategory.map((item, index) => {
                                                return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                            })}
                                        </Select>) : ('')}
                                        <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('Site Category', this.state.selectedSiteCategoryId, 'required')}</p>
                                    </div>
                                </div>
                            </div>
                        ) : ('')}
                        {this.state.current == 1 ? (
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Standby Generator Available<x style={{ color: "red" }}>*</x>
                                        </label>
                                        {this.state.isStandbyGeneratorAvailable != null ? (<Select
                                            showSearch
                                            onChange={this.onChangeisStandbyGeneratorAvailable}
                                            defaultValue={(this.state.isStandbyGeneratorAvailable).toString()}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            <Option value='1' className='textStyles-small'>Yes</Option>
                                            <Option value='0' className='textStyles-small'>No</Option>

                                        </Select>) : ("")}

                                        <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('Standby Generator Available', this.state.isStandbyGeneratorAvailable, 'required')}</p>

                                    </div>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            PG Possibility<x style={{ color: "red" }}>*</x>
                                        </label>
                                        {this.state.pgPossibility != null ? (<Select
                                            showSearch
                                            onChange={this.onChangePGPossibility}
                                            defaultValue={this.state.pgPossibility}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            <Option value='Yes' className='textStyles-small'>Yes</Option>
                                            <Option value='No' className='textStyles-small'>No</Option>
                                        </Select>) : ("")}
                                        <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('PG Possibility', this.state.pgPossibility, 'required')}</p>
                                    </div>

                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            No Of Phases <x style={{ color: "red" }}>*</x>
                                        </label>
                                        <Form.Item>
                                            <InputNumber
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                value={this.state.noOfPhases} id="siteOnAirDate" onChange={this.handleNoOfPhases} />
                                            <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('No Of Phases', this.state.noOfPhases, 'required')}</p>

                                        </Form.Item>

                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Shelter Type <x style={{ color: "red" }}>*</x>
                                        </label>
                                        {this.state.shelterTypeId != null ? (<Select
                                            showSearch
                                            onChange={this.onChangeShelterType}
                                            defaultValue={this.state.shelterTypeId}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {this.state.allShelterType.map((item, index) => {
                                                return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                            })}
                                        </Select>) : ("")}
                                        <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('Shelter Type', this.state.shelterTypeId, 'required')}</p>


                                    </div>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Access Permission Group
                                        </label>
                                        {this.state.accessPermitionGroupId != null ? (<Select
                                            showSearch
                                            onChange={this.onChangeaccessPermitionGroup}
                                            defaultValue={this.state.accessPermitionGroupId}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {this.state.accessPermitionGroupType.map((item, index) => {
                                                return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                            })}
                                        </Select>) : ("")}
                                    </div>
                                </div>
                            </div>
                        ) : ('')}
                        {this.state.current == 2 ? (
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Site Owner<x style={{ color: "red" }}>*</x>
                                        </label>
                                        {this.state.selectedSiteOwnerId != null ? (<Select
                                            showSearch
                                            onChange={this.onChangeSiteOwner}
                                            defaultValue={this.state.selectedSiteOwnerId}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {this.state.allSiteOwner.map((item, index) => {
                                                return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                            })}

                                        </Select>) : ("")}

                                        <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message(' Site Owner', this.state.selectedSiteOwnerId, 'required')}</p>

                                    </div>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Site Entity<x style={{ color: "red" }}>*</x>
                                        </label>
                                        {this.state.selectedSiteEntityId != null ? (<Select
                                            showSearch
                                            onChange={this.onChangeSiteEntity}
                                            defaultValue={this.state.selectedSiteEntityId}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {this.state.allSiteEntity.map((item, index) => {
                                                return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                            })}

                                        </Select>) : ("")}

                                        <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('Site Entity', this.state.selectedSiteEntityId, 'required')}</p>

                                    </div>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Site Type<x style={{ color: "red" }}>*</x>
                                        </label>
                                        {this.state.selectedSiteTypeId != null ? (<Select
                                            showSearch
                                            onChange={this.onChangeSiteType}
                                            defaultValue={this.state.selectedSiteTypeId}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {this.state.allSiteType.map((item, index) => {
                                                return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                            })}
                                        </Select>) : ("")}
                                        <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('Site Type', this.state.selectedSiteTypeId, 'required')}</p>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Site Status
                                        </label>
                                        <Form.Item >
                                            <Input id="status" style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'

                                                value={this.state.siteStatus} onChange={this.handleStatus} />
                                        </Form.Item>

                                    </div>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Site On Air Date
                                        </label>
                                        {this.state.siteOnAirDate != null ? (<DatePicker
                                            defaultValue={this.state.siteOnAirDate != '' ? (moment(this.state.siteOnAirDate)) : ("")}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'

                                            onChange={this.onChangeSiteOnAirDate}
                                        />) : ('')}


                                    </div>
                                    <div className="col-md-4">
                                        <label className="textStyles-small" for="depot">
                                            Site Elevation
                                        </label>
                                        <Form.Item>
                                            <Input id="site_elevation"
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                value={this.state.siteElevation} onChange={this.handleSiteElevation} />
                                        </Form.Item>

                                    </div>

                                </div>
                            </div>
                        ) : ('')}
                        {this.state.current == 3 ? (
                            <div className='row'> <div className='row'>
                                <div className="col-md-4">
                                    <label className="textStyles-small" for="depot">
                                        Tower Category<x style={{ color: "red" }}>*</x>
                                    </label>
                                    {this.state.selectedtowerCategoryId != null ? (<Select
                                        showSearch
                                        onChange={this.onChangeTowerCategory}
                                        defaultValue={this.state.selectedtowerCategoryId}
                                        style={{ width: "100%", height: '34px' }}
                                        className='borderedSelect'
                                        bordered={false}
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {this.state.allTowerCategory.map((item, index) => {
                                            return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                        })}

                                    </Select>) : ("")}


                                </div>
                                <div className="col-md-4">
                                    <label className='textStyles-small' for="depot">
                                        Tower Type <x style={{ color: "red" }}>*</x>
                                    </label>
                                    {this.state.selectedTowerTypeId != null ? (<Select
                                        showSearch
                                        onChange={this.onChangeTowerType}
                                        defaultValue={this.state.selectedTowerTypeId}
                                        style={{ width: "100%", height: '34px' }}
                                        className='borderedSelect'
                                        bordered={false}
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {this.state.allTowerType.map((item, index) => {
                                            return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                        })}

                                    </Select>) : ("")}


                                </div>
                                <div className="col-md-4">
                                    <label className='textStyles-small' for="depot">
                                        Tower Owner Category<x style={{ color: "red" }}>*</x>
                                    </label>
                                    <Form.Item >
                                        <Input id="towerOwnerCategory"
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            value={this.state.towerOwnerCategory} onChange={this.handleTowerOwnerCategory} />
                                    </Form.Item>

                                </div>

                            </div>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Tower Height
                                        </label>
                                        <Form.Item >
                                            <Input id="tower_height"
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                value={this.state.towerHeight} onChange={this.handleTowerHeight} />
                                        </Form.Item>

                                    </div>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Tower Legs Count
                                        </label>
                                        <Form.Item >
                                            <InputNumber style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                value={this.state.towerLegsCount} id="tower_legs_count" onChange={this.handleTowerLegsCount} />
                                        </Form.Item>

                                    </div>
                                </div>
                            </div>
                        ) : ('')}
                        {this.state.current == 4 ? (
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            PG Manual Distance
                                        </label>
                                        <Form.Item>
                                            <InputNumber style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                value={this.state.manualDistanceMeter} id="manualDistanceMeter" onChange={this.handleManualDistanceMeter} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Refuel Manual Distance
                                        </label>
                                        <Form.Item >
                                            <InputNumber style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                value={this.state.refuelManualDistance} id="refuelManualDistance" onChange={this.handleRefuelManualDistanc} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Distance from regional office
                                        </label>
                                        <Form.Item >
                                            <InputNumber style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                value={this.state.distancefromRegionalOffice} id="regionToSiteDistance" onChange={this.handleDistancefromRegionalOffice} />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Manual Transport
                                        </label>
                                        {this.state.manualTransport != null ? (<Select
                                            showSearch
                                            onChange={this.onChangeManualTransport}
                                            defaultValue={this.state.manualTransport}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            <Option value='Yes' className='textStyles-small'>Yes</Option>
                                            <Option value='No' className='textStyles-small'>No</Option>

                                        </Select>) : ("")}

                                    </div>
                                </div>
                            </div>
                        ) : ('')}
                        {this.state.current == 5 ? (
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Longitude <x style={{ color: "red" }}>*</x>
                                        </label>
                                        <Form.Item >
                                            <InputNumber style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                value={this.state.siteLongitude} id="siteLongitude" onChange={this.handleSiteLongitude} />
                                        </Form.Item>

                                    </div>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Latitude <x style={{ color: "red" }}>*</x>
                                        </label>
                                        <Form.Item >
                                            <InputNumber style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                value={this.state.siteLatitude} id="siteLatitude" onChange={this.handleSiteLatitude} />
                                        </Form.Item>

                                    </div>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Address <x style={{ color: "red" }}>*</x>
                                        </label>
                                        <Form.Item>
                                            <Input style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                id="siteAddress" value={this.state.siteAddress} onChange={this.handleSiteAddress} />
                                        </Form.Item>
                                    </div>

                                </div>
                            </div>
                        ) : ('')}
                        {this.state.current == 6 ? (
                            <div className='row'>
                                <div className='row'>
                                    <div className='row'>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Available Generator Hut 1 Type <x style={{ color: "red" }}>*</x>
                                            </label>
                                            {this.state.selectedGeneratorHut1Typ != null ? (<Select
                                                showSearch
                                                onChange={this.onChangeGeneratorHut1Typ}
                                                defaultValue={this.state.selectedGeneratorHut1Typ}
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                bordered={false}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {this.state.GenHutType.map((item, index) => {
                                                    return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                                })}

                                            </Select>) : (<Select
                                                showSearch
                                                onChange={this.onChangeGeneratorHut1Typ}
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                bordered={false}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {this.state.GenHutType.map((item, index) => {
                                                    return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                                })}

                                            </Select>)}
                                            <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('Available_Generator_Hut_1_Type', this.state.selectedGeneratorHut1Typ, 'required')}</p>
                                            <br />
                                        </div>

                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Available Generator Hut 2 Type <x style={{ color: "red" }}>*</x>
                                            </label>
                                            {this.state.selectedGeneratorHut2Typ != null ? (<Select
                                                showSearch
                                                onChange={this.onChangeGeneratorHut2Typ}
                                                defaultValue={this.state.selectedGeneratorHut2Typ}
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                bordered={false}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {this.state.GenHutType.map((item, index) => {
                                                    return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                                })}

                                            </Select>) : (<Select
                                                showSearch
                                                onChange={this.onChangeGeneratorHut2Typ} style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                bordered={false}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {this.state.GenHutType.map((item, index) => {
                                                    return <Option className='textStyles-small' value={item.id}>{item.name}</Option>;
                                                })}

                                            </Select>)}
                                            <p style={{ color: "#f5325c" }} className='textStyles-small'>{this.validator.message('Available_Generator_Hut_2_Type', this.state.selectedGeneratorHut2Typ, 'required')}</p>
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            No. of tenants
                                        </label>
                                        <Form.Item >
                                            {/* <InputNumber style={{ width: '100%' }} value={this.state.tenants} id="tenants" onChange={this.handleTenants} /> */}
                                            <Select
                                                defaultValue={this.state?.tenants}
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                bordered={false}
                                                onChange={(e) => {
                                                    this.handleTenants(e);
                                                }}>
                                                {this.state.dropdownDataSet.noOftenants?.map((x) => {
                                                    return <Option className='textStyles-small' value={x}>{x}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Power Shared Tenants
                                        </label>
                                        <Form.Item >
                                            <InputNumber style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                value={this.state.powerSharedTenants} id="powerSharedTenants" onChange={this.handlePowerSharedTenants} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Indoor Outdoor
                                        </label>
                                        {this.state.indoorOutdoorid != null ? (<Select
                                            showSearch
                                            onChange={this.onChangeInDoorOutDoor}
                                            defaultValue={this.state.indoorOutdoorid.toString()}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            <Option value='1' className='textStyles-small'>Indoor</Option>
                                            <Option value='2' className='textStyles-small'>Outdoor</Option>
                                            <Option value='3' className='textStyles-small'>N/A</Option>
                                            <Option value='4' className='textStyles-small'>Lamp Pole</Option>
                                        </Select>) : ("")}
                                        {this.state.indoorOutdoorid == null ? (<Select
                                            showSearch
                                            onChange={this.onChangeInDoorOutDoor}
                                            style={{ width: "100%", height: '34px' }}
                                            className='borderedSelect'
                                            bordered={false}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            <Option value='1' className='textStyles-small'>Indoor</Option>
                                            <Option value='2' className='textStyles-small'>Outdoor</Option>
                                            <Option value='3' className='textStyles-small'>N/A</Option>
                                            <Option value='4' className='textStyles-small'>Lamp Pole</Option>
                                        </Select>) : ("")}
                                    </div>


                                </div>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Ambient Temperature
                                        </label>
                                        <Form.Item >
                                            <Input style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                id="ambientTemperature" value={this.state.ambientTemperature} onChange={this.handleAmbientTemperature} />
                                        </Form.Item>

                                    </div>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Dimension
                                        </label>
                                        <Form.Item >
                                            <Input
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'

                                                id="dimension" value={this.state.dimension} onChange={this.handleDimension} />
                                        </Form.Item>


                                    </div>
                                    <div className="col-md-4">
                                        <label className='textStyles-small' for="depot">
                                            Free Land Available
                                        </label>
                                        <Form.Item>
                                            <Input style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                id="freeLandAvailable" value={this.state.freeLandAvailable} onChange={this.handleFreeLandAvailable} />
                                        </Form.Item>

                                    </div>
                                    <div className='row'>


                                        <div className="col-md-4">

                                            <label className='textStyles-small' for="depot">
                                                Non Shading Space Availability Onshelter
                                            </label>
                                            <Form.Item>
                                                <Input style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="nonShadingSpaceAvailabilityOnshelter" value={this.state.nonShadingSpaceAvailabilityOnshelter} onChange={this.handleNonShadingSpaceAvailabilityOnshelter} />
                                            </Form.Item>




                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Non Shading Space Availability Ground
                                            </label>
                                            <Form.Item >
                                                <Input style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="freeLanonShadingSpaceAvailabilityGroundndAvailable" value={this.state.nonShadingSpaceAvailabilityGround} onChange={this.handleNonShadingSpaceAvailabilityGround} />
                                            </Form.Item>

                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Dependent CellCount
                                            </label>
                                            <Form.Item >
                                                <InputNumber style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    value={this.state.dependentCellCount} id="dependentCellCount" onChange={this.handleDependentCellCount} />
                                            </Form.Item>



                                        </div>


                                    </div>
                                    <div className='row'>


                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Travel Time From Depot
                                            </label>
                                            <Form.Item>
                                                <Input style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="travelTimeFromDepot" value={this.state.travelTimeFromDepot} onChange={this.handleTravelTimeFromDepot} />
                                            </Form.Item>

                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Idle Generator Hut Avaialability
                                            </label>
                                            {this.state.isIdleGeneratorHutAvaialable != null ? (<Select
                                                showSearch
                                                onChange={this.onChangeisidlegeneratorhutavaialability}
                                                defaultValue={(this.state.isIdleGeneratorHutAvaialable).toString()}
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                bordered={false}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                <Option value='1' className='textStyles-small'>Yes</Option>
                                                <Option value='0' className='textStyles-small'>No</Option>

                                            </Select>) : ("")}



                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Space Available New Gen
                                            </label>
                                            {this.state.isSpaceAvailableNewGen != null ? (<Select
                                                showSearch
                                                onChange={this.onChangeisSpaceAvailableNewGen}
                                                defaultValue={(this.state.isSpaceAvailableNewGen).toString()}
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                bordered={false}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                <Option value='1' className='textStyles-small'>Yes</Option>
                                                <Option value='0' className='textStyles-small'>No</Option>
                                            </Select>) : ("")}
                                        </div>
                                    </div>
                                    <div className='row'>


                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Working Days Accessible Hours
                                            </label>
                                            <Form.Item >
                                                <Input style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="workingDaysAccessibleHours" value={this.state.workingDaysAccessibleHours} onChange={this.handleWorkingDaysAccessibleHours} />
                                            </Form.Item>
                                            {/* <label className="form-label" for="depot">
                                            Manual Delivery
                                        </label>
                                        <Form.Item required name="manualDelivery" tooltip="This is a required field">
                                            <Input placeholder="manual delivery" id="manualDelivery" />
                                        </Form.Item> */}
                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Weekends Or Holidays Accessible Hours
                                            </label>
                                            <Form.Item >
                                                <Input style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="weekendsOrHolidaysAccessibleHours" value={this.state.weekendsOrHolidaysAccessibleHours} onChange={this.handleWeekendsOrHolidaysAccessibleHours} />
                                            </Form.Item>
                                            {/* <label className="form-label" for="depot">
                                            User
                                        </label>
                                        <Select
                                            showSearch
                                            //   onChange={this.onChangeSite}

                                            style={{ width: "100%" }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >

                                        </Select> */}
                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Year of the JFS
                                            </label>
                                            <Form.Item >
                                                <InputNumber style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    value={this.state.yearoftheJFS} id="yearoftheJFS" onChange={this.handleYearoftheJFS} />
                                            </Form.Item>
                                        </div>

                                    </div>
                                    <div className='row'>


                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Access Permission Providing Party
                                            </label>
                                            <Form.Item >
                                                <Input style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="accessPermissionProvidingParty" value={this.state.accessPermissionProvidingParty} onChange={this.handleAccessPermissionProvidingParty} />
                                            </Form.Item>

                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Active Passive
                                            </label>
                                            <Form.Item>
                                                <Input
                                                    style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="activePassive" value={this.state.activePassive} onChange={this.handleactivePassive} />
                                            </Form.Item>

                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Site Topology Terrain Mapping
                                            </label>
                                            <Form.Item >
                                                <Input style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    value={this.state.siteTopologyTerrainMapping} id="siteTopologyTerrainMapping" onChange={this.handlesiteTopologyTerrainMapping} />
                                            </Form.Item>
                                        </div>

                                    </div>
                                    <div className='row'>


                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Vehicle Type
                                            </label>
                                            <Form.Item>
                                                <Input style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="vehicleType" value={this.state.vehicleType} onChange={this.handlevehicleType} />
                                            </Form.Item>

                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Time Restricted Reason
                                            </label>
                                            <Form.Item >
                                                <Input
                                                    style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="timeRestrictedReason" value={this.state.timeRestrictedReason} onChange={this.handletimeRestrictedReason} />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Access Permission Required
                                            </label>
                                            {this.state.isAccessPermissionRequired != null ? (<Select
                                                showSearch
                                                onChange={this.onChangeisAccessPermissionRequired}
                                                defaultValue={(this.state.isAccessPermissionRequired).toString()}
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                bordered={false}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                <Option value='1' className='textStyles-small'>Yes</Option>
                                                <Option value='0' className='textStyles-small'>No</Option>

                                            </Select>) : ("")}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Site Key Required
                                            </label>
                                            {this.state.isSiteKeyRequired != null ? (<Select
                                                showSearch
                                                onChange={this.onChangeisSiteKeyRequired}
                                                defaultValue={(this.state.isSiteKeyRequired).toString()}
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                bordered={false}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                <Option value='1' className='textStyles-small'>Yes</Option>
                                                <Option value='0' className='textStyles-small'>No</Option>

                                            </Select>) : ("")}


                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Portable Gen Connecting Setup Availability
                                            </label>

                                            {this.state.portablegenConnectingSetupAvailability != null ? (<Select
                                                showSearch
                                                onChange={this.onportablegenConnectingSetupAvailability}
                                                defaultValue={(this.state.portablegenConnectingSetupAvailability).toString()}
                                                style={{ width: "100%", height: '34px' }}
                                                className='borderedSelect'
                                                bordered={false}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                <Option value='1' className='textStyles-small'>Yes</Option>
                                                <Option value='0' className='textStyles-small'>No</Option>

                                            </Select>) : ('')}

                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                PIS vendor
                                            </label>
                                            <Form.Item >
                                                <Input
                                                    style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="pisVendor" onChange={this.handlePISvendor} value={this.state.pisVendor} />


                                            </Form.Item>

                                        </div>
                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Land Areain Perch
                                            </label>
                                            <Form.Item >
                                                <InputNumber style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="landAreainPerch" onChange={this.handlelandAreainPerch} value={this.state.landAreainPerch} />
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Site Key Location
                                            </label>
                                            <Form.Item >
                                                <Input style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="keyLocation" value={this.state.keyLocation} onChange={this.handleKeyLocation} />
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Site Owner Name
                                            </label>
                                            <Form.Item >
                                                <Input style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="sOwnerName" onChange={this.handleSOwnerName} value={this.state.sOwnerName} />
                                            </Form.Item>
                                        </div>

                                        <div className="col-md-4">
                                            <label className='textStyles-small' for="depot">
                                                Site Owner Address
                                            </label>
                                            <Form.Item >
                                                <Input style={{ width: "100%", height: '34px' }}
                                                    className='borderedSelect'
                                                    id="sOwnerAddress" onChange={this.handleSOwnerAddress}
                                                    value={this.state.sOwnerAddress} />
                                            </Form.Item>
                                        </div>

                                    </div>
                                    <div className='row'>

                                    </div>
                                </div>

                            </div>
                        ) : ('')}
                        <br></br>
                        <div className='row'>
                            <div className="steps-action d-flex justify-content-end">
                                {this.state.current > 0 && (
                                    <Button
                                        style={{ borderRadius: '10px', width: '100px', height: '34px', marginLeft: 5 }}
                                        onClick={() => prev()}
                                    >
                                        <span style={{ color: 'black' }} className='textStyles-small'>Previous</span>
                                    </Button>
                                )}
                                {this.state.current < steps.length - 1 && (
                                    <Button
                                        style={{ borderColor: '#F5E5E8', backgroundColor: '#F5E5E8', borderRadius: '10px', width: '100px', height: '34px', marginLeft: 5 }}
                                        onClick={() => next()}
                                    >
                                        <span style={{ color: '#B9183A' }} className='textStyles-small'>Next</span>
                                    </Button>
                                )}
                                {this.state.current === steps.length - 1 && (
                                    <>
                                        <Button
                                            type="primary"
                                            style={{ borderColor: '#F5E5E8', backgroundColor: '#F5E5E8', borderRadius: '10px', width: '100px', height: '34px', marginLeft: 5 }}
                                            onClick={() => {
                                                const { history } = this.props;
                                                history.push('/site/all/new');
                                            }}
                                        >
                                            <span style={{ color: '#B9183A' }} className='textStyles-small'>Cancel</span>
                                        </Button>
                                        <Button
                                            type="primary"
                                            style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '10px', width: '100px', height: '34px', marginLeft: 5 }}
                                            onClick={() => this.AllDataSubmit()}
                                        >
                                            <span style={{ color: 'white' }} className='textStyles-small'>Update</span>
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        );
    }
}
function mapStateToProps(state) {
    //console.log("state", state);
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })(SiteNewEdit));