import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
  Table,
  Empty,
  Modal,
  Tag
} from "antd";
import { data_set, gen_models } from "./utils/relations";
import { CSVLink, CSVDownload } from "react-csv";
import {
  CloseCircleOutlined,
  CopyOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  FilterOutlined,
  HistoryOutlined,
  HomeOutlined,
  SendOutlined,
  SaveOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  getPowerDbFilterData,
  getAllQueryTemplates,
  getQueryTemplatesCreate,
  getQueryTemplatesDelete,
  getAllDropdowns,
} from "../../api/index";
import { compose } from "redux";
import { headers } from "../../api/api.config";
import { get } from "../../api/api.client";
import '../master-data/style.css';
import moment from "moment";

//import { ToastContainer, toast } from 'material-react-toastify';
//import 'material-react-toastify/dist/ReactToastify.css';

const { Option } = Select;

const MapQueryBuilder = () => {
  let firstTable = "";
  let relation = "";
  let firstTablePrimaryStatus = false;
  var tableList = [];
  const [formItems, setFormItems] = useState([
    {
      field_name: "",
      label: "",
      operator: "",
      selectedOperator: "",
      value: "",
      query_name: "",
      extra_fields: [],
      detail_view: false,
    },
  ]);
  const [sql, setSql] = useState("");
  const [operatorOptions, setOperatorOptions] = useState({});
  const [filterList, setFilterList] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [generatedModels, setGeneratedModels] = useState([]);
  const [boolState, setBoolState] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filteredModels, setFilteredModels] = useState(generatedModels);
  const [messageApi, contextHolder] = message.useMessage();
  const [savedData, setSavedData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fieldValidator, setFieldValidator] = useState("");
  const [isRapidTemplate, setIsRapidTemplate] = useState(false);
  const [templateItems, setTemplateItems] = useState([
    {
      templateName: "",
      templateDescription: "",
      headers: "",
      queryBody: "",
      createdAt: "",
      createdBy: "",
      id: "",
    },
  ]);
  const [showRapidFilterSection, setShowRapidFilterSection] = useState(false);
  const [showFilterSection, setShowFilterSection] = useState(true);
  const [showNormalFilterSection, setShowNormalFilterSection] = useState(true);
  const [fieldNames, setFieldNames] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const handleFilterSection = (query, headers) => {
    resetTableSection();
    setShowNormalFilterSection(!showNormalFilterSection);
    setShowFilterSection(!showFilterSection);
    setShowRapidFilterSection(true);
    setFieldNames(headers);
    setSql(query);
  };
  const [assetsTypes, setAssetsTypes] = useState({
    dcduTypes: [],
    cabinetTypes: [],
  });

  const date = new Date();

  const handleSearch = (value) => {
    console.log(savedData);
    const lowercasedValue = value.toLowerCase();
    const filteredItems = savedData.filter((item) =>
      item.templateName.toLowerCase().includes(lowercasedValue)
    );
    setFilteredData(filteredItems);
    setSearchText(value);
  };

  // Load All Data When The Page Loads
  useEffect(() => {
    getAssetsTypes();
  }, []);

  useEffect(() => {
    console.log(assetsTypes);
  }, [assetsTypes]);

  const getAssetsTypes = async () => {
    var res = await getAllDropdowns();
    if (res.success) {
      setAssetsTypes({
        dcduTypes: res.data["Access Technologies"]["GSM"],
        cabinetTypes: res.data["Access Technologies"]["GSM"],
      });
    } else {
      message.warning("Some Data Can't Be Loaded");
    }
    console.log("===============================+++++++++++++++++++++++++++==============================");
    console.log(assetsTypes);
  };

  const createTemplate = async () => {
    const fieldNames = filterList.map((item) => item.display_name);
    const requestBody = {
      createdBy: localStorage.getItem("userId"),
      templateName: templateName+"_mapQuery",
      templateDescription: "SQL Query Template Description",
      headers: fieldNames.join(","),
      queryBody: sql,
    };
    try {
      var res = await getQueryTemplatesCreate(requestBody);
      console.log(res);
      if (res.success) {
        if (res.data.status === "Error") {
          notification.error({
            message: "Template Creation Failed",
            description: res.data.message,
          });
        } else {
          console.log("Template created successfully:");
          notification.success({
            message: <span className='textStyles-small'>Template Created Successfully</span>,
            description:
              <span className='textStyles-small'>Template Created Successfully, You can view the template in the template list, And you can use it in the future</span>,
          });
        }
      } else {
        notification.error({
          message: <span className='textStyles-small'>Template Creation Failed</span>,
          description: res?.data?.message,
        });
      }
    } catch (error) {
      notification.error({
        message: <span className='textStyles-small'>Template Creation Failed</span>,
        description: error.message,
      });
    }
  };

  // Function to retrieve all templates from the database
  const retriveAllTemplates = async () => {
    var res = await getAllQueryTemplates(localStorage.getItem("userId"));

    if (res.success) {
      if (res.data.status === "Error") {
        notification.error({
          message: <span className='textStyles-small'>Template Retrieval Failed</span>,
          description: res.data.message,
        });
      } else {
        // get only templates with `templateName` ending with `_mapQuery`
        const filteredTemplates = res.data.value.filter((template) => template.templateName.endsWith("_mapQuery"));
        setSavedData(filteredTemplates);
      }
    } else {
      notification.error({
        message: <span className='textStyles-small'>Template Retrieval Failed</span>,
      });
    }
  };

  // Remove Template From The List and From The Database
  const deleteTemplate = async (templateId) => {
    try {
      var res = await getQueryTemplatesDelete(templateId);

      console.log(res);
      if (res.success) {
        if (res.data.status === "Error") {
          notification.error({
            message: <span className='textStyles-small'>Template Deletion Failed</span>,
            description: res.data.message,
          });
        } else {
          prepareData();
          notification.success({
            message: <span className='textStyles-small'>Template Deleted Successfully</span>,              
          });
        }
      } else {
        notification.error({
          message: <span className='textStyles-small'>Template Deletion Failed</span>,
          description: res?.data?.message,
        });
      }
    } catch (error) {
      notification.error({
        message: <span className='textStyles-small'>Template Deletion Failed</span>,
        description: error.message,
      });
    }
  };

  // Confirmation Modal to Delete the Template
  const showDeleteConfirmationModal = (templateId) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: <span className='textStyles-small'>Are you sure you want to delete this template?</span>,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deleteTemplate(templateId),
    });
  };

  // This function is used to show the modal and Handle Logic Behind it
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    console.log("Template Name:", templateName);
    setIsModalVisible(false);
    createTemplate();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleReload = () => {
    window.location.pathname = window.location.pathname;
  };

  const resetRapidFilterSection = () => {
    setShowRapidFilterSection(false);
    setShowFilterSection(true);
    setShowNormalFilterSection(true);

    setSql("");
    setFilterList([]);
    setApiData([]);
    setGeneratedModels([]);
    setBoolState(false);
  };

  const resetTableSection = () => {
    setApiData([]);
  };

  // That Function is used to get the operator options
  const filteredOptions = (value) => {
    var data = [];
    for (let i = 0; i < value.length; i++) data.push(data_set.fields[value[i]]);
    setFilterList(data);
  };

  // This function is used to add new form item
  const handleAddFormItem = () => {
    const newItem = {
      field_name: "",
      label: "",
      operator: "",
      value: "",
      query_name: "",
      extra_fields: [],
      detail_view: false,
      response_type: "",
      display_name: "",
    };
    setFormItems([...formItems, newItem]);
  };

  // This function is used to get the model data
  const fetchModelData = async (sql, fieldNames, targetData) => {
    console.log("IN THIS FUNCTION 01 ================================================================");
    console.log(targetData);
    if (targetData.toLowerCase() === "DCDU- Type") {
      console.log("IN THIS FUNCTION 02 ================================================================");
      const models = getAssetsTypes.dcduTypes.map((name, index) => ({
        id: (index + 1).toString(),
        model: name,
      }));
      setGeneratedModels(models);
    } else {
      const formData = new FormData();
      formData.append("query", sql);
      formData.append("headers", fieldNames);
      var generatedModels = [];
      var res = await getPowerDbFilterData(formData);
      if (res.success) {
        if (Array.isArray(res.data)) {
          const uniqueNames = [...new Set(res.data.map((item) => item.name))];
          console.log("Unique Names: ", uniqueNames);
          const models = uniqueNames.map((name, index) => ({
            id: (index + 1).toString(),
            model: name,
          }));
          setGeneratedModels(models);
        } else {
          console.error("API Response Data is not an array");
        }
      }
    }

    //label
  };

  // This function is used to remove the form item
  const handleRemoveFormItem = (index) => {
    const updatedFormItems = formItems.filter((item, i) => i !== index);
    setFormItems(updatedFormItems);
  };

  // This function is used to get the details
  const getDetails = (index) => {
    const updatedFormItems = formItems.filter((item, i) => i === index);
    console.log("------------------------------------------------------------------------------");
    console.log(updatedFormItems);
    if (updatedFormItems.length === 1) {
      if (updatedFormItems[0].detail_view === true) {
        const execute_query = updatedFormItems[0].query_name;
        const extra_fields = updatedFormItems[0].extra_fields;
        const label = updatedFormItems[0].label;
        fetchModelData(execute_query, extra_fields, label);
      }
    } else {
      console.log("No matching item found or multiple items found.");
    }
  };

  // This function is used to handle the label change
  const handleLabelChange = (index, value) => {
    var selectedItem = data_set.fields.filter(function (el) {
      return el.display_name === value;
    });

    if (selectedItem.length == 0) {
      return;
    }

    const updatedFormItems = [...formItems];
    updatedFormItems[index].label = selectedItem[0].field_name;
    updatedFormItems[index].field_name = selectedItem[0].field_name;
    updatedFormItems[index].table_prefix = selectedItem[0].table_prefix;
    updatedFormItems[index].table_name = selectedItem[0].table_name;
    updatedFormItems[index].response_type = selectedItem[0].response_type;
    updatedFormItems[index].operator = selectedItem[0].operotors;
    updatedFormItems[index].selectedOperator = "";
    updatedFormItems[index].value = "";
    updatedFormItems[index].query_name = selectedItem[0].query_name;
    updatedFormItems[index].extra_fields = selectedItem[0].extra_fields;
    updatedFormItems[index].detail_view = selectedItem[0].detail_view;
    updatedFormItems[index].response_type = selectedItem[0].response_type;
    updatedFormItems[index].display_name = selectedItem[0].display_name;

    console.log(selectedItem[0].detail_view);

    if (selectedItem[0].detail_view === true) {
      setBoolState(true);
    }
    setFormItems(updatedFormItems);
    var data = {};
    data[value] = selectedItem[0].operotors;
    setOperatorOptions(data);
    console.log(formItems);
  };

  const handleFormItemChange = (index, key, value) => {
    const updatedFormItems = [...formItems];
    updatedFormItems[index][key] = value;
    console.log(updatedFormItems);
    setFormItems(updatedFormItems);
  };

  function isValidResponseType(inputType, inputValue) {
    // Define an array of valid response types
    const validResponseTypes = ["text", "number"];

    // Check if the inputType is a valid response type
    if (validResponseTypes.includes(inputType)) {
      // Depending on the response type, you can perform additional checks on the inputValue
      if (inputType === "text") {
        // Check if the inputValue is a string
        setFieldValidator(true);
        return typeof inputValue === "string";
      } else if (inputType === "number") {
        // Check if the inputValue is a number
        setFieldValidator(true);
        return typeof inputValue === "number";
      }
    }
    setFieldValidator(false);
    return false; // Input type is not valid or doesn't match the input value's type
  }

  const generateSQLQuery = () => {
    setApiData([]);

    let selectedItems = "";
    for (let i = 0; i < filterList.length; i++) {
      selectedItems +=
        filterList[i].table_prefix +
        "." +
        filterList[i].field_name +
        " " +
        filterList[i].display_name +
        (filterList.length > 1 && filterList.length !== i + 1 ? "," : "");
    }
    let sqlQuery = "SELECT " + selectedItems + " FROM ";

    if (filterList.length > 0 && filterList[0].field_name !== "") {
      // var selectedItem = data_set.fields.filter(function (el) {
      //     return el.field_name === filterList[0].field_name;
      // });
      firstTable = filterList.length > 1 ? "site" : filterList[0].table_name;
      tableList.push(filterList.length > 1 ? "site" : filterList[0].table_name);
      firstTablePrimaryStatus =
        filterList.length > 1 ? true : filterList[0].is_primary_table;
      let table_prefix =
        filterList.length > 1 ? "tbl_site" : filterList[0].table_prefix;
      sqlQuery += `${firstTable} ${table_prefix}`;

      for (let i = filterList.length > 1 ? 0 : 1; i < filterList.length; i++) {
        var selectedItem = data_set.fields.filter(function (el) {
          return (
            el.field_name === filterList[i].field_name &&
            el.table_name === filterList[i].table_name
          );
        });

        const tableName = selectedItem[0].table_name;
        const table_prefix = selectedItem[0].table_prefix;
        console.log(tableList);
        console.log(tableName);
        console.log(checkTableAlreadyAvailable(tableName));
        if (!checkTableAlreadyAvailable(tableName)) {
          if (selectedItem[0].is_primary_table && firstTablePrimaryStatus) {
            const joinCondition = getJoinCondition(firstTable, tableName);
            sqlQuery += ` INNER JOIN ${tableName} ${table_prefix} ON ${joinCondition}`;
          } else {
            if (
              !checkTableAlreadyAvailable(selectedItem[0].primary_table_name)
            ) {
              const primaryJoinCondition = getJoinCondition(
                firstTable,
                selectedItem[0].primary_table_name
              );
              sqlQuery += ` INNER JOIN ${selectedItem[0].primary_table_name} ${selectedItem[0].primary_table_prefix} ON ${primaryJoinCondition}`;
            }
            const joinCondition = getJoinCondition(
              selectedItem[0].primary_table_name,
              tableName
            );
            sqlQuery += ` INNER JOIN ${tableName} ${table_prefix} ON ${joinCondition}`;
          }
        }
      }
      if (formItems.length > 0 && formItems[0].field_name !== "") {
        for (let i = 0; i < formItems.length; i++) {
          const item = formItems[i];
          if (i === 0) {
            sqlQuery += ` WHERE ${item.table_prefix}.${item.label} ${item.selectedOperator}`;
            if (item.selectedOperator == "LIKE") {
              sqlQuery +=
                item.response_type == "text"
                  ? ` '%${item.value}%'`
                  : ` ${item.value}`;
            } else {
              sqlQuery +=
                item.response_type == "text"
                  ? `'${item.value}'`
                  : ` ${item.value}`;
            }
          } else {
            if (item.selectedOperator == "LIKE") {
              sqlQuery += ` AND ${item.table_prefix}.${item.label} ${item.selectedOperator} '%${item.value}%'`;
            } else {
              sqlQuery += ` AND ${item.table_prefix}.${item.label} ${item.selectedOperator} ${item.value}`;
            }
          }
        }
      }
    } else {
      if (filterList.length > 0 && filterList[0].field_name !== "") {
        sqlQuery += filterList[0].table_name;
      } else {
        sqlQuery = "";
      }
    }
    setSql(sqlQuery);
  };

  // This function is used to check the table already available
  const checkTableAlreadyAvailable = (tableName) => {
    const list = tableList;
    const check = list.filter(function (el) {
      return el === tableName;
    });

    if (check.length > 0) {
      return true;
    }
    return false;
  };

  // This function is used to copy the text
  const copyText = (text) => {
    navigator.clipboard.writeText("");
    navigator.clipboard.writeText(text);
    setGeneratedModels([]);
    setOpenDrawerDetails(false);
  };

  const getJoinCondition = (tableName, primaryTable) => {
    const count = tableList.filter(function (el) {
      return el === tableName;
    });
    if (count.length == 0) {
      tableList.push(tableName);
    }

    const primaryTableCOunt = tableList.filter(function (el) {
      return el === primaryTable;
    });
    if (primaryTableCOunt.length == 0) {
      tableList.push(primaryTable);
    }

    relation = data_set.relations.filter(function (el) {
      return el.main_table === primaryTable && el.sub_table === tableName;
    })[0];
    if (relation === undefined) {
      relation = data_set.relations.filter(function (el) {
        return el.main_table === tableName && el.sub_table === primaryTable;
      })[0];
    }

    return (
      relation.main_table_prefix +
      "." +
      relation.main_table_id +
      " = " +
      relation.sub_table_prefix +
      "." +
      relation.sub_table_id
    );
  };

  const onClose = () => {
    setOpenDrawerDetails(false);
  };

  const apiResponse = () => {
    const fieldNames = filterList.map((item) => item.display_name);
    fetchApiData1(fieldNames.join(","));
  };
  const rapidAPIResponse = () => {
    fetchApiData(fieldNames);
  };
  // Function to fetch data from the API



  const fetchApiData1 = async (fieldNames) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("query", sql);
    formData.append("headers", fieldNames);
    var res = await getPowerDbFilterData(formData);
    if (res.success) {
      setApiData(res.data);
      setLoading(false);
      notification.success({
        message: <span className='textStyles-small'>Success</span>,
        description: <span className='textStyles-small'>API request was successful.</span>,
      });
    } else {
      setLoading(false);
      notification.error({
        message: <span className='textStyles-small'>Error</span>,
        description: <span className='textStyles-small'>Error fetching data: {res.message}</span>,
      });
    }
  };

  const fetchApiData = async (fieldNames) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("query", sql);
    formData.append("headers", fieldNames);
    var res = await getPowerDbFilterData(formData);
    if (res.success) {
      setApiData(res.data);
      notification.success({
        message: <span className='textStyles-small'>Success</span>,
        description: <span className='textStyles-small'>API request was successful.</span>,
      });
    } else {
      notification.error({
        message: <span className='textStyles-small'>Error</span>,
        description: <span className='textStyles-small'>Error fetching data: {res.message}</span>,
      });
    }
  };



  // const fetchApiData = (fieldNames) => {
  //   const formData = new FormData();
  //   formData.append("query", sql);
  //   formData.append("headers", fieldNames);

  //   const requestTimestamp = new Date().toISOString(); // Get current date and time as a string

  //   fetch("https://dns.eimsky.com/common/powerSiteData/getPowerDbFilterData", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       console.log("API Response Code : ", response.status);
  //       return response
  //         .json()
  //         .then((data) => ({ data, status: response.status }));
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         console.log("API Response Data: ", response.data);
  //         setApiData(response.data); // Set the API data in the state

  //         const requestData = {
  //           query: sql,
  //           headers: fieldNames,
  //           responseCode: response.status,
  //         };

  //         if (isRequestDataAlreadyStored(requestData)) {
  //           console.log("Request data already exists in localStorage.");
  //         } else {
  //           const storageKey = requestTimestamp;
  //           localStorage.setItem(storageKey, JSON.stringify(requestData));
  //           console.log("Request data saved successfully.");
  //         }
  //         notification.success({
  //           message: "Success",
  //           description: "API request was successful.",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //       // Show an error notification
  //       notification.error({
  //         message: "Error",
  //         description: "Error fetching data: " + error.message,
  //       });
  //     });

  //   retrieveSavedData();
  // };

  // const isRequestDataAlreadyStored = (requestData) => {
  //   return Object.keys(localStorage).some((key) => {
  //     try {
  //       const storedData = JSON.parse(localStorage.getItem(key));
  //       // Customize the comparison logic based on your data structure
  //       return (
  //         storedData &&
  //         storedData.query === requestData.query &&
  //         storedData.headers === requestData.headers
  //       );
  //     } catch (error) {
  //       // Handle potential JSON parsing errors
  //       console.error("Error parsing stored data:", error);
  //       return false;
  //     }
  //   });
  // };

  // // Function to retrieve saved data from local storage
  const retrieveSavedData = () => {
    const data = [];
    for (let key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        try {
          const item = JSON.parse(localStorage.getItem(key));
          if (item) {
            console.log("Item: ", item);
            data.push({ timestamp: key, ...item });
          }
        } catch (error) {
          console.error("Error parsing local storage data:", error);
        }
      }
    }
    setSavedData(data);
  };

  const prepareData = () => {
    //setSavedData([]);
    retriveAllTemplates();
    setVisible(true);
  };

  const columns = apiData[0]
    ? Object.keys(apiData[0]).map((key) => ({
      title: key
        .replace(/_/g, " ")
        .replace("value", "")
        .replace(/\b\w/g, (l) => l.toUpperCase()), // Format the title here
      dataIndex: key,
      key,
    }))
    : [];

  const updateFilterText = (e) => {
    const searchText = e.target.value;
    setFilterText(searchText);

    if (searchText) {
      setFilterText(searchText);

      const filtered = generatedModels.filter((item) =>
        item.model.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredModels(filtered);
    } else {
      setFilterText("");
      setFilteredModels("");
    }
  };

  const spanStyles = {
    fontWeight: "bold",
    fontSize: "14.5px",
  };

  const dataShow = {
    fontSize: "14.5px",
    marginTop: "20px",
  };
  const alignment = {
    padding: "10px",
  };

  return (
    <div style={{ marginTop: "5px" }} className="filter-container">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="row">
              <div className="col">
                <span className="textStyles-small" style={{ fontSize: '14px', fontWeight: 'bold' }}>Map Query</span>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a className='textStyles-small'>Site View</a></li>
                  <li className="breadcrumb-item"><a className='textStyles-small'>Map Query</a></li>
                </ol>
              </div>
              <div className="col-auto align-self-center">
                <Button
                  type="primary"
                  onClick={() => prepareData()}
                  style={{ marginLeft: 10, backgroundColor: '#BA183A', borderColor: '#BA183A', borderRadius: 12, height: 39, width: 140 }}
                >
                  <span className='textStyles-small'>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 5}}>
                      <path fill-rule="evenodd" 
                      clip-rule="evenodd" 
                      d="M21.0838 19.25V21.0833H16.5005V19.25H21.0838ZM15.5838 19.25V21.0833H13.7505V19.25H15.5838ZM21.0838 16.5V18.3333H16.5005V16.5H21.0838ZM15.5838 16.5V18.3333H13.7505V16.5H15.5838ZM7.11138 13.0557C8.10668 14.051 9.48168 14.6666 11.0005 14.6666C11.6433 14.6666 12.2604 14.5564 12.8338 14.3537L12.8339 16.2689C12.2479 16.4198 11.6335 16.5 11.0005 16.5C8.9754 16.5 7.14205 15.6792 5.81497 14.3521L7.11138 13.0557ZM21.0838 13.75V15.5833H16.5005V13.75H21.0838ZM15.5838 13.75V15.5833H13.7505V13.75H15.5838ZM11.0005 1.83331C15.0506 1.83331 18.3338 5.11656 18.3338 9.16665C18.3338 10.5024 17.9767 11.7547 17.3527 12.8333L15.0999 12.8334C15.9709 11.8604 16.5005 10.5754 16.5005 9.16665C16.5005 6.12908 14.038 3.66665 11.0005 3.66665C7.9629 3.66665 5.50046 6.12908 5.50046 9.16665C5.50046 9.33962 5.50845 9.51073 5.52407 9.67963L6.41713 8.78695L7.71349 10.0833L4.5838 13.213L1.4541 10.0833L2.75046 8.78695L3.68801 9.72417C3.67417 9.54014 3.66713 9.35422 3.66713 9.16665C3.66713 5.11656 6.95038 1.83331 11.0005 1.83331ZM11.9171 4.58331V8.67165L14.2546 10.2391L13.2463 11.7608L10.0838 9.66165V4.58331H11.9171Z" 
                      fill="white" />
                    </svg>
                    Templates</span>
                </Button>
                {
                  apiData.length != 0 && (
                    <Button
                      style={{ marginLeft: 10, backgroundColor: '#BA183A', borderColor: '#BA183A', borderRadius: 12, height: 39, width: 112 }}
                      onClick={() => {
                        let requestJson = {
                          siteIds: apiData.map((item) => item?.site_primary_key),
                          regionIds: [],
                          depotIds: [],
                          status: null,
                          siteOwnerIds: [],
                          siteTypeIds: [],
                          towerCategoryIds: [],
                          towerTypeIds: []
                        }
                        localStorage.setItem('mapQuery', JSON.stringify(requestJson));
                        window.location.href = '/site/map';
                      }}
                    >
                      <svg width="18" height="18" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.75 5.43344C21.75 4.23043 21.75 3.62892 21.4236 3.17608C21.0972 2.72323 20.5266 2.53301 19.3853 2.15258L18.0426 1.70502C16.4666 1.17969 15.6787 0.917035 14.8853 1.02327C14.092 1.12951 13.4009 1.59025 12.0186 2.51172L10.4251 3.57411C9.346 4.29347 8.80651 4.65315 8.20123 4.79622C7.97893 4.84878 7.75198 4.87917 7.52368 4.88696C6.90209 4.90819 6.28694 4.70314 5.05664 4.29304C3.44515 3.75588 2.6394 3.48729 2.02631 3.76004C1.80864 3.85687 1.61332 3.99765 1.45263 4.17353C1 4.6689 1 5.51823 1 7.2169V11.902M21.75 9.85747V14.8037C21.75 16.5023 21.75 17.3517 21.2974 17.847C21.1367 18.0229 20.9413 18.1637 20.7237 18.2605C20.1106 18.5333 19.3048 18.2647 17.6934 17.7275C16.463 17.3174 15.8479 17.1124 15.2263 17.1336C14.9981 17.1414 14.7711 17.1717 14.5488 17.2243C13.9435 17.3674 13.404 17.727 12.3249 18.4465L10.7314 19.5088C9.34911 20.4303 8.65803 20.8911 7.86471 20.9972C7.07138 21.1035 6.2834 20.8408 4.70744 20.3155L3.36472 19.868C2.22343 19.4875 1.65278 19.2973 1.3264 18.8444C1.01737 18.4157 1.00092 17.8537 1.00005 16.7741"
                          stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" />
                        <path d="M14.8333 1.21167V5.24639M14.8333 16.7742V9.8575" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" />
                        <path d="M7.91675 20.8088V16.7741M7.91675 5.24634V12.163" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" />
                      </svg>
                      <span className='textStyles-small' style={{ color: 'white', marginLeft: 10 }}>Map</span>
                    </Button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title={<span className="textStyles-small" style={{ fontWeight: "bold", fontSize: 14 }}>Saved Templates</span>}
        width={500}
        onClose={() => setVisible(false)}
        visible={visible}
        onShow={retrieveSavedData}
      >
        <div>
          <Input
            placeholder="Search by template name"
            style={{ width: '100%', height: 33, marginBottom: 10 }}
            className='borderedSelect'
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {filteredData != null ? (
            <>
              {[...filteredData]
                .sort((a, b) => b.id - a.id)
                .map((dataItem, index) => (
                  <div
                    key={index}
                    className="action-container"
                    style={alignment}
                  >
                    <span className="textStyles-small" style={{ fontSize: 14, fontWeight: 'bold' }}>{dataItem.templateName}</span>
                    <div style={{ backgroundColor: "#f0f0f0", padding: 10, borderRadius: 10, marginTop: 10 }}>
                      <Row>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                          <CopyOutlined
                            style={{ fontSize: 14, cursor: 'pointer' }}
                            onClick={() => {
                              copyText(dataItem.queryBody);
                              message.success(<span className="textStyles-small">Query Copied Successfully</span>)
                            }
                            } />
                        </div>
                      </Row>
                      <span className="textStyles-small">{dataItem.queryBody}</span>
                    </div>
                    <span className="textStyles-small" style={{ marginTop: '10px' }}>Target Headers:</span>
                    <div>
                      {dataItem.headers.split(',').map((item, index) => {
                        return index === 0 ? 
                        // <Tag style={{ margin: 3 }} color="blue" key={index}><span style={{ fontSize: 11 }} className="textStyles-small">
                        //   {item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                        // </span></Tag>
                                  //   <Tag color='blue' style={{ padding: 5, borderRadius: 5 }} className="textStyles-small">
                                  //   <span style={{ color: 'blue', fontWeight: 550, fontSize: '12px' }} className="textStyles-small">
                                  //   {item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                                  //   </span>
                                  // </Tag>
                          <Tag color='blue' style={{ padding: 5, borderRadius: 5, margin: 3 }} className="textStyles-small">
                            <span style={{ color: '#1FAD4B', fontWeight: 450, fontSize: '11px' }} className="textStyles-small">
                              {item.display_name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </span>
                          </Tag>
                        :
                          // <Tag color="blue" style={{ margin: 3 }} key={index}><span style={{ fontSize: 11 }} className="textStyles-small">
                          //   {item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                          // </span></Tag>
                          <Tag color='blue' style={{ padding: 5, borderRadius: 5, margin: 3 }} className="textStyles-small">
                            <span style={{ color: '#1FAD4B', fontWeight: 450, fontSize: '11px' }} className="textStyles-small">
                              {item.display_name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </span>
                          </Tag>
                          ;
                      })}
                    </div>
                    <br />
                    <span className="textStyles-small" style={{ color: 'gray', marginTop: 10 }}>Create Date: {moment(dataItem.createdAt).format('YYYY-MM-DD HH:mm')}</span>
                    <br />
                    <Row>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button
                          shape="circle"
                          type="primary"
                          size="small"
                          onClick={() => {
                            handleFilterSection(
                              dataItem.queryBody,
                              dataItem.headers
                            );
                            setVisible(false);
                          }}
                          icon={<EyeOutlined />}
                        />
                        <Button
                          shape="circle"
                          type="primary"
                          size="small"
                          danger
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            showDeleteConfirmationModal(dataItem.id);
                            setVisible(false);
                          }}
                          icon={<DeleteOutlined />}
                        />
                      </div>
                    </Row>
                  </div>
                ))}
            </>
          ) : (
            <>
              {[...savedData]
                .sort((a, b) => b.id - a.id)
                .map((dataItem, index) => (
                  <div
                    key={index}
                    className="action-container"
                    style={alignment}
                  >
                    <span className="textStyles-small" style={{ fontSize: 14, fontWeight: 'bold' }}>{dataItem.templateName}</span>
                    <div style={{ backgroundColor: "#f0f0f0", padding: 10, borderRadius: 10, marginTop: 10 }}>
                      <Row>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                          <CopyOutlined
                            style={{ fontSize: 14, cursor: 'pointer' }}
                            onClick={() => {
                              copyText(dataItem.queryBody);
                              message.success(<span className="textStyles-small">Query Copied Successfully</span>)
                            }
                            } />
                        </div>
                      </Row>
                      <span className="textStyles-small">{dataItem.queryBody}</span>
                    </div>
                    <span className="textStyles-small" style={{ marginTop: '10px' }}>Target Headers:</span>
                    <div>
                      {dataItem.headers.split(',').map((item, index) => {
                        return index === 0 ? 
                                                  <Tag color='blue' style={{ padding: 5, borderRadius: 5, margin: 3 }} className="textStyles-small">
                                                  <span style={{ color: '#1FAD4B', fontWeight: 450, fontSize: '11px' }} className="textStyles-small">
                                                  {item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                                                  </span>
                                                </Tag>
                        :
                         
                                                    <Tag color='blue' style={{ padding: 5, borderRadius: 5, margin: 3 }} className="textStyles-small">
                                                    <span style={{ color: '#1FAD4B', fontWeight: 450, fontSize: '11px' }} className="textStyles-small">
                                                    {item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                                                    </span>
                                                  </Tag>
                          ;
                      })}
                    </div>
                    <br />
                    <span className="textStyles-small" style={{ color: 'gray', marginTop: 10 }}>Create Date: {moment(dataItem.createdAt).format('YYYY-MM-DD HH:mm')}</span>
                    <br />
                    <Row>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button
                          shape="circle"
                          type="primary"
                          size="small"
                          onClick={() => {
                            handleFilterSection(
                              dataItem.queryBody,
                              dataItem.headers
                            );
                            setVisible(false);
                          }}
                          icon={<EyeOutlined />}
                        />
                        <Button
                          shape="circle"
                          type="primary"
                          size="small"
                          danger
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            showDeleteConfirmationModal(dataItem.id);
                            setVisible(false);
                          }}
                          icon={<DeleteOutlined />}
                        />
                      </div>
                    </Row>
                  </div>
                ))}
            </>
          )}
        </div>
      </Drawer>

      {showFilterSection && (
        <div id="filterSection">
          <div style={{ marginTop: 10, backgroundColor: 'white', padding: 10, borderRadius: 12 }}>
            <Form className="action-container" style={{ marginTop: 10 }}>
              <Form.Item className="main-dropdown">
                <Select
                  mode="multiple"
                  onChange={(value) => filteredOptions(value)}
                  placeholder="Please Select Attributes"
                  style={{ width: '100%', height: 'auto' }}
                  className='borderedSelect'
                  bordered={false}
                  defaultValue={data_set.fields[0]}
                >
                  {data_set.fields.map((column, index) => (
                    <Option key={index} value={index}>
                      <span className="textStyles-small">{column.label}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>

            {/* <Button
                style={{ borderRadius: '5px', height: 33 }}
                type="primary"
                danger
                className="main-dropdown"
                onClick={handleReload}
                icon={<DeleteOutlined />}
              >
                <span className='textStyles-small'>Reload</span>
              </Button> */}

            <Form>
              <Row span={24}>
                <Col span={12}>
                  <span className="textStyles-small" style={{ fontSize: 14, fontWeight: 'bold' }}>Advanced Filteration</span>
                </Col>
                <Col span={12}>
                  <Button
                    type="dashed"
                    onClick={generateSQLQuery}
                    style={{ background: "white", borderColor: "gray", borderRadius: '12px', height: 39, width: 145, float: 'right' }}
                    className="main-dropdown"
                  >
                    <span className='textStyles-small' style={{ color: 'white' }}>
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_44_755)">
                          <path d="M16.6875 3.71875H6.28125V4.875H16.6875V3.71875Z" fill="#BA183A" />
                          <path d="M16.6875 7.1875H6.28125V8.34375H16.6875V7.1875Z" fill="#BA183A" />
                          <path d="M16.6875 10.6562H9.17188V11.8125H16.6875V10.6562Z" fill="#BA183A" />
                          <path d="M16.6875 14.125H6.28125V15.2812H16.6875V14.125Z" fill="#BA183A" />
                          <path d="M2.8125 8.34375L6.85938 11.2344L2.8125 14.125V8.34375Z" fill="#BA183A" />
                        </g>
                        <defs>
                          <clipPath id="clip0_44_755">
                            <rect width="18.5" height="18.5" fill="white" transform="translate(0.5 0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                      <sapn className='textStyles-small' style={{ marginLeft: 5, color: 'black' }}>Generate Query</sapn>
                    </span>
                  </Button>
                  <Button
                    type="dashed"
                    onClick={handleAddFormItem}
                    style={{ background: "#BA183A", borderColor: "#BA183A", borderRadius: '12px', height: 39, width: 114, float: 'right' }}
                    className="main-dropdown"
                  >
                    <span className='textStyles-small' style={{ color: 'white' }}>
                      <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.80138 2.59829C9.80138 1.71558 9.05493 1 8.13407 1C7.21322 1 6.46677 1.71558 6.46677 2.59829M9.80138 2.59829C9.80138 3.481 9.05493 4.19658 8.13407 4.19658C7.21322 4.19658 6.46677 3.481 6.46677 2.59829M9.80138 2.59829H14.8033M6.46677 2.59829H1.46484M11.4687 8.19231C11.4687 9.07504 12.2151 9.7906 13.136 9.7906C14.0569 9.7906 14.8033 9.07504 14.8033 8.19231C14.8033 7.30957 14.0569 6.59402 13.136 6.59402C12.2151 6.59402 11.4687 7.30957 11.4687 8.19231ZM11.4687 8.19231H1.46484M4.79946 13.7863C4.79946 12.9036 4.05298 12.188 3.13215 12.188C2.21132 12.188 1.46484 12.9036 1.46484 13.7863C1.46484 14.6691 2.21132 15.3846 3.13215 15.3846C4.05298 15.3846 4.79946 14.6691 4.79946 13.7863ZM4.79946 13.7863H14.8033" stroke="white" stroke-linecap="round" />
                        <circle cx="14.1493" cy="12.1154" r="5.38462" fill="white" stroke="#BA183A" />
                        <path d="M16.143 11.625H14.9007V10.3827C14.9007 10.2179 14.8352 10.06 14.7188 9.94347C14.6023 9.82698 14.4443 9.76154 14.2795 9.76154C14.1148 9.76154 13.9568 9.82698 13.8403 9.94347C13.7238 10.06 13.6584 10.2179 13.6584 10.3827V11.625H12.4161C12.2513 11.625 12.0933 11.6904 11.9769 11.8069C11.8604 11.9234 11.7949 12.0814 11.7949 12.2462C11.7949 12.4109 11.8604 12.5689 11.9769 12.6854C12.0933 12.8019 12.2513 12.8673 12.4161 12.8673H13.6584V14.1096C13.6584 14.2744 13.7238 14.4323 13.8403 14.5488C13.9568 14.6653 14.1148 14.7308 14.2795 14.7308C14.4443 14.7308 14.6023 14.6653 14.7188 14.5488C14.8352 14.4323 14.9007 14.2744 14.9007 14.1096V12.8673H16.143C16.3077 12.8673 16.4657 12.8019 16.5822 12.6854C16.6987 12.5689 16.7642 12.4109 16.7642 12.2462C16.7642 12.0814 16.6987 11.9234 16.5822 11.8069C16.4657 11.6904 16.3077 11.625 16.143 11.625Z" fill="#BA183A" />
                      </svg>
                      <sapn className='textStyles-small' style={{ marginLeft: 5 }}>Add Filter</sapn>
                    </span>
                  </Button>
                </Col>
              </Row>

              <div className="main-advanded-filter" style={{ marginTop: 15 }}>
                {formItems.map((item, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", marginBottom: "-10px", marginTop: 20 }}
                  >
                    {console.log(item.response_type)}
                    <Form.Item>
                      <Select
                        className='borderedSelect'
                        bordered={false}
                        style={{ width: '310px' }}
                        placeholder="Label"
                        value={item.display_name}
                        onChange={(value) => handleLabelChange(index, value)}
                      >
                        .value
                        {filterList &&
                          filterList.map((column, index) => {
                            console.log(
                              index,
                              column.field_name,
                              column.label,
                              column.value
                            );
                            return (
                              <Option key={index} value={column.display_name}>
                                <span className="textStyles-small">{column.label}</span>
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Select
                        className='borderedSelect'
                        bordered={false}
                        style={{ width: '310px', marginLeft: 4 }}
                        placeholder="Operator"
                        value={item.selectedOperator}
                        onChange={(value) =>
                          handleFormItemChange(index, "selectedOperator", value)
                        }
                      >
                        .value.value
                        {item.operator &&
                          item.operator.map((op) => (
                            <Option key={op.key} value={op.value}>
                              <span className="textStyles-small">{op.value}</span>
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Input
                        type={item.response_type == "text" ? "text" : "number"}
                        style={{ width: '310px', marginLeft: 4, height: '34px' }}
                        placeholder="Value"
                        className='borderedSelect'
                        value={item.value}
                        onChange={(e) =>
                          handleFormItemChange(index, "value", e.target.value)
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        style={{ marginLeft: 8, width: '34px', height: '34px', borderRadius: '12px', backgroundColor: '#BA183A45', borderColor: '#BA183A45' }}
                        danger
                        shape="square"
                        size="small"
                        onClick={() => handleRemoveFormItem(index)}
                      >
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M13.1004 2.41286C13.3952 2.41286 13.6407 2.65318 13.6407 2.95898V3.2417C13.6407 3.54006 13.3952 3.78782 13.1004 3.78782H0.540968C0.245426 3.78782 0 3.54006 0 3.2417V2.95898C0 2.65318 0.245426 2.41286 0.540968 2.41286H2.75054C3.19938 2.41286 3.59 2.09963 3.69097 1.65768L3.80668 1.15026C3.98651 0.459062 4.57833 0 5.25565 0H8.38501C9.05496 0 9.65341 0.459062 9.82661 1.1138L9.95043 1.65694C10.0507 2.09963 10.4413 2.41286 10.8909 2.41286H13.1004ZM11.9764 12.748C12.2071 10.6372 12.611 5.62246 12.611 5.57187C12.6257 5.4186 12.5749 5.27352 12.4739 5.15671C12.3656 5.04733 12.2285 4.9826 12.0774 4.9826H1.56609C1.41427 4.9826 1.26981 5.04733 1.16958 5.15671C1.06787 5.27352 1.01775 5.4186 1.02512 5.57187C1.02648 5.58117 1.04097 5.75781 1.0652 6.05312C1.17283 7.36503 1.47261 11.0189 1.66633 12.748C1.80341 14.0217 2.65466 14.8223 3.88769 14.8513C4.83917 14.8729 5.8194 14.8803 6.82174 14.8803C7.76585 14.8803 8.72471 14.8729 9.70568 14.8513C10.9814 14.8297 11.832 14.0433 11.9764 12.748Z" fill="#BA183A" />
                        </svg>
                      </Button>
                    </Form.Item>
                    {item.detail_view && (
                      <Form.Item>
                        <Button
                          style={{ marginLeft: 5, width: '34px', height: '34px', borderRadius: '12px', backgroundColor: '#953B1645', borderColor: '#953B1645' }}
                          type="primary"
                          shape="square"
                          size="small" onClick={() => {
                            const fieldValus = getDetails(index);
                            setOpenDrawerDetails(true);
                          }}
                        >
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_44_781)">
                              <path d="M16.3187 6.29291C14.5986 3.63895 11.6618 2.02482 8.49926 1.99515C5.33676 2.02482 2.4 3.63895 0.679869 6.29291C-0.226623 7.62324 -0.226623 9.37281 0.679869 10.7032C2.39903 13.3588 5.33595 14.9746 8.4993 15.0052C11.6618 14.9755 14.5986 13.3614 16.3187 10.7075C17.2271 9.37612 17.2271 7.62421 16.3187 6.29291ZM14.5586 9.49701C13.2453 11.58 10.9617 12.8509 8.49926 12.8692C6.03688 12.8509 3.75326 11.58 2.43995 9.49701C2.03053 8.8955 2.03053 8.10483 2.43995 7.50336C3.75323 5.42033 6.03685 4.14947 8.49926 4.13121C10.9616 4.14944 13.2453 5.42033 14.5586 7.50336C14.968 8.10483 14.968 8.8955 14.5586 9.49701Z" fill="#953B16" />
                              <path d="M8.49944 11.3481C10.0724 11.3481 11.3475 10.073 11.3475 8.50005C11.3475 6.9271 10.0724 5.65198 8.49944 5.65198C6.92649 5.65198 5.65137 6.9271 5.65137 8.50005C5.65137 10.073 6.92649 11.3481 8.49944 11.3481Z" fill="#953B16" />
                            </g>
                            <defs>
                              <clipPath id="clip0_44_781">
                                <rect width="17" height="17" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </Button>
                      </Form.Item>
                    )}
                  </div>
                ))}
              </div>
            </Form>
          </div>
        </div>
      )}

      {showRapidFilterSection && (
        <div>
          <div className="action-container">
            {sql !== "" && (
              <div style={{ padding: 10, borderRadius: 10, marginTop: 10, width: '100%', backgroundColor: 'white' }}>
                <span className="textStyles-small" style={{ fontSize: 13, marginBottom: 20, fontWeight: 550 }}>Auto Generated Query :</span>
                <div style={{ backgroundColor: "#f0f0f0", padding: 10, borderRadius: 10, marginTop: 5, width: '100%' }}>
                  <Row>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                      <CopyOutlined
                        style={{ fontSize: 14, cursor: 'pointer' }}
                        onClick={() => {
                          copyText(sql);
                          message.success(<span className="textStyles-small">Query Copied Successfully</span>)
                        }
                        } />
                    </div>
                  </Row>
                  <span className="textStyles-small">{sql}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <Button
                    type="primary"
                    onClick={rapidAPIResponse}
                    icon={<SendOutlined />}
                    style={{ marginLeft: 10, backgroundColor: '#BA183A', borderColor: '#BA183A', borderRadius: 12, height: 39, width: 140, marginTop: 10 }}
                  >
                    <span className='textStyles-small'>Request Data</span>
                  </Button>

                  <Button
                    type="primary"
                    danger
                    onClick={() => handleReload()}
                    style={{ marginLeft: 10, backgroundColor: '#BA183A45', borderColor: '#BA183A45', borderRadius: 12, height: 39, width: 140, marginTop: 10 }}
                  > <span className='textStyles-small' style={{color: '#BA183A'}}>Back</span>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {showNormalFilterSection && (
        <div className="action-container">
          {sql !== "" && (
            <div style={{ padding: 10, borderRadius: 10, marginTop: 10, width: '100%', backgroundColor: 'white' }}>
              <span className="textStyles-small" style={{ fontSize: 13, marginBottom: 20, fontWeight: 550 }}>Target Field List :</span>
              <br />
              {
                filterList.map((item, index) => {
                  return (
                    <Tag color='blue' style={{ padding: 5, borderRadius: 5, margin: 3 }} className="textStyles-small">
                    <span style={{ color: '#1FAD4B', fontWeight: 450, fontSize: '11px' }} className="textStyles-small">
                      {item.display_name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                    </span>
                  </Tag>
                  );
                })
              }
              <div style={{ marginTop: 15 }} />
              <span className="textStyles-small" style={{ fontSize: 13, marginBottom: 20, fontWeight: 550 }}>Auto Generated Query :</span>
              <div style={{ backgroundColor: "#f0f0f0", padding: 10, borderRadius: 10, marginTop: 5, width: '100%' }}>
                <Row>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <CopyOutlined
                      style={{ fontSize: 14, cursor: 'pointer' }}
                      onClick={() => {
                        copyText(sql);
                        message.success(<span className="textStyles-small">Query Copied Successfully</span>)
                      }
                      } />
                  </div>
                </Row>
                <span className="textStyles-small">{sql}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button
                  type="primary"
                  onClick={apiResponse}
                  loading={loading}
                  style={{ marginLeft: 10, backgroundColor: '#BA183A', borderColor: '#BA183A', borderRadius: 12, height: 39, width: 140, marginTop: 10 }}
                  icon={<SendOutlined />}
                >
                  <span className='textStyles-small'>Request Data</span>
                </Button>

                <Button
                    type="primary"
                    danger
                    onClick={() => handleReload()}
                    style={{ marginLeft: 10, backgroundColor: '#BA183A45', borderColor: '#BA183A45', borderRadius: 12, height: 39, width: 140, marginTop: 10 }}
                  > <span className='textStyles-small' style={{color: '#BA183A'}}>Back</span>
                  </Button>
              </div>
            </div>
          )}
        </div>
      )}

      <Drawer
        title={<span className="textStyles-small" style={{ fontWeight: "bold", fontSize: 14 }}>Model Details</span>}
        placement="right"
        onClose={onClose}
        visible={openDrawerDetails}
      >
        <>
          <Form.Item>
            <Input
              placeholder="Filter Models"
              value={filterText}
              onChange={updateFilterText}
              className="borderedSelect"
              style={{ height: 34 }}
            />
          </Form.Item>

          <div className="action-container">
            {filteredModels.length != 0 &&
              filteredModels.map((item) => (
                <Row type="flex" justify="start" style={{ marginTop: "4px" }}>
                  <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="main-detailview">
                        {item.id}. {item.model}
                      </span>
                      <CopyOutlined
                        onClick={() =>
                          copyText(
                            filteredModels.map((item) => item.model).join("\n")
                          )
                        }
                        style={{
                          fontSize: "12px",
                          cursor: "pointer",
                          marginRight: "10px",
                          color: "#001eca73",
                          marginLeft: "20px",
                        }}
                        className="main-detailview"
                      />
                    </div>
                  </Col>
                </Row>
              ))}
          </div>

          <div className="action-container">
            {generatedModels.map((item) => (
              <Row type="flex" justify="start" key={item.id}>
                <Col span={24}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="main-detailview textStyles-small" style={{ fontSize: 12, margin: 8 }}>
                      {item.id}. {item.model}
                    </span>
                    <CopyOutlined
                      onClick={() => copyText(item.model)}
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        marginRight: "10px",
                        color: "#001eca73",
                        marginLeft: "20px",
                      }}
                      className="main-detailview"
                    />
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </>
      </Drawer>

      <Modal
        title={<span className="textStyles-small" style={{ fontWeight: "bold", fontSize: 14 }}>Save As Template</span>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <span className="textStyles-small">Template Name:</span>
        <Input
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          className="borderedSelect"
          style={{ height: 34 }}
          rows={4}
        />
        <br />
        <span className="textStyles-small" style={{ marginTop: 5 }}>Target Headers:</span>
        <Input.TextArea
          className="borderedSelect"
          style={{ color: "black" }}
          value={filterList.map((item) => item.label).join(", ")}
          disabled
          rows={4}
        />
        <br />
        <span className="textStyles-small">Auto Generated Query:</span>
        <Input.TextArea
          style={{ color: "black" }}
          value={sql}
          disabled
          className="borderedSelect"
          rows={4}
        />
      </Modal>

      {apiData.length != 0 && (
        <div className="action-container" style={{ backgroundColor: 'white', padding: 10, borderRadius: 10 }}>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            className="main-dropdown"
            style={{ borderColor: '#B01F88', backgroundColor: '#B01F88', borderRadius: '10px', width: '143px', height: '34px', marginLeft: 5, float: "right", marginBottom: 10 }}
          >
            <CSVLink
              data={apiData}
              filename={{ date }.date + ".csv"}
              target="_blank"
            >
              <span className="textStyles-small" style={{ color: 'white', marginLeft: 3 }}>Export Data</span>
            </CSVLink>
          </Button>

          {
            !isRapidTemplate && (
              <Button
                type="primary"
                style={{ borderColor: '#BA183A45', backgroundColor: '#BA183A45', borderRadius: '10px', width: '150px', height: '34px', marginLeft: 5, float: "right", marginBottom: 10 }}
                onClick={showModal}
                className="main-dropdown"
                icon={<SaveOutlined style={{color: '#B01F88'}}/>}
              >
                <span className="textStyles-small" style={{ color: '#B01F88', marginLeft: 3 }}>Save As Template</span>
              </Button>
            )
          }

          {apiData && (
            <Table
              style={{ marginTop: 20 }}
              columns={columns}
              dataSource={apiData}
              bordered
              size="small"
              className='table-striped-rows textStyles-small'
            />
          )}
        </div>
      )}

      {apiData.length === 0 && (
        <div className="action-container" style={{ backgroundColor: 'white', padding: 10, borderRadius: 10 }}>
          <Empty style={{ padding: "20px" }}
            description={<span className="textStyles-small">
              {sql === "" ? "Please generate the query to view the data." : "No data available."}
            </span>} />
        </div>
      )}
    </div>
  );
};

export default MapQueryBuilder;
