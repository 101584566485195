import React, { Component, Fragment } from "react";
import checkPermissions from "../../utils/permissionManager";
import {
  Table,
  Tag,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  InputNumber,
  message,
  Popconfirm,
  Collapse,
  Card,
  Divider,
  Row,
  Col
} from "antd";
import { PlusCircleOutlined, DeleteOutlined, HomeOutlined, EditOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import { createNewdcdu, getDcduDetails, UpdateDcdu } from "../../api/index";
import AssetDelete from "../site_components/asset_delete";
import { checkServiceType } from "../../actions/service-type";

class Dcdus extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      title: "",
      tabaleLoading: false,
      actionView: false,
      showModal: false,
      open: false,
      openEdit: false,
      selectedAssetId: null,
      AssetType: [],
      dcduType: "",
      dcduCapacity: "",
      dcduFreeBreakerSlots: "",
      dcduPowerFromLLVD: "",
      dcduNoOfBreakerSlots: "",
      rbcapacityToPowerDCDU: "",
      AssetManagementPermission: false,
      openViewMoreDetails: false,
      selectedElement: "",
      rectifier: null,
      rectifierId: null,

      objDropdowndcdu: {
        dcduType: "",
        dcduPowerFromLLVD: "",
        dcduRbcapacityToPowerDCDU: "",
        dcduNoOfBreakerSlots: "",
        dcduFreeBreakerSlots: "",
      },
    };
    this.requestFromClose = this.requestFromClose.bind(this);
    this.handleDcduType = this.handleDcduType.bind(this);
    this.handleDcduCapacity = this.handleDcduCapacity.bind(this);
    this.handleFreeBreakerSlots = this.handleFreeBreakerSlots.bind(this);
    this.handlePowerFromLLVD = this.handlePowerFromLLVD.bind(this);
    this.handleNoOfBreakerSlots = this.handleNoOfBreakerSlots.bind(this);
    this.handleRbcapacityToPowerDCDU =
      this.handleRbcapacityToPowerDCDU.bind(this);
  }
  dcduUpdata(id) {
    // console.log('dfdfd')
    console.log(id);
    this.getDataDcduDetails(id);
    this.setState({ openEdit: true });
  }
  getDataDcduDetails = async (id) => {
    var res = await getDcduDetails(id);
    console.log(res);
    if (res.success) {
      this.setState({
        dcduEdit: res.data,
        dcduId: res.data.id,
        dcduType: res.data.type,
        dcduCapacity: res.data.totalCapacity,
        dcduFreeBreakerSlots: res.data.freeBreakerSlots,
        dcduPowerFromLLVD: res.data.powerFromLLVD,
        dcduNoOfBreakerSlots: res.data.noOfBreakerSlots,
        rbcapacityToPowerDCDU: res.data.rbcapacityToPowerDCDU,
      });
      if (res.data.rectifier != null && res.data.rectifier.delete == false) {
        console.log("ssssssssssss");
        console.log(res.data.rectifier);
        this.setState({
          rectifier: res.data.rectifier,
          rectifierId: res.data.rectifier.id,
        });
      } else {
        this.setState({
          rectifier: null,
          rectifierId: null,
        });
      }
    }
  };
  handleRbcapacityToPowerDCDU(e) {
    console.log(e);
    // console.log(e)
    this.setState({ rbcapacityToPowerDCDU: Math.round(e) });
  }
  handleNoOfBreakerSlots(e) {
    // console.log('ddddddd')
    // console.log(e)
    this.setState({ dcduNoOfBreakerSlots: Math.round(e) });
  }
  handlePowerFromLLVD(e) {
    this.setState({ dcduPowerFromLLVD: e });
  }
  handleFreeBreakerSlots(e) {
    //console.log(e.target.value)
    this.setState({ dcduFreeBreakerSlots: Math.round(e) });
  }
  handleDcduType(e) {
    //console.log(e.target.value)
    this.setState({ dcduType: e });
  }
  handleDcduCapacity(e) {
    console.log(e);
    this.setState({ dcduCapacity: e });
  }
  componentDidMount() {
    if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
      const { history } = this.props;

      history.push("/");
    } else {
      this.getSetPageData(
        JSON.parse(localStorage.getItem("serviceTypeSet")),
        38
      );
    }
    checkServiceType(38).then((data) =>
      this.setState(
        {
          AssetManagementPermission: data,
        },
        () => { }
      )
    );
  }
  getSetPageData = (serviceTypeIs, Pageid) => {
    if (
      serviceTypeIs.serviceType.filter(
        (checkuser) => checkuser?.id == Pageid
      )[0] != null
    ) {
      console.log("fddf");
      this.setState({
        actionView: true,
      });
    }
  };
  requestFromClose() {
    console.log("dsdsd");
    this.setState({ showModal: true, open: true });
  }
  onDrawerClose = () => {
    console.log("sddsds");
    this.formRef.current.resetFields();

    this.validator.hideMessages();
    this.setState({
      open: false,
      openEdit: false,
      selectedAssetId: null,
      AssetType: [],
      dcduType: "",
      dcduCapacity: "",
      dcduFreeBreakerSlots: "",
      dcduPowerFromLLVD: "",
      dcduNoOfBreakerSlots: "",
      rbcapacityToPowerDCDU: "",
      rectifier: null,
      rectifierId: null,
    });
  };
  onChangeAsset = (value) => {
    console.log("onChangeAsset");
    //console.log(value);
    this.setState({
      selectedAssetId: value,
      AssetType: {
        id: value,
      },
    });
  };
  AllDataSubmitUpdate = async () => {
    if (this.validator.allValid()) {
      var dataSet;
      console.log("ssss");
      dataSet = {
        id: this.state.dcduId,
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        type: this.state.dcduType,
        totalCapacity: this.state.dcduCapacity,
        freeBreakerSlots: this.state.dcduFreeBreakerSlots,
        powerFromLLVD: this.state.dcduPowerFromLLVD,
        noOfBreakerSlots: this.state.dcduNoOfBreakerSlots,
        rbcapacityToPowerDCDU: this.state.rbcapacityToPowerDCDU,
        rectifier: this.state.rectifier,
      };
      console.log(dataSet);
      var res = await UpdateDcdu(dataSet);
      console.log(res);
      if (res.success) {
        message.success("Successfully DCDU Edited!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,
          openEdit: false,

          selectedAssetId: null,
          AssetType: [],
          dcduType: "",
          dcduCapacity: "",
          dcduFreeBreakerSlots: "",
          dcduPowerFromLLVD: "",
          dcduNoOfBreakerSlots: "",
          rbcapacityToPowerDCDU: "",
        });
        this.props.parentMethod();
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  getDataDetailsForDelete = async (id) => {
    var res = await getDcduDetails(id);
    console.log(res);
    if (res.success) {
      var respons = AssetDelete.Action(
        res.data,
        this.props?.detailsSite?.site_details.id,
        UpdateDcdu
      );

      respons.then((value) => {
        if (value == 1) {
          message.success("Successfully Delete!");
          this.props.parentMethod();
        } else {
          message.warning(" Something went wrong!");
        }
      });
    }
  };
  submitForm = async (item) => {
    this.getDataDetailsForDelete(item);
  };
  AllDataSubmit = async () => {
    if (this.validator.allValid()) {
      var dataSet;

      dataSet = {
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        type: this.state.dcduType,
        totalCapacity: this.state.dcduCapacity,
        freeBreakerSlots: this.state.dcduFreeBreakerSlots,
        powerFromLLVD: this.state.dcduPowerFromLLVD,
        noOfBreakerSlots: this.state.dcduNoOfBreakerSlots,
        rbcapacityToPowerDCDU: this.state.rbcapacityToPowerDCDU,
        rectifier: this.state.rectifier,
      };
      console.log(dataSet);
      var res = await createNewdcdu(dataSet);
      if (res.success) {
        message.success("Successfully DCDU Added!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,
          selectedAssetId: null,
          AssetType: [],
          dcduType: "",
          dcduCapacity: "",
          dcduFreeBreakerSlots: "",
          dcduPowerFromLLVD: "",
          dcduNoOfBreakerSlots: "",
          rbcapacityToPowerDCDU: "",
        });
        this.props.parentMethod();
        // const { history } = this.props;
        // history.push("/site");
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  onChangeRectifier = (value) => {
    console.log(value);
    if (value !== undefined) {
      this.setState({
        rectifier: { id: value },
      });
    } else {
      this.setState({
        rectifier: null,
      });
    }
  };

  viewMoreDetails(element) {
    this.setState({
      showModal2: true,
      openViewMoreDetails: true,
      selectedElement: element,
    });
  }

  onViewMoreDetailsDrawerClose = () => {
    this.setState({
      showModal2: false,
      openViewMoreDetails: false,
    });
  };

  validatedTextLable = (field) => {
    return field !== null ? (
      <span class="badge badge-outline-secondary text-muted">{field}</span>
    ) : (
      <span class="badge text-danger">Currently Not Set</span>
    );
  };

  render() {
    const { Option } = Select;
    const { Panel } = Collapse;
    const _dcdu = this.state.selectedElement;

    const columns2 = [
      {
        title: "Type",
        details: [],
        content: (
          <Tag color="blue" style={{ margin: "0px" }}>
            <span>{_dcdu.type}</span>
          </Tag>
        ),
      },
      {
        title: "Power from LLVD/BLVD",
        details: [],
        content: this.validatedTextLable(_dcdu.powerFromLLVD),
      },
      {
        title: "Rectifier Breaker capacity (A) to power DCDU",
        details: [],
        content: this.validatedTextLable(_dcdu.rbcapacityToPowerDCDU),
      },
      {
        title: "Total Capacity",
        details: [],
        content: this.validatedTextLable(_dcdu.totalCapacity),
      },
      {
        title: "No Of Breaker Slots",
        details: [],
        content: this.validatedTextLable(_dcdu.noOfBreakerSlots),
      },
      {
        title: "Free Breaker Slots",
        details: [],
        content: this.validatedTextLable(_dcdu.freeBreakerSlots),
      },
      {
        title: "Rectifier",
        details: [],
        content:
          _dcdu !== "" ? (
            _dcdu.rectifier != null && _dcdu.rectifier.delete == false ? (
              <span
                class="badge badge-soft-pink fw-semibold"
                style={{ marginLeft: "5px" }}
              >
                {_dcdu.rectifier?.name}
              </span>
            ) : (
              <span class="badge badge-outline-secondary text-muted">No</span>
            )
          ) : (
            <></>
          ),
      },
    ];

    return (
      <Fragment>
        <div className="row">
          <div class="col-lg-12">
            <div class="text-end">
              <ul class="list-inline">
                <li class="list-inline-item">
                  {checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                    <Button
                      type="button"
                      class="btn btn-soft-primary btn-sm"
                      style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px' }}
                      onClick={this.requestFromClose}
                      icon={<PlusCircleOutlined style={{ color: 'white' }} />}>
                      <span className="textStyles-small" style={{ color: 'white' }}>New Asset</span>
                    </Button>
                  ) : (
                    ""
                  )}
                </li>
              </ul>
            </div>
          </div>

          {/* {this.props.details.access_technologies_data != null ? (
            <div className="col-lg-2" style={{ paddingBottom: 12 }}>
              <table class="table table-dark2 mb-0" style={{ fontSize: 12 }}>
                <tbody>
                  <tr>
                    <td scope="row">
                      <b>No of DCDUs:</b>
                    </td>
                    <td>
                      {this.props.details2.dcdus_data.length}
                    </td>
                    <td scope="row"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )} */}

          <Row span={24}>
            {this.props.details2.dcdus_data.map((element, index) => (
              <Col span={8} key={index}>
                <Card
                  bordered={false}
                  style={{
                    width: '98%',
                    borderRadius: '10px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                    marginTop: 10
                  }}
                >
                  <div style={{ display: 'flex', marginBottom: 16, backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '10px' }}>
                    <div>
                      <HomeOutlined style={{ marginLeft: '3px', marginRight: '3px' }} /> &rarr;
                      <span style={{ marginLeft: '3px', marginRight: '3px', fontWeight: 'bold' }} className="textStyles-small">
                        {element.rectifier != null
                          ? element.rectifier?.name?.substring(0, 11)
                          : "No Rectifier"}
                      </span>
                      &rarr;
                      <span style={{ marginLeft: '3px', color: '#BA183A', fontWeight: 'bold' }} className="textStyles-small">{element?.type?.substring(0, 18)}</span>
                    </div>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small">Type</span>
                    <Tag color="magenta" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                      <span className="textStyles-small" style={{ fontSize: 11 }}>
                        {element.type}
                      </span></Tag>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small" style={{ fontSize: 12 }}>Total Capacity</span>
                    <Tag color="default" style={{ float: 'right', borderRadius: 5 }}>
                      <span className="textStyles-small">
                        {element.totalCapacity}
                      </span>
                    </Tag>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small">Breaker Capacity To Power DCDU (A)</span>
                    <Tag color="yellow" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                      <span className="textStyles-small" style={{ fontSize: 11 }}>
                        {element.rbcapacityToPowerDCDU ? element.rbcapacityToPowerDCDU : 'Currently Not Set'}
                      </span>
                    </Tag>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small">Breaker Slots</span>
                    {
                      element.noOfBreakerSlots ? (
                        <Tag color="green" style={{ float: 'right', borderRadius: 5 }} >
                          <span className="textStyles-small" style={{ fontSize: 11 }}>
                            {element.noOfBreakerSlots}
                          </span></Tag>
                      ) : (
                        <Tag color="magenta" style={{ float: 'right', borderRadius: 5 }} >
                          <span className="textStyles-small" style={{ fontSize: 11 }}>
                            Currently Not Set
                          </span></Tag>
                      )
                    }
                  </div>
                  <Divider style={{ marginTop: '3px' }} />
                  <Button
                    type="primary"
                    style={{ marginRight: 8, borderRadius: '5px' }}
                    size='small'
                    onClick={() => this.viewMoreDetails(element)}>
                    <span className="textStyles-small" style={{ fontSize: 10 }}> <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details</span>
                  </Button>
                  {
                    checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                      <Button
                        icon={<EditOutlined style={{ color: 'white' }} />}
                        style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                        onClick={() => this.dcduUpdata(element.id)}
                        size='small'
                      />
                    ) : ('')
                  }
                  <Popconfirm
                    title={<span className='textStyles-small' style={{ fontSize: 14 }}>Are you sure to delete this Asset?</span>}
                    description={<span className="textStyles-small">Are you sure to delete this Asset?</span>}
                    onConfirm={() => this.submitForm(element.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    {checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                      <Button
                        icon={<DeleteOutlined style={{ color: 'white' }} />}
                        danger
                        size='small'
                        style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#BA183A', borderColor: '#BA183A' }}
                      />
                    )
                      : ('')}
                  </Popconfirm>

                </Card>
              </Col>
            ))}
          </Row>

          <Drawer
            maskClosable={false}
            title={<span className="textStyles-small" style={{ fontSize: 14 }}>DCDU Details</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onViewMoreDetailsDrawerClose()}
            visible={this.state.openViewMoreDetails}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <Collapse
              style={{ alignContent: "center", borderColor: "white" }}
            >
              {columns2.map((item, index) => (
                <Panel
                  style={{
                    borderColor: index % 2 === 0 ? "#f8f9fa" : "white",
                    backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white"
                  }}
                  header={
                    <li className="d-flex justify-content-between align-items-center"
                      style={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white" }}>
                      <div>
                        <span className="textStyles-small">{item.title}</span>
                      </div>
                      <span className="textStyles-small">{item.content}</span>
                    </li>
                  }
                  key={item.title}
                  showArrow={item.details.length > 0}
                  collapsible={item.details.length > 0 ? "" : "icon"}
                >
                  {item.details.map((detail) => (
                    <li
                      className="d-flex justify-content-between align-items-center me-2"
                      style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                      key={detail.title}
                    >
                      <div>
                        <span className='textStyles-small'>{detail.title}</span>
                      </div>
                      <span className="textStyles-small">{detail.content}</span>
                    </li>
                  ))}
                </Panel>
              ))}
            </Collapse>
          </Drawer>

          <Drawer
            maskClosable={false}
            title={<span className="textStyles-small" style={{ fontSize: 14 }}>New DCDU</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.open}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card" style={{ marginBottom: "10px", borderColor: 'white' }}>
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Type <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="dcdu_Type"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                onChange={(e) => {
                                  this.handleDcduType(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduType"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "dcdu_Type",
                                this.state.dcduType,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Power from LLVD/BLVD{" "}
                            </span>
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item
                            required
                            name="powerFromLLVD"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                onChange={(e) => {
                                  this.handlePowerFromLLVD(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduPowerFromLLVD"
                                ].map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "powerFromLLVD",
                                this.state.dcduPowerFromLLVD,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Rectifier Breaker capacity (A) to power DCDU{" "}
                            </span>
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item required>
                            <div>
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handleRbcapacityToPowerDCDU(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduRbcapacityToPowerDCDU"
                                ].map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "RbcapacityToPowerDCDU",
                                this.state.rbcapacityToPowerDCDU,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Total Capacity{" "}
                            </span>
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item required name="dcdu_Capacity">
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              id="dcdu_Capacity"
                              onChange={this.handleDcduCapacity}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "dcdu_Capacity",
                                this.state.dcduCapacity,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              No Of Breaker Slots{" "}
                            </span>
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item required>
                            <div>
                              <Select
                                style={{ width: "100%", height: 34 }}
                                className='borderedSelect'
                                bordered={false}
                                onChange={(e) => {
                                  this.handleNoOfBreakerSlots(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduNoOfBreakerSlots"
                                ].map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "NoOfBreakerSlots",
                                this.state.dcduNoOfBreakerSlots,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Free Breaker Slots <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item required>
                            <div>
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handleFreeBreakerSlots(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduFreeBreakerSlots"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "free_Breaker_Slots",
                                this.state.dcduFreeBreakerSlots,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Rectifier <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              className='borderedSelect'
                              bordered={false}
                              onChange={this.onChangeRectifier}
                              style={{ width: "100%" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              allowClear
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.dcduMasterData?.dcduMasterData?.rectifierList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item?.id}>
                                      {item?.name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Rectifier",
                                this.state.rectifier,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        <Button
                          className="btn btn-primary"
                          onClick={() => this.AllDataSubmit()}
                          style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}
                        >
                          <span className="textStyles-small" style={{ color: 'white' }}>Add This Asset</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>

          <Drawer
            maskClosable={false}
            title={<span className="textStyles-small" style={{ fontSize: 14 }}>Edit DCDU</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.openEdit}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card" style={{ marginBottom: "10px", borderColor: 'white' }}>
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Type <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <div>
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                value={this.state.dcduType}
                                onChange={(e) => {
                                  this.handleDcduType(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduType"
                                ].map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "dcdu_Type",
                                this.state.dcduType,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Power from LLVD/BLVD{" "}
                            </span>
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item>
                            <div>
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                value={this.state.dcduPowerFromLLVD}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handlePowerFromLLVD(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduPowerFromLLVD"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "powerFromLLVD",
                                this.state.dcduPowerFromLLVD,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Rectifier Breaker capacity (A) to power DCDU{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <div>
                              <Select
                                value={this.state.rbcapacityToPowerDCDU}
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                onChange={(e) => {
                                  this.handleRbcapacityToPowerDCDU(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduRbcapacityToPowerDCDU"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "RbcapacityToPowerDCDU",
                                this.state.rbcapacityToPowerDCDU,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Total Capacity{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <InputNumber
                              style={{ width: "100%", height: 34 }}
                              className='borderedSelect'
                              value={this.state.dcduCapacity}
                              id="dcdu_Capacity"
                              onChange={this.handleDcduCapacity}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "dcdu_Capacity",
                                this.state.dcduCapacity,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              No Of Breaker Slots <x style={{ color: "red" }}></x>
                            </span>
                          </label>
                          <Form.Item>
                            <div>
                              <Select
                                value={this.state.dcduNoOfBreakerSlots}
                                className='borderedSelect'
                                bordered={false}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handleNoOfBreakerSlots(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduNoOfBreakerSlots"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "NoOfBreakerSlots",
                                this.state.dcduNoOfBreakerSlots,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Free Breaker Slots <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item>
                            <div>
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                value={this.state.dcduFreeBreakerSlots}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handleFreeBreakerSlots(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduFreeBreakerSlots"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "free_Breaker_Slots",
                                this.state.dcduFreeBreakerSlots,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Rectifier <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          {this.state.rectifierId != null ? (
                            <Form.Item>
                              <Select
                                showSearch
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={this.state.rectifierId}
                                onChange={this.onChangeRectifier}
                                style={{ width: "100%" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.dcduMasterData?.dcduMasterData?.rectifierList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item?.id}>
                                        {item?.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                {this.validator.message(
                                  "Rectifier",
                                  this.state.rectifier,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                          {this.state.rectifierId == null ? (
                            <Form.Item required>
                              <Select
                                showSearch
                                className='borderedSelect'
                                bordered={false}
                                onChange={this.onChangeRectifier}
                                style={{ width: "100%" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.dcduMasterData?.dcduMasterData?.rectifierList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item?.id}>
                                        {item?.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                {this.validator.message(
                                  "Rectifier",
                                  this.state.rectifier,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        <Button
                          className="btn btn-primary"
                          onClick={() => this.AllDataSubmitUpdate()}
                          style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}
                        >
                          <span className="textStyles-small" style={{ color: 'white' }}>Update This Asset</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </Fragment>
    );
  }
}
export default Dcdus;
