import React, { Component } from 'react';
import './header.css';
import $ from 'jquery';
import {
    UserOutlined
} from "@ant-design/icons";

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
    }


    all = () => {
        $("body").toggleClass("enlarge-menu");
        $('.button-menu-mobile').on('click', function (event) {
            event.preventDefault();
        });
    }

    render() {
        const { data, isViewerOpen } = this.state;
        return (
            <div>
                <div className="topbar new-updet-heder">
                    <nav class="navbar-custom" 
                    // style={{ borderRadius: '10px', width: '99%', margin: '10px', zIndex: '1000' }}
                    >
                        <ul class="list-unstyled topbar-nav float-end mb-0">
                            <li class="dropdown hide-phone">
                                <this.props.mainContent />
                            </li>
                            <li class="dropdown">
                                <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-bs-toggle="dropdown" href="#" role="button"
                                    aria-haspopup="false" aria-expanded="false">

                                    <img src={'https://cdn-icons-png.flaticon.com/512/219/219986.png'} alt="profile-user" class="rounded-circle thumb-xs" />
                                </a>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a class="dropdown-item" href={'/profile'}><i data-feather="user" class="align-self-center icon-xs icon-dual me-1"></i> Profile</a>
                                    <a class="dropdown-item" href="#"><i data-feather="settings" class="align-self-center icon-xs icon-dual me-1"></i> Settings</a>
                                    <div class="dropdown-divider mb-0"></div>
                                    <a class="dropdown-item" href="#"><i data-feather="power" class="align-self-center icon-xs icon-dual me-1"></i> <this.props.signOutButton /></a>
                                </div>
                            </li>
                        </ul>
                        <ul class="list-unstyled topbar-nav mb-0">
                            <li>
                                <button class="nav-link button-menu-mobile cc" onClick={this.all}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu align-self-center topbar-icon"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}

export default Header;

