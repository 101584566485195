import React, { Component, Fragment } from 'react'
import { Table, Input, InputNumber, message, Modal, Select, Row, Col } from "antd";
import { updateDcPowerSharing, getAllDropdowns } from "../../api/index";
import checkPermissions from '../../utils/permissionManager';
const { Option } = Select;

class DCPowerSharing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchStatus: true,
            objectSet: {
                "dcpsSharedFrom": "",
                "dcpsSharedCapacity": 0,
                "dcpsAverageLoad": 0
            },
            dropDownData: {
                "dcpsSharedFrom": [],
                "dcpsSharedCapacity": [],
            }
        }
        this.handledcpsSharedFrom = this.handledcpsSharedFrom.bind(this);
        this.handldcpsSharedCapacity = this.handldcpsSharedCapacity.bind(this);
        this.handldcpsAverageLoad = this.handldcpsAverageLoad.bind(this);
    }

    getSharedBackupPowerDropDownData = async () => {
        var res = await getAllDropdowns();
        if (res.success) {
            this.setState({
                dropDownData: {
                    "dcpsSharedFrom": res.data["DC power Sharing"]["Shared from"],
                    "dcpsSharedCapacity": res.data["DC power Sharing"]["Shared capacity(A)"],
                }
            })
        } else {
            message.warning('Issue With Dropdown Data Fetching! : ' + res.data.message);
        }
    }

    handledcpsSharedFrom(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                dcpsSharedFrom: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }

    handldcpsSharedCapacity(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                dcpsSharedCapacity: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handldcpsAverageLoad(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                dcpsAverageLoad: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    componentDidMount() {
        console.log(this.props.details)
        this.setState({
            objectSet: {
                "dcpsSharedFrom": this.props.details.access_technologies_data?.dcpsSharedFrom,
                "dcpsSharedCapacity": this.props.details.access_technologies_data?.dcpsSharedCapacity,
                "dcpsAverageLoad": this.props.details.access_technologies_data?.dcpsAverageLoad,
            }
        })
        this.getSharedBackupPowerDropDownData();
    }


    showUpdateConfirm = () => {
        const { confirm } = Modal;
        confirm({
            title: 'Are You Want To Update These Data, Related To DC Power Sharing?',
            onOk: async () => {
                this.dataSetToUpdate();
            },
            onCancel() { },
        });
    };

    dataSetToUpdate = async () => {
        var res = await updateDcPowerSharing(this.props.details2.site_details.id, this.state.objectSet);
        if (res.success) {
            message.success('Successfully Update!')
            this.props.parentMethod();
            this.setState({
                switchStatus: true
            })
        } else {
            message.warning(' Something went wrong!')
        }
    }

    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "dcpsSharedFrom": this.props.details.access_technologies_data?.dcpsSharedFrom,
                "dcpsSharedCapacity": this.props.details.access_technologies_data?.dcpsSharedCapacity,
                "dcpsAverageLoad": this.props.details.access_technologies_data?.dcpsAverageLoad,
            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        const { details } = this.props.details
        return (
            <Fragment>
                {this.props.details.access_technologies_data != null ? (
                    <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
                        <div className="d-flex justify-content-end align-items-center" style={{ marginBottom: "10px", marginRight: "10px" }}>
                            {this.state.switchStatus ? (
                                checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                    <button
                                        type="button"
                                        className="btn btn-soft-primary btn-icon-square-sm"
                                        onClick={() => this.dataUpdate()}
                                        style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                    >
                                        <i className="mdi mdi-pencil-outline" style={{ color: 'white' }}></i>
                                    </button>
                                ) : null
                            ) : (
                                <div className="d-flex justify-content-end align-items-center">
                                    <button
                                        type="button"
                                        className="btn btn-soft-secondary"
                                        onClick={() => this.close()}
                                        style={{ marginRight: "10px" }}
                                    >
                                        <span className="badge badge-danger">Cancel</span>
                                    </button>
                                    {this.state.objectSet?.dcpsAverageLoad == null || this.state.objectSet?.dcpsAverageLoad == "" ? (
                                        <button type="button" className="btn btn-soft-primary" disabled>
                                            <span className="badge badge-danger">Submit To Update</span>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-soft-primary"
                                            onClick={() => this.showUpdateConfirm()}
                                        >
                                            <span className="badge badge-danger">Submit To Update</span>
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                        <ul class="list-group">
                            <Row>
                                <Col span={12}>
                                    <div style={{ width: '99%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f5f5f5' }}>
                                            <div>
                                                <span className='textStyles-small'>DC Power Shared From</span>
                                            </div>
                                            <div>
                                                {this.state.switchStatus ? this.props.details.access_technologies_data?.dcpsSharedFrom != null ||
                                                    this.props.details.access_technologies_data?.dcpsSharedFrom != undefined ? (
                                                    <span className="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.dcpsSharedFrom}</span>) :
                                                    (<span className="badge badge-outline-danger textStyles-small">Not Defined Yet</span>) : (
                                                    <Select
                                                        className='borderedSelect'
                                                        bordered={false}
                                                        defaultValue={this.props.details.access_technologies_data?.dcpsSharedFrom}
                                                        style={{ width: 120 }}
                                                        onChange={(e) => {
                                                            this.state.objectSet.dcpsSharedFrom = e;
                                                        }} >
                                                        {this.state.dropDownData.dcpsSharedFrom.map((x, y) => {
                                                            return <Option value={x}>{x}</Option>
                                                        })}
                                                    </Select>
                                                )}
                                            </div>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: 'white' }}>
                                            <div>
                                                <span className='textStyles-small'>Shared Capacity(A)</span>
                                            </div>
                                            <div>
                                                {this.state.switchStatus ? this.props.details.access_technologies_data?.dcpsSharedCapacity != null ||
                                                    this.props.details.access_technologies_data?.dcpsSharedCapacity != undefined ? (
                                                    <span className="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.dcpsSharedCapacity}</span>) :
                                                    (<span className="badge badge-outline-danger textStyles-small">Not Defined Yet</span>) : (
                                                    <Select
                                                        className='borderedSelect'
                                                        bordered={false}
                                                        defaultValue={this.props.details.access_technologies_data?.dcpsSharedCapacity}
                                                        style={{ width: 120 }}
                                                        onChange={(e) => {
                                                            this.state.objectSet.dcpsSharedCapacity = e;
                                                        }}>
                                                        {this.state.dropDownData.dcpsSharedCapacity.map((x, y) => {
                                                            return <Option value={x}>{x}</Option>
                                                        })}
                                                    </Select>
                                                )}
                                            </div>
                                        </li>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{ width: '99%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: 'white' }}>
                                            <div>
                                                <span className='textStyles-small'>Average Load(A)
                                                    {this.state.switchStatus == false ? "*Required" : ""}</span>
                                            </div>
                                            {this.state.switchStatus ? this.props.details.access_technologies_data?.dcpsAverageLoad ? (
                                                <span class="badge badge-outline-success textStyles-small">{
                                                    this.props.details.access_technologies_data.dcpsAverageLoad}</span>) : (
                                                <span class="badge badge-outline-warning textStyles-small">{
                                                    this.props.details.access_technologies_data.dcpsAverageLoad}</span>) :
                                                <InputNumber style={{ width: 120 }}
                                                    className='borderedSelect'
                                                    value={this.state.objectSet?.dcpsAverageLoad} id="mpcNoOfPhases" onChange={this.handldcpsAverageLoad} />}
                                        </li>
                                    </div>
                                </Col>
                            </Row>
                        </ul>
                    </div>
                ) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 style={{ color: "#c5c5c5" }}>No Dat Found DC Power Sharing</h4></div>)}
            </Fragment>
        )
    }
}
export default DCPowerSharing;
