import React, { Component, Fragment } from "react";
import checkPermissions from "../../utils/permissionManager";
import {
  Table,
  Tag,
  Button,
  Drawer,
  Form,
  message,
  Select,
  Input,
  DatePicker,
  Popconfirm,
  Collapse,
  InputNumber,
  Row,
  Col,
  Card,
  Divider
} from "antd";
import { PlusCircleOutlined, DeleteOutlined, HomeOutlined, EditOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import {
  createNewFcb,
  getFcbDetails,
  UpdateFcb,
  dataUpdateGeneralDetails,
} from "../../api/index";
import moment from "moment";
import AssetDelete from "../site_components/asset_delete";
import { checkServiceType } from "../../actions/service-type";

class Fdc extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

    this.state = {
      showModal: false,
      open: false,
      openEdit: false,
      title: "",
      tabaleLoading: false,
      actionView: false,
      selectedAssetId: null,
      AssetType: [],
      selectedMakeId: null,
      makeType: [],
      fbcCapacity: "",
      selectedModelId: null,
      modelType: [],
      fbclastMaintainanceDate: null,
      fbcwarrantyExpireDate: null,
      fbcInstalledDate: null,
      AssetManagementPermission: false,
      openViewMoreDetails: false,
      selectedElement: "",
      serialNumber: null,
      switchStatus: true,

      objectSet: {
        fcmTicketNumberOfPendingFaultyTicket: 0,
      },
    };
    this.requestFromClose = this.requestFromClose.bind(this);
    this.handleFbcType = this.handleFbcType.bind(this);
    this.handleFbcSerialNumber = this.handleFbcSerialNumber.bind(this);
  }
  fbcUpdata(id) {
    // console.log('dfdfd')
    console.log(id);
    this.getDataFcbDetails(id);
    this.setState({ openEdit: true });
  }
  getDataFcbDetails = async (id) => {
    var res = await getFcbDetails(id);
    console.log(res);
    if (res.success) {
      this.setState({
        fbcEdit: res.data,
        fbcId: res.data.id,
        selectedMakeId: res.data.make.id,
        makeType: res.data.make,
        selectedModelId: res.data.model.id,
        modelType: res.data.model,
        fbcCapacity: res.data.capacity,
        serialNumber: res.data.serialNumber,
      });
      if (res.data?.installedDate != null) {
        this.setState({
          fbcInstalledDate: res.data?.installedDate,
        });
      } else {
        this.setState({
          fbcInstalledDate: "",
        });
      }
      if (res.data?.warrantyExpireDate != null) {
        this.setState({
          fbcwarrantyExpireDate: res.data?.warrantyExpireDate,
        });
      } else {
        this.setState({
          fbcwarrantyExpireDate: "",
        });
      }
      if (res.data?.lastMaintainanceDate != null) {
        this.setState({
          fbclastMaintainanceDate: res.data?.lastMaintainanceDate,
        });
      } else {
        this.setState({
          fbclastMaintainanceDate: "",
        });
      }
    }
  };
  handleFbcSerialNumber(e) {
    console.log(e.target.value);
    this.setState({ serialNumber: e.target.value });
  }

  handleFbcType(e) {
    console.log(e);
    this.setState({ fbcCapacity: e });
  }

  requestFromClose() {
    console.log("dsdsd");
    this.setState({ showModal: true, open: true });
  }

  componentDidMount() {
    console.log("fcdMasterData");
    console.log(this.props.fcdMasterData);
    if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
      const { history } = this.props;

      history.push("/");
    } else {
      this.getSetPageData(
        JSON.parse(localStorage.getItem("serviceTypeSet")),
        38
      );
    }
    checkServiceType(38).then((data) =>
      this.setState(
        {
          AssetManagementPermission: data,
        },
        () => { }
      )
    );
    this.setState({
      objectSet: {
        fcmTicketNumberOfPendingFaultyTicket:
          this.props?.details?.access_technologies_data
            ?.fcmTicketNumberOfPendingFaultyTicket,
      },
    });
    // console.log(this.props.details2)
  }
  getSetPageData = (serviceTypeIs, Pageid) => {
    if (
      serviceTypeIs.serviceType.filter(
        (checkuser) => checkuser?.id == Pageid
      )[0] != null
    ) {
      console.log("fddf");
      this.setState({
        actionView: true,
      });
    }
  };
  onDrawerClose = () => {
    console.log("sddsds");
    this.formRef.current.resetFields();
    this.validator.hideMessages();
    this.setState({
      open: false,
      openEdit: false,
      selectedAssetId: null,
      AssetType: [],
      selectedMakeId: null,
      makeType: [],
      fbcCapacity: "",
      selectedModelId: null,
      modelType: [],
      fbcCapacity: "",
      fbclastMaintainanceDate: null,
      fbcwarrantyExpireDate: null,
      fbcInstalledDate: null,
      serialNumber: null,
    });
  };
  onChangeAsset = (value) => {
    console.log("onChangeAsset");
    //console.log(value);
    this.setState({
      selectedAssetId: value,
      AssetType: {
        id: value,
      },
    });
  };
  onChangeMake = (value) => {
    console.log("onChangeMake");
    //console.log(value);
    this.setState({
      selectedMakeId: value,
      makeType: {
        id: value,
      },
    });
  };
  onChangeModel = (value) => {
    console.log("onChangeModel");
    //console.log(value);
    this.setState({
      selectedModelId: value,
      modelType: {
        id: value,
      },
    });
  };
  onChangeInstalledDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      fbcInstalledDate: dateString,
    });
  };
  onChangeWarrantyExpireDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      fbcwarrantyExpireDate: dateString,
    });
  };
  onChangeLastMaintainanceDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      fbclastMaintainanceDate: dateString,
    });
  };
  AllDataSubmitUpdate = async () => {
    if (this.validator.allValid()) {
      var dataSet;

      dataSet = {
        id: this.state.fbcId,
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        make: this.state.makeType,
        model: this.state.modelType,
        capacity: this.state.fbcCapacity,
        lastMaintainanceDate: this.state.fbclastMaintainanceDate,
        warrantyExpireDate: this.state.fbcwarrantyExpireDate,
        installedDate: this.state.fbcInstalledDate,
        serialNumber: this.state.serialNumber,
      };

      var res = await UpdateFcb(dataSet);
      console.log(res);
      if (res.success) {
        message.success("Successfully FBC Edited!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,
          openEdit: false,

          selectedAssetId: null,
          AssetType: [],
          selectedMakeId: null,
          makeType: [],
          fbcCapacity: "",
          selectedModelId: null,
          modelType: [],
          fbcCapacity: "",
          fbclastMaintainanceDate: null,
          fbcwarrantyExpireDate: null,
          fbcInstalledDate: null,
          serialNumber: null,
        });
        this.props.parentMethod();
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  AllDataSubmit = async () => {
    if (this.validator.allValid()) {
      var dataSet;
      dataSet = {
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        make: this.state.makeType,
        model: this.state.modelType,
        capacity: this.state.fbcCapacity,
        lastMaintainanceDate: this.state.fbclastMaintainanceDate,
        warrantyExpireDate: this.state.fbcwarrantyExpireDate,
        installedDate: this.state.fbcInstalledDate,
        serialNumber: this.state.serialNumber,
      };

      var res = await createNewFcb(dataSet);
      if (res.success) {
        message.success("Successfully FBC Added!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,

          selectedAssetId: null,
          AssetType: [],
          selectedMakeId: null,
          makeType: [],
          fbcCapacity: "",
          selectedModelId: null,
          modelType: [],
          fbcCapacity: "",
          fbclastMaintainanceDate: null,
          fbcwarrantyExpireDate: null,
          fbcInstalledDate: null,
          serialNumber: null,
        });
        this.props.parentMethod();
        // const { history } = this.props;
        // history.push("/site");
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  getDataDetailsForDelete = async (id) => {
    var res = await getFcbDetails(id);
    console.log(res);
    if (res.success) {
      var respons = AssetDelete.Action(
        res.data,
        this.props?.detailsSite?.site_details.id,
        UpdateFcb
      );
      respons.then((value) => {
        if (value == 1) {
          message.success("Successfully Delete!");
          this.props.parentMethod();
        } else {
          message.warning(" Something went wrong!");
        }
      });
    }
  };
  submitForm = async (item) => {
    this.getDataDetailsForDelete(item);
  };

  viewMoreDetails(element) {
    this.setState({
      showModal2: true,
      openViewMoreDetails: true,
      selectedElement: element,
    });
  }

  onViewMoreDetailsDrawerClose = () => {
    this.setState({
      showModal2: false,
      openViewMoreDetails: false,
    });
  };

  validatedTextLable = (field) => {
    return field !== null && field !== "" ? (
      <span class="badge badge-outline-secondary text-muted">{field}</span>
    ) : (
      <span class="badge text-danger">Currently Not Set</span>
    );
  };
  handlfcmTicketNumberOfPendingFaultyTicket = (e) => {
    this.setState(
      {
        objectSet: {
          ...this.state.objectSet,
          fcmTicketNumberOfPendingFaultyTicket: e,
        },
      },
      () => {
        console.log(this.state.objectSet);
      }
    );
  };
  dataUpdate() {
    console.log("ssss");
    this.setState({
      switchStatus: false,
    });
  }
  close = async () => {
    this.props.parentMethod();
    this.setState({
      objectSet: {
        fcmTicketNumberOfPendingFaultyTicket:
          this.props?.details?.access_technologies_data
            ?.fcmTicketNumberOfPendingFaultyTicket,
      },
    });
    this.setState({
      switchStatus: true,
    });
  };
  dataSetToUpdate = async () => {
    console.log(this.state.objectSet);
    console.log(this.props.detailsupdateGeneralDetails.updateGeneralDetails);
    let remoteJob = {
      ...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
      ...this.state.objectSet,
    };
    const formData = new FormData();
    formData.append("id", this.props?.details?.access_technologies_data?.id);
    formData.append("object", JSON.stringify(remoteJob));

    var res = await dataUpdateGeneralDetails(formData);

    if (res.success) {
      message.success("Successfully Update!");
      this.props.parentMethod();
      this.setState({
        switchStatus: true,
      });
    } else {
      message.warning(" Something went wrong!");
    }
  };

  render() {
    const { Option } = Select;
    const { Panel } = Collapse;
    const _fcb = this.state.selectedElement;
    const columns2 = [
      {
        title: "Make",
        content:
          _fcb !== "" ? (
            <Tag color="gray" style={{ margin: "0px" }}>
              <span>{_fcb.make?.name}</span>
            </Tag>
          ) : (
            <></>
          ),
        details: [],
      },
      {
        title: "Model",
        content:
          _fcb !== "" ? (
            <span class="badge badge-outline-info badge-pill">
              {_fcb.model?.name}
            </span>
          ) : (
            <></>
          ),
        details: [
          {
            title: "Capacity (W)",
            content: this.validatedTextLable(_fcb.model?.capacity),
          },
        ],
      },
      {
        title: "Serial Number",
        content: this.validatedTextLable(_fcb.serialNumber),
        details: [],
      },
      {
        title: "Warranty Expire Date",
        content: this.validatedTextLable(_fcb.warrantyExpireDate),
        details: [],
      },
      {
        title: "Installed Date",
        content: this.validatedTextLable(_fcb.installedDate),
        details: [],
      },
      {
        title: "Last Maintainance Date",
        content: this.validatedTextLable(_fcb.lastMaintainanceDate),
        details: [],
      },
    ];
    return (
      <Fragment>
        <div className="row">
          <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
            <div className="d-flex justify-content-end align-items-center" style={{ marginBottom: "10px", marginRight: "10px" }}>
              {this.state.switchStatus
                ?
                checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                <Fragment>
                  <button type="button" class="btn btn-soft-primary btn-icon-square-sm"
                    style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                    onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline" style={{ color: 'white' }}></i></button>
                  {this.state.actionView ? (
                    <Button
                      type="button"
                      class="btn btn-soft-primary btn-sm"
                      style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginLeft: 10 }}
                      onClick={this.requestFromClose}
                      icon={<PlusCircleOutlined style={{ color: 'white' }} />}>
                      <span className="textStyles-small" style={{ color: 'white' }}>New Asset</span>
                    </Button>
                  ) : (
                    <></>
                  )}
                </Fragment>) : <></>
                : <div class="d-flex justify-content-end align-items-center">
                  <button type="button" class="btn btn-soft-secondary  textStyles-small" onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
                  <button type="button" class="btn btn-soft-primary  textStyles-small" onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                </div>
              }
            </div>
            <ul class="list-group">
              <Row span={24}>
                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        <span className="textStyles-small">
                          No of FCB
                        </span>
                      </div>
                      {this.props.details?.access_technologies_data
                        ?.noOfFCB != null ? (
                        <span class="badge badge-outline-success textStyles-small">
                          {
                            this.props.details2.fcbs_data.length
                          }
                        </span>
                      ) : (
                        <span class="badge badge-outline-warning textStyles-small">
                          Currently Not Set
                        </span>
                      )}
                    </li>
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                      <div>
                        <span className="textStyles-small">
                          Ticket number of pending Faulty ticket
                        </span>
                      </div>
                      {this.state.switchStatus ? (
                        <>
                          {this.props?.details?.access_technologies_data
                            ?.fcmTicketNumberOfPendingFaultyTicket !=
                            null ? (
                            <span class="badge badge-outline-success textStyles-small">
                              {
                                this.props?.details
                                  ?.access_technologies_data
                                  ?.fcmTicketNumberOfPendingFaultyTicket
                              }
                            </span>
                          ) : (
                            <span class="badge badge-outline-warning textStyles-small">
                              Currently Not Set
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          <InputNumber
                            min={0}
                            style={{ width: '150px', height: 34 }}
                            className='borderedSelect'
                            value={
                              this.state.objectSet
                                ?.fcmTicketNumberOfPendingFaultyTicket
                            }
                            id="cnoOfCabinetsWithEquipmentInstalled"
                            onChange={
                              this.handlfcmTicketNumberOfPendingFaultyTicket
                            }
                          />
                        </>
                      )}
                    </li>
                  </div>
                </Col>
              </Row>
            </ul>
          </div>

          <Row span={24}>
            {this.props.details2.fcbs_data.map((element, index) => (
              <Col span={8} key={index}>
                <Card
                  bordered={false}
                  style={{
                    width: '98%',
                    borderRadius: '10px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                    marginTop: 10
                  }}
                >
                  <div style={{ display: 'flex', marginBottom: 16, backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '10px' }}>
                    <div>
                      <HomeOutlined style={{ marginLeft: '3px', marginRight: '3px' }} /> &rarr;
                      <span style={{ marginLeft: '3px', marginRight: '3px', fontWeight: 'bold' }} className="textStyles-small">
                        {this.props?.detailsSite?.site_details.siteName}
                      </span>
                      &rarr;
                      <span style={{ marginLeft: '3px', color: '#BA183A', fontWeight: 'bold' }} className="textStyles-small">{element.make?.name}</span>
                    </div>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small" style={{ fontSize: 12 }}>FCB Make</span>
                    <Tag color="default" style={{ float: 'right', borderRadius: 5 }}> <span className="textStyles-small">{element.make?.name}</span></Tag>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small">Model</span>
                    <Tag color="magenta" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                      <span className="textStyles-small" style={{ fontSize: 11 }}>
                        {element.model?.name}
                      </span></Tag>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small">Capacity (W)</span>
                    <Tag color="green" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                      <span className="textStyles-small" style={{ fontSize: 11 }}>
                        {element.model?.capacity != null ? element.model?.capacity : "Currently Not Set"} A
                      </span></Tag>
                  </div>
                  <div style={{ marginBottom: 8 }}>
                    <span className="textStyles-small">Warranty Expire Date</span>
                    {
                      element.warrantyExpireDate ?
                        <Tag color="orange" style={{ float: 'right', borderRadius: 5 }}><span className="textStyles-small" style={{ fontSize: 11 }}>{element.warrantyExpireDate}</span></Tag> :
                        <Tag color="red" style={{ float: 'right', borderRadius: 5 }}><span className="textStyles-small" style={{ fontSize: 11 }}>Currently Not Set</span></Tag>
                    }
                  </div>
                  <Divider style={{ marginTop: '3px' }} />
                  <Button
                    type="primary"
                    style={{ marginRight: 8, borderRadius: '5px' }}
                    size='small'
                    onClick={() => this.viewMoreDetails(element)}>
                    <span className="textStyles-small" style={{ fontSize: 10 }}> <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details</span>
                  </Button>
                  {checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                  <Button
                    icon={<EditOutlined style={{ color: 'white' }} />}
                    style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                    onClick={() => this.fbcUpdata(element.id)}
                    size='small'
                  />) : ('')}
                  <Popconfirm
                    title={<span className='textStyles-small' style={{ fontSize: 14 }}>Are you sure to delete this Asset?</span>}
                    description={<span className="textStyles-small">Are you sure to delete this Asset?</span>}
                    onConfirm={() => this.submitForm(element.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    {checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                        <Button
                          icon={<DeleteOutlined style={{ color: 'white' }} />}
                          danger
                          size='small'
                          style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#BA183A', borderColor: '#BA183A' }}
                        />
                      )
                      : ('')}
                  </Popconfirm>

                </Card>
              </Col>
            ))}
          </Row>

          <Drawer
            maskClosable={false}
            title={<span className="textStyles-small" style={{ fontSize: "14px" }}>FCB Details</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onViewMoreDetailsDrawerClose()}
            visible={this.state.openViewMoreDetails}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <Collapse
              style={{ alignContent: "center", borderColor: "white" }}
            >
              {columns2.map((item, index) => (
                <Panel
                  style={{
                    borderColor: index % 2 === 0 ? "#f8f9fa" : "white",
                    backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white"
                  }}
                  header={
                    <li className="d-flex justify-content-between align-items-center"
                      style={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white" }}>
                      <div>
                        <span className="textStyles-small">{item.title}</span>
                      </div>
                      <span className="textStyles-small">{item.content}</span>
                    </li>
                  }
                  key={item.title}
                  showArrow={item.details.length > 0}
                  collapsible={item.details.length > 0 ? "" : "icon"}
                >
                  {item.details.map((detail) => (
                    <li
                      className="d-flex justify-content-between align-items-center me-2"
                      style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                      key={detail.title}
                    >
                      <div>
                        <span className='textStyles-small'>{detail.title}</span>
                      </div>
                      <span className="textStyles-small">{detail.content}</span>
                    </li>
                  ))}
                </Panel>
              ))}
            </Collapse>

          </Drawer>

          <Drawer
            maskClosable={false}
            title={<span className="textStyles-small" style={{ fontSize: "14px" }}>New FCB</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.open}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card" style={{ borderColor: 'white' }}>
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Make <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              onChange={this.onChangeMake}
                              style={{ width: "100%" }}
                              className='borderedSelect'
                              bordered={false}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.fcdMasterData?.fcdMasterData?.fcdMakeList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item.id}>{item.name}</Option>
                                  );
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Make",
                                this.state.selectedMakeId,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Model <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              onChange={this.onChangeModel}
                              style={{ width: "100%" }}
                              className='borderedSelect'
                              bordered={false}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.fcdMasterData?.fcdMasterData?.fcdModelList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item.id}>{item.name}</Option>
                                  );
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Model",
                                this.state.selectedModelId,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Serial Number <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="cabinetSerialNumber"
                            tooltip="This is a required field"
                          >
                            <Input
                              id="cabinetSerialNumber"
                              onChange={this.handleFbcSerialNumber}
                              className='borderedSelect'
                              style={{ width: "100%", height: 33 }}
                            />
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "Cabinet Serial Number",
                                this.state.serialNumber,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Warranty Expire Date
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="warrantyExpireDate"
                            tooltip="This is a required field"
                          >
                            <DatePicker
                              style={{ width: "100%", height: 33 }}
                              className='borderedSelect'
                              onChange={this.onChangeWarrantyExpireDate}
                            />
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Installed Date
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="installedDate"
                            tooltip="This is a required field"
                          >
                            <DatePicker
                              className='borderedSelect'
                              style={{ width: "100%", height: 33 }}
                              onChange={this.onChangeInstalledDate}
                            />
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Last Maintainance Date
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="LastMaintainanceDate"
                            tooltip="This is a required field"
                          >
                            <DatePicker
                              className='borderedSelect'
                              style={{ width: "100%", height: 33 }}
                              onChange={this.onChangeLastMaintainanceDate}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        <Button
                          className="btn btn-primary"
                          style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginRight: '5px' }}
                          onClick={() => this.AllDataSubmit()}
                        >
                          <span className="textStyles-small" style={{ color: 'white' }}>Add This Asset</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>

          <Drawer
            maskClosable={false}
            title={<span className="textStyles-small" style={{ fontSize: "14px" }}>Edit FCB</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.openEdit}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card" style={{ borderColor: 'white' }}>
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        {this.state.selectedMakeId != null ? (
                          <div className="col-md-6">
                            <label className="form-label" for="depot">
                              <span className="textStyles-small">
                                Make <x style={{ color: "red" }}>*</x>
                              </span>
                            </label>

                            <Form.Item
                              required
                              name="Is_active"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                onChange={this.onChangeMake}
                                defaultValue={this.state.selectedMakeId}
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.fcdMasterData?.fcdMasterData?.fcdMakeList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                {this.validator.message(
                                  "Make",
                                  this.state.selectedMakeId,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          </div>
                        ) : (
                          <></>
                        )}
                        {this.state.selectedModelId != null ? (
                          <div className="col-md-6">
                            <label className="form-label" for="depot">
                              <span className="textStyles-small">
                                Model <x style={{ color: "red" }}>*</x>
                              </span>
                            </label>
                            <Form.Item
                              required
                              name="Is_active"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                onChange={this.onChangeModel}
                                defaultValue={this.state.selectedModelId}
                                className='borderedSelect'
                                bordered={false}
                                style={{ width: "100%" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.fcdMasterData?.fcdMasterData?.fcdModelList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                                {this.validator.message(
                                  "Model",
                                  this.state.selectedModelId,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            <span className="textStyles-small">
                              Serial Number <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item required>
                            <Input
                              id="cabinetSerialNumber"
                              value={this.state.serialNumber}
                              className='borderedSelect'
                              style={{ width: "100%", height: 34 }}
                              onChange={this.handleFbcSerialNumber}
                            />
                            <p style={{ color: "#f5325c", marginTop: "5px" }} className="textStyles-small">
                              {this.validator.message(
                                "Cabinet Serial Number",
                                this.state.serialNumber,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        {this.state.fbcwarrantyExpireDate != null ? (
                          <div className="col-md-6">
                            <label className="form-label" for="depot">
                              <span className="textStyles-small">
                                Warranty Expire Date
                              </span>
                            </label>
                            <Form.Item>
                              <DatePicker
                                defaultValue={
                                  this.state.fbcwarrantyExpireDate != ""
                                    ? moment(this.state.fbcwarrantyExpireDate)
                                    : ""
                                }
                                className='borderedSelect'
                                style={{ width: "100%", height: 34 }}
                                onChange={this.onChangeWarrantyExpireDate}
                              />
                            </Form.Item>
                          </div>
                        ) : (
                          <></>
                        )}
                        {this.state.fbcInstalledDate != null ? (
                          <div className="col-md-6">
                            <label className="form-label" for="depot">
                              <span className="textStyles-small">
                                Installed Date
                              </span>
                            </label>
                            <Form.Item>
                              <DatePicker
                                defaultValue={
                                  this.state.fbcInstalledDate != ""
                                    ? moment(this.state.fbcInstalledDate)
                                    : ""
                                }
                                className='borderedSelect'
                                style={{ width: "100%", height: 34 }}
                                onChange={this.onChangeInstalledDate}
                              />
                            </Form.Item>
                          </div>
                        ) : (
                          <></>
                        )}
                        {this.state.fbclastMaintainanceDate != null ? (
                          <div className="col-md-6">
                            <label className="form-label" for="depot">
                              <span className="textStyles-small">
                                Last Maintainance Date
                              </span>
                            </label>
                            <Form.Item>
                              <DatePicker
                                defaultValue={
                                  this.state.fbclastMaintainanceDate != ""
                                    ? moment(this.state.fbclastMaintainanceDate)
                                    : ""
                                }
                                className='borderedSelect'
                                style={{ width: "100%", height: 34 }}
                                onChange={this.onChangeLastMaintainanceDate}
                              />
                            </Form.Item>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <Button
                        className="btn btn-primary"
                        onClick={() => this.AllDataSubmitUpdate()}
                        style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}
                      >
                        <span className="textStyles-small" style={{ color: 'white' }}>Update This Asset</span>
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </Fragment>
    );
  }
}
export default Fdc;
