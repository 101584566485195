import React, { Component, Fragment } from "react";
import checkPermissions from '../../utils/permissionManager';
import {
  Table,
  Tag,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  InputNumber,
  DatePicker,
  Popconfirm,
  message,
  Collapse,
  Row,
  Col,
  Divider,
  Card
} from "antd";

import { PlusCircleOutlined, DeleteOutlined, EditOutlined, HomeOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import {
  createNewReWind,
  getReWindDetails,
  UpdateReWind,
  dataUpdateGeneralDetails,
} from "../../api/index";
import moment from "moment";
import { checkServiceType } from "../../actions/service-type";
import AssetDelete from "../site_components/asset_delete";

class Rew extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

    this.state = {
      title: "",
      actionView: false,
      tabaleLoading: false,
      open: false,
      openEdit: false,
      selectedMakeId: null,
      makeType: [],

      rewWindPotential: "",
      rewPerWindTurbineCapacity: "",
      rewWindTurbineCount: "",
      rewInstalledLocation: "",
      rewInstalledHeight: "",
      rewEnergyFeed: "",
      rewRmsconnectionInband: "",
      rewRmsconnectionMode: "",
      rewCommissionedDate: null,
      AssetManagementPermission: false,
      openViewMoreDetails: false,
      selectedElement: "",
      rectifier: [],
      selectedRectifierList: [],
      switchStatus: true,
      objectSet: {
        resExpectedEnergyGenerationFromWind: 0,
        resAverageEnergyGenerationFromWind: 0,
        windPotential: null,
      },

      dropdownObjWind: {
        rewPotential: "",
        rewWindTurbineCount: "",
        rewInstalledLocation: "",
        rewRmsconnectionInband: "",
        rewEnergyFeed: "",
      },
    };
    this.requestFromClose = this.requestFromClose.bind(this);
    this.handleWindPotential = this.handleWindPotential.bind(this);
    this.handleperWindTurbineCapacity =
      this.handleperWindTurbineCapacity.bind(this);
    this.handleInstalledLocation = this.handleInstalledLocation.bind(this);
    this.handleInstalledHeight = this.handleInstalledHeight.bind(this);
    this.handleRmsconnectionInband = this.handleRmsconnectionInband.bind(this);
    this.handleRmsconnectionMode = this.handleRmsconnectionMode.bind(this);
    this.handleEnergyFeed = this.handleEnergyFeed.bind(this);
    this.handleWindTurbineCount = this.handleWindTurbineCount.bind(this);
  }
  requestFromClose() {
    console.log("dsdsd");
    this.setState({ showModal: true, open: true });
  }
  handleWindTurbineCount(e) {
    console.log(e);
    this.setState({ rewWindTurbineCount: Math.round(e) });
  }
  handleEnergyFeed(e) {
    this.setState({ rewEnergyFeed: e });
  }
  handleRmsconnectionMode(e) {
    this.setState({ rewRmsconnectionMode: e });
  }
  handleRmsconnectionInband(e) {
    this.setState({ rewRmsconnectionInband: e });
  }
  handleInstalledHeight(e) {
    console.log(e);
    this.setState({ rewInstalledHeight: e });
  }
  handleInstalledLocation(e) {
    this.setState({ rewInstalledLocation: e });
  }
  handleperWindTurbineCapacity(e) {
    console.log(e);
    this.setState({ rewPerWindTurbineCapacity: e });
  }
  handleWindPotential(e) {
    this.setState({ rewWindPotential: e });
  }
  onChangeCommissionedDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      rewCommissionedDate: dateString,
    });
  };
  onChangeRectifier = (value) => {
    var _tempList = [];
    value.map((e) => {
      _tempList.push({ id: e });
    });
    this.setState({
      rectifier: _tempList,
    });
  };
  RewUpdata(id) {
    // console.log('dfdfd')
    console.log(id);
    this.getDataReWindDetails(id);
    this.setState({ openEdit: true });
  }
  getDataReWindDetails = async (id) => {
    console.log("s34");
    this.setState({
      selectedRectifierList: [],
    });

    var res = await getReWindDetails(id);
    console.log(res);
    if (res.success) {
      this.setState({
        REWId: res.data?.id,
        REWEdit: res.data,
        selectedMakeId: res.data?.make?.id,
        makeType: res.data?.make,

        rewWindPotential: res.data?.windPotential,
        rewPerWindTurbineCapacity: res.data?.perWindTurbineCapacity,
        rewWindTurbineCount: res.data?.windTurbineCount,
        rewInstalledLocation: res.data?.installedLocation,
        rewInstalledHeight: res.data?.installedHeight,
        rewEnergyFeed: res.data?.energyFeed,
        rewRmsconnectionInband: res.data?.rmsconnectionInband,
        rewRmsconnectionMode: res.data?.rmsconnectionMode,
        rectifier: res.data.rectifiers,
      });
      if (res.data.rectifiers != null) {
        res.data.rectifiers
          ?.filter((name) => name.delete == false)
          .map((e) => this.state.selectedRectifierList.push(e.id));
      }
      if (res.data?.commissionedDate != null) {
        this.setState({
          rewCommissionedDate: res.data?.commissionedDate,
        });
      } else {
        this.setState({
          rewCommissionedDate: "",
        });
      }
    }
  };
  onDrawerClose = () => {
    console.log("sddsds");
    this.formRef.current.resetFields();
    this.validator.hideMessages();

    this.setState({
      open: false,
      openEdit: false,
      selectedMakeId: null,
      makeType: [],

      rewWindPotential: "",
      rewPerWindTurbineCapacity: "",
      rewWindTurbineCount: "",
      rewInstalledLocation: "",
      rewInstalledHeight: "",
      rewEnergyFeed: "",
      rewRmsconnectionInband: "",
      rewRmsconnectionMode: "",
      rewCommissionedDate: null,
      selectedElement: "",
      rectifier: [],
      selectedRectifierList: [],
    });
  };
  AllDataSubmitUpdate = async () => {
    if (this.validator.allValid()) {
      var dataSet;
      dataSet = {
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        id: this.state.REWId,

        make: this.state.makeType,
        windPotential: this.state.rewWindPotential,
        perWindTurbineCapacity: this.state.rewPerWindTurbineCapacity,
        windTurbineCount: this.state.rewWindTurbineCount,
        installedLocation: this.state.rewInstalledLocation,
        installedHeight: this.state.rewInstalledHeight,
        commissionedDate: this.state.rewCommissionedDate,
        energyFeed: this.state.rewEnergyFeed,
        rmsconnectionInband: this.state.rewRmsconnectionInband,
        rmsconnectionMode: this.state.rewRmsconnectionMode,
        rectifiers: this.state.rectifier,
      };
      console.log(dataSet);
      var res = await UpdateReWind(dataSet);
      console.log(res);
      if (res.success) {
        message.success("Successfully Renewable Energy Wind Edited!");
        this.formRef.current.resetFields();
        this.setState(
          {
            showModal: false,
            open: false,
            openEdit: false,
            selectedMakeId: null,
            makeType: [],

            rewWindPotential: "",
            rewPerWindTurbineCapacity: "",
            rewWindTurbineCount: "",
            rewInstalledLocation: "",
            rewInstalledHeight: "",
            rewEnergyFeed: "",
            rewRmsconnectionInband: "",
            rewRmsconnectionMode: "",
            rewCommissionedDate: null,
            rectifier: [],
            selectedRectifierList: [],
          },
          () => {
            console.log("selectedRectifierList");
            console.log(this.state.selectedRectifierList);
          }
        );
        this.props.parentMethod();
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      console.log(this.validator);
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  AllDataSubmit = async () => {
    if (this.validator.allValid()) {
      var dataSet;
      dataSet = {
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        make: this.state.makeType,
        windPotential: this.state.rewWindPotential,
        perWindTurbineCapacity: this.state.rewPerWindTurbineCapacity,
        windTurbineCount: this.state.rewWindTurbineCount,
        installedLocation: this.state.rewInstalledLocation,
        installedHeight: this.state.rewInstalledHeight,
        commissionedDate: this.state.rewCommissionedDate,
        energyFeed: this.state.rewEnergyFeed,
        rmsconnectionInband: this.state.rewRmsconnectionInband,
        rmsconnectionMode: this.state.rewRmsconnectionMode,
        rectifiers: this.state.rectifier,
      };
      console.log(dataSet);
      var res = await createNewReWind(dataSet);
      console.log(res);
      if (res.success) {
        message.success("Successfully Renewable Energy Wind Added!");
        this.setState({
          showModal: false,
          open: false,

          selectedMakeId: null,
          makeType: [],

          rewWindPotential: "",
          rewPerWindTurbineCapacity: "",
          rewWindTurbineCount: "",
          rewInstalledLocation: "",
          rewInstalledHeight: "",
          rewEnergyFeed: "",
          rewRmsconnectionInband: "",
          rewRmsconnectionMode: "",
          rewCommissionedDate: null,
          rectifier: [],
          selectedRectifierList: [],
        });
        this.props.parentMethod();
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      console.log(this.validator);
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
      const { history } = this.props;
      history.push("/");
    } else {
      this.getSetPageData(
        JSON.parse(localStorage.getItem("serviceTypeSet")),
        38
      );
    }

    checkServiceType(38).then((data) =>
      this.setState(
        {
          AssetManagementPermission: data,
        },
        () => { }
      )
    );
    this.setState({
      objectSet: {
        resExpectedEnergyGenerationFromWind:
          this.props?.details.access_technologies_data
            ?.resExpectedEnergyGenerationFromWind,
        resAverageEnergyGenerationFromWind:
          this.props?.details.access_technologies_data
            ?.resAverageEnergyGenerationFromWind,
        rewWindPotential:
          this.props?.details.access_technologies_data?.rewWindPotential,
      },
    });
  }

  getSetPageData = (serviceTypeIs, Pageid) => {
    if (
      serviceTypeIs.serviceType.filter(
        (checkuser) => checkuser?.id == Pageid
      )[0] != null
    ) {
      console.log("fddf");
      this.setState({
        actionView: true,
      });
    }
  };
  onChangeMake = (value) => {
    console.log("onChangeMake");
    //console.log(value);
    this.setState({
      selectedMakeId: value,
      makeType: {
        id: value,
      },
    });
  };

  viewMoreDetails(element) {
    this.setState({
      showModal2: true,
      openViewMoreDetails: true,
      selectedElement: element,
    });
  }

  onViewMoreDetailsDrawerClose = () => {
    this.setState({
      showModal2: false,
      openViewMoreDetails: false,
    });
  };

  validatedTextLable = (field) => {
    return field !== null && field !== "" ? (
      <span class="badge badge-outline-secondary text-muted">{field}</span>
    ) : (
      <span class="badge text-danger">Currently Not Set</span>
    );
  };
  submitForm = async (item) => {
    this.getDataDetailsForDelete(item);
  };
  getDataDetailsForDelete = async (id) => {
    var res = await getReWindDetails(id);
    console.log(res);
    if (res.success) {
      var respons = AssetDelete.Action(
        res.data,
        this.props?.detailsSite?.site_details.id,
        UpdateReWind
      );

      respons.then((value) => {
        if (value == 1) {
          message.success("Successfully Delete!");
          this.props.parentMethod();
        } else {
          message.warning(" Something went wrong!");
        }
      });
    }
  };
  handlresresAverageEnergyGenerationFromWind = (e) => {
    this.setState(
      {
        objectSet: {
          ...this.state.objectSet,
          resAverageEnergyGenerationFromWind: e,
        },
      },
      () => {
        console.log(this.state.objectSet);
      }
    );
  };

  handlresresExpectedEnergyGenerationFromWind = (e) => {
    this.setState(
      {
        objectSet: {
          ...this.state.objectSet,
          resExpectedEnergyGenerationFromWind: e,
        },
      },
      () => {
        console.log(this.state.objectSet);
      }
    );
  };

  dataUpdate() {
    console.log("ssss");
    this.setState({
      switchStatus: false,
    });
  }
  close = async () => {
    this.props.parentMethod();
    this.setState({
      objectSet: {
        resExpectedEnergyGenerationFromWind:
          this.props?.details.access_technologies_data
            ?.resExpectedEnergyGenerationFromWind,
        resAverageEnergyGenerationFromWind:
          this.props?.details.access_technologies_data
            ?.resAverageEnergyGenerationFromWind,
        windPotential:
          this.props?.details.access_technologies_data?.windPotential,
      },
    });
    this.setState({
      switchStatus: true,
    });
  };
  dataSetToUpdate = async () => {
    console.log(this.state.objectSet);
    console.log(this.props.detailsupdateGeneralDetails.updateGeneralDetails);
    let remoteJob = {
      ...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
      ...this.state.objectSet,
    };
    const formData = new FormData();
    formData.append("id", this.props?.details?.access_technologies_data?.id);
    formData.append("object", JSON.stringify(remoteJob));

    var res = await dataUpdateGeneralDetails(formData);

    if (res.success) {
      message.success("Successfully Update!");
      this.props.parentMethod();
      this.setState({
        switchStatus: true,
      });
    } else {
      message.warning(" Something went wrong!");
    }
  };
  render() {
    const { Option } = Select;
    const { Panel } = Collapse;
    const _rew = this.state.selectedElement;

    const columns2 = [
      {
        title: <span className="textStyles-small">Wind Turbine Capacity of a single unit (kW)</span>,
        details: [],
        content: this.validatedTextLable(_rew.perWindTurbineCapacity),
      },
      {
        title: <span className="textStyles-small">Wind Turbine Count</span>,
        details: [],
        content: this.validatedTextLable(_rew.windTurbineCount),
      },
      {
        title: <span className="textStyles-small">Installed Location</span>,
        details: [],
        content: this.validatedTextLable(_rew.installedLocation),
      },
      {
        title: <span className="textStyles-small">Installed Height (m)</span>,
        details: [],
        content: this.validatedTextLable(_rew.installedHeight),
      },
      {
        title: <span className="textStyles-small">Commissioned Date</span>,
        details: [],
        content: this.validatedTextLable(_rew.commissionedDate),
      },
      {
        title: <span className="textStyles-small">Make</span>,
        details: [],
        content:
          _rew !== "" ? (
            <Tag color="gray" style={{ margin: "0px" }}>
              <span>{_rew.make?.name}</span>
            </Tag>
          ) : (
            <></>
          ),
      },
      {
        title: <span className="textStyles-small">RMS connection Inband/Outband</span>,
        details: [],
        content: this.validatedTextLable(_rew.rmsconnectionInband),
      },
      {
        title: <span className="textStyles-small">RMS Connection Mode</span>,
        details: [],
        content: this.validatedTextLable(_rew.rmsconnectionMode),
      },
      {
        title: <span className="textStyles-small">Energy feed onto the DC/AC bus</span>,
        details: [],
        content: this.validatedTextLable(_rew.energyFeed),
      },
      // {
      //     title: <span className="textStyles-small">Wind Potential</span>,
      //     details: [],
      //     content: this.validatedTextLable(_rew.windPotential),
      // },
      {
        title: <span className="textStyles-small">Rectifiers</span>,
        details: [],
        content:
          _rew !== "" ? (
            _rew.rectifiers.filter((name) => name.delete == false).length !=
              0 ? (
              <div class="d-flex justify-content-end align-items-center">
                {_rew.rectifiers
                  .filter((name) => name.delete == false)
                  .map((e) => (
                    <span
                      class="badge badge-soft-pink fw-semibold"
                      style={{ marginLeft: "5px" }}
                    >
                      {e.name}
                    </span>
                  ))}
              </div>
            ) : (
              <span class="badge badge-outline-secondary text-muted">No</span>
            )
          ) : (
            <></>
          ),
      },
    ];

    return (
      <Fragment>
        <div className="row">
          <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
            <div className="d-flex justify-content-end align-items-center" style={{ marginBottom: "10px", marginRight: "10px" }}>
              {this.state.switchStatus ? (
                checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                <Fragment>
                  <button type="button" class="btn btn-soft-primary btn-icon-square-sm"
                    style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                    onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline" style={{ color: 'white' }}></i></button>
                  {this.state.actionView ? (
                    <Button
                      type="button"
                      class="btn btn-soft-primary btn-sm"
                      style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginLeft: 10 }}
                      onClick={this.requestFromClose}
                      icon={<PlusCircleOutlined style={{ color: 'white' }} />}>
                      <span className="textStyles-small" style={{ color: 'white' }}>New Asset</span>
                    </Button>
                  ) : (
                    <></>
                  )}
                </Fragment>) : ('')
              ) : (
                <div className="d-flex justify-content-end align-items-center">
                  <button
                    type="button"
                    className="btn btn-soft-secondary"
                    onClick={() => this.close()}
                    style={{ marginRight: "10px" }}
                  >
                    <span className="textStyles-small">Cancel</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-soft-primary"
                    onClick={() => this.dataSetToUpdate()}
                  >
                    <span className="textStyles-small">Submit to Update</span>
                  </button>
                </div>
              )}
            </div>
            <ul class="list-group">
              <Row span={24}>
                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        <span className="textStyles-small">
                          Wind Potential</span>
                      </div>
                      {this.state.switchStatus ? (
                        <>
                          {this.props.details.access_technologies_data
                            ?.windPotential != null ? (
                            <span className="badge badge-outline-success textStyles-small">
                              {
                                this.props?.details.access_technologies_data
                                  ?.windPotential
                              }
                            </span>
                          ) : (
                            <span className="badge badge-outline-warning textStyles-small">
                              Currently Not Set
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          <Select
                            style={{ width: '150px' }}
                            bordered={false}
                            className='borderedSelect'
                            value={this.state.objectSet?.windPotential}
                            onChange={(selectedValue) => {
                              this.setState(
                                (prevState) => ({
                                  objectSet: {
                                    ...prevState.objectSet,
                                    windPotential: selectedValue,
                                  },
                                }),
                                () => {
                                  console.log(this.state.objectSet);
                                }
                              );
                            }}
                          >
                            {this.props?.windDropdown["dropdownObjWind"][
                              "rewPotential"
                            ]?.map((x, y) => (
                              <Option key={y} value={x}>
                                {x}
                              </Option>
                            ))}
                          </Select>
                        </>
                      )}
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                      <div>
                        <span className="textStyles-small">
                          Expected energy generation from wind (kWh / day)
                        </span>
                      </div>
                      {this.state.switchStatus ? (
                        <>
                          {this.props?.details.access_technologies_data
                            ?.resExpectedEnergyGenerationFromWind !=
                            null ? (
                            <span class="badge badge-outline-success textStyles-small">
                              {
                                this.props?.details.access_technologies_data
                                  ?.resExpectedEnergyGenerationFromWind
                              }
                            </span>
                          ) : (
                            <span class="badge badge-outline-warning textStyles-small">
                              Currently Not Set
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <InputNumber
                            min={0}
                            style={{ width: '150px' }}
                            className='borderedSelect'
                            value={
                              this.state.objectSet
                                ?.resExpectedEnergyGenerationFromWind
                            }
                            id="airCoolingMethod"
                            onChange={
                              this
                                .handlresresExpectedEnergyGenerationFromWind
                            }
                          />{" "}
                        </>
                      )}
                    </li>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ width: '98%' }}>
                    <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                      <div>
                        <span className="textStyles-small">
                          Average energy generation from wind (kWh / day)</span>
                      </div>
                      {this.state.switchStatus ? (
                        <>
                          {this.props?.details.access_technologies_data
                            ?.resAverageEnergyGenerationFromWind != null ? (
                            <span class="badge badge-outline-success textStyles-small">
                              {
                                this.props?.details.access_technologies_data
                                  ?.resAverageEnergyGenerationFromWind
                              }
                            </span>
                          ) : (
                            <span class="badge badge-outline-warning textStyles-small">
                              Currently Not Set
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <InputNumber
                            min={0}
                            style={{ width: '150px' }}
                            className='borderedSelect'
                            value={
                              this.state.objectSet
                                ?.resAverageEnergyGenerationFromWind
                            }
                            id="airCoolingMethod"
                            onChange={
                              this
                                .handlresresAverageEnergyGenerationFromWind
                            }
                          />{" "}
                        </>
                      )}
                    </li>
                  </div>
                </Col>
              </Row>
            </ul>
          </div>

          <Row span={24} style={{ marginTop: '20px' }}>
            {
              this.props.details2.renewableEnergyWind_data.map((element, index) => (
                <Col span={8} key={index}>
                  <Card
                    bordered={false}
                    style={{
                      width: '98%',
                      borderRadius: '10px',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      position: 'relative'
                    }}
                  >
                    <div style={{ display: 'flex', marginBottom: 16, backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '10px' }}>
                      <div>
                        <HomeOutlined style={{ marginLeft: '3px', marginRight: '3px' }} /> &rarr;
                        <span style={{ marginLeft: '3px', marginRight: '3px', fontWeight: 'bold' }} className="textStyles-small">{element.rectifiers != null &&
                          element.rectifiers.length > 0
                          ? element.rectifiers[0].name +
                          (element.rectifiers.filter(
                            (name) => name.delete == false
                          ).length > 1
                            ? " ..."
                            : "")
                          : ""}</span>
                        &rarr;
                        <span style={{ marginLeft: '3px', color: '#BA183A', fontWeight: 'bold' }} className="textStyles-small">{element.make?.name}</span>
                      </div>
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <span className="textStyles-small">Make</span>
                      <Tag color="default" style={{ float: 'right', borderRadius: 5 }}> <span className="textStyles-small">{element.make?.name}</span></Tag>
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <span className="textStyles-small">Wind Turbine Count</span>
                      <Tag color="magenta" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                        <span className="textStyles-small">
                          {element.windTurbineCount}
                        </span></Tag>
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <span className="textStyles-small">Installed</span>
                      <Tag color="green" style={{ float: 'right', borderRadius: 5, fontSize: 10 }}><span className="textStyles-small">
                        {element.installedLocation}{" "}
                      </span></Tag>
                      <Tag color="orange" style={{ float: 'right', borderRadius: 5, fontSize: 10 }}><span className="textStyles-small">
                        <i class="far fa-fw ti-ruler"></i>{" "}
                        {element.installedHeight} m Height
                      </span>
                      </Tag>
                    </div>
                    <Divider style={{ marginTop: '3px' }} />
                    <Button
                      type="primary"
                      style={{ marginRight: 8, borderRadius: '5px' }}
                      size='small'
                      onClick={() => this.viewMoreDetails(element)}>
                      <span className="textStyles-small" style={{ fontSize: 10 }}>More Details</span>
                    </Button>
                    {checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                    <Button
                      icon={<EditOutlined style={{ color: 'white' }} />}
                      style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                      onClick={() => this.RewUpdata(element.id)}
                      size='small'
                    /> ) : ('')}
                    <Popconfirm
                      title={<span className='textStyles-small' style={{ fontSize: 14 }}>Are you sure to delete this Asset?</span>}
                      description={<span className="textStyles-small">Are you sure to delete this Asset?</span>}
                      onConfirm={() => this.submitForm(element.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      { checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                          <Button
                            icon={<DeleteOutlined style={{ color: 'white' }} />}
                            danger
                            size='small'
                            style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#BA183A', borderColor: '#BA183A' }}
                          />
                        )
                        : ('')}
                    </Popconfirm>

                  </Card>
                </Col>
              ))
            }
          </Row>

          <Drawer
            maskClosable={false}
            title={<span className="textStyles-small" style={{ fontSize: "14px" }}>Renewable Energy Wind Info</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onViewMoreDetailsDrawerClose()}
            visible={this.state.openViewMoreDetails}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <Collapse
              style={{ alignContent: "center", borderColor: "white" }}
            >
              {columns2.map((item, index) => (
                <Panel
                  style={{
                    borderColor: index % 2 === 0 ? "#f8f9fa" : "white",
                    backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white"
                  }}
                  header={
                    <li className="d-flex justify-content-between align-items-center"
                      style={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white" }}>
                      <div>
                        <span className="fw-normal m-2">{item.title}</span>
                      </div>
                      <span className="textStyles-small">{item.content}</span>
                    </li>
                  }
                  key={item.title}
                  showArrow={item.details.length > 0}
                  collapsible={item.details.length > 0 ? "" : "icon"}
                >
                  {item.details.map((detail) => (
                    <li
                      className="d-flex justify-content-between align-items-center me-2"
                      style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                      key={detail.title}
                    >
                      <div>
                        <span>{detail.title}</span>
                      </div>
                      <span className="textStyles-small">{detail.content}</span>
                    </li>
                  ))}
                </Panel>
              ))}
            </Collapse>
          </Drawer>
        </div>

        <Drawer
          maskClosable={false}
          title={<span className='textStyles-small' style={{ fontSize: '14px' }}>New Renewable Energy Wind</span>}
          width={"60%"}
          placement="right"
          onClose={() => this.onDrawerClose()}
          visible={this.state.open}
          closeIcon={
            <button
              type="button"
              class="btn btn-soft-danger btn-icon-square-sm"
              style={{ marginRight: "8px" }}
            >
              <i class="mdi dripicons-cross"></i>
            </button>
          }
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="card" style={{ borderColor: 'white' }}>
                <div className="card-body">
                  <Form ref={this.formRef}>
                    <div className="row">
                      <div className="col-md-6">
                        <label
                          className="form-label"
                          for="rewPerWindTurbineCapacity"
                        ><span className='textStyles-small'>
                            Wind Turbine Capacity of a single unit (kW)
                            <x style={{ color: "red" }}>*</x></span>
                        </label>
                        <Form.Item
                          required
                          name="perWindTurbineCapacity"
                          tooltip="This is a required field"
                        >
                          <InputNumber
                            style={{ width: "100%", height: 34 }}
                            className='borderedSelect'
                            id="perWindTurbineCapacity"
                            onChange={this.handleperWindTurbineCapacity}
                          />
                          <p className='textStyles-small' style={{ color: "#f5325c", marginTop: 5 }}>
                            {this.validator.message(
                              "Wind Turbine Capacity of a single unit (kW)",
                              this.state.rewPerWindTurbineCapacity,
                              "required"
                            )}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" for="depot">
                          <span className='textStyles-small'>
                            Wind Turbine Count <x style={{ color: "red" }}>*</x>
                          </span>
                        </label>
                        <Form.Item>
                          <div>
                            <Select
                              className='borderedSelect'
                              bordered={false}
                              style={{ width: "100%" }}
                              id="windTurbineCount"
                              onChange={(e) => {
                                this.handleWindTurbineCount(e);
                              }}
                            >
                              {this.props.windDropdown["dropdownObjWind"][
                                "rewWindTurbineCount"
                              ]?.map((x) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          </div>
                          <p className='textStyles-small' style={{ color: "#f5325c", marginTop: 5 }}>
                            {this.validator.message(
                              "Wind Turbine Count",
                              this.state.rewWindTurbineCount,
                              "required"
                            )}
                          </p>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" for="dcdu"><span className='textStyles-small'>
                          Installed Location <x style={{ color: "red" }}>*</x></span>
                        </label>
                        <Form.Item
                          required
                          name="installedLocation"
                          tooltip="This is a required field"
                        >
                          <div>
                            <Select
                              className='borderedSelect'
                              bordered={false}
                              style={{ width: "100%" }}
                              id="installedLocation"
                              onChange={(e) => {
                                this.handleInstalledLocation(e);
                              }}
                            >
                              {this.props.windDropdown["dropdownObjWind"][
                                "rewInstalledLocation"
                              ]?.map((x) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          </div>
                          <p className='textStyles-small' style={{ color: "#f5325c", marginTop: 5 }}>
                            {this.validator.message(
                              "Installed Location",
                              this.state.rewInstalledLocation,
                              "required"
                            )}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" for="dcdu"><span className='textStyles-small'>
                          Installed Height (m) <x style={{ color: "red" }}>*</x></span>
                        </label>
                        <Form.Item
                          required
                          name="installedHeight"
                          tooltip="This is a required field"
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            className='borderedSelect'
                            id="installedHeight"
                            onChange={this.handleInstalledHeight}
                          />
                          <p className='textStyles-small' style={{ color: "#f5325c", marginTop: 5 }}>
                            {this.validator.message(
                              " Installed Height",
                              this.state.rewInstalledHeight,
                              "required"
                            )}
                          </p>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" for="depot">
                          <span className='textStyles-small'>
                            Commissioned Date</span>
                        </label>
                        <Form.Item
                          required
                          name="commissionedDate"
                          tooltip="This is a required field"
                        >
                          <DatePicker
                            style={{ width: "100%", height: 34 }}
                            className='borderedSelect'
                            onChange={this.onChangeCommissionedDate}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" for="depot">
                          <span className='textStyles-small'>
                            Make <x style={{ color: "red" }}>*</x>
                          </span>
                        </label>

                        <Form.Item
                          required
                          name="Is_active"
                          tooltip="This is a required field"
                        >
                          <Select
                            showSearch
                            onChange={this.onChangeMake}
                            style={{ width: "100%" }}
                            className='borderedSelect'
                            bordered={false}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {this.props.rewMasterData?.rewMasterData?.rewMakelList?.map(
                              (item, index) => {
                                return (
                                  <Option value={item?.id}>{item?.name}</Option>
                                );
                              }
                            )}
                          </Select>

                          <p className='textStyles-small' style={{ color: "#f5325c", marginTop: 5 }}>
                            {this.validator.message(
                              "Make",
                              this.state.selectedMakeId,
                              "required"
                            )}
                          </p>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" for="dcdu">
                          <span className='textStyles-small'>
                            RMS connection Inband/Outband
                          </span>
                        </label>
                        <Form.Item
                          required
                          name="rmsconnectionInband"
                          tooltip="This is a required field"
                        >
                          <div>
                            <Select
                              style={{ width: "100%" }}
                              className='borderedSelect'
                              bordered={false}
                              id="rmsconnectionInband"
                              onChange={(e) => {
                                this.handleRmsconnectionInband(e);
                              }}
                            >
                              {this.props.windDropdown["dropdownObjWind"][
                                "rewRmsconnectionInband"
                              ]?.map((x) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          </div>
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" for="dcdu">
                          <span className='textStyles-small'>
                            RMS Connection Mode
                          </span>
                        </label>
                        <Form.Item
                          required
                          name="rmsconnectionMode"
                          tooltip="This is a required field"
                        >
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            className='borderedSelect'
                            bordered={false}
                            id="rmsconnectionMode"
                            onChange={(e) => {
                              this.handleRmsconnectionMode(e);
                            }}
                          >
                            {this.props?.windDropdown["dropdownObjWind"][
                              "rewRmsconnectionMode"
                            ]?.map((x) => {
                              return <Option value={x}>{x}</Option>;
                            })}
                          </Select>
                        </Form.Item>
                      </div>

                      <div className="col-md-6">
                        <label className="form-label" for="dcdu">
                          <span className='textStyles-small'>
                            Energy feed onto the DC/AC bus{" "}
                          </span>
                          <x style={{ color: "red" }}>*</x>
                        </label>
                        <Form.Item
                          required
                          name="energyFeed"
                          tooltip="This is a required field"
                        >
                          <div>
                            <Select
                              style={{ width: "100%" }}
                              className='borderedSelect'
                              bordered={false}
                              id="energyFeed"
                              onChange={(e) => {
                                this.handleEnergyFeed(e);
                              }}
                            >
                              {this.props.windDropdown["dropdownObjWind"][
                                "rewEnergyFeed"
                              ]?.map((x) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          </div>
                          <p className='textStyles-small' style={{ color: "#f5325c", marginTop: 5 }}>
                            {this.validator.message(
                              "Energy feed onto the DC/AC bus",
                              this.state.rewEnergyFeed,
                              "required"
                            )}
                          </p>
                        </Form.Item>
                      </div>

                      <div className="col-md-6">
                        <label className="form-label" for="depot">
                          <span className='textStyles-small'>
                            Rectifiers <x style={{ color: "red" }}>*</x>
                          </span>
                        </label>
                        <Form.Item
                          required
                          name="Is_active"
                          tooltip="This is a required field"
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            onChange={this.onChangeRectifier}
                            style={{ width: "100%" }}
                            className='borderedSelect'
                            bordered={false}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {this.props.rewMasterData?.rewMasterData?.rectifierList?.map(
                              (item, index) => {
                                return (
                                  <Option value={item?.id}>{item?.name}</Option>
                                );
                              }
                            )}
                          </Select>
                          <p style={{ color: "#f5325c" }}>
                            {this.validator.message(
                              "Rectifiers",
                              this.state.rectifier,
                              "required"
                            )}
                          </p>
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                  <div className="row" style={{ justifyContent: "end" }}>
                    <div className="col-md-2">
                      <Button
                        style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginRight: '5px' }}
                        className="btn btn-primary"
                        onClick={() => this.AllDataSubmit()}
                      >
                        <span className="textStyles-small" style={{ color: 'white' }}>Add This Asset</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>

        <Drawer
          maskClosable={false}
          title={<span className="textStyles-small" style={{ fontSize: "14px" }}>Edit Renewable Energy Wind</span>}
          width={"60%"}
          placement="right"
          onClose={() => this.onDrawerClose()}
          visible={this.state.openEdit}
          closeIcon={
            <button
              type="button"
              class="btn btn-soft-danger btn-icon-square-sm"
              style={{ marginRight: "8px" }}
            >
              <i class="mdi dripicons-cross"></i>
            </button>
          }
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="card" style={{ borderColor: 'white' }}>
                <div className="card-body">
                  <Form ref={this.formRef}>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" for="dcdu">
                          <span className='textStyles-small'>
                            Wind Turbine Capacity Of a Single Unit (kW){" "}
                          </span>
                          <x style={{ color: "red" }}>*</x>
                        </label>
                        <Form.Item>
                          <Input
                            className='borderedSelect'
                            style={{ height: 34 }}
                            id="perWindTurbineCapacity"
                            value={this.state.rewPerWindTurbineCapacity}
                            onChange={this.handleperWindTurbineCapacity}
                          />
                          <p
                            className="textStyles-small"
                            style={{ color: "#f5325c", marginTop: 5 }}>
                            {this.validator.message(
                              "Wind Turbine Capacity of a single unit (kW)",
                              this.state.rewPerWindTurbineCapacity,
                              "required"
                            )}
                          </p>
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" for="depot">
                          <span className='textStyles-small'>
                            Wind Turbine Count <x style={{ color: "red" }}>*</x>
                          </span>
                        </label>
                        <Form.Item>
                          <div>
                            <Select
                              className='borderedSelect'
                              bordered={false}
                              style={{ width: "100%" }}
                              value={this.state.rewWindTurbineCount}
                              id="windTurbineCount"
                              onChange={(e) => {
                                this.handleWindTurbineCount(e);
                              }}
                            >
                              {this.props.windDropdown["dropdownObjWind"][
                                "rewWindTurbineCount"
                              ]?.map((x) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          </div>
                          <p
                            className="textStyles-small" style={{ color: "#f5325c", marginTop: 5 }}>
                            {this.validator.message(
                              "Wind Turbine Count",
                              this.state.rewWindTurbineCount,
                              "required"
                            )}
                          </p>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" for="dcdu">
                          <span className='textStyles-small'>
                            Installed Location <x style={{ color: "red" }}>*</x>
                          </span>
                        </label>
                        <Form.Item>
                          <div>
                            <Select
                              className='borderedSelect'
                              bordered={false}
                              value={this.state.rewInstalledLocation}
                              style={{ width: "100%" }}
                              id="installedLocation"
                              onChange={(e) => {
                                this.handleInstalledLocation(e);
                              }}
                            >
                              {this.props.windDropdown["dropdownObjWind"][
                                "rewInstalledLocation"
                              ]?.map((x) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          </div>
                          <p
                            className="textStyles-small"
                            style={{ color: "#f5325c", marginTop: 5 }}>
                            {this.validator.message(
                              "Installed Location",
                              this.state.rewInstalledLocation,
                              "required"
                            )}
                          </p>
                        </Form.Item>
                      </div>

                      <div className="col-md-6">
                        <label className="form-label" for="dcdu">
                          <span className='textStyles-small'>
                            Installed Height (m) <x style={{ color: "red" }}>*</x>
                          </span>
                        </label>
                        <Form.Item>
                          <InputNumber
                            className='borderedSelect'
                            style={{ width: "100%", height: 34 }}
                            id="installedHeight"
                            value={this.state.rewInstalledHeight}
                            onChange={this.handleInstalledHeight}
                          />
                          <p
                            className="textStyles-small"
                            style={{ color: "#f5325c", marginTop: 5 }}>
                            {this.validator.message(
                              " Installed Height",
                              this.state.rewInstalledHeight,
                              "required"
                            )}
                          </p>
                        </Form.Item>
                      </div>

                      {this.state.rewCommissionedDate != null ? (
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className='textStyles-small'>
                              Commissioned Date
                            </span>
                          </label>
                          <Form.Item>
                            <DatePicker
                              defaultValue={
                                this.state.rewCommissionedDate != ""
                                  ? moment(this.state.rewCommissionedDate)
                                  : ""
                              }
                              className='borderedSelect'
                              style={{ width: "100%", height: 34 }}
                              onChange={this.onChangeCommissionedDate}
                            />
                          </Form.Item>
                        </div>
                      ) : (<></>)}

                      {this.state.selectedMakeId != null ? (
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className='textStyles-small'>
                              Make <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>

                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              onChange={this.onChangeMake}
                              defaultValue={this.state.selectedMakeId}
                              className='borderedSelect'
                              bordered={false}
                              style={{ width: "100%" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.rewMasterData?.rewMasterData?.rewMakelList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item?.id}>
                                      {item?.name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                            <p
                              className="textStyles-small"
                              style={{ color: "#f5325c", marginTop: 5 }}>
                              {this.validator.message(
                                "Make",
                                this.state.selectedMakeId,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="col-md-6">
                        <label className="form-label" for="dcdu">
                          <span className='textStyles-small'>
                            RMS connection Inband/Outband
                          </span>
                        </label>
                        <Form.Item>
                          <div>
                            <Select
                              className='borderedSelect'
                              bordered={false}
                              style={{ width: "100%" }}
                              value={this.state.rewRmsconnectionInband}
                              id="rmsconnectionInband"
                              onChange={(e) => {
                                this.handleRmsconnectionInband(e);
                              }}
                            >
                              {this.props.windDropdown["dropdownObjWind"][
                                "rewRmsconnectionInband"
                              ]?.map((x) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          </div>
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" for="dcdu">
                          <span className='textStyles-small'>
                            RMS Connection Mode
                          </span>
                        </label>
                        <Form.Item>
                          <Select
                            showSearch
                            className='borderedSelect'
                            bordered={false}
                            style={{ width: "100%" }}
                            value={this.state.rewRmsconnectionMode}
                            id="rmsconnectionMode"
                            onChange={(e) => {
                              this.handleRmsconnectionMode(e);
                            }}
                          >
                            {this.props?.windDropdown["dropdownObjWind"][
                              "rewRmsconnectionMode"
                            ]?.map((x) => {
                              return <Option value={x}>{x}</Option>;
                            })}
                          </Select>
                        </Form.Item>
                      </div>

                      <div className="col-md-6">
                        <label className="form-label" for="dcdu">
                          <span className='textStyles-small'>
                            Energy feed onto the DC/AC bus{" "}
                            <x style={{ color: "red" }}>*</x>
                          </span>
                        </label>
                        <Form.Item>
                          <div>
                            <Select
                              style={{ width: "100%" }}
                              className='borderedSelect'
                              bordered={false}
                              value={this.state.rewEnergyFeed}
                              id="energyFeed"
                              onChange={(e) => {
                                this.handleEnergyFeed(e);
                              }}
                            >
                              {this.props.windDropdown["dropdownObjWind"][
                                "rewEnergyFeed"
                              ]?.map((x) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          </div>
                          <p
                            className="textStyles-small"
                            style={{ color: "#f5325c", marginTop: 5 }}>
                            {this.validator.message(
                              "Energy feed onto the DC/AC bus",
                              this.state.rewEnergyFeed,
                              "required"
                            )}
                          </p>
                        </Form.Item>
                      </div>

                      <div className="col-md-6">
                        <label className="form-label" for="depot">
                          <span className='textStyles-small'>
                            Rectifiers <x style={{ color: "red" }}>*</x>
                          </span>
                        </label>
                        {this.state.selectedRectifierList.length > 0 ? (
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              mode="multiple"
                              className='borderedSelect'
                              bordered={false}
                              showSearch
                              defaultValue={this.state.selectedRectifierList}
                              onChange={this.onChangeRectifier}
                              style={{ width: "100%" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.rewMasterData?.rewMasterData?.rectifierList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item?.id}>
                                      {item?.name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                            <p
                              className="textStyles-small"
                              style={{ color: "#f5325c", marginTop: 5 }}>
                              {this.validator.message(
                                "Rectifiers",
                                this.state.rectifier,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        ) : (
                          ""
                        )}
                        {this.state.selectedRectifierList.length == 0 ? (
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              mode="multiple"
                              className='borderedSelect'
                              bordered={false}
                              showSearch
                              onChange={this.onChangeRectifier}
                              style={{ width: "100%" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.rewMasterData?.rewMasterData?.rectifierList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item?.id}>
                                      {item?.name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "Rectifiers",
                                this.state.rectifier,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Form>
                  <div className="row" style={{ justifyContent: "end" }}>
                    <div className="col-md-2">
                      <Button
                        className="btn btn-primary"
                        onClick={() => this.AllDataSubmitUpdate()}
                        style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginRight: '5px' }}
                      >
                        <span className="textStyles-small" style={{ color: 'white' }}>Update This Asset</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </Fragment>
    );
  }
}
export default Rew;
